<template>
  <div class="date-picker-box" >
    <div class="date-input-box">
      <input type="text" class="date-input" @focus="inputFocus" maxlength="0" :placeholder="tip" v-model="date.text">
      <i class="el-icon-time"></i>
    </div>
    <div class="date-picker" :class="onfocus?'active':''">
      <div class="header">
        <span class="header-moment-text" @click="prev" :class="prevMoment.val?'':'disabled'">
          <i class="el-icon-arrow-left"></i>
          {{ prevMoment.val ?? currentMoment.prevVal }}
        </span>
        <span class="header-moment-text" :class="currentMoment.val?'':'disabled'">
          {{ currentMoment.val }}
        </span>
        <span class="header-moment-text" @click="next" :class="nextMoment.val?'':'disabled'">
          {{ nextMoment.val ?? this.currentMoment.nextVal }}
          <i class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div class="body" :style="`grid-template-columns:repeat(${rowLength},1fr);`">
        <span class="body-moment-text" v-for="(val,index) in this.currentMoment.info" @click="selectValue(val)">
          {{ fillZero(val) }}
        </span>
      </div>
      <div class="footer">
        <span>{{ tip }}</span>

        <span>单位：{{ unit }}</span>
      </div>
      <div class="arrow"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MomentPicker",
  props: {
    moment: {
      type: Array,
      default: function () {
        return [{
          index: 0,
          val: '昨天',
          info: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
          prevVal: '前天'
        }, {
          index: 1,
          val: '今天',
          info: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
          nextVal: '明天'
        }];
      }
    },
    defaultMomentIndex: {
      type: Number,
      default: 1
    },
    unit: {
      type: String,
      default: '时'
    },
    tip: {
      type: String,
      default: '请选择起始小时'
    },
    startMoment: {
      type: Object,
      default: function () {
        return {
          val: '昨天',
          info: 0,
        };
      }
    },
    endMoment: {
      type: Object,
      default: function () {
        return {
          val: '今天',
          info: 23
        };
      }
    },
    tools: {
      type: Object,
      default: function () {
        return {};
      }
    },
    date: {
      type: Object,
      default: function () {
        return {
          text: '',
          val: ''
        }
      }
    }
  },
  data() {
    return {
      currentMomentIndex: 1,
      onfocus: false,
    }
  },
  computed: {
    prevMoment() {
      return this.moment.find((e) => {
        return e.index === (this.currentMomentIndex - 1);
      }) ?? {};
    },
    currentMoment() {
      return this.moment.find((e) => {
        return e.index === this.currentMomentIndex;
      }) ?? {};
    },
    nextMoment() {
      return this.moment.find((e) => {
        return e.index === (this.currentMomentIndex + 1);
      }) ?? {};
    },
    rowLength() {
      return Math.ceil(this.currentMoment.info.length / 4);
    }
  },
  watch: {
    defaultMomentIndex(newVal, oldVal) {
      this.currentMomentIndex = newVal
    },
    currentMomentIndex(newVal, oldVal) {

    }
  },
  created() {
    this.initEvent();
  },
  methods: {
    prev() {
      this.currentMomentIndex--;
    },
    next() {
      this.currentMomentIndex++;
    },
    fillZero(num, length = 2) {
      return (Array(length).join("0") + Math.abs(num)).slice(-length);
    },
    inputFocus(e) {
      this.onfocus = true;
    },
    inputBlur() {
      this.onfocus = false;
    },
    initEvent() {

    },
    selectValue(o) {
      this.date.text = `${this.currentMoment.val} ${this.fillZero(o)} ${this.unit}`;
      this.inputBlur();
    }
  }
}
</script>

<style scoped>
i {
  padding: 0 5px;
}

.date-picker-box {
  position: relative;
}

.date-picker-box .date-input-box {
  position: relative;
}

.date-picker-box .date-input {
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: small;
  height: 28px;
  line-height: 28px;
  outline: 0;
  padding: 0 5px 0 28px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 140px;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
}

.date-picker-box .date-input-box i {
  position: absolute;
  left: 4px;
  top: 0;
  line-height: 28px;
}

.date-picker {
  user-select: none;
  color: #606266;
  border: 1px solid #e4e7ed;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  background: #fff;
  border-radius: 4px;
  line-height: 30px;
  margin: 5px 0;
  position: fixed;
  /*left: 0;*/
  /*top: 40px;*/
  transition: .1s all;
  transform: scaleY(0) translateY(-145px);
  opacity: 0;
  transform-origin: top;
  z-index: 2022;
}

.date-picker.active {
  transform: scaleY(1) translateY(-145px);
  opacity: 1;
}

.date-picker .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 12px;
  height: 20px;
}

.date-picker .header > * {
  width: 80px;
  padding: 0 20px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.date-picker .header .header-moment-text:first-child {
  justify-content: start;
}

.date-picker .header .header-moment-text:nth-child(2) {
  justify-content: center;
  cursor: auto;
  pointer-events: none;
}

.date-picker .header .header-moment-text:last-child {
  justify-content: end;
}

.header-moment-text.disabled, .body-moment-text.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .5;
}

.header-moment-text:hover {
  color: #409eff;
}

.date-picker .body {
  margin: 12px;
  height: 160px;
  display: grid;
  border-top: 1px solid #e4e7ed;
  border-bottom: 1px solid #e4e7ed;
}

.date-picker .body .body-moment-text {
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.date-picker .body .body-moment-text:hover {
  cursor: pointer;
  color: #409eff;
}

.date-picker .footer {
  margin: 12px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.date-picker .arrow {
  border-width: 6px;
  filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03));
}

.date-picker .arrow, .date-picker .arrow:after {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: -12px;
  left: 15px;
}

.date-picker .arrow:after {
  content: " ";
  border-width: 6px;
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #fff;
}

</style>