import ToastContainer from "./Toast";

export default {
    install(Vue) {
        const __Toast = new (Vue.extend({
            data() {
                return {
                    toasts: []
                }
            },
            methods: {
                toastAdd(options) {
                    options.key=UUID(),
                    this.toasts.push(options);
                }
            },
            created() {
                this.$on("toast-add", this.toastAdd);
            },
            render: h => h(ToastContainer)
        }))().$mount();

        document.body.appendChild(__Toast.$el);


        Vue.prototype.$toast = function (msg, type = "default") {
            __Toast.$emit("toast-add", {msg, type});
        };

    },

};