import {config} from "../config";
import fa from "element-ui/src/locale/lang/fa";

class Auth {
    constructor() {
        this.updateToken();
        this.updateData();
    }

    updateToken() {
        config.token = localStorage.getItem("token") ?? NaN;
    }

    updateData() {
        this.data = JSON.parse(localStorage.getItem("userdata") ?? '[]');
    }

    isHasToken() {
        if (config.token) return true;
        return false;
    }

    setData(data) {
        localStorage.setItem("token", data.api_token ?? config.token);
        localStorage.setItem("userdata", JSON.stringify(data))
        config.token = data.api_token ?? config.token;
        this.data = data;
    }

    getNickname() {
        return this.data.nickname ?? '';
    }

    isAdmin() {
        return (this.data.type ?? 'user') === 'admin';
    }

    getId() {
        return this.data.id ?? 0;
    }

}

let auth = new Auth();

export default auth;


