<template>
  <el-form class="p-20-15" ref="form" :model="form" label-width="80px">
    <el-form-item label="登陆名称">
      <el-input v-model="form.login" disabled required></el-input>
    </el-form-item>
    <el-form-item label="用户昵称">
      <el-input v-model="form.nickname" disabled></el-input>
    </el-form-item>
    <el-form-item label="用户权限">
      <el-select v-model="form.type" placeholder="请选择用户权限。" disabled>
        <template v-for="(item,i) in group">
          <el-option :label="item" :value="i"></el-option>
        </template>
      </el-select>
    </el-form-item>
    <el-form-item label="创建时间">
      <el-input v-model="form.created_at" disabled></el-input>
    </el-form-item>

    <!--    <el-form-item>-->
    <!--      <el-button @click="onCancel" type="success">返回</el-button>-->
    <!--    </el-form-item>-->
  </el-form>
</template>

<script>
import {group} from "@/plugins/config";
import encryption from "@/plugins/encryption";

export default {
  name: "index",
  data() {
    return {
      group,
      form: {
        login: '',
        nickname: '',
        type: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  methods: {
    getInfo() {
      this.$http.get(`user/info/${this.$route.query.id ?? 0}`).then(res => {
        this.form = res.data;
        this.form.password = "12345678";
        this.form.password_confirmation = "12345678";
      });
    },
    onCancel() {
      this.$router.push({name: "UserList"});
    }
  },
  mounted() {
    this.getInfo();
  }
}
</script>

<style scoped>

</style>