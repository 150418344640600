<template>
  <form autocomplete="off" class="login-container">
    <img src="../../assets/card-top.png" alt="Background" class="card-top">
    <div class="login-card">
      <form autocomplete="off" class="login-form">
        <img src="../../assets/logo2.png" alt="logo" class="login-card-img mb-4">
        <div class="login-input-frame mb-2">
          <i class="sr-icon sr-icon-user"></i>
          <input type="text" name="sr-username" v-model="name" placeholder="请输入用户名"></div>
        <div class="login-input-frame mb-2">
          <i class="sr-icon sr-icon-password"></i>
          <input type="password" name="sr-pwd" v-model="password" placeholder="请输入密码"></div>
        <button type="submit" class="login-btn" @click.prevent="login">登录</button>
      </form>
    </div>
    <span class="copyright">沪ICP备15020842号-2 Copyright © 2018上海申瑞继保电气有限公司版权所有</span>
    <img src="../../assets/card-bottom.png" alt="Background" class="card-bottom">
  </form>
</template>

<script>
import auth from '@/plugins/auth'
import encryption from "@/plugins/encryption";
import JSEncrypt from "jsencrypt";
import {config} from "@/plugins/config";

export default {
  name: "Login",
  data() {
    return {
      name: '',
      password: ''
    }
  },
  methods: {
    login() {
      // this.$http.post('login', {login: this.name, password: encryption.encrypt(this.password)}).then(res => {
      //   auth.setData(res.data);
      //   this.$toast(`${res.data.nickname ?? ""}，欢迎回来!`);
      //   this.$router.push({name: 'dashboard'});
      // });
      let login = this.name;
      let password = encryption.encrypt(this.password);
      let password2 = this.password;
      let pubkey = '-----BEGIN PUBLIC KEY-----';
      pubkey += 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDQJix8NVoee2N9GEONunDQwzen';
      pubkey += 'khqklasH5hBP7YY7aeUPSEaqWMKcZs18Sij5/7acqYzuoarvEIHz51xX25l9mHSe';
      pubkey += 'A0cMhEVKMnHIALx4GK7w40oM4S8098dvxHVgmnJ1Xh/H33H3EkEDKBs4jLxk9cdJ';
      pubkey += 'w2wpVmDxRbCc5eia1wIDAQAB';
      pubkey += '-----END PUBLIC KEY-----';
      let encrypt = new JSEncrypt();
      encrypt.setPublicKey(pubkey);
      let encrypted = encrypt.encrypt(JSON.stringify({"encrypt": "yes", "password": password2}));

      this.$http.post('login2', {login, password, password2: encodeURIComponent(encrypted)}).then(res => {
        auth.setData(res.data);
        this.$toast(`${res.data.nickname ?? ""}，欢迎回来!`);
        if (auth.data.login == config.admin) this.$router.push({name: 'setting'});
        else this.$router.push({name: 'TemplateList'});
      });
    }
  },
  created() {

  }

}
</script>

<style scoped>
@import url(../../assets/sr-icon.css);

.login-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.card-top {
  position: absolute;
  right: 0;
  top: 0;
  height: 20%;
}

.card-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30%;
}

.copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 99;
  font-size: 12px;
}

.login-card {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  box-shadow: #ddd 0px 5px 10px 1px;
  border-radius: 10px;
  padding: 30px 20px;
}

.login-input-frame {
  border: 1px solid #d4d4d4;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 0.875rem;
  width: 100%;
}

.login-input-frame input:-webkit-autofill, .login-input-frame input:-webkit-autofill:focus, .login-input-frame input {
  outline: none;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  font-size: 0.875rem;
  width: 100%;
}

.cross-axis-center {
  align-items: center;
}

.remember-box {
  margin-right: 6px;
}

.fs-small {
  font-size: 12px;
}

.login-btn {
  width: 100%;
  padding: 8px 0;
  font-size: 0.875rem;
  background-image: linear-gradient(90deg, #0495fa, #2945f8);
  color: #fff;
  cursor: pointer;
  border-radius: 0;
}

button, input, select {
  border: none;
  background: none;
}

.d-flex {
  display: flex;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}
</style>