<template>
  <div :class="'scrollbar '+type">
    <div class="scrollbar-thumb" :style="{width:(type=='vertical'?'100%':length+'px'),
    height:(type=='vertical'?length+'px':'100%'),left:(type=='vertical'?0:offset+'px'),
    top:(type=='vertical'?offset+'px':0)}"
    @mousedown.stop="scrollbarMousedown"></div>
  </div>
</template>

<script>
export default {
  name: "Scrollbar",
  props:{
    type:{
      type:String,
      default:'vertical'
    },
    length:{
      type:Number,
      default:300,
    },
    offset:{
      type:Number,
      default:100,
    },
  },
  data(){
    return {

    }
  },
  methods:{
    scrollbarMousedown(e){
      this.$emit('scrollbarMousedown',{type:this.type,e:e});
    },
  }
}
</script>

<style scoped>
.scrollbar{
  border:1px solid #D9D9D9;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  user-select: none;
}
.scrollbar.vertical{
  flex:0 0 12px;
  height: 100%;
}
.scrollbar.horizontal{
  width: 100%;
  height: 12px;
}
.scrollbar-thumb{
  border-radius: 5px;
  background-color: #CCCCCC;
  position: absolute;
  user-select: none;
  transition: .15s;
}
.scrollbar-thumb:hover{
  background-color: #878787;
}
</style>