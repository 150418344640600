<template>
  <div class="bottombar" @click="init">

    <div class="sheet-menu">
      <div class="sheet" @click.stop="addSheet" v-if="!identifying_code">
        <svg class="sheet-add" width="10" height="10">
          <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M6,4 L6,0 L4,0 L4,4 L0,4 L0,6 L4,6 L4,10 L6,10 L6,6 L10,6 L10,4 L6,4 Z"/></g>
        </svg>
      </div>
      <div v-for="(sheet,i) in sheets" :class="'sheet '+(sheetIndex==i?'active':'')"
           @click.stop="selectSheet(i)" @dblclick.stop="rename(i)" @contextmenu.prevent="sheetContextmenu(i)">
        <span v-show="!sheet.sheetRename">{{sheet.name}}</span>
        <input v-model="name" v-show="sheet.sheetRename" ref="renameInput" type="text" class="" @blur="save" @keydown.enter="save">
        <div class="context-menu" v-show="sheetMenuShow[i]">
          <div class="context-menu-item" @click.stop="deleteSheet(i)">
            <span>删除</span>
          </div>
        </div>
      </div>
    </div>

    <div class="save-state" v-if="!identifying_code">
      <i class="el-icon-circle-check" v-if="!saveState.loading"></i>
      <i class="el-icon-loading" v-else></i>
      <span class="save-state-text" v-if="!saveState.loading">最近保存 {{ lastedSaveTime(saveState.time) }}</span>
      <span class="save-state-text" v-else>保存中</span>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "SheetBar",
  props:{
    sheets:Array,
    sheetIndex:0,
    identifying_code:null,
    saveState:{
      type:Object,
      default:function (){
        return{
          time:moment(),
          loading:false,
        }
      }
    }
  },
  watch:{
    sheetIndex(newVal,oldVal){
      this.name=this.sheets[newVal].name;
    },
  },
  data(){
    return {
      name:'',
      sheetMenuShow:[],
    }
  },
  methods:{
    lastedSaveTime(time){
      // $moment(saveState.time).fromNow()
      if(this.$moment().isSame(this.$moment(time),'d'))
        return this.$moment(time).format('HH:mm');
      else if(this.$moment().isSame(this.$moment(time),'y'))
        return this.$moment(time).format('MoDo')
      return this.$moment(time).format('YYYY年MoDo');
    },
    addSheet(){
      this.$emit('addSheet');
    },
    deleteSheet(index){
      this.$emit('deleteSheet',index);
    },
    sheetContextmenu(index=null){
      if(this.identifying_code)
        return false;
      for (let i in this.sheetMenuShow){
        this.sheetMenuShow[i]=false;
      }
      if(index!==null)
        this.sheetMenuShow[index]=true;
      this.$forceUpdate();
    },
    save(){
      this.$emit('renameSave',{
        index:this.sheetIndex,
        value:this.name,
      });
    },
    selectSheet(index){
      this.sheetContextmenu();
      this.$emit('selectSheet',index);
    },
    rename(index){
      this.$emit('rename',index);
      setTimeout(()=>{
        this.$refs.renameInput[index].focus();
      },10);
    },
    init(){
      this.sheetMenuShow=[];
      for (let i in this.sheets){
        this.sheetMenuShow.push(false);
      }
    }
  },
  created() {
    this.name=this.sheets[this.sheetIndex].name;
    this.init();
  }
}
</script>

<style scoped>
.bottombar{
  position: relative;
  border-top: 1px solid #e0e2e4;
  height: 40px;
  padding: 0 30px;
  text-align: left;
  background: #f5f6f7;
  display: flex;
  justify-content: space-between;
  user-select: none;
}
.sheet-menu{
  display: flex;
  align-items: center;
}
.sheet{
  height: 40px;
  padding: 12px;
  border-right:1px solid #e8eaed;
  cursor: pointer;
  color: #80868b;
  font-weight: bold;
  white-space: nowrap;
  font-size: 12px;
  position: relative;
}
.sheet-add{
  opacity: 0.5;
  transition: 0.2s opacity;
}
.sheet:hover .sheet-add{
  opacity: 0.85;
}
.sheet.active{
  background-color: #fff;
  color: rgba(0, 0, 0, 0.65);
}
.context-menu{
  width: 160px;
  height: 32px;
  position: absolute;
  box-shadow: 1px 2px 5px 2px rgba(51,51,51,0.15);
  background: #fff;
  z-index: 805;
  left:0;
  top:-32px;
  line-height: 32px;
}
.context-menu-item{
  width:100%;
  height: 100%;
  padding: 2px 10px;
}
.context-menu-item:hover{
  background: rgba(0, 0, 0, 0.05);
}
.save-state{
  display: flex;
  align-items: center;
  font-size: 12px;
  opacity: 0.4;
}
.save-state-text{
  margin-left:7px;
}
</style>