<template>
  <div class="chart-board" @click.stop="hideInput">
    <div class="chart-type-area flex">
      <div :class="'chart-type-box '+(chartData.chartType=='line'?' active':'')" @click="selectType('line')">
        <svg class="chart-type" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
          <path d="M959.159 928.219H64.063V98.125h37.045v787.166h858.051z" fill="#819292"></path>
          <path d="M580.658 796.245L369.686 628.169 158.642 760.785l-17.659-37.737 230.991-145.15 208.508 166.113 334.054-270.609 21.225 35.183z" fill="#D73949"></path>
          <path d="M152.112 601.753l-28.68-27.17L360.23 238.944 569.003 494.92 903.52 103.683l26.316 30.213-361.462 422.75L362.245 303.91z" fill="#159A8D"></path>
        </svg>
      </div>
      <div :class="'chart-type-box '+(chartData.chartType=='bar'?'active':'')" @click="selectType('bar')">
        <svg class="chart-type" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
          <path d="M290.15 503.877l-149.461-2.114v333.16H290.15z" fill="#D73949"></path>
          <path d="M497.316 227.066l-149.462-3.883v611.74h149.462z" fill="#0CA294"></path>
          <path d="M704.481 368.468l-149.461-2.98v469.435h149.461z" fill="#35A0D6"></path>
          <path d="M911.647 638.005l-149.462-1.258v198.176h149.462z" fill="#D97B24"></path>
          <path d="M102.139 885.365V98.152H65.063v830.142h895.855v-42.929z" fill="#819292"></path>
        </svg>
      </div>
      <div :class="'chart-type-box '+(chartData.chartType=='pie'?'active':'')" @click="selectType('pie')">
        <svg class="chart-type" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="200" height="200">
          <path d="M519.6 109h-30.9c-57.3 0-113 11.3-165.3 33.5-50.6 21.4-96 52.1-135 91.2s-69.6 84.6-91 135.2C75.2 421.3 64 477.1 64 534.5c0 57.4 11.2 113.2 33.4 165.6 21.4 50.7 52 96.2 91 135.2s84.4 69.8 135 91.2c52.4 22.2 108 33.5 165.3 33.5 57.3 0 113-11.3 165.3-33.5 50.6-21.4 96-52.1 135-91.2s69.6-84.6 91-135.2c22.1-52.5 33.4-108.2 33.4-165.6v-30.9H519.6V109z" fill="#849192"></path>
          <path d="M580.5 64l-0.9 379.4H960C960 261.3 789.6 64 580.5 64z" fill="#49A094"></path>
        </svg>
      </div>
    </div>
    <div v-if="chartData.chartType" class="">
      <div class="option-item flex" v-if="chartData.chartType==='bar'||chartData.chartType==='pie'">
        <el-checkbox v-model="chartData.showValue">显示数值</el-checkbox>
      </div>
      <div class="option-item flex" v-if="chartData.chartType==='pie'">
        <el-checkbox v-model="chartData.showScale">显示比例</el-checkbox>
      </div>
      <div class="option-item flex" v-if="chartData.chartType==='line'">
        <el-checkbox v-model="chartData.showMin">显示最小值</el-checkbox>
      </div>
      <div class="option-item flex" v-if="chartData.chartType==='line'">
        <el-checkbox v-model="chartData.showMax">显示最大值</el-checkbox>
      </div>
      <div class="option-item flex" v-if="chartData.chartType==='line'">
        <el-checkbox v-model="chartData.showAverage">显示平均值</el-checkbox>
      </div>
    </div>
    <div class="chart-input-area">
      <div class="flex align-items-center chart-mb">
        <div class="chart-info-title">标题名:</div>
        <input type="text" class="flex-grow chart-data-input" v-model="chartData.title">
      </div>

      <div class="flex align-items-center chart-mb">
        <div class="chart-info-title">数据区域</div>
        <div :class="'flex chart-info-content '+(totalSourceSelecting?'active':'')">
          <div v-if="totalSourceSelecting">{{ chartData.totalSourceRange&&chartData.totalSourceRange.join(',') }}</div>
          <input v-else
                 type="text"
                 class="area-select-input"
                 v-model="chartData.totalSource.rangeStr"
                 @keyup="inputChange('totalSource')"
          >
          <span class="el-icon-edit-outline"
                @click.stop="selectTotalSource"
          />
        </div>
      </div>

      <div class="vertical-driver"></div>

      <div class="flex align-items-center chart-mb">
        <div class="chart-info-title">数据源:</div>
        <div :class="'flex chart-info-content '+(sourceSelecting?'active':'')">
          <div v-if="sourceSelecting">{{ chartData.sourceRange&&chartData.sourceRange.join(',') }}</div>
          <input v-else
                 type="text"
                 class="area-select-input"
                 v-model="chartData.chartSource.rangeStr"
                 @keyup="inputChange('chartSource')"
          >
          <span class="el-icon-edit-outline"
                @click.stop="selectSource"
          />
        </div>
      </div>

      <div class="flex align-items-center chart-mb">
        <div class="chart-info-title">标题源:</div>
        <div :class="'flex chart-info-content '+(headSelecting?'active':'')">
          <div v-if="headSelecting">{{ chartData.headRange&&chartData.headRange.join(',') }}</div>
          <input v-else
                 type="text"
                 class="area-select-input"
                 v-model="chartData.chartHead.rangeStr"
                 @keyup="inputChange('chartHead')"
          >
          <span class="el-icon-edit-outline"
                @click.stop="selectHead"
          />
        </div>
      </div>

      <div class="flex align-items-center">
        <div class="chart-info-title">系列源:</div>
        <div :class="'flex chart-info-content '+(seriesSelecting?'active':'')">
          <div v-if="seriesSelecting">{{ chartData.seriesRange&&chartData.seriesRange.join(',') }}</div>
          <input v-else
                 type="text"
                 class="area-select-input"
                 v-model="chartData.chartSeries.rangeStr"
                 @keyup="inputChange('chartSeries')"
          >
          <span class="el-icon-edit-outline"
                @click.stop="selectSeries"
          />
        </div>
      </div>

      <div class="vertical-driver"></div>

      <div class="flex align-items-center">
        <div class="chart-info-title">图例项:</div>
        <button class="flex-grow" @click.stop="changeDataType">切换行/列(当前为{{chartData.type === 'row' ? '行' : '列'}})</button>
      </div>
      <div class="chart-data-area">
        <div v-for="(ser,index) in chartData.series" class="chart-data-option flex"
             @click.stop="hideInput(index)"
             @dblclick="showInput(index)">
          <div v-show="!inputShow[index]" class="chart-data-option-name">{{ ser.name&&ser.name.join(',') }}</div>
          <input type="color" class="chart-data-option-color" v-show="!inputShow[index]" v-model="ser.color">
          <div v-show="!inputShow[index]">{{ser.range&&ser.range.join(',')}}</div>
          <input type="text" class="chart-data-option-rename"
                 v-show="inputShow[index]"
                 v-model="ser.name"
                 :ref="'renameInput'+index"
                 @keydown.enter="hideInput"
          >
        </div>
      </div>

      <div class="chart-button-group flex">
        <button @click="cancel">取消</button>
        <button class="primary-color text-white" @click="createChart">确认</button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ChartBoard",
  props:{
    chartData:{
      type:Object,
      required:true,
      default:function(){
        return {
          title:'',
          type:'row',
          options:[],
          showValue: false,
          showScale: false,
          showMax: false,
          showMin: false,
          showAverage: false,
        }
      }
    },
    sourceSelecting:{
      type: Boolean,
    },
    headSelecting:{
      type: Boolean,
    },
    totalSourceSelecting:{
      type: Boolean,
    },
    seriesSelecting:{
      type: Boolean,
    },
  },
  watch:{
    'chartData.options':{
      handler:function (newVal,oldVal){
        this.inputShow=[];
        for (let i in newVal){
          this.inputShow.push(false);
        }
      }
    },
  },
  data(){
    return{
      inputShow:[],
      index:null,
    }
  },
  methods:{
    inputChange(type){
      this.$emit('chartInputChange',type);
    },

    selectTotalSource(){
      this.$emit('selectHead',false);
      this.$emit('selectSource',false);
      this.$emit('totalSourceSelect',!this.totalSourceSelecting);
    },

    //创建图表
    createChart(){
      if(!this.chartData.chartType){
        this.$toast('请选择表格类型','danger');
        return false;
      }
      else if(!this.chartData.title){
        this.$toast('请输入表格标题','danger');
        return false;
      }
      else if(!this.chartData.sourceRange){
        this.$toast('请选择数据源','danger');
        return false;
      }
      else if(!this.chartData.headRange){
        this.$toast('请选择标题源','danger');
        return false;
      }
      else if(!this.chartData.seriesRange){
        this.$toast('请选择标题源','danger');
        return false;
      }

      this.$emit('selectHead',false);
      this.$emit('selectSource',false);
      this.$emit('seriesSelect',false);
      this.$emit('totalSourceSelect',false);

      this.$emit('createChart',this.chartData);
    },

    //显示输入框
    showInput(index){
      this.hideInput(index);
      this.inputShow[index]=true;
      this.index=index;
      this.$forceUpdate();
      this.$nextTick(()=>{
        this.$refs['renameInput'+index][0].focus();
      });
    },

    //隐藏输入框
    hideInput(index=null){
      for (let i in this.inputShow){
        if(index!==this.index)
          this.inputShow[i]=false;
      }
      this.$forceUpdate();
    },

    //切换行列
    changeDataType(){
      this.$emit('changeDataType');
    },

    //开始选择数据源
    selectSource(){
      this.$emit('seriesSelect',false);
      this.$emit('selectHead',false);
      this.$emit('totalSourceSelect',false);
      this.$emit('selectSource',!this.sourceSelecting);
    },

    //开始选择标题源
    selectHead(){
      this.$emit('seriesSelect',false);
      this.$emit('selectSource',false);
      this.$emit('totalSourceSelect',false);
      this.$emit('selectHead',!this.headSelecting);
    },

    //开始选择系列源
    selectSeries(){
      this.$emit('seriesSelect',!this.seriesSelecting);
      this.$emit('selectSource',false);
      this.$emit('totalSourceSelect',false);
      this.$emit('selectHead',false);
    },

    //点击取消按钮
    cancel(){
      this.$emit('selectHead',false);
      this.$emit('selectSource',false);
      this.$emit('cancel');
    },

    //选择图表类型
    selectType(type){
      this.chartData.chartType=type;
      this.chartData.showAverage=false;
      this.chartData.showMax=false;
      this.chartData.showMin=false;
      this.chartData.showScale=false;
      this.chartData.showValue=false;
      this.$forceUpdate();
    },
  },
  created() {

  }
}
</script>

<style scoped>
.chart-board{
  width:100%;
  background-color: #fff;
  /*padding: 5px;*/
  z-index: 800;
  font-size: 14px;
}
.chart-type-box{
  width: 70px;
  height: 70px;
  padding: 10px;
}
.chart-type-box:hover{
  background-color: #eee;
}
.chart-type-box.active{
  background-color: #dbdbdb;
}
.chart-type{
  width: 50px;
  height: 50px;
}
.chart-info-title{
  margin-right: 10px;
}
.chart-input-area{
  margin-top:5px;
}
.chart-info-content{
  border: 1px solid #DCDFE6;
  color:#606266;
  padding: 3px 5px;
  flex-grow: 1;
  border-radius: 3px;
  height: 28px;
  justify-content: space-between;
  transition: .1s;
  position: relative;
}
.chart-info-content .icon{
  width: 20px;
  height: 20px;
  fill: #606266;
  transition: .1s;
}
/*.chart-info-content:hover{*/
/*  color: #409EFF;*/
/*  border-color: #c6e2ff;*/
/*  background-color: #ecf5ff;*/
/*}*/
/*.chart-info-content:hover .icon{*/
/*  fill: #409EFF;*/
/*}*/
.chart-info-content.active{
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.chart-info-content.active .icon{
  fill: #409EFF;
}
.chart-data-area{
  width:100%;
  height: 120px;
  border: 1px solid #DCDFE6;
  color:#606266;
  border-radius: 3px;
  margin-top:15px;
  overflow-y: auto;
}
.chart-data-option{
  height: 24px;
  padding: 0 8px;
  justify-content: space-between;
  align-items: center;
}
.chart-data-option:hover{
  background-color: #ecf5ff;
}
.chart-button-group{
  margin-top:15px;
  justify-content: end;
}
.chart-button-group button{
  margin-left:5px;
}
.chart-data-input{
  height: 28px;
  outline: none;
  border: 1px solid #DCDFE6;
  color:#606266;
  border-radius: 3px;
  padding: 0 5px;
}
.chart-mb{
  margin-bottom:15px;
}
.chart-data-option-rename{
  width: 100%;
  height: 100%;
  border: 1px solid #DCDFE6;
  padding: 0 3px;
}
.vertical-driver{
  margin:15px 0;
}
.chart-data-option-color{
  border: none;
  outline: none;
  height: 24px;
}
.chart-data-option-name{
  flex:0 0 110px;
}
.el-icon-edit-outline{
  position: absolute;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
}
.area-select-input{
  border:none;
  outline:none;
  width: 100%;
  height: 100%;
}
.option-item{
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>