<template>
  <div class="template-info">
    <ExcelTemplate></ExcelTemplate>
  </div>
</template>

<script>
import ExcelTemplate from '@/views/Home'
export default {
  name: "index",
  components:{
    ExcelTemplate,
  },
  data(){
    return{

    }
  }
}
</script>

<style scoped>

</style>