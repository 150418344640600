<template>
  <div class="data-source">
    <div>
      <el-form ref="preForm" label-width="80px">
        <el-form-item label="名称" style="margin-bottom: 12px;">
          <el-input v-model="preData.name" placeholder="请输入数据源名称"></el-input>
        </el-form-item>
        <el-form-item label="数据类型" style="margin-bottom: 12px;">
          <el-select v-model="preData.type" class="w-100" filterable placeholder="请选择API类别"
                     @change="preDataTypeChange">
            <el-option
                v-for="item in typeArr"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="preData.type=='rtinfo'||preData.type=='htinfo'" label="数据表" style="margin-bottom: 12px;">
          <el-select v-model="preData.table" @change="getParams2" class="w-100" filterable placeholder="请选择数据表">
            <el-option
                v-for="item in selectType"
                :key="item.id"
                :label="item.table_name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="preData.type=='ndinfo'" label="节点类型" style="margin-bottom: 12px;">
          <el-select v-model="preData.nodeType" class="w-100" filterable placeholder="请选择节点类型"
                     @change="nodeTypeChange" style="margin-bottom: 10px;">
            <el-option
                v-for="item in nodeTypeArr"
                :key="item.id"
                :label="item.appname"
                :value="item.apptype">
            </el-option>
          </el-select>
          <p class="tip-text" style="margin-top: -20px;">切换节点类型将清空已选参数</p>
        </el-form-item>

        <el-form-item v-if="preData.type=='ndinfo'" label="节点选择" class="nodeInfo" style="margin-bottom: 12px;">
          <div class="w-100 grid-523">
            <el-tree
                style="border-radius: 4px 0 0 4px;border: 1px solid #DCDFE6;padding: 20px;height: calc(100vh - 413px);overflow: scroll;"
                :data="nodeArr"
                show-checkbox
                default-expand-all
                node-key="id"
                :check-strictly="true"
                :default-checked-keys="currentNodeKeys"
                @check="currentTreeChecked"
                :props="defaultTreeProps">
            </el-tree>
            <div class="select-node">
              <h4 style="padding-left: 10px;" v-if="preData.nodeId.length">已选节点</h4>
              <div class="select-node-list" @dragover="listDragOver">
                <template v-for="o in preData.nodeId">
                  <div class="select-node-text" style="padding-left: 10px;line-height: 30px;" draggable="true"
                       @dragstart="nodeDragStart(o)" @drop="nodeDragEnd" :data-id="o.id">
                    {{ o.label }}
                  </div>
                </template>
              </div>
            </div>
            <div class="node-params-info">
              <NodeParamsInfo :paramsInfo="paramsInfo" :paramsData="paramsData" :datatype="preData.type"></NodeParamsInfo>
            </div>
          </div>

        </el-form-item>

        <el-form-item label="站点" v-if="preData.type=='rtinfo'||preData.type=='htinfo'" style="margin-bottom: 12px;">
          <el-select v-model="preData.station" class="w-100" filterable placeholder="请选择站点"
                     @change="getParams">
            <el-option
                v-for="item in stationArr"
                :key="item.stid"
                :label="item.stname"
                :value="item.stid">
            </el-option>
          </el-select>
          <p class="tip-text">切换站点将清空已选参数</p>
        </el-form-item>
        <el-form-item label="参数" class="w-100" v-if="preData.type=='rtinfo'||preData.type=='htinfo'">
          <SelectList :optionParamsArr="optionParamsArr" :selectedParamsArr="selectedParamsArr" :paramsInfo="paramsInfo"
                      :paramsData="paramsData" :datatype="preData.type">
          </SelectList>
        </el-form-item>


      </el-form>
    </div>
    <div slot="footer" class="dialog-footer" style="position: absolute;right: 20px;bottom: 20px;">
      <el-button @click="$emit('dataSourceClose')">取消</el-button>
      <el-button type="primary" @click.stop="appendDataSet" :disabled="loading">
        <template v-if="loading">
          {{ type == 'create' ? '添加中' : '修改中' }}
        </template>
        <template v-else>
          {{ type == 'create' ? '添加' : '修改' }}
        </template>
      </el-button>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import SelectList from "@/components/SelectList";
import NodeParamsInfo from "@/components/NodeParamsInfo";

export default {
  name: "DataSource",
  props: {
    dataset: {
      default: null,
    },
    type: {
      default: null,
    },
    templateType: {
      default: 'year',
    },
    allData: {
      type: Object,
      default: {},
    }
  },
  components: {
    SelectList,
    NodeParamsInfo
  },
  watch: {
    'preData.nodeType': function (newVal) {
      this.nodeArrKey++;
      let n = this.allData.ndinfo.find((e) => {
        return e.apptype == newVal;
      }) ?? [];
      // let n1 = ((n.data ?? [])[0])??[];
      let arr = [];

      for (let i of (n.data ?? [])) {
        for (let j of (i.item ?? [])) {
          if (i.class == 1) {
            arr.push({id: j.id, label: j.name});
          } else {
            this.arrAppend(arr ?? [], j);
          }
        }
      }

      this.nodeArr = arr;
      this.nodeArrKey++;
    },
    dataset(newVal) {
      // console.log(newVal);

      if (newVal !== null && newVal[0] !== null) {
        this.preData = JSON.parse(JSON.stringify(newVal.source.preData));
        this.selectType = this.allData[this.preData.type];

        if (this.preData.type == 'rtinfo' || this.preData.type == 'htinfo') {
          if (this.preData.table !== null && this.preData.station !== null) {
            this.$http.get(`uiinterface?reqType=GetTableRecords&stid=${this.preData.station}&tbid=${this.preData.table}`).then(res => {
              if (res.data.length !== 0) {
                this.paramsArr = res.data;
                for (let o of this.paramsArr) {
                  this.optionParamsArr.push({
                    name: o.NAME,
                    value: o.ID,
                  });
                }
              } else {
                this.paramsArr = [];
                this.optionParamsArr = [];
              }
              this.$forceUpdate();
            })
          }
          if (this.preData.table !== null) {
            let type = this.typeArr.find((e) => {
              return e.value === this.preData.type;
            });
            this.$http.get(`uiinterface?reqType=GetTableParameter&tabletype=${this.templateTypeId}&datastyle=${type.index}&tbid=${this.preData.table}`).then(res => {
              this.paramsInfo = res.data;
              this.$forceUpdate();
            })
          }
        } else {
          this.currentNodeKeys = [];
          for (let i of this.preData.nodeId) {
            this.currentNodeKeys.push(i.id);
          }
          // console.log(this.currentNodeKeys)
          let type = this.typeArr.find(t => t.value == this.preData.type);
          this.$http.get(`uiinterface?reqType=GetTableParameter&tabletype=${this.templateTypeId}&datastyle=${type.index}`).then(res => {
            this.paramsInfo = res.data;

            this.$forceUpdate();
          })
        }

        this.selectedParamsArr = JSON.parse(JSON.stringify(newVal.source.selectParams));
        this.paramsData = JSON.parse(JSON.stringify(newVal.source.paramsData));
      } else {
        this.reset();
      }
    },
    allData: {
      handler: function () {
        this.getData();
      },
      deep: true
    }
  },
  data() {
    return {
      http: null,
      loading: false,
      preData: {
        type: null,
        table: null,
        nodeId: [],
        nodeType: null,
        station: null,
        name: ''
      },
      nodeType: null,
      nodeArrKey: true,
      typeArr: [{label: '实时数据表信息', value: 'rtinfo', index: 0},
        {label: '历史数据表信息', value: 'htinfo', index: 1},
        {label: '节点信息', value: 'ndinfo', index: 2}],
      defaultTreeProps: {
        children: 'children',
        label: 'label'
      },
      selectType: [],
      stationArr: [],
      nodeArr: [],
      nodeTypeArr: [],
      dataNameArr: [],
      dataValue: [],
      paramsArr: [],
      optionParamsArr: [],
      selectedParamsArr: [],
      paramsInfo: {},
      paramsData: {direction: 'horizontal', value: {}, date: {}},
      draging: {},
      currentNodeKeys: []
    }
  },
  methods: {
    appendDataSet() {
      this.loading = true;
      // if(this.preData.name==''){
      //   this.$toast('请输入数据源名称！', 'danger');
      //   this.loading = false;
      //   return false;
      // }else
      if (this.preData.type == null) {
        this.$toast('请选择API类型', 'danger');
        this.loading = false;
        return false;
      } else if (this.preData.type != 'ndinfo' && this.preData.table == null) {
        this.$toast('请选择表', 'danger');
        this.loading = false;
        return false;
      } else if (this.preData.type == 'ndinfo' && this.preData.nodeType == null) {
        this.$toast('请选择节点类型', 'danger');
        this.loading = false;
        return false;
      } else if (this.preData.type == 'ndinfo' && this.preData.nodeId.length == 0) {
        this.$toast('请选择节点', 'danger');
        this.loading = false;
        return false;
      } else if (this.preData.type != 'ndinfo' && this.preData.table == null) {
        this.$toast('请选择表', 'danger');
        this.loading = false;
        return false;
      } else if (this.preData.type != 'ndinfo' && this.preData.station == null) {
        this.$toast('请选择站点', 'danger');
        this.loading = false;
        return false;
      } else if (this.preData.type != 'ndinfo' && this.selectedParamsArr.length == 0) {
        this.$toast('请选择参数', 'danger');
        this.loading = false;
        return false;
      }
      //计算时间
      function calcTime(d){
        if (d === '-0') return 0;
        return d >= 0 ? (d - -1000) : Math.abs(d);
      }
      //判断paramsData的date参数中的分钟合理性
      if (this.paramsData.date.from_minute != null && this.paramsData.date.to_minute != null) {
        if (calcTime(this.paramsData.date.from_minute) > calcTime(this.paramsData.date.to_minute)) {
          this.$toast('起始的分钟数不能大于结束的分钟数', 'danger');
          this.loading = false;
          return false;
        }
      }
      //判断paramsData的date参数中的小时合理性
      if (this.paramsData.date.from_hour != null && this.paramsData.date.to_hour != null) {
        if (calcTime(this.paramsData.date.from_hour) > calcTime(this.paramsData.date.to_hour)) {
          this.$toast('起始的小时数不能大于结束的小时数', 'danger');
          this.loading = false;
          return false;
        }
      }
      //判断paramsData的date参数中的天合理性
      if (this.paramsData.date.from_day != null && this.paramsData.date.to_day != null) {
        if (calcTime(this.paramsData.date.from_day) > calcTime(this.paramsData.date.to_day)) {
          this.$toast('起始的天数不能大于结束的天数', 'danger');
          this.loading = false;
          return false;
        }
      }
      //判断paramsData的date参数中的月份合理性
      if (this.paramsData.date.from_month != null && this.paramsData.date.to_month != null) {
        if (calcTime(this.paramsData.date.from_month) > calcTime(this.paramsData.date.to_month)) {
          this.$toast('起始的月份不能大于结束的月份', 'danger');
          this.loading = false;
          return false;
        }
      }

      // else if (this.preData.dataName == null) {
      //   this.$toast('请选择数据', 'danger');
      //   this.loading = false;
      //   return false;
      // }


      // console.log(this.preData);
      if (this.type == 'create') {
        this.$emit('appendDataSet', {
          // type: this.typeArr.find((t) => this.preData.type == t.value),
          // table: this.preData.table != null ? this.selectType.find((t) => t.id == this.preData.table) : null,
          // station: this.preData.station != null ? this.stationArr.find((t) => t.stid == this.preData.station) : null,
          // nodeType: this.preData.nodeType != null ? this.nodeTypeArr.find((n) => n.apptype == this.preData.nodeType) : null,
          // nodeId: this.preData.nodeId ?? [],
          preData: this.preData,
          selectParams: this.selectedParamsArr ?? [],
          paramsData: this.paramsData ?? {}
        });
      } else {
        this.$emit('modifySelectDataset', {
          preData: this.preData,
          selectParams: this.selectedParamsArr ?? [],
          paramsData: this.paramsData ?? {}
        });
        // this.dataset.source.preData = this.preData;
        // this.dataset.source.selectParams = this.selectParams;
        // this.dataset.source.paramsData = this.paramsData;
      }
      this.$emit('dataSourceClose');
      this.loading = false;
      this.reset();
    },
    nodeChange(data) {
      // console.log(data);
      this.preData.table = data[data.length - 1];
    },
    preDataTypeChange(type) {
      this.selectType = this.allData[type];
      this.selectedParamsArr = [];
      this.paramsArr = [];
      this.optionParamsArr = [];
      this.paramsInfo = {};
      this.preData.nodeType = null;
      this.preData.table = null;
      this.preData.nodeId = [];
      this.preData.station = null;
      this.paramsData = {
        direction: 'horizontal',
        value: {value: 0, info: []}, date: {
          date_type:null,
          from_month: null,
          to_month: null,
          from_day: null,
          to_day: null,
          from_hour: null,
          to_hour: null,
          from_minute: null,
          to_minute: null,
          interval: null,
          intervalUnit: null,
          start_date: null,
          end_date: null
        }
      };
      // this.preData.dataName = null;
      this.nodeArr = [];
    },
    getData() {
      this.stationArr = this.allData.stinfo;
      this.nodeTypeArr = this.allData.ndinfo;

      // this.setDefault()

      // console.log(this.allData)

      this.$forceUpdate();
    },
    // setDefault() {
    //   this.preData.type = 'rtinfo';
    //   this.preDataTypeChange('rtinfo');
    //   this.preData.table = this.selectType[0].id;
    //
    // },
    getParams2() {
      this.getParams();
    },
    getParams() {
      this.preData.nodeId = [];
      this.paramsArr = [];
      this.optionParamsArr = [];

      this.selectedParamsArr = [];

      //restore paramsData
      this.paramsData = {
        direction: 'horizontal',
        value: {value: 0, info: []}, date: {
          date_type:null,
          from_month: null,
          to_month: null,
          from_day: null,
          to_day: null,
          from_hour: null,
          to_hour: null,
          from_minute: null,
          to_minute: null,
          interval: null,
          intervalUnit: null,
          start_date: null,
          end_date: null
        }
      };
      this.paramsInfo = {};

      if (this.preData.table !== null && this.preData.station !== null) {
        this.$http.get(`uiinterface?reqType=GetTableRecords&stid=${this.preData.station}&tbid=${this.preData.table}`).then(res => {
          if (res.data.length !== 0) {
            this.paramsArr = res.data;
            this.optionParamsArr = [];
            for (let o of this.paramsArr) {
              this.optionParamsArr.push({
                name: o.NAME,
                value: o.ID,
              });
            }
          } else {
            this.paramsArr = [];
            this.optionParamsArr = [];
          }

          this.$forceUpdate();
        })
      }

      if (this.preData.table !== null) {
        let type = this.typeArr.find((e) => {
          return e.value === this.preData.type;
        });
        this.$http.get(`uiinterface?reqType=GetTableParameter&tabletype=${this.templateTypeId}&datastyle=${type.index}&tbid=${this.preData.table}`).then(res => {
          this.paramsData = {
            direction: 'horizontal',
            value: {value: 0, info: []}, date: {
              date_type:null,
              from_month: null,
              to_month: null,
              from_day: null,
              to_day: null,
              from_hour: null,
              to_hour: null,
              from_minute: null,
              to_minute: null,
              interval: null,
              intervalUnit: null,
              start_date: null,
              end_date: null
            }
          };
          // for (let i in (res.data.value ?? [])) {
          //   this.paramsData.value.info.push({
          //     value: [],
          //     id: i,
          //     name: res.data.value[i].name
          //   })
          // }
          this.paramsInfo = res.data;

          this.paramsDataDefault();

          this.$forceUpdate();
        })
      }

    },
    reset() {
      this.selectedParamsArr = [];
      this.paramsArr = [];
      this.optionParamsArr = [];
      this.paramsInfo = {};

      this.nodeArr = [];

      this.preData = {
        type: null,
        table: null,
        nodeId: [],
        nodeType: null,
        station: null,
        name: ''
      };
      this.paramsData = {
        direction: 'horizontal',
        value: {value: 0, info: []}, date: {
          date_type:null,
          from_month: null,
          to_month: null,
          from_day: null,
          to_day: null,
          from_hour: null,
          to_hour: null,
          from_minute: null,
          to_minute: null,
          interval: null,
          intervalUnit: null,
          start_date: null,
          end_date: null
        }
      };

      this.loading = false;

      this.defaultSet();
    },
    defaultSet() {
      if ((this.allData.coid ?? null) !== null) {
        this.preData.type = 'htinfo';
        this.preDataTypeChange('htinfo');
        //默认选择五分钟采样定义表
        for (let i in this.selectType) {
          if (this.selectType[i].table_name === '五分钟采样定义表') {
            this.preData.table = this.selectType[i].id;
            break;
          }
        }
        //如果table为null，说明没有五分钟采样定义表，选择第一个
        if (this.preData.table === null) {
          this.preData.table = this.selectType[0].id;
        }
        this.getParams();
      } else {
        setTimeout(() => {
          this.defaultSet();
        }, 50)
      }

    },
    arrAppend(arr, i) {
      for (let j of arr) {
        if ((j.id ?? -1) == i.pid) {
          if (!((j.children ?? -1) instanceof Array)) j.children = [];
          j.children.push({id: i.id, label: i.name});
        } else {
          this.arrAppend(j.children ?? [], i);
        }
      }
    },
    currentTreeChecked(nodeObj, SelectedObj) {
      // this.preData.nodeId = SelectedObj.checkedKeys;
      // this.preData.nodeId = SelectedObj.checkedNodes;
      // console.log(nodeObj, SelectedObj)
      // if (nodeObj.children) {
      //   for (let c of nodeObj.children) {
      //     this.currentTreeChecked(c, SelectedObj);
      //   }
      // } else {
      let has = SelectedObj.checkedNodes.find((e) => {
        return e.id === nodeObj.id
      });
      let nodeObj2 = this.preData.nodeId.find((e) => {
        return e.id === nodeObj.id
      });
      let index = this.preData.nodeId.indexOf(nodeObj2);
      // console.log(has,index)
      if (has && index === -1) {
        this.preData.nodeId.push(nodeObj);
      } else if (!has && index !== -1) {
        this.preData.nodeId.splice(index, 1);
      }
      // }
    },
    nodeTypeChange() {
      this.preData.nodeId = [];
      this.selectedParamsArr = [];
      this.paramsArr = [];
      this.optionParamsArr = [];
      this.paramsInfo = {};

      let type = this.typeArr.find(t => t.value == this.preData.type);
      this.$http.get(`uiinterface?reqType=GetTableParameter&tabletype=${this.templateTypeId}&datastyle=${type.index}`).then(res => {
        this.paramsData = {
          direction: 'horizontal',
          value: {value: null, info: [], enkind: [], entype: null}, date: {
            date_type:null,
            from_month: null,
            to_month: null,
            from_day: null,
            to_day: null,
            from_hour: null,
            to_hour: null,
            from_minute: null,
            to_minute: null,
            interval: null,
            intervalUnit: null,
            start_date: null,
            end_date: null
          }
        };
        // for (let i in (res.data.value ?? [])) {
        //   this.paramsData.value.info.push({
        //     value: [],
        //     id: i,
        //     name: res.data.value[i].name
        //   })
        // }

        this.paramsInfo = res.data;
        // console.log(this.paramsInfo)

        this.paramsDataDefault();

        this.$forceUpdate();
      })
    },
    paramsDataDefault() {
      if(this.preData.type==='htinfo'&&this.$root.templateType==='config'){
        this.paramsData.date.date_type='period';
      }
      else this.paramsData.date.date_type=null;

      for (let index in this.paramsInfo.date ?? []) {
        if (index === 'from_month') {
          this.paramsData.date[index] = 1;
        }
        if (index === 'to_month') {
          this.paramsData.date[index] = 1;
        }
        if (index === 'from_day') {
          this.paramsData.date[index] = "1";
        }
        if (index === 'to_day') {
          this.paramsData.date[index] = "1";
        }
        if (index === 'from_hour') {
          this.paramsData.date[index] = "0";
        }
        if (index === 'to_hour') {
          this.paramsData.date[index] = "0";
        }
        if (index === 'from_minute') {
          this.paramsData.date[index] = 0;
        }
        if (index === 'to_minute') {
          this.paramsData.date[index] = 0;
        }


        if (index == 'interval') {
          this.paramsData.date['intervalUnit'] = this.paramsInfo.date['interval'][0]['unit'] ?? '';
          this.paramsData.date['interval'] = this.paramsInfo.date['interval'][0]['val'][0] ?? '';
        }
      }

      if ((this.paramsInfo.value.enkind ?? []).length) {
        this.paramsData.value.enkind = [this.paramsInfo.value.enkind[0]['val'] ?? ''];
        this.paramsData.value.entype = this.paramsInfo.value.entype[0]['objid'] ?? '';
      } else {
        for (let o of this.paramsInfo.value) {
          if (o.field === 'value') this.paramsData.value.value = 'value';
        }
        if (!this.paramsData.value.value) this.paramsData.value.value = this.paramsInfo.value[0]['field'] ?? '';
      }
    },
    listDragOver(e) {
      e.preventDefault();
    },
    nodeDragStart(o) {
      this.draging = o;
    },
    nodeDragEnd(e) {
      let to = e.toElement;
      let id = to.dataset.id;
      let nodeId = this.preData.nodeId.find(e => {
        return e.id === id
      });
      let newIndex = this.preData.nodeId.indexOf(nodeId);
      let oldIndex = this.preData.nodeId.indexOf(this.draging);
      if (newIndex > oldIndex) {
        this.preData.nodeId.splice(newIndex + 1, 0, this.draging);
        this.preData.nodeId.splice(oldIndex, 1);
      } else if (oldIndex > newIndex) {
        this.preData.nodeId.splice(oldIndex, 1);
        this.preData.nodeId.splice(newIndex, 0, this.draging);
      }
      // console.log(newIndex, oldIndex);
    },
  },
  created() {
    // console.log(window.UUID());
    this.defaultSet();
  },
  computed: {
    templateTypeId() {
      let type = {
        'year': 2,
        'month': 1,
        'day': 0,
        'config': 3,
      }

      return type[this.templateType];
    }
  }
}
</script>

<style>
.data-source {
  padding: 20px;
  /*width: 1160px;*/
}

.el-transfer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-transfer-panel {
  width: 300px;
}

.el-transfer-panel__item {
  height: auto;
}

.el-checkbox.el-transfer-panel__item .collapse-box {
  max-height: 0;
  transition: .3s all;
}

.el-checkbox.el-transfer-panel__item.is-checked .collapse-box {
  max-height: 100px;
}

.value-type-radio {
  display: grid;
  /*grid-template-columns: 1fr 1fr;*/
  grid-template-columns: 1fr;
}

.el-transfer__buttons {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  flex-wrap: wrap;
}

.el-transfer__buttons .el-button {
  margin-left: 0px;
}

.el-transfer__buttons .el-button [class*=el-icon-] + span {
  margin-left: 0px;
}

.collapse-box h6 {
  height: 16px;
  line-height: 16px;
  margin-top: -3px;
  margin-bottom: 3px;
  color: rgb(96, 96, 104);
}

.collapse-box span {
  font-size: 12px;
}

.nodeInfo {
  color: #606266;
}

.node-params-info {
  padding: 10px 20px;
  border: 1px solid #D3D6D8;
  border-left: none;
  border-radius: 0 4px 4px 0;
  overflow: auto;
  height: calc(100vh - 413px);
}

.select-node {
  border: 1px solid #D3D6D8;
  border-left: none;
  padding: 10px 0px;
  overflow: auto;
  height: calc(100vh - 413px);
}

.tip-text {
  /*提示文字*/
  color: #a5a5a8;
  font-size: 12px;
  margin-bottom: -10px;
  margin-top: -10px;
  user-select: none;
}

.select-node-text:hover{
  background: #ecf0ff;
  cursor: pointer;
}
</style>