<template>
  <el-dialog :title="title" :width="width+'px'" :visible.sync="visible" @close="close">
    <slot></slot>
    <div slot="footer" class="dialog-footer">
      <el-button @click="hide">{{cancelButtonText}}</el-button>
      <el-button type="primary" @click.stop="submitForm(formName)" :disabled="loading">
        <template v-if="loading">
          {{ confirmingButtonText }}
        </template>
        <template v-else>
          {{ confirmButtonText }}
        </template>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "Modal",
  props:{
    width:{
      type:Number,
      default:500,
    },
    title:{
      type:String,
      default:'',
    },
    confirmingButtonText:{
      type:String,
      default:'保存中',
    },
    confirmButtonText:{
      type:String,
      default:'保存',
    },
    cancelButtonText:{
      type:String,
      default:'取消',
    },
    formName:{
      type:String,
      default:null,
    },
    confirm: {
      type: Function,
      default: () => {
        return new Promise((resolve, reject) => {
          resolve();
        });
      }
    },
    //关闭时的回调函数
    close: {
      type: Function,
      default: () => {
        return new Promise((resolve, reject) => {
          resolve();
        });
      }
    },
  },
  data(){
    return{
      visible:false,
      loading:false,
    }
  },
  methods:{
    show(){
      this.visible=true;
    },
    hide(){
      this.visible=false;
    },
    submitForm(formName){
      this.loading=true;
      if(formName){
        this.$parent.$refs[formName].validate((valid) => {
          if (valid) {
            this.onConfirm();
          } else {
            this.loading = false;
            return false;
          }
        });
      }
    },
    onConfirm(){
      const save = this.confirm();
      if (save === false || save === undefined) {
        this.loading = false;
        return;
      }

      if (save instanceof Promise) {
        save.finally(() => {
          this.loading = false;
          // this.hide();
        });
      }
      else {
        this.loading = false;
        this.hide();
      }
    },
  }
}
</script>

<style scoped>
.modal-area{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
}
.modal{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
}
</style>