const transformNumber=(num)=>{
    let reg=/^-?[0-9]+.?[0-9]*/;
    if(reg.test(Number(num)))
        return Number(num);
    else if(!isNaN(new Date(num))){
        return new Date(num).valueOf()/86400000+25569;
    }
    // else return 0;
    else throw new Error('calcError');
}

const calcPrecision=(num)=>{
    let baseNum;
    try {
        baseNum = num.toString().split(".")[1].length;
    } catch (e) {
        baseNum = 0;
    }

    return baseNum;
}

function toFixed(number, precision) {
    let str = number + ''
    let len = str.length
    let last = str.substring(len - 1, len)
    if (last == '5') {
        last = '6'
        str = str.substring(0, len - 1) + last
        return (str - 0).toFixed(precision)
    } else {
        return number.toFixed(precision)
    }
}

const negative = (a) => {
    return reduce(0 , transformNumber(a));
}

const reverse = (a) => {
    return !transformNumber(a);
}

const add = (a, b) => {
    let precision=Math.max(calcPrecision(a),calcPrecision(b));
    return toFixed(transformNumber(a) + transformNumber(b),precision);
}

const reduce = (a, b) => {
    let precision=Math.max(calcPrecision(a),calcPrecision(b));
    return toFixed(transformNumber(a) - transformNumber(b),precision);
}

const multiply = (a, b) => {
    let precision=Math.max(calcPrecision(a),calcPrecision(b));
    return transformNumber(a).toString().replace(',','') * transformNumber(b).toString().replace(',','') / Math.pow(10, precision);
}

const divide = (a, b) => {
    let precision1=calcPrecision(a),
        precision2=calcPrecision(b),
        baseNum1 = Number(a.toString().replace(".", "")),
        baseNum2 = Number(b.toString().replace(".", ""));
    return (baseNum1 / baseNum2) * Math.pow(10, precision2 - precision1);
}

const equal=(a,b)=>{
    let reg=/^[0-9]+.?[0-9]*/,left,right;
    if(reg.test(Number(a)))
        left=Number(a);
    else if(a=='true'||a=='TRUE')
        left=true;
    else if(a=='false'||a=='FALSE')
        left=false;
    if(reg.test(Number(b)))
        right=Number(b);
    else if(b=='true'||b=='TRUE')
        right=true;
    else if(b=='false'||b=='FALSE')
        right=false;

    return left==right;
}

const less=(a,b)=>{
    let reg=/^[0-9]+.?[0-9]*/,left,right;
    if(reg.test(Number(a)))
        left=Number(a);
    else if(a=='true'||a=='TRUE')
        left=true;
    else if(a=='false'||a=='FALSE')
        left=false;
    if(reg.test(Number(b)))
        right=Number(b);
    else if(b=='true'||b=='TRUE')
        right=true;
    else if(b=='false'||b=='FALSE')
        right=false;

    return left<right;
}

const lessEqual=(a,b)=>{
    let reg=/^[0-9]+.?[0-9]*/,left,right;
    if(reg.test(Number(a)))
        left=Number(a);
    else if(a=='true'||a=='TRUE')
        left=true;
    else if(a=='false'||a=='FALSE')
        left=false;
    if(reg.test(Number(b)))
        right=Number(b);
    else if(b=='true'||b=='TRUE')
        right=true;
    else if(b=='false'||b=='FALSE')
        right=false;

    return left<=right;
}

const greater=(a,b)=>{
    let reg=/^[0-9]+.?[0-9]*/,left,right;
    if(reg.test(Number(a)))
        left=Number(a);
    else if(a=='true'||a=='TRUE')
        left=true;
    else if(a=='false'||a=='FALSE')
        left=false;
    if(reg.test(Number(b)))
        right=Number(b);
    else if(b=='true'||b=='TRUE')
        right=true;
    else if(b=='false'||b=='FALSE')
        right=false;

    return left>right;
}

const greaterEqual=(a,b)=>{
    let reg=/^[0-9]+.?[0-9]*/,left,right;
    if(reg.test(Number(a)))
        left=Number(a);
    else if(a=='true'||a=='TRUE')
        left=true;
    else if(a=='false'||a=='FALSE')
        left=false;
    if(reg.test(Number(b)))
        right=Number(b);
    else if(b=='true'||b=='TRUE')
        right=true;
    else if(b=='false'||b=='FALSE')
        right=false;

    return left>=right;
}

const notEqual=(a,b)=>{
    let reg=/^[0-9]+.?[0-9]*/,left,right;
    if(reg.test(Number(a)))
        left=Number(a);
    else if(a=='true'||a=='TRUE')
        left=true;
    else if(a=='false'||a=='FALSE')
        left=false;
    if(reg.test(Number(b)))
        right=Number(b);
    else if(b=='true'||b=='TRUE')
        right=true;
    else if(b=='false'||b=='FALSE')
        right=false;

    return left!=right;
}

const SUM = (num1, num2 = undefined, ...array) => {
    let nums = [], result = 0;
    if (Array.isArray(num1))
        nums = num1;
    else nums.push(num1);

    if (Array.isArray(num2)) {
        nums = [...nums, ...num2];
    } else if (num2)
        nums.push(num2);

    for (let i in array) {
        if (Array.isArray(array[i])) {
            nums = [...nums, ...array[i]];
        } else nums.push(array[i]);
    }

    for (let n of nums) {
        result=add(result,transformNumber(n))
    }

    return result;
}

const AVERAGE = (num1, num2 = undefined, ...array) => {
    let nums = [], result = 0;
    if (Array.isArray(num1))
        nums = num1;
    else nums.push(num1);

    if (Array.isArray(num2)) {
        nums = [...nums, ...num2];
    } else if (num2)
        nums.push(num2);

    for (let i in array) {
        if (Array.isArray(array[i])) {
            nums = [...nums, ...array[i]];
        } else nums.push(array[i]);
    }

    for (let n of nums) {
        result=add(result,transformNumber(n))
    }

    return divide(result,nums.length);
}

const COUNT=(num1,num2=undefined,...array)=>{
    let nums=[],result=0;
    let reg=/^[0-9]+.?[0-9]*/;

    if(Array.isArray(num1))
        nums=num1;
    else nums.push(num1);

    if(Array.isArray(num2)){
        nums=[...nums,...num2];
    }
    else if(num2)
        nums.push(num2);

    for (let i in array){
        if(Array.isArray(array[i])){
            nums=[...nums,...array[i]];
        }
        else nums.push(array[i]);
    }

    for (let n of nums){
        if(reg.test(Number(n)))
        result++;
    }

    return result;
}

const MAX=(num1,num2=undefined,...array)=>{
    let nums=[],result=0;

    if(Array.isArray(num1))
        nums=num1;
    else nums.push(num1);

    if(Array.isArray(num2)){
        nums=[...nums,...num2];
    }
    else if(num2)
        nums.push(num2);

    for (let i in array){
        if(Array.isArray(array[i])){
            nums=[...nums,...array[i]];
        }
        else nums.push(array[i]);
    }

    result=nums[0];
    for (let n of nums){
        result=Math.max(result,transformNumber(n));
    }

    return result;
}

const MIN=(num1,num2=undefined,...array)=>{
    let nums=[],result=0;

    if(Array.isArray(num1))
        nums=num1;
    else nums.push(num1);

    if(Array.isArray(num2)){
        nums=[...nums,...num2];
    }
    else if(num2)
        nums.push(num2);

    for (let i in array){
        if(Array.isArray(array[i])){
            nums=[...nums,...array[i]];
        }
        else nums.push(array[i]);
    }

    result=nums[0];
    for (let n of nums){
        result=Math.min(result,transformNumber(n));
    }

    return result;
}

const CONCAT=(num1,...array)=>{
    let nums=[],result='';

    if(Array.isArray(num1))
        nums=num1;
    else nums.push(num1);

    for (let i in array){
        if(Array.isArray(array[i])){
            nums=[...nums,...array[i]];
        }
        else nums.push(array[i]);
    }

    for (let n of nums){
        if(n.trim()!==''){
            result+=n.toString();
        }
    }

    return result;
}

const IF=(requirement,truePart,falsePart)=>{
    if(requirement)
        return truePart.replace(/\'/g,'').replace(/\"/g,'');
    else return falsePart.replace(/\'/g,'').replace(/\"/g,'');
}

const ABS=(num)=>{
    return Math.abs(transformNumber(num));
}

const INT=(num)=>{
    return Math.floor(transformNumber(num));
}

const MOD=(num1,num2)=>{
    return transformNumber(num1)%transformNumber(num2);
}

const ROUND=(num,digits=0)=>{
    let number=transformNumber(num);
    if(digits>=0){
        return number.toFixed(digits);
    }
    else{
        let digitsNum=Math.pow(10,digits);
        return Math.round(number*digitsNum)/digitsNum;
    }
}



const operator=[
    {
        order:2,
        value:'+',
        func:add,
    },
    {
        order:2,
        value:'-',
        func:reduce,
    },
    {
        order:3,
        value:'*',
        func:multiply,
    },
    {
        order:3,
        value:'/',
        func:divide,
    },
    {
        order:4,
        value: '^',
        func:negative,
    },
    {
        order:4,
        value: '!',
        func:reverse,
    },
    {
        order:1,
        value: '=',
        func:equal,
    },
    {
        order:1,
        value: '<',
        func:less,
    },
    {
        order:1,
        value: '<=',
        func:lessEqual,
    },
    {
        order:1,
        value: '>',
        func:greater,
    },
    {
        order:1,
        value: '>=',
        func:greaterEqual,
    },
    {
        order:1,
        value: '<>',
        func:notEqual,
    },
    {
        value: '(',
    },
    {
        value: ')',
    },
    {
        value: ',',
    },
];

const func=[
    {
        name:'SUM',
        func:SUM,
    },
    {
        name:'ABS',
        func:ABS,
    },
    {
        name:'INT',
        func:INT,
    },
    {
        name:'MOD',
        func:MOD,
    },
    {
        name:'ROUND',
        func:ROUND,
    },
    {
        name:'AVERAGE',
        func:AVERAGE,
    },
    {
        name:'COUNT',
        func:COUNT,
    },
    {
        name:'MAX',
        func:MAX,
    },
    {
        name:'MIN',
        func:MIN,
    },
    {
        name:'CONCAT',
        func:CONCAT,
    },
    {
        name:'IF',
        func:IF,
    },
]

const requirement=[
    {
        key:'greater',
        func:greater,
    },
    {
        key:'less',
        func:less,
    },
    {
        key:'equal',
        func:equal,
    },
    {
        key:'notEqual',
        func:notEqual,
    },
    {
        key:'greaterEqual',
        func:greaterEqual,
    },
    {
        key:'lessEqual',
        func:lessEqual,
    },
]

const calcFormula=(val)=>{

}

//分割字符串
const participle=(val)=>{
    let part=[];

    let str="";
    for (let i in val){
        let index=operator.findIndex((op)=>op.value===val[i]);
        if(index===-1){
            str+=val[i];
            if(i==val.length-1){
                part.push(str);
            }
        }
        else{
            if((val[i]=='<'||val[i]=='>')&&str.length===0){
                str+=val[i];
                continue;
            }

            let w=val[i];
            if(str.length!==0){
                if((str=='<'&&(val[i]=='='||val[i]=='>'))||(str=='>'&&val[i]=='=')){
                    str+=val[i];
                    part.push(str);
                    str="";
                    continue;
                }

                part.push(str);
                str="";
            }
            else if(val[i]==='-'&&str.length===0&&(part.length===0||part[part.length-1]!==')')){
                w='^';
            }

            part.push(w);
        }
    }

    return part;
}

//中缀转后缀
const infixToPostFix=(words)=>{
    let output=[],op=[],args=[],args_count=[];
    while (words.length){
        let word=words.shift();
        if(word==='('){
            op.push(word);
        }
        else if(word===')'){
            while (op[op.length-1]!=='('){
                output.push(op.pop());
                if(!op.length){
                    throw 'Error';
                }
            }

            op.pop();
            if(func.findIndex(f=>f.name===op[op.length-1])!==-1){
                args[args.length-1]++;
                args_count.push(args.pop());
                output.push(op.pop());
            }
        }
        else if(func.findIndex(f=>f.name===word)!==-1){
            op.push(word);
            args.push(0);
        }
        else if(word===','){
            args[args.length-1]++;
            while (op[op.length-1]!=='('){
                output.push(op.pop());
                if(!op.length){
                    throw 'Error';
                }
            }
        }
        else if(operator.findIndex(op=>op.value===word)!==-1){
            while (op.length&&op[op.length-1]!=='('&&
            operator.find((o)=>o.value===word).order<=operator.find((o)=>o.value===op[op.length-1]).order){
                output.push(op.pop());
            }

            op.push(word);
        }
        else{
            output.push(word);
        }
    }

    while(op.length){
        if(op[op.length-1]==='('){
            throw 'Error';
        }

        output.push(op.pop());
    }


    return {
        output,
        args:args_count,
    }
}

//计算后缀表达式
const calcPostfix=(postfix)=>{
    let stack=[];
    while (postfix.output.length){
        let el=postfix.output.shift(),operationIndex=-1,funcIndex=-1;
        operationIndex=operator.findIndex(op=>op.value===el);
        funcIndex=func.findIndex(f=>f.name===el);
        if(operationIndex!==-1){
            let param1,param2;
            if(el==='^'){
                param1=stack.pop();
                stack.push((operator[operationIndex].func)(param1));
            }
            else{
                param1=stack.pop();
                param2=stack.pop();
                stack.push((operator[operationIndex].func)(param2,param1));
            }
        }
        else if(funcIndex!==-1){
            let params=[],num=postfix.args.pop();
            for(let i=0;i<num;i++){
                params.unshift(stack.pop());
            }
            if(!params.length||(params.length===1&&params[0]===undefined)){
                throw new Error('functionError');
            }
            stack.push((func[funcIndex].func)(...params));
        }
        else{
            stack.push(el);
        }
    }

    if(stack[0] instanceof Array){
        let result=0;
        for(let i in stack[0]){
            if(stack[0][i]===''){
                // result+=0
            }
            else result = add(result,parseFloat(stack[0][i]));
        }
        return result;
    }
    else return stack[0];
}

const executeFormula=(formula,param1,param2)=>{
    let index=requirement.findIndex((r)=>r.key==formula)
    if(index!==-1)
        return (requirement[index].func)(param1,param2);
    else return false;
}



export {
    calcFormula,
    participle,
    infixToPostFix,
    calcPostfix,
    executeFormula,
}