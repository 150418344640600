<template>
  <div class="side-box">
    <div class="side-box-header flex">
      <div class="side-box-title">
        <slot name="title"></slot>
      </div>
      <div class="side-box-close">
        <i class="el-icon-close" @click="close"></i>
      </div>
    </div>
    <div class="side-box-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBox",
  data(){
    return{

    }
  },
  methods:{
    close(){
      this.$emit('closeSideBox');
    },
  }
}
</script>

<style scoped>
.side-box{
  flex:0 0 280px;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  box-shadow: 1px 2px 5px 2px rgba(51,51,51,15%);
  position: relative;
  z-index:900;
  overflow: hidden;
}
.side-box-header{
  justify-content: space-between;
  align-items: center;
}
.side-box-close{
  color: #909399;
  font-size: 16px;
  cursor: pointer;
}
.el-icon-close:hover{
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.side-box-title{
  font-size: 18px;
  color: #303133;
}
.side-box-content{
  padding:20px 0;
  height: 100%;
  overflow-y: auto;
}
</style>