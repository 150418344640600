<template>
  <div class="p-20-15">
    <el-button @click="backup">备份数据库</el-button>
    <el-button @click="selectFile">还原数据库</el-button>
    <el-button @click="awakenApiAddress">配置API地址</el-button>
    <input type="file" ref="file" style="display: none" @change="uploadFile">
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      apiAddress: ''
    }
  },
  methods: {
    backup() {
      //使用axios缓存并下载数据库备份文件
      this.$http({
        method: 'get',
        url: 'mysql/backup',
        responseType: 'blob'
      }).then((e) => {
        const blob = new Blob([e.data ?? ""], {
          type: 'text/markdown'
        });
        let url = URL.createObjectURL(blob);
        let aTag = document.createElement('a');
        aTag.href = url;
        //获取当前年份_月份_日期_时_分_秒_毫秒
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();
        let millisecond = date.getMilliseconds();
        //拼接文件名
        let fileName = 'mysql_backup_' + year + "_" + month + "_" + day + "_" + hour + "_" + minute + "_" + second + "_" + millisecond + ".sql";
        aTag.download = `${fileName}`;
        aTag.click();
        this.$toast("成功下载备份文件！")
      }).catch(() => {
      });
    },
    selectFile() {
      //触发input的点击事件
      this.$refs.file.click();
    },
    uploadFile(e) {
      if (e.target.value) {
        //获取文件
        let file = e.target.files[0];
        //创建FormData对象
        let formData = new FormData();
        //将文件添加到FormData对象中
        formData.append("file", file);
        //使用axios上传文件
        this.$http({
          method: 'post',
          url: 'mysql/restore',
          data: formData
        }).then(() => {
          this.$toast("成功还原数据库！")
          //清空input的value值
          e.target.value = "";
        }).catch(() => {
        });
      }
    },
    awakenApiAddress() {
      this.$prompt('请输入API地址', '配置', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.apiAddress,
      }).then(({value}) => {
        this.apiAddress = value;
        this.setApiAddress();

      }).catch(() => {

      });
    },
    getApiAddress() {
      this.$http.get('configure/api').then(res => {
        this.apiAddress = res.data;
      })
    },
    setApiAddress() {
      this.$http.post('configure/api', {apiAddress: this.apiAddress}).then(res => {
        this.$toast(`成功设置API地址为${this.apiAddress}`);
      })
    },
  },
  mounted() {
    this.getApiAddress();
  }
}
</script>

<style scoped>
#restore-input {
  height: 0;
  width: 0;
  display: none;
  visibility: hidden;
  opacity: 0;
}
</style>