<template>
  <div>
    <template v-for="(o,i) in paramsInfo" v-if="(paramsInfo.value??[]).length!==0">
      <div class="date" v-if="o.length!==0">
        <h4>{{ paramsDataName[i] }}</h4>
        <template v-if="i==='value'">
          <el-radio-group v-model="paramsData['value']['value']">
            <template v-for="(j,ji) in o">
              <div style="margin-bottom: 5px;">
                <el-radio style="height: 20px;line-height: 20px;" :label="j.field">{{ j.chname }}</el-radio>
                <el-checkbox-group :disabled="paramsData['value']['value']!==j.field"
                                   v-model="paramsData['value']['info']"
                                   style="margin-left: 10px;margin-top: 3px;">
                  <template v-for="k in j.info">
                    <div style="height: 20px;line-height: 20px;">
                      <el-checkbox :label="k.field">{{ k.chname }}</el-checkbox>
                    </div>
                  </template>
                </el-checkbox-group>
              </div>
            </template>
          </el-radio-group>
        </template>

        <template v-if="i==='date'">
          <div style="margin-top: -10px;">
            <el-radio-group v-model="paramsData.date.date_type" @change="dateTypeChange" v-if="$root.templateType==='config'&&datatype!=='rtinfo'">
              <div style="margin-bottom: 5px;">
                <el-radio style="height: 20px;line-height: 20px;" :label="'start_date'">开始时间</el-radio>
              </div>
              <div style="margin-bottom: 5px;">
                <el-radio style="height: 20px;line-height: 20px;" :label="'period'">时段时间</el-radio>
              </div>
              <div style="margin-bottom: 5px;">
                <el-radio style="height: 20px;line-height: 20px;" :label="'end_date'">结束时间</el-radio>
              </div>
            </el-radio-group>

            <div class="absolute-datetime" v-if="$root.templateType==='config'&&datatype!=='rtinfo'&&paramsData.date.date_type==='start_date'">
              <el-form-item label="开始时间">
                <el-time-picker v-model="paramsData.date.start_date" size="mini" placeholder="请选择开始时间"
                                type="datetime" align="center" value-format="HH:mm">
                </el-time-picker>
              </el-form-item>
            </div>

            <div class="absolute-datetime" v-if="$root.templateType==='config'&&datatype!=='rtinfo'&&paramsData.date.date_type==='end_date'">
              <el-form-item label="结束时间">
                <el-time-picker v-model="paramsData.date.end_date" size="mini" placeholder="请选择结束时间"
                                type="datetime" align="center" value-format="HH:mm">
                </el-time-picker>
              </el-form-item>
            </div>

            <template v-for="(j,ji) in o">
              <div class="month" v-if="ji==='from_month'">
                <el-form-item label="月">
                  <el-select v-model="paramsData.date.from_month" size="mini" placeholder="开始">
                    <el-option
                        v-for="(item,i) in j"
                        :key="i"
                        :disabled="validateDate(item,paramsData.date.to_month,'greater')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <span class="connector">-</span>
                  <el-select v-model="paramsData.date.to_month" size="mini" placeholder="结束">
                    <el-option
                        v-for="(item,i) in j"
                        :key="i"
                        :disabled="validateDate(item,paramsData.date.from_month,'less')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="day" v-if="ji==='from_day'">
                <el-form-item label="日">
                  <el-select v-model="paramsData.date.from_day" size="mini" placeholder="开始">
                    <el-option
                        v-for="(item,i) in j"
                        :key="i"
                        :disabled="validateDate(item,paramsData.date.to_day,'greater')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <span class="connector">-</span>
                  <el-select v-model="paramsData.date.to_day" size="mini" placeholder="结束">
                    <el-option
                        v-for="(item,i) in j"
                        :key="i"
                        :disabled="validateDate(item,paramsData.date.from_day,'less')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="hour" v-if="ji==='from_hour'">
                <el-form-item label="小时">
                  <el-select v-model="paramsData.date.from_hour" placeholder="开始" size="mini">
                    <el-option
                        v-for="(item,hi) in paramsInfo.date.from_hour"
                        :key="hi"
                        :disabled="validateDate(item,paramsData.date.to_hour,'greater')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <span class="connector">-</span>
                  <el-select v-model="paramsData.date.to_hour" placeholder="结束" size="mini">
                    <el-option
                        v-for="(item,hi) in paramsInfo.date.to_hour"
                        :key="hi"
                        :disabled="validateDate(item,paramsData.date.from_hour,'less')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="minute" v-if="ji==='from_minute'">
                <el-form-item label="分钟">
                  <el-select v-model="paramsData.date.from_minute" placeholder="开始" size="mini">
                    <el-option
                        v-for="(item,hi) in paramsInfo.date.from_minute"
                        :key="hi"
                        :disabled="validateDate(item,paramsData.date.to_minute,'greater')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <span class="connector">-</span>
                  <el-select v-model="paramsData.date.to_minute" placeholder="结束" size="mini">
                    <el-option
                        v-for="(item,hi) in paramsInfo.date.to_minute"
                        :key="hi"
                        :disabled="validateDate(item,paramsData.date.from_minute,'less')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="interval" v-if="ji==='interval'">
                <el-form-item label="间隔">
                  <el-select v-model="paramsData.date.interval" size="mini" placeholder="时间">
                    <el-option
                        v-for="(item,i) in (j.find(e=>{return e.unit == paramsData.date.intervalUnit;})??[]).val"
                        :key="i"
                        :label="item.unit"
                        :value="item">
                    </el-option>
                  </el-select>
                  <span class="connector">单位</span>
                  <el-select v-model="paramsData.date.intervalUnit" size="mini" placeholder="单位"
                             @change="defaultUnit">
                    <el-option
                        v-for="(item,i) in j"
                        :key="i"
                        :label="item.unit"
                        :value="item.unit">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
    <template v-if="(paramsInfo.value??[]).length!==0">
      <div>
        <h4>参数排列方向</h4>
        <el-radio-group v-model="paramsData.direction">
          <div style="margin-bottom: 5px;height: 20px;line-height: 20px;">
            <el-radio label="horizontal">横向</el-radio>
          </div>
          <div style="margin-bottom: 5px;height: 20px;line-height: 20px;">
            <el-radio label="vertical">纵向</el-radio>
          </div>
        </el-radio-group>
      </div>
    </template>
  </div>
</template>

<script>
import DatePicker from "@/components/MomentPicker";

export default {
  name: "ParamsInfo",
  props: {
    datatype:{
      default:'',
    },
    paramsInfo: {
      type: Object
    },
    paramsData: {
      type: Object
    },
  },
  components: {
    DatePicker
  },
  data() {
    return {
      paramsDataName: {
        'date': '时间信息',
        'value': '取值类型'
      },
      test: '',

    }
  },
  methods: {
    dateTypeChange(val){
      this.paramsData.date.start_date=null;
      this.paramsData.date.end_date=null;

      if(val==='start_date')
        this.paramsData.date.start_date='00:00:00';
      else if(val==='end_date')
        this.paramsData.date.end_date='23:59:59';
    },
    rename(number, startText, middleText, endText) {
      if (number < 0) {
        return startText[0] + middleText + (Math.abs(number)) + endText;
      } else {
        return startText[1] + middleText + number + endText;
      }
    },
    transferDate(d) {
      if (d === '-0') return 0;
      return d >= 0 ? (d - -1000) : Math.abs(d);
    },
    validateDate(item, require, type = 'greater') {
      return false;
      if (require === '') {
        return false;
      } else {
        // console.log(item, require, this.transferDate(item), this.transferDate(require))
        if (type == 'greater')
          return this.transferDate(item) > this.transferDate(require);
        else if (type == 'less')
          return this.transferDate(item) < this.transferDate(require);
      }
    },
    defaultUnit() {
      // console.log(this.paramsInfo.date.interval.filter(i=> i.unit == this.paramsData.date.intervalUnit)[0].val[0])
      this.paramsData.date.interval = this.paramsInfo.date.interval.filter(i => i.unit == this.paramsData.date.intervalUnit)[0].val[0];
    }
  }
}
</script>

<style scoped>

</style>