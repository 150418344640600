<template>
  <div :class="'resize-dashed-line '+data.type"
       :style="{left:(data.type=='row'?'0':data.left+40+data.size-offset.offsetLeft)+'px',
       top:(data.type=='col'?'0':data.top+30+data.size-offset.offsetTop)+'px',}"></div>
</template>

<script>
export default {
  name: "ResizeDashedLine",
  props:{
    data:Object,
    offset:Object,
  },
  data(){
    return{

    }
  },
  methods:{

  },
  created() {

  }
}
</script>

<style scoped>
.resize-dashed-line{
  position: absolute;
  z-index:800;
}
.resize-dashed-line.row{
  width: 100%;
  height: 1px;
  border: 1px dashed #5A87F7;
}
.resize-dashed-line.col{
  height: 100%;
  width: 1px;
  border: 1px dashed #5A87F7;
}
</style>