<template>
  <div class="flex toolbar" @click="closeDropdown" >
<!--    撤销-->
    <div :class="'toolbar-button '+(undoList.length?'':'disabled')" @click.stop="doAction('undo')" data-tooltip="撤销">
      <svg width="16" height="16" style="opacity: 0.5;">
        <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M11.5656391,4.43436088 L9,7 L16,7 L16,0 L13.0418424,2.95815758 C11.5936787,1.73635959 9.72260775,1 7.67955083,1 C4.22126258,1 1.25575599,3.10984908 0,6 L2,7 C2.93658775,4.60974406 5.12943697,3.08011229 7.67955083,3 C9.14881247,3.0528747 10.4994783,3.57862053 11.5656391,4.43436088 Z" transform="matrix(-1 0 0 1 17 5)"/></g>
      </svg>
    </div>

<!--    取消撤销-->
    <div :class="'toolbar-button '+(redoList.length?'':'disabled')" @click.stop="doAction('redo')" data-tooltip="取消撤销">
      <svg width="16" height="16" style="opacity: 0.5;">
        <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M11.5656391,4.43436088 L9,7 L16,7 L16,0 L13.0418424,2.95815758 C11.5936787,1.73635959 9.72260775,1 7.67955083,1 C4.22126258,1 1.25575599,3.10984908 0,6 L2,7 C2.93658775,4.60974406 5.12943697,3.08011229 7.67955083,3 C9.14881247,3.0528747 10.4994783,3.57862053 11.5656391,4.43436088 Z" transform="translate(1 5)"/></g>
      </svg>
    </div>

    <div class="horizontal-driver small"></div>

<!--    字体-->
    <div :class="'toolbar-button '+(toolbar.font?'active':'')" @click.stop="clickDropdown('font')" data-tooltip="字体">
      <Dropdown :options="font" :type="'font'" :select-value="dropdownData.font" v-on:save="saveStyle"></Dropdown>
    </div>

<!--    字体大小-->
    <div :class="'toolbar-button '+(toolbar.fontSize?'active':'')" @click.stop="clickDropdown('fontSize')" data-tooltip="字体大小">
      <Dropdown :options="size" :option-width="60" :type="'fontSize'" :select-value="dropdownData.fontSize" v-on:save="saveStyle"></Dropdown>
    </div>

    <div class="horizontal-driver small"></div>

<!--    粗体-->
    <div :class="'toolbar-button '+(dropdownData.bold?'active':'')" @click.stop="saveStyle({type:'bold',value:!dropdownData.bold})" data-tooltip="粗体">
      <svg width="10" height="12" style="opacity: 0.5;">
        <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M9,3.5 C9,1.57 7.43,0 5.5,0 L1.77635684e-15,0 L1.77635684e-15,12 L6.25,12 C8.04,12 9.5,10.54 9.5,8.75 C9.5,7.45 8.73,6.34 7.63,5.82 C8.46,5.24 9,4.38 9,3.5 Z M5,2 C5.82999992,2 6.5,2.67 6.5,3.5 C6.5,4.33 5.82999992,5 5,5 L3,5 L3,2 L5,2 Z M3,10 L3,7 L5.5,7 C6.32999992,7 7,7.67 7,8.5 C7,9.33 6.32999992,10 5.5,10 L3,10 Z"/></g>
      </svg>
    </div>

<!--    斜体-->
    <div :class="'toolbar-button '+(dropdownData.italic?'active':'')" @click.stop="saveStyle({type:'italic',value:!dropdownData.italic})" data-tooltip="斜体">
      <svg width="12" height="12" style="opacity: 0.5;">
        <g xmlns="http://www.w3.org/2000/svg"><polygon fill="#000000" fill-rule="evenodd" points="4 0 4 2 6.58 2 2.92 10 0 10 0 12 8 12 8 10 5.42 10 9.08 2 12 2 12 0"/></g>
      </svg>
    </div>

<!--    下划线-->
    <div :class="'toolbar-button '+(dropdownData.underline?'active':'')" @click.stop="saveStyle({type:'underline',value:!dropdownData.underline})" data-tooltip="下划线">
      <svg width="12" height="15" style="opacity: 0.5;">
        <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M6,12 C8.76,12 11,9.76 11,7 L11,0 L9,0 L9,7 C9,8.75029916 7.49912807,10 6,10 C4.50087193,10 3,8.75837486 3,7 L3,0 L1,0 L1,7 C1,9.76 3.24,12 6,12 Z M0,13 L0,15 L12,15 L12,13 L0,13 Z"/></g>
      </svg>
    </div>

<!--    字体颜色-->
    <div :class="'toolbar-button '+(toolbar.color?'active':'')" @click.stop="clickDropdown('color')" data-tooltip="字体颜色">
      <svg width="19" height="18" :style="{opacity: 0.5,'border-bottom':'3px solid #'+dropdownData.color}">
        <g xmlns="http://www.w3.org/2000/svg" transform="translate(4,0)"><path fill="#000000" fill-rule="evenodd" d="M7,0 L5,0 L0.5,12 L2.5,12 L3.62,9 L8.37,9 L9.49,12 L11.49,12 L7,0 L7,0 Z M4.38,7 L6,2.67 L7.62,7 L4.38,7 L4.38,7 Z"/></g>
      </svg>
      <ColorPicker :hex="dropdownData.color" :type="'color'" v-on:save="saveStyle"></ColorPicker>
    </div>

    <div class="horizontal-driver small"></div>

<!--    背景颜色-->
    <div :class="'toolbar-button '+(toolbar.backgroundColor?'active':'')" @click.stop="clickDropdown('backgroundColor')" data-tooltip="背景颜色">
      <svg width="16" height="19" :style="{opacity: 0.5,'border-bottom':'3px solid #'+dropdownData.backgroundColor}">
        <g xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path fill="#000000" d="M14.5,8.87 C14.5,8.87 13,10.49 13,11.49 C13,12.32 13.67,12.99 14.5,12.99 C15.33,12.99 16,12.32 16,11.49 C16,10.5 14.5,8.87 14.5,8.87 L14.5,8.87 Z M12.71,6.79 L5.91,0 L4.85,1.06 L6.44,2.65 L2.29,6.79 C1.9,7.18 1.9,7.81 2.29,8.2 L6.79,12.7 C6.99,12.9 7.24,13 7.5,13 C7.76,13 8.01,12.9 8.21,12.71 L12.71,8.21 C13.1,7.82 13.1,7.18 12.71,6.79 L12.71,6.79 Z M4.21,7 L7.5,3.71 L10.79,7 L4.21,7 L4.21,7 Z"/>
          </g>
        </g>
      </svg>
      <ColorPicker :hex="dropdownData.backgroundColor" :type="'backgroundColor'" v-on:save="saveStyle"></ColorPicker>
    </div>

<!--    设置边框-->
    <div :class="'toolbar-button '+(toolbar.border?'active':'')" @click.stop="clickDropdown('border')" data-tooltip="边框">
      <svg width="14" height="14" style="opacity: 0.5;">
        <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M0,0 L0,14 L14,14 L14,0 L0,0 L0,0 Z M6,12 L2,12 L2,8 L6,8 L6,12 L6,12 Z M6,6 L2,6 L2,2 L6,2 L6,6 L6,6 Z M12,12 L8,12 L8,8 L12,8 L12,12 L12,12 Z M12,6 L8,6 L8,2 L12,2 L12,6 L12,6 Z"/></g>
      </svg>
      <BorderPalette v-on:setBorder="setBorder"></BorderPalette>
    </div>

<!--    合并单元格-->
    <div :class="'toolbar-button '+((selectArea.length&&selectArea[0].data.merge)?'active':'')+' '+(!selectArea.length||(selectArea.length==1&&!selectArea[0].data.merge)?'disabled':'')"
         @click.stop="mergeCell" data-tooltip="合并单元格">
      <svg width="18" height="18" style="opacity: 0.5;">
        <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M3,6 L1,6 L1,2 L8,2 L8,4 L3,4 L3,6 Z M10,4 L10,2 L17,2 L17,6 L15,6 L15,4 L10,4 Z M10,14 L15,14 L15,12 L17,12 L17,16 L10,16 L10,14 Z M1,12 L3,12 L3,14 L8,14 L8,16 L1,16 L1,12 Z M1,8 L5,8 L5,6 L8,9 L5,12 L5,10 L1,10 L1,8 Z M10,9 L13,6 L13,8 L17,8 L17,10 L13,10 L13,12 L10,9 Z"/></g>
      </svg>
    </div>

    <div class="horizontal-driver small"></div>

<!--    水平对齐-->
    <div :class="'toolbar-button '+(toolbar.textAlign?'active':'')" @click.stop="clickDropdown('textAlign')" data-tooltip="水平对齐">
      <Dropdown :options="textAlign" :type="'textAlign'" :select-value="dropdownData.textAlign"
                :icon="true" :option-width="40"
                v-on:save="saveStyle">
        <template v-slot:icon>
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M0,14 L10,14 L10,12 L0,12 L0,14 Z M10,4 L0,4 L0,6 L10,6 L10,4 Z M0,0 L0,2 L14,2 L14,0 L0,0 Z M0,10 L14,10 L14,8 L0,8 L0,10 Z"/></g>
          </svg>
        </template>
      </Dropdown>
    </div>

<!--    垂直对齐-->
    <div :class="'toolbar-button '+(toolbar.verticalAlign?'active':'')" @click.stop="clickDropdown('verticalAlign')" data-tooltip="垂直对齐">
      <Dropdown :options="verticalAlign" :type="'verticalAlign'" :select-value="dropdownData.verticalAlign"
                :icon="true" :option-width="40"
                v-on:save="saveStyle">
        <template v-slot:icon>
          <svg width="12" height="18" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M9.5,3 L7,3 L7,0 L5,0 L5,3 L2.5,3 L6,6.5 L9.5,3 L9.5,3 Z M0,8 L0,10 L12,10 L12,8 L0,8 L0,8 Z M2.5,15 L5,15 L5,18 L7,18 L7,15 L9.5,15 L6,11.5 L2.5,15 L2.5,15 Z"/></g>
          </svg>
        </template>
      </Dropdown>
    </div>

    <div class="horizontal-driver small"></div>

<!--    单元格格式-->
    <div :class="'toolbar-button '+(toolbar.type?'active':'')" @click.stop="clickDropdown('type')" data-tooltip="单元格格式">
      <Dropdown :options="type" :type="'type'" :option-width="50" :select-value="dropdownData.type" v-on:save="saveStyle"></Dropdown>
    </div>
    <div :class="'toolbar-button '+(toolbar.extra?'active':'')" v-if="dropdownData.type=='number'" @click.stop="clickDropdown('extra')" data-tooltip="小数点后位数">
      <input class="extraInput" type="number" min="0" max="5" v-model:value="dropdownData.extra" @input="extraChange">
    </div>

    <div class="horizontal-driver small"></div>

<!--    插入图片-->
    <div :class="'toolbar-button '" @click.stop="closeDropdown();$refs.imageInput.click();" data-tooltip="插入图片">
      <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" height="20" style="opacity: 0.5;">
        <path d="M918.457 862.043H105.543c-20.435 0-37-16.565-37-37V198.957c0-20.435 16.565-37 37-37h812.913c20.435 0 37 16.565 37 37v626.087c0.001 20.434-16.565 36.999-36.999 36.999z m-775.914-74h738.913V235.957H142.543v552.086z"></path>
        <path d="M155.696 826.143l-55.391-49.069L316.609 532.9c12.337-13.926 33.096-16.526 48.486-6.072l95.808 65.08 182.756-233.7a36.996 36.996 0 0 1 27.779-14.182 37.005 37.005 0 0 1 28.751 12.093L945.84 626.466l-54.768 49.765-216.147-237.879L497.88 664.749c-11.996 15.339-33.828 18.756-49.937 7.814l-97.517-66.241-194.73 219.821z"></path>
        <path d="M331.174 373m-55 0a55 55 0 1 0 110 0 55 55 0 1 0-110 0Z"></path>
      </svg>
      <form ref="imageForm">
        <input type="file" accept="image/*" class="imageInput" ref="imageInput" @change="imageSelect">
      </form>
    </div>

<!--    插入图表-->
    <div :class="'toolbar-button '+(toolbar.chart?'active':'')" @click.stop="clickDropdown('chart')" data-tooltip="插入图表">
      <svg width="16" height="16" style="opacity: 0.5;">
        <g xmlns="http://www.w3.org/2000/svg">
          <path fill="#000000" fill-rule="evenodd" d="M15,0 C15.55,0 16,0.45 16,1 L16,15 C16,15.55 15.55,16 15,16 L1,16 C0.45,16 0,15.55 0,15 L0,1 C0,0.45 0.45,0 1,0 L15,0 Z M2,2 L2,14 L14,14 L14,2 L2,2 Z M6,12 L4,12 L4,7 L6,7 L6,12 L6,12 Z M9,12 L7,12 L7,4 L9,4 L9,12 L9,12 Z M12,12 L10,12 L10,8 L12,8 L12,12 L12,12 Z"/>
        </g>
      </svg>
    </div>

    <div class="horizontal-driver small"></div>

<!--    函数-->
    <div :class="'toolbar-button '+(toolbar.func?'active':'')+' '+(!selectArea.length?'disabled':'')" @click.stop="clickDropdown('func')" data-tooltip="函数">
      <Dropdown :options="functions" :type="'func'"
                :icon="true"
                v-on:save="selectFunction">
        <template v-slot:icon>
          <svg width="10" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><polygon fill="#000000" fill-rule="evenodd" points="10 0 0 0 0 1.8 5.5 7 0 12.2 0 14 10 14 10 12 3.1 12 8 7 3.1 2 10 2"/></g>
          </svg>
        </template>
      </Dropdown>
    </div>

<!--    冻结行列-->
<!--    <div :class="'toolbar-button '+(toolbar.freeze?'active':'')" @click.stop="clickDropdown('freeze')" data-tooltip="冻结行列">-->
<!--      <div class="freeze" v-if="!toolbar.freeze">冻结行列</div>-->
<!--      <div class="freeze" v-else>取消冻结</div>-->
<!--    </div>-->

    <div class="horizontal-driver small"></div>

<!--    保存模版-->
    <div class="toolbar-button" data-tooltip="保存模版" @click.stop="saveExcel">
      <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
        <path d="M683.84 170.666667L853.333333 344.704V789.333333a64 64 0 0 1-64 64H234.666667a64 64 0 0 1-64-64V234.666667a64 64 0 0 1 64-64h449.173333zM320 234.645333L234.666667 234.666667v554.666666l85.333333-0.021333V533.333333h384v255.978667L789.333333 789.333333V370.730667l-85.333333-87.637334V384H320v-149.354667zM640 597.333333H384v191.978667h256V597.333333z m-42.666667 64v64h-170.666666v-64h170.666666z m42.666667-426.666666H384v85.333333h256v-85.333333z" fill="#000000"></path>
      </svg>
    </div>

<!--    页面选项-->
    <div :class="'toolbar-button '+(toolbar.page?'active':'')" data-tooltip="页面选项" @click.stop="clickDropdown('page')">
      <div class="page-option">页面选项</div>
    </div>

<!--    条件格式-->
    <div :class="'toolbar-button '+(toolbar.requirement?'active':'')" data-tooltip="条件格式" @click.stop="clickDropdown('requirement')">
      <div class="page-option">条件格式</div>
    </div>

<!--    数据源-->
    <div :class="'toolbar-button '+(toolbar.dataSource?'active':'')" data-tooltip="数据源" @click.stop="clickDropdown('dataSource')">
      <div class="page-option">数据源</div>
    </div>

    <button class="toolbar-action-button" @click.stop="preview">预览</button>
  </div>
</template>

<script>
import ChartBoard from "@/components/ChartBoard";
import BorderPalette from "@/components/BorderPalette";
import ColorPicker from "@/components/ColorPicker";
import Dropdown from "@/components/Dropdown";
import selectArea from "@/components/SelectArea";
import {calcPostfix, infixToPostFix, participle} from "@/core/formula";
export default {
  name: "ToolBar",
  props:{
    'selectArea':{

    },
    'freezeRow':{},
    'freezeCol':{},
    'freeze':{},
    'sheetIndex':null,
    'templateID':null,
    'undoList': {
      type:Array,
      default:function(){
        return [];
      }
    },
    'redoList': {
      type:Array,
      default:function(){
        return [];
      }
    },
  },
  data() {
    return {
      toolbar:{},
      lastClickDropdown:'',
      dropdownData:{},
      font:[{show:'宋体',value:'Songti SC'},{show:'黑体',value:'Heiti SC'},{show:'微软雅黑',value:'Microsoft YaHei'}],
      type:[{show:'常规',value:'normal'},{show:'数字',value:'number'},{show:'文本',value:'text'},{show:'日期',value:'date'},{show:'时间',value:'time'}],
      size:[{show:10,value:10},{show:11,value:11},{show:12,value:12},{show:14,value:14},{show:16,value:16},
        {show:18,value:18},{show:20,value:20},{show:22,value:22},{show:24,value:24},{show:26,value:26},
        {show:28,value:28},{show:28,value:28},{show:36,value:36},{show:48,value:48},],
      textAlign:[
        {
          show:`<svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M0,14 L10,14 L10,12 L0,12 L0,14 Z M10,4 L0,4 L0,6 L10,6 L10,4 Z M0,0 L0,2 L14,2 L14,0 L0,0 Z M0,10 L14,10 L14,8 L0,8 L0,10 Z"/></g>
          </svg>`,
          value:'left',
        },
        {
          show:`<svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M2,12 L2,14 L12,14 L12,12 L2,12 Z M2,4 L2,6 L12,6 L12,4 L2,4 Z M0,10 L14,10 L14,8 L0,8 L0,10 Z M0,0 L0,2 L14,2 L14,0 L0,0 Z"/></g>
            </svg>`,
          value:'center',
        },
        {
          show:`<svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M4,14 L14,14 L14,12 L4,12 L4,14 Z M0,10 L14,10 L14,8 L0,8 L0,10 Z M0,0 L0,2 L14,2 L14,0 L0,0 Z M4,6 L14,6 L14,4 L4,4 L4,6 Z"/></g>
            </svg>`,
          value:'right',
        },
      ],
      verticalAlign:[
        {
          show:`<svg width="12" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M0,0 L0,2 L12,2 L12,0 L0,0 L0,0 Z M2.5,7 L5,7 L5,14 L7,14 L7,7 L9.5,7 L6,3.5 L2.5,7 L2.5,7 Z"/></g>
            </svg>`,
          value:'start',
        },
        {
          show:`<svg width="12" height="18" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M9.5,3 L7,3 L7,0 L5,0 L5,3 L2.5,3 L6,6.5 L9.5,3 L9.5,3 Z M0,8 L0,10 L12,10 L12,8 L0,8 L0,8 Z M2.5,15 L5,15 L5,18 L7,18 L7,15 L9.5,15 L6,11.5 L2.5,15 L2.5,15 Z"/></g>
            </svg>`,
          value:'center',
        },
        {
          show:`<svg width="12" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M9.5,7 L7,7 L7,0 L5,0 L5,7 L2.5,7 L6,10.5 L9.5,7 L9.5,7 Z M0,12 L0,14 L12,14 L12,12 L0,12 L0,12 Z"/></g>
            </svg>`,
          value:'end',
        },
      ],
      functions:[
        {
          show:'ABS',
          value:'ABS',
          func:'=ABS()',
        },
        {
          show:'INT',
          value:'INT',
          func:'=INT()',
        },
        {
          show:'MOD',
          value:'MOD',
          func:'=MOD()',
        },
        {
          show:'ROUND',
          value:'ROUND',
          func:'=ROUND()',
        },
        {
          show:'SUM',
          value:'SUM',
          func:'=SUM()',
        },
        {
          show:'AVERAGE',
          value:'AVERAGE',
          func:'=AVERAGE()',
        },
        {
          show:'COUNT',
          value:'COUNT',
          func:'=COUNT()',
        },
        {
          show:'MAX',
          value:'MAX',
          func:'=MAX()',
        },
        {
          show:'MIN',
          value:'MIN',
          func:'=MIN()',
        },
        {
          show:'CONCAT',
          value:'CONCAT',
          func:'=CONCAT()',
        },
        {
          show:'IF',
          value:'IF',
          func:'=IF()',
        },
      ],
    }
  },
  watch:{
    selectArea(newVal,oldVal){
      if(newVal.length){
        this.closeDropdown();
        this.dropdownData= {
          font:this.font.find((f)=>newVal[0].data.cellStyle.font==f.value).value,
          type:this.type.find((f)=>newVal[0].data.cellType.type==f.value).value,
          extra:newVal[0].data.cellType.extra,
          fontSize:newVal[0].data.cellStyle.fontSize,
          bold:newVal[0].data.cellStyle.bold,
          italic:newVal[0].data.cellStyle.italic,
          underline:newVal[0].data.cellStyle.underline,
          color:newVal[0].data.cellStyle.color,
          backgroundColor:newVal[0].data.cellStyle.backgroundColor,
        };
      }
    },
    freeze(newVal,oldVal){
      this.toolbar.freeze=newVal;
    },
    chartSource(newVal,oldVal){
      this.calcChartOptions(newVal);
    },
    chartHead(newVal,oldVal){
      this.calcChartHead(newVal);
    },
    sheetIndex(newVal,oldVal){
      this.closeDropdown();
    },
    imageIndex(newVal,oldVal){
      this.closeDropdown();
    },
    'toolbar.chart':function (newVal,oldVal){
      if(this.lastClickDropdown!=='page')
        this.$emit('getChartData',newVal);
    },
    'toolbar.page':function (newVal,oldVal){
      if(this.lastClickDropdown!=='chart')
        this.$emit('page',newVal);
    },
  },
  components:{
    Dropdown,
    ColorPicker,
    BorderPalette,
    ChartBoard,
  },
  methods:{
    //预览
    preview(){
      this.$emit('preview');
    },

    //关闭所有菜单
    closeMenu(){
      this.$emit('closeMenu',false);
    },

    //撤回以及取消撤回
    doAction(action){
      this.$emit(action);
      this.closeMenu();
    },

    //选择函数
    selectFunction(data){
      if(this.selectArea.length){
        let func=this.functions.find((f)=>data.value==f.value);
        this.closeDropdown();
        this.$emit('selectFunction',func.func);
      }
    },
    imageSelect(e){
      let file=e.target.files[0],
          reader=new FileReader(),
          _this=this;
      if(file.size/1024/1024>8){
        this.$toast('上传图片失败，图片大小不可超过8M','danger');
        return false;
      }
      reader.onload=function(){
        _this.$emit('insertImage',this.result);
        _this.$refs.imageForm.reset();
      }
      reader.readAsDataURL(file);
    },
    saveExcel(){
      this.closeDropdown();
      this.$emit('saveExcel');
    },
    download(){
      this.$emit('download');
    },
    mergeCell(){
      this.$emit('mergeCell');
    },
    setBorder(border){
      this.$emit('setBorder',border);
      this.closeDropdown();
    },
    extraChange(){
      this.$emit('saveStyle', {type:'cellType',value:{type:this.dropdownData.type,extra:this.dropdownData.extra}});
    },
    saveStyle(data){
      this.closeMenu();
      this.closeDropdown();

      this.dropdownData[data.type]=data.value;
      if(data.type=='type')
        this.$emit('saveStyle', {type:'cellType',value:{type:data.value,extra:this.dropdownData.extra}});
      else this.$emit('saveStyle',data);
    },
    closeDropdown(){
      this.toolbar.font=false;
      this.toolbar.fontSize=false;
      this.toolbar.color=false;
      this.toolbar.backgroundColor=false;
      this.toolbar.border=false;
      this.toolbar.textAlign=false;
      this.toolbar.verticalAlign=false;
      this.toolbar.func=false;
      this.toolbar.type=false;
      this.toolbar.extra=false;
      this.toolbar.chart=false;
      this.toolbar.page=false;
      this.toolbar.dataSource=false;
      this.toolbar.requirement=false;

      this.$emit('selectHead',false);
      this.$emit('selectSource',false);
      this.$emit('totalSourceSelect',false);
      this.$emit('seriesSelect',false);
    },
    clickDropdown(data){
      if(this.lastClickDropdown!==data&&this.toolbar[data]!==true){
        this.closeDropdown();
      }
      this.closeMenu();
      this.toolbar[data]=!this.toolbar[data];
      this.lastClickDropdown=data;

      this.$nextTick(()=>{
        if(data=='freeze'){
          this.$emit('freeze',this.toolbar.freeze);
        }
        else if(data=='chart'){
          this.$emit('getChartData',this.toolbar.chart);
          this.$emit('selectHead',false);
          this.$emit('selectSource',false);
        }
        else if(data=='page'){
          this.$emit('page',this.toolbar.page);
        }
        else if(data=='requirement'){
          this.$emit('requirement',this.toolbar.requirement);
        }
        else if(data=='dataSource'){
          this.$emit('dataSource',this.toolbar.dataSource);
        }
      })

    },
    initData(){
      this.$set(this,'toolbar',{
        font:false,
        fontSize:false,
        bold:false,
        italic:false,
        underline:false,
        color:false,
        backgroundColor:false,
        border:false,
        textAlign:false,
        verticalAlign:false,
        func:false,
        chart:false,
        freeze:false,
        type:false,
        extra:false,
        page:false,
        dataSource:false,
        requirement:false,
      });
      this.$set(this,'dropdownData',{
        font:'Microsoft YaHei',
        fontSize:12,
        bold:false,
        italic:false,
        underline:false,
        color:'333333',
        backgroundColor:'FFFFFF',
        textAlign:'left',
        verticalAlign:'start',
        type:'normal',
        extra:0,
      });
      this.$set(this.dropdownData,'chart',{});
    }
  },
  created() {
    this.initData();
    if(this.freezeRow.index||this.freezeCol.index){
      this.toolbar.freeze=true;
    }
  }

}
</script>

<style scoped>
.toolbar{
  /*overflow: auto;*/
  background-color: #f4f6f7;
  padding: 5px;
  align-items: center;
  height: 36px;
}
.select-form{
  height: 30px;
  width: 150px;
  font-size: 12px;
}
.font-size-select{
  width: 50px;
}
.toolbar-driver{
  display: inline-block;
  border-right: 1px solid #e0e2e4;
  width: 0;
  vertical-align: middle;
  height: 18px;
  margin: 0 3px;
}
.toolbar-button{
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f6f7;
  height: 26px;
  min-width: 26px;
  margin: 0 1px;
  padding: 0;
  text-align: center;
  border-radius: 2px;
  position: relative;
  user-select: none;
}
.toolbar-button:hover{
  background: rgba(0, 0, 0, 0.08);
}
.toolbar-button.active{
  background: rgba(0, 0, 0, 0.08);
}
.toolbar-button.disabled{
  pointer-events: none;
  opacity: 0.5;
}
.toolbar-button:not(.nh)::before{
  content: attr(data-tooltip);
  border-radius: 3px;
  background-color: #000;
  position: absolute;
  top:30px;
  padding: 5px 10px;
  z-index:799;
  color:#fff;
  font-size: 12px;
  width: max-content;
  left:0;
  display: none;
}
.toolbar-button:not(.active):hover::before{
  display: block;
}
.imageInput{
  display: none;
}
.freeze,.page-option{
  font-size: 12px;
  padding: 0 10px;
}
.extraInput{
  text-align: center;
  border-radius: 0;
  outline: none;
  background: #f4f6f7;
  border: 1px solid #ccc;
  font-size: 10px;
  font-family: -apple-system;
  width: 36px;
  height: 24px;
}

.toolbar-action-button{
  margin-left:10px;
}
</style>