import {setCellData} from "@/core/init";
import {participle} from "@/core/formula";

const transferData=(_this,type,index,direction=1)=>{
    moveData(_this,type,index,direction);

    checkFunctions(_this,type,index,direction)

    _this.sheet.resetData(_this.localSheet);
}

const moveData=(_this,type,index,direction=1)=>{
    let cells=Object.values({..._this.localSheet.uploadData.cell});
    cells.sort((a,b)=>{
        if(type=='row'){
            if(direction>0)
                return a.ri==b.ri?b.ci-a.ci:b.ri-a.ri;
            else
                return a.ri==b.ri?a.ci-b.ci:a.ri-b.ri;
        }
        else{
            if(direction>0)
                return a.ci==b.ci?b.ri-a.ri:b.ci-a.ci;
            else
                return a.ci==b.ci?a.ri-b.ri:a.ci-b.ci;
        }
    });

    if(direction<0)
        resetRowColCellData(_this,type,index);

    let mergeArr=[],addNewMergeArr=[];
    for (let i in cells){
        let cell=cells[i],ri=cell.ri,ci=cell.ci,ident;
        if(type=='row'){
            ri+=direction;
            ident=cell.ri;
        }
        else {
            ci+=direction;
            ident=cell.ci;
        }
        if(ident>=(direction>0?(index*1):(index*1+1))){
            let target=_this.localSheet.map[_this.localSheet.col[ci].name+_this.localSheet.row[ri].name];
            target.data.valid=cell.data.valid;
            target.data.content=cell.data.content;
            target.data.merge=cell.data.merge;
            target.data.cellType={...cell.data.cellType};
            target.data.cellStyle={...cell.data.cellStyle};

            //被合并的单元格
            if(cell.data.mergeTo){
                //合并单元格的位置小于被操作的行或列
                if(cell.data.mergeTo[type=='row'?'ri':'ci']<index){
                    target.data.mergeTo=_this.localSheet.map[cell.data.mergeTo.name];
                    if(mergeArr.findIndex((m)=>m==target.data.mergeTo.name)===-1){
                        if(type=='row')
                            target.data.mergeTo.data.mergeCellRange.row.max += direction;
                        else
                            target.data.mergeTo.data.mergeCellRange.col.max += direction;
                        setMergeData(_this,target.data.mergeTo);

                        mergeArr.push(target.data.mergeTo.name);
                    }
                }
                else{
                    let name;
                    if(direction>0){
                        if(type=='row')
                            name=cell.data.mergeTo.data.col+_this.localSheet.row[cell.data.mergeTo.ri+direction].name;
                        else name=_this.localSheet.row[cell.data.mergeTo.ci+direction].name+cell.data.mergeTo.data.row;
                    }
                    else{
                        console.log(cell.name,cell.data.mergeTo.name)
                        if(type=='row')
                            name=cell.data.mergeTo.data.col+_this.localSheet.row[cell.data.mergeTo.ri].name;
                        else name=_this.localSheet.row[cell.data.mergeTo.ci].name+cell.data.mergeTo.data.row;
                    }
                    target.data.mergeTo=_this.localSheet.map[name];
                }
            }

            //合并的大单元格
            if(cell.data.merge){
                target.data.mergeCellRange={...cell.data.mergeCellRange};
                if(type=='row'){
                    target.data.mergeCellRange.row.min+=direction;
                    target.data.mergeCellRange.row.max+=direction;
                }
                else{
                    target.data.mergeCellRange.col.min+=direction;
                    target.data.mergeCellRange.col.max+=direction;
                }
                // mergeCell
                setMergeData(_this,target,direction,type);
                console.log(target.data.mergeCellArray,target.name)
                for (let i in target.data.mergeCellArray){
                    if(target.name!==target.data.mergeCellArray[i]){
                        _this.localSheet.map[target.data.mergeCellArray[i]].data.mergeTo=target;
                    }
                }

                let index=_this.localSheet.merges.findIndex((m)=>m.name==cell.name);
                if(index!==-1)
                    _this.localSheet.merges[index]=target;

            }

            setCellData(_this,cell.ci,cell.ri);

            //如果新添加的列在被合并的单元格内
            let r=false;
            if(type=='row'&&cell.ri>0&&cell.ri<_this.localSheet.row.length-1&&cell.ri===index) {
                let preCell=_this.localSheet.map[_this.localSheet.col[cell.ci].name + _this.localSheet.row[cell.ri - 1].name],
                    nextCell=_this.localSheet.map[_this.localSheet.col[cell.ci].name + _this.localSheet.row[cell.ri + 1].name];
                r = ((preCell.data.merge||preCell.data.mergeTo)&&(nextCell.data.merge||nextCell.data.mergeTo));
            }
            else if(type=='col'&&cell.ci>0&&cell.ci<_this.localSheet.col.length-1&&cell.ci===index){
                let preCell=_this.localSheet.map[_this.localSheet.col[cell.ci - 1].name + _this.localSheet.row[cell.ri].name],
                    nextCell=_this.localSheet.map[_this.localSheet.col[cell.ci + 1].name + _this.localSheet.row[cell.ri].name];
                r = ((preCell.data.merge||preCell.data.mergeTo)&&(nextCell.data.merge||nextCell.data.mergeTo));
            }
            if(r){
                let preCell,target;
                if(type=='row')
                    preCell=_this.localSheet.map[_this.localSheet.col[cell.ci].name + _this.localSheet.row[cell.ri - 1].name];
                else preCell=_this.localSheet.map[_this.localSheet.col[cell.ci - 1].name + _this.localSheet.row[cell.ri].name];
                if(preCell.data.merge)
                    target=preCell;
                else target=preCell.data.mergeTo;

                addNewMergeArr.push({name:cell.name,target:target.name})
            }
        }
    }

    let rcData;
    if(type=='row'){
        rcData=_this.localSheet.row;
    }
    else if(type=='col'){
        rcData=_this.localSheet.col;
    }

    //修改行或列的数据
    changeRCData(_this,rcData,type,index,direction);

    //修改保存的数据中的cell
    _this.localSheet.uploadData.cell=modifyUploadCell(_this,type,index,direction);

    //修改保存的数据中的row或col
    if(type=='row')
        _this.localSheet.uploadData.row=modifyUploadRow(_this,index,direction);
    else if(type=='col')
        _this.localSheet.uploadData.col=modifyUploadCol(_this,index,direction);

    _this.checkImagesPos();

    for (let i in _this.localSheet.merges){
        calcMergeCellWH(_this,_this.localSheet.merges[i].name)
    }

    for (let i in addNewMergeArr){
        let {name,target}=addNewMergeArr[i];
        let cell=_this.localSheet.map[name];
        cell.data.valid=false;
        cell.data.mergeTo=_this.localSheet.map[target];
        _this.insertUploadData('cell',name,cell,false);
    }

    _this.localSheet.select={
        start:_this.localSheet.select.start,
        end:_this.localSheet.select.end,
        selecting:false,
    }
}

const checkFunctions=(_this,type,index,direction=1)=>{
    let fp=(type=='row'?'col':'row'),other=(type=='row'?'row':'col'),cellIndexKey=(type=='row'?'ri':'ci'),otherCellIndexKey=(type=='row'?'ci':'ri');
    let cells=Object.values({..._this.localSheet.uploadData.cell});
    let cellArr=[];
    for (let i=0;i<_this.localSheet[fp].length;i++){
        let currentCellName;
        if(type=='row')
            currentCellName=_this.localSheet.col[i].name+_this.localSheet.row[index].name;
        else
            currentCellName=_this.localSheet.col[index].name+_this.localSheet.row[i].name;
        for (let cell of cells){
            if(cell.data.content.length&&cell.data.content[0]==='='){
                let part=participle(cell.data.content.slice(1))
                for (let j in [...part]){
                    let p=part[j];
                    if(_this.localSheet.map[p]){
                        let c=_this.localSheet.map[p];
                        if(p==currentCellName&&direction<0){
                            part.splice(j,1,'#REF!')
                        }
                        else if(c[cellIndexKey]>=index&&c[otherCellIndexKey]==i){
                            let newName;
                            if(type=='row')
                                newName=_this.localSheet.col[c.ci].name+_this.localSheet.row[c.ri+direction].name;
                            else
                                newName=_this.localSheet.col[c.ci+direction].name+_this.localSheet.row[c.ri].name;

                            part.splice(j,1,newName)
                        }
                    }
                    else if(p.split(':').length>1){
                        let range=getRangeByStr(_this,p);
                        //插入的那种类型就用对应的key
                        if(range[other].min<=index&&index<=range[other].max&&cellArr.findIndex(name=>name==cell.name)===-1){
                            range[other].max+=direction;
                            let newRangeStr=getStrByRange(_this,range.col.min,range.col.max,range.row.min,range.row.max);
                            part.splice(j,1,newRangeStr)

                            cellArr.push(cell.name);
                        }
                    }
                }
                cell.data.content='='+part.join('');
            }
        }
    }
}

const getStrByRange=(_this,minCol,maxCol,minRow,maxRow)=>{
    let start=_this.localSheet.col[minCol].name+_this.localSheet.row[minRow].name,
        end=_this.localSheet.col[maxCol].name+_this.localSheet.row[maxRow].name;

    return start+':'+end;
}

const getRangeByStr=(_this,name)=>{
    let start = _this.localSheet.map[name.split(':')[0]];
    let end = _this.localSheet.map[name.split(':')[1]];
    let minRow = Math.min(start.ri, end.ri),
        maxRow = Math.max(start.ri, end.ri),
        minCol = Math.min(start.ci, end.ci),
        maxCol = Math.max(start.ci, end.ci);

    return {
        row:{
            min:minRow,
            max:maxRow,
        },
        col:{
            min:minCol,
            max:maxCol,
        },
    }
}

const modifyUploadCell=(_this,type,index,direction)=>{
    let newCells={..._this.localSheet.uploadData.cell},result={};
    for (let i in newCells){
        let cell=newCells[i];
        let dir=type=='row'?'ri':'ci';
        if(direction<0&&index==cell[dir])continue;
        if(cell[dir]>=(direction>0?index:(index+1))){
            let newri=type=='row'?(cell.ri+direction):cell.ri,newci=type=='col'?(cell.ci+direction):cell.ci;
            let name=_this.localSheet.col[newci].name+_this.localSheet.row[newri].name;
            result[name]=_this.localSheet.map[name];
        }
        else result[i]=_this.localSheet.map[i];
    }

    return result;
}

const modifyUploadRow=(_this,index,direction)=>{
    let newRow={..._this.localSheet.uploadData.row},result={};
    for (let i in newRow){
        let row=newRow[i];
        if(row.index>index){
            result[_this.localSheet.row[row.index+direction].name]=_this.localSheet.row[row.index+direction];
        }
        else result[i]=newRow[i];
    }

    return result;
}

const modifyUploadCol=(_this,index,direction)=>{
    let newCol={..._this.localSheet.uploadData.col},result={};
    for (let i in newCol){
        let col=newCol[i];
        if(col.index>index){
            result[_this.localSheet.col[col.index+direction].name]=_this.localSheet.col[col.index+direction];
        }
        else result[i]=newCol[i];
    }

    return result;
}

const setMergeData=(_this,target,direction=1,type=null)=>{
    target.data.mergeCell=
        _this.localSheet.col[target.data.mergeCellRange.col.min].name+
        _this.localSheet.row[target.data.mergeCellRange.row.min].name+
        ':'+
        _this.localSheet.col[target.data.mergeCellRange.col.max].name+
        _this.localSheet.row[target.data.mergeCellRange.row.max].name;
    target.data.mergeCellArray=[];
    for (let n=target.data.mergeCellRange.row.min;n<=target.data.mergeCellRange.row.max;n++) {
        for (let m = target.data.mergeCellRange.col.min; m <= target.data.mergeCellRange.col.max; m++) {
            target.data.mergeCellArray.push(_this.localSheet.col[m].name+_this.localSheet.row[n].name);
        }
    }

    let rc={
        row:{
            min:target.data.mergeCellRange.row.min,
            max:target.data.mergeCellRange.row.max,
        },
        col:{
            min:target.data.mergeCellRange.col.min,
            max:target.data.mergeCellRange.col.max,
        },
    }

    if(direction<0){
        if(type=='row')
            rc.row.max+=1;
        else if(type=='col')
            rc.col.max+=1;
    }

    for (let n=rc.row.min;n<=rc.row.max;n++) {
        for (let m = rc.col.min; m <= rc.col.max; m++) {
            if(m!==rc.col.min||n!==rc.row.min)
                _this.localSheet.map[_this.localSheet.col[m].name+_this.localSheet.row[n].name].data.mergeTo=target
        }
    }

    let {rowSize,colSize}=_this.fromRangeGetAttr(target.data.mergeCellRange.row.min,target.data.mergeCellRange.row.max,target.data.mergeCellRange.col.min,target.data.mergeCellRange.col.max);

    target.data.cellStyle.width=colSize;
    target.data.cellStyle.height=rowSize;
}

const changeRCData=(_this,rc,type,index,direction=1)=>{
    if(direction>0){
        let size,bsize;
        size=_this.localSheet[type][index].size;

        //重置index的行或列
        resetRowCol(_this,type,index);
        //将上一个行或列的属性赋予下一个
        for (let i=index;i<rc.length-1;i++){
            let now=rc[i],target=rc[i+1];
            bsize=target.size;
            target.size=size;
            if(type=='row'){
                target.top=now.top+now.size;
                setRowCell(_this,i);
            }
            else {
                target.left=now.left+now.size;
                setColCell(_this,i);
            }

            size=bsize;
        }
    }
    else{
        for (let i=index;i<rc.length-1;i++){
            let now=rc[i],target=rc[i+1];
            now.size=target.size;
            if(type=='row'){
                if(i!==index)
                    now.top=rc[i-1].top+rc[i-1].size;
                setRowCell(_this,i);
            }
            else {
                if(i!==index)
                    now.left=rc[i-1].left+rc[i-1].size;
                setColCell(_this,i);
            }
        }
        resetRowCol(_this,type,rc.length-1);
    }
}

//修改这一行内所有的单元格高度
const setRowCell=(_this,index)=>{
    for(let i=0;i<_this.localSheet.col.length;i++){
        _this.localSheet.map[_this.localSheet.col[i].name+_this.localSheet.row[index].name].data.cellStyle.top=_this.localSheet.row[index].top;
        if(!_this.localSheet.map[_this.localSheet.col[i].name+_this.localSheet.row[index].name].data.merge)
            _this.localSheet.map[_this.localSheet.col[i].name+_this.localSheet.row[index].name].data.cellStyle.height=_this.localSheet.row[index].size;
        else{
            let name=_this.localSheet.col[i].name+_this.localSheet.row[index].name;
            calcMergeCellWH(_this,name);
        }
    }
}

//修改这一列内所有的单元格高度
const setColCell=(_this,index)=>{
    for(let i=0;i<_this.localSheet.row.length;i++){
        _this.localSheet.map[_this.localSheet.col[index].name+_this.localSheet.row[i].name].data.cellStyle.left=_this.localSheet.col[index].left;
        if(!_this.localSheet.map[_this.localSheet.col[index].name+_this.localSheet.row[i].name].data.merge)
            _this.localSheet.map[_this.localSheet.col[index].name+_this.localSheet.row[i].name].data.cellStyle.width=_this.localSheet.col[index].size;
        else{
            let name=_this.localSheet.col[index].name+_this.localSheet.row[i].name;
            calcMergeCellWH(_this,name);
        }
    }
}

const resetRowCol=(_this,type,index)=>{
    if(type=='row'){
        _this.localSheet.row[index].size=30;
        for(let i=0;i<_this.localSheet.col.length;i++){
            _this.localSheet.map[_this.localSheet.col[i].name+_this.localSheet.row[index].name].data.cellStyle.height=_this.localSheet.row[index].size;
        }
    }
    else if(type=='col'){
        _this.localSheet.col[index].size=80;
        for(let i=0;i<_this.localSheet.row.length;i++){
            _this.localSheet.map[_this.localSheet.col[index].name+_this.localSheet.row[i].name].data.cellStyle.width=_this.localSheet.col[index].size;
        }
    }
}

//重新计算并改变合并后单元格的宽高
const calcMergeCellWH=(_this,name)=>{
    let cell=_this.localSheet.map[name],result={row:0,col:0};
    for (let i=cell.data.mergeCellRange.col.min;i<=cell.data.mergeCellRange.col.max;i++){
        result.col+=_this.localSheet.col[i].size;
    }
    for (let i=cell.data.mergeCellRange.row.min;i<=cell.data.mergeCellRange.row.max;i++){
        result.row+=_this.localSheet.row[i].size;
    }

    cell.data.width=result.col;
    cell.data.height=result.row;
    cell.data.cellStyle.width=result.col;
    cell.data.cellStyle.height=result.row;

    return result;
};

const resetRowColCellData=(_this,type,index)=>{
    if(type=='row'){
        for (let i=0;i<_this.localSheet.col.length;i++){
            setCellData(_this,i,index);
        }
    }
    else if(type=='col'){
        for (let i=0;i<_this.localSheet.row.length;i++){
            setCellData(_this,index,i);
        }
    }
}

export {
    transferData,
    calcMergeCellWH
}