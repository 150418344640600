const config = {
    pubkey: `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCyGAN2RV6f8sLp0y2574GkJskJ
oKvNR14xZeTamTUd/3gDNdTKTCO6sY8itzvfyHdLi06c9VudXGP8bwSW3iuDWnnE
KtCksg9QOOc/RIvhuIxdTZfvW84UB+/OATMpgecKUPmk7+A/Ho+TI/Zw9eMtv+3h
CTbr+MFqI3DiFnRh7wIDAQAB
-----END PUBLIC KEY-----`,
    // baseURL: `./back-end/public/index.php/api/`,
    // baseURL: `http://coding.recline.cn/back-end/public/api/`,
    baseURL: `https://excel.coxnet.cn/back-end/public/api/`,
    // baseURL: `http://localhost:8001/api/`,
    token: '',
    admin: 'toolAdmin'
}, group = {
    admin: "管理员",
    staff: "员工"
}, user = {

};

export {config, group}