<template>
  <div class="excel-dropdown">
    <div class="excel-dropdown-header">
      <div class="excel-dropdown-title">
        <slot name="icon" v-if="icon"></slot>
        <div v-else>{{ showValue }}</div>
      </div>
      <div class="excel-dropdown-icon">
        <svg width="8" height="4" style="opacity: 0.5;">
          <g xmlns="http://www.w3.org/2000/svg"><polygon fill="#000000" fill-rule="evenodd" points="0 0 4 4 8 0"/></g>
        </svg>
      </div>
    </div>
    <div class="excel-dropdown-content" :style="{width:optionWidth+'px'}">
      <div v-for="(o,i) in options" class="dropdown-item" @click.stop="select(o)" v-html="o.show"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props:{
    optionWidth:{
      type:Number,
      default:160,
    },
    options:{
      type:Array,
      default:[],
    },
    selectValue:{
      default: '',
    },
    type:{
      default:'',
    },
    icon:false,
  },
  data(){
    return{
      showValue:'',
    }
  },
  watch:{
    selectValue(newVal,oldVal){
      if(!this.icon){
        this.showValue=this.options.find((o)=>o.value==newVal).show;
      }
    }
  },
  methods:{
    select(option){
      this.showValue=option.show;
      this.$emit('save',{
        type:this.type,
        value: option.value,
      });
    }
  },
  created() {
    if(!this.icon)
      this.showValue=this.options.find((o)=>o.value==this.selectValue).show;
  }
}
</script>

<style scoped>
.excel-dropdown{
  position: relative;
}
.excel-dropdown-header{
  display: flex;
  line-height: 26px;
}
.excel-dropdown-title{
  min-width: 20px;
  padding: 0 5px;
  display: flex;
  font-size: 12px;
  align-items: center;
  user-select: none;
}
.excel-dropdown-icon{
  user-select: none;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 26px;
  margin:0 5px 0 1px;
  /*text-align: center;*/
  /*vertical-align: middle;*/
}
.excel-dropdown-content{
  position: absolute;
  z-index: 800;
  background: #fff;
  box-shadow: 1px 2px 5px 2px rgba(51,51,51,15%);
  top: calc(100% + 5px);
  left: 0;
}
.dropdown-item {
  user-select: none;
  background: 0;
  border: 1px solid transparent;
  outline: none;
  height: 32px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 32px;
  list-style: none;
  padding: 0 10px;
  cursor: default;
  text-align: left;
  overflow: hidden;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.dropdown-item:hover{
  background: rgba(0, 0, 0, 0.05);
}
.excel-dropdown-content{
  display: none;
}
</style>