import './echarts.min.js'

export function getChart(){
    let el=document.createElement('div');
    el.style.width=this.chartAttr.width+'px';
    el.style.height=this.chartAttr.height+'px';

    if(this.chart){
        this.chart.dispose();
    }

    this.chart=window.echarts.init(el);
    this.chartColors=['#5A6FC0', '#9ECB7F', '#F2CA6B', '#DE6F6B', '#85BEDB', '#69A076', '#EC8A5D', '#9263AF', '#DC82C8'];
    this.chart.resize();

    calcChartHead.call(this);
    calcChartOptions.call(this);
    calcChartSeries.call(this);
    let data=initData(this.chartData);
    let options=chartTypeOptions.call(this,data);
    this.chart.setOption(options,true);

    return this.chart.getDataURL();
}

function chartTypeOptions(d){
    let _this=this;
    return ({
        line(){
            let option={
                backgroundColor:'#fff',
                title: {
                    text: _this.chartData.title,
                    left:'center',
                    top:'6%'
                },
                legend: {
                    data: d.names,
                    bottom:'6%',
                    icon:'rect',
                },
                xAxis: {
                    type: 'category',
                    data: d.heads,
                },
                yAxis: {
                    type: 'value'
                },
                series:[],
                animation: false,
            }

            for (let i in _this.chartData.series){
                option.series.push({
                    data:_this.chartData.options[i].datasets,
                    type:'line',
                    color:_this.chartData.series[i].color,
                    name:_this.chartData.series[i].name.join(','),
                })
            }

            return option;
        },
        bar(){
            let option={
                backgroundColor:'#fff',
                title: {
                    text: _this.chartData.title,
                    left:'center',
                    top:'6%',
                },
                legend: {
                    data: d.names,
                    bottom:'6%',
                    icon:'rect',
                },
                xAxis: {
                    type: 'category',
                    data: d.heads,
                },
                yAxis: {
                    type: 'value'
                },
                series:[],
                animation: false,
            }

            for (let i in _this.chartData.series){
                option.series.push({
                    data:_this.chartData.options[i].datasets,
                    type:'bar',
                    color:_this.chartData.series[i].color,
                    name:_this.chartData.series[i].name.join(','),
                })
            }

            return option;
        },
        pie(){
            let option={
                backgroundColor:'#fff',
                title: {
                    text: _this.chartData.title,
                    left:'center',
                    top:'6%',
                },
                legend: {
                    data: d.names,
                    bottom:'6%'
                },
                series:[],
                animation: false,
            }

            let data=[],color=[];
            for (let i in _this.chartData.series){
                color.push(_this.chartData.series[i].color);
                let values=0;
                for (let j in _this.chartData.options[i].datasets){
                    values+=Number(_this.chartData.options[i].datasets[j]);
                }
                data.push({
                    value:values,
                    name:_this.chartData.series[i].name.join(','),
                });
            }

            option.series.push({
                type: 'pie',
                radius: '50%',
                data:data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            });

            return option;
        }
    })[this.chartData.chartType]();
}

function initData(chartData){
    let heads=[],names=[];
    heads=chartData.heads;
    for(let i=0;i<chartData.series.length;i++){
        names.push(chartData.series[i].name.join(','));
    }

    return {
        heads,
        names,
    }
}

function calcChartHead() {
    if (!this.chartData.headRange) return false;
    let heads = [];
    for (let k in this.chartData.headRange){
        let rc=transferStrToRange.call(this,this.chartData.headRange[k]);
        if (rc.row.max - rc.row.min == 0) {
            for (let i = rc.col.min; i <= rc.col.max; i++) {
                heads.push(this.calcCellContentCopy(this.data.map[this.data.col[i].name + this.data.row[rc.row.min].name].data.content))
            }
        } else if (rc.col.max - rc.col.min == 0) {
            for (let i = rc.row.min; i <= rc.row.max; i++) {
                heads.push(this.calcCellContentCopy(this.data.map[this.data.col[rc.col.min].name + this.data.row[i].name].data.content))
            }
        } else {
            for (let i = rc.col.min; i <= rc.col.max; i++) {
                let head = [];
                for (let j = rc.row.min; j <= rc.row.max; j++) {
                    head.push(this.calcCellContentCopy(this.data.map[this.data.col[i].name + this.data.row[j].name].data.content))
                }
                heads.push(head.join(''));
            }
        }
    }

    this.chartData.heads = heads;
}

function calcChartOptions(){
    if (!this.chartData.sourceRange) return false;
    let options = [];
    for (let k in this.chartData.sourceRange){
        let rc=transferStrToRange.call(this,this.chartData.sourceRange[k]);
        if (this.chartData.type == 'row') {
            for (let j = rc.row.min; j <= rc.row.max; j++) {
                let data;
                if(k!=0&&options.findIndex(o=>o.index===j)!==-1)
                    data=options[options.findIndex(o=>o.index===j)];
                else data={
                    index:j,
                    range:[],
                    datasets:[]
                };
                let r=[];
                for (let i = rc.col.min; i <= rc.col.max; i++) {
                    r.push(this.data.col[i].name + this.data.row[j].name);
                    data.datasets.push(this.calcCellContentCopy(this.data.map[this.data.col[i].name + this.data.row[j].name].data.content));
                }
                if(r.length)
                    data.range.push(r[0]+':'+r[r.length-1]);
                options.push(data);
            }
        }
        else{
            for (let i = rc.col.min; i <= rc.col.max; i++) {
                let data;
                if(k!=0&&options.findIndex(o=>o.index===i)!==-1)
                    data=options[options.findIndex(o=>o.index===i)];
                else data={
                    index:i,
                    range:[],
                    datasets:[]
                };
                let r=[];
                for (let j = rc.row.min; j <= rc.row.max; j++) {
                    r.push(this.data.col[i].name + this.data.row[j].name);
                    data.datasets.push(this.calcCellContentCopy(this.data.map[this.data.col[i].name + this.data.row[j].name].data.content));
                }
                if(r.length)
                    data.range.push(r[0]+':'+r[r.length-1]);
                options.push(data);
            }
        }
    }

    this.chartData.options = options;
}

function calcChartSeries(){
    if (!this.chartData.seriesRange) return false;
    let ss = [];
    for (let k in this.chartData.seriesRange){
        let rc=transferStrToRange.call(this,this.chartData.seriesRange[k]);

        if (rc.row.max - rc.row.min == 0) {
            for (let i = rc.col.min; i <= rc.col.max; i++) {
                let r=(ss.length>=this.chartData.options.length?[]:this.chartData.options[ss.length].range);
                ss.push({
                    name:[this.calcCellContentCopy(this.data.map[this.data.col[i].name + this.data.row[rc.row.min].name].data.content)],
                    color:this.chartData.series[ss.length].color??this.chartColors[ss.length%this.chartColors.length],
                    range:r
                })
            }
        } else if (rc.col.max - rc.col.min == 0) {
            for (let i = rc.row.min; i <= rc.row.max; i++) {
                let r=(ss.length>=this.chartData.options.length?[]:this.chartData.options[ss.length].range);
                ss.push({
                    name:[this.calcCellContentCopy(this.data.map[this.data.col[rc.col.min].name + this.data.row[i].name].data.content)],
                    color:this.chartData.series[ss.length].color??this.chartColors[ss.length%this.chartColors.length],
                    range:r
                })
            }
        } else {
            for (let i = rc.col.min; i <= rc.col.max; i++) {
                let r=(ss.length>=this.chartData.options.length?[]:this.chartData.options[ss.length].range);
                let s = {
                    name:[],
                    color:this.chartData.series[i].color??this.chartColors[ss.length%this.colors.length],
                    range:r
                };
                for (let j = rc.row.min; j <= rc.row.max; j++) {
                    s.name.push(this.calcCellContentCopy(this.data.map[this.data.col[i].name + this.data.row[j].name].data.content))
                }
                ss.push(s);
            }
        }
    }

    this.chartData.series = ss;
}

function transferStrToRange(str){
    let strArr=str.split(':');
    let start=this.data.map[strArr[0]],end=this.data.map[strArr[1]];
    return {
        row:{
            min:start.ri,
            max:end.ri,
        },
        col:{
            min:start.ci,
            max:end.ci,
        }
    }
}