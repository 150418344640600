<template>
  <div class="border-palette" @click.stop="">
    <div class="flex">
      <div class="border-palette-style">
        <div class="border-palette-cell" @click="setBorder('border-all')">
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M0,0 L0,14 L14,14 L14,0 L0,0 L0,0 Z M6,12 L2,12 L2,8 L6,8 L6,12 L6,12 Z M6,6 L2,6 L2,2 L6,2 L6,6 L6,6 Z M12,12 L8,12 L8,8 L12,8 L12,12 L12,12 Z M12,6 L8,6 L8,2 L12,2 L12,6 L12,6 Z"/></g>
          </svg>
        </div>
        <div class="border-palette-cell" @click="setBorder('border-inside')">
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <g fill="#000000" fill-rule="evenodd">
                <path d="M0,14 L2,14 L2,12 L0,12 L0,14 L0,14 Z M2,3 L0,3 L0,5 L2,5 L2,3 L2,3 Z M3,14 L5,14 L5,12 L3,12 L3,14 L3,14 Z M11,0 L9,0 L9,2 L11,2 L11,0 L11,0 Z M2,0 L0,0 L0,2 L2,2 L2,0 L2,0 Z M5,0 L3,0 L3,2 L5,2 L5,0 L5,0 Z M0,11 L2,11 L2,9 L0,9 L0,11 L0,11 Z M9,14 L11,14 L11,12 L9,12 L9,14 L9,14 Z M12,0 L12,2 L14,2 L14,0 L12,0 L12,0 Z M12,5 L14,5 L14,3 L12,3 L12,5 L12,5 Z M12,14 L14,14 L14,12 L12,12 L12,14 L12,14 Z M12,11 L14,11 L14,9 L12,9 L12,11 L12,11 Z" opacity=".54"/>
                <polygon points="8 0 6 0 6 6 0 6 0 8 6 8 6 14 8 14 8 8 14 8 14 6 8 6"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="border-palette-cell" @click="setBorder('border-horizontal')">
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <g fill="#000000" fill-rule="evenodd">
                <path d="M6,14 L8,14 L8,12 L6,12 L6,14 L6,14 Z M3,2 L5,2 L5,0 L3,0 L3,2 L3,2 Z M6,11 L8,11 L8,9 L6,9 L6,11 L6,11 Z M3,14 L5,14 L5,12 L3,12 L3,14 L3,14 Z M0,5 L2,5 L2,3 L0,3 L0,5 L0,5 Z M0,14 L2,14 L2,12 L0,12 L0,14 L0,14 Z M0,2 L2,2 L2,0 L0,0 L0,2 L0,2 Z M0,11 L2,11 L2,9 L0,9 L0,11 L0,11 Z M12,11 L14,11 L14,9 L12,9 L12,11 L12,11 Z M12,14 L14,14 L14,12 L12,12 L12,14 L12,14 Z M12,5 L14,5 L14,3 L12,3 L12,5 L12,5 Z M12,0 L12,2 L14,2 L14,0 L12,0 L12,0 Z M6,2 L8,2 L8,0 L6,0 L6,2 L6,2 Z M9,2 L11,2 L11,0 L9,0 L9,2 L9,2 Z M6,5 L8,5 L8,3 L6,3 L6,5 L6,5 Z M9,14 L11,14 L11,12 L9,12 L9,14 L9,14 Z" opacity=".54"/>
                <polygon points="0 8 14 8 14 6 0 6"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="border-palette-cell" @click="setBorder('border-vertical')">
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <g fill="#000000" fill-rule="evenodd">
                <path d="M3,14 L5,14 L5,12 L3,12 L3,14 L3,14 Z M0,5 L2,5 L2,3 L0,3 L0,5 L0,5 Z M0,2 L2,2 L2,0 L0,0 L0,2 L0,2 Z M3,8 L5,8 L5,6 L3,6 L3,8 L3,8 Z M3,2 L5,2 L5,0 L3,0 L3,2 L3,2 Z M0,14 L2,14 L2,12 L0,12 L0,14 L0,14 Z M0,8 L2,8 L2,6 L0,6 L0,8 L0,8 Z M0,11 L2,11 L2,9 L0,9 L0,11 L0,11 Z M12,0 L12,2 L14,2 L14,0 L12,0 L12,0 Z M12,8 L14,8 L14,6 L12,6 L12,8 L12,8 Z M12,14 L14,14 L14,12 L12,12 L12,14 L12,14 Z M12,5 L14,5 L14,3 L12,3 L12,5 L12,5 Z M12,11 L14,11 L14,9 L12,9 L12,11 L12,11 Z M9,14 L11,14 L11,12 L9,12 L9,14 L9,14 Z M9,8 L11,8 L11,6 L9,6 L9,8 L9,8 Z M9,2 L11,2 L11,0 L9,0 L9,2 L9,2 Z" opacity=".54"/>
                <polygon points="6 14 8 14 8 0 6 0"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="border-palette-cell" @click="setBorder('border-outside')">
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <g fill="#000000" fill-rule="evenodd">
                <path d="M8,3 L6,3 L6,5 L8,5 L8,3 L8,3 Z M11,6 L9,6 L9,8 L11,8 L11,6 L11,6 Z M8,6 L6,6 L6,8 L8,8 L8,6 L8,6 Z M8,9 L6,9 L6,11 L8,11 L8,9 L8,9 Z M5,6 L3,6 L3,8 L5,8 L5,6 L5,6 Z" opacity=".54"/>
                <path d="M0,0 L14,0 L14,14 L0,14 L0,0 Z M12,12 L12,2 L2,2 L2,12 L12,12 Z"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="border-palette-cell" @click="setBorder('border-left')">
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <g fill="#000000" fill-rule="evenodd">
                <path d="M6,8 L8,8 L8,6 L6,6 L6,8 L6,8 Z M6,5 L8,5 L8,3 L6,3 L6,5 L6,5 Z M6,11 L8,11 L8,9 L6,9 L6,11 L6,11 Z M6,14 L8,14 L8,12 L6,12 L6,14 L6,14 Z M3,14 L5,14 L5,12 L3,12 L3,14 L3,14 Z M3,2 L5,2 L5,0 L3,0 L3,2 L3,2 Z M3,8 L5,8 L5,6 L3,6 L3,8 L3,8 Z M12,14 L14,14 L14,12 L12,12 L12,14 L12,14 Z M12,8 L14,8 L14,6 L12,6 L12,8 L12,8 Z M12,11 L14,11 L14,9 L12,9 L12,11 L12,11 Z M12,5 L14,5 L14,3 L12,3 L12,5 L12,5 Z M6,2 L8,2 L8,0 L6,0 L6,2 L6,2 Z M12,0 L12,2 L14,2 L14,0 L12,0 L12,0 Z M9,14 L11,14 L11,12 L9,12 L9,14 L9,14 Z M9,8 L11,8 L11,6 L9,6 L9,8 L9,8 Z M9,2 L11,2 L11,0 L9,0 L9,2 L9,2 Z" opacity=".54"/>
                <polygon points="0 14 2 14 2 0 0 0"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="border-palette-cell" @click="setBorder('border-top')">
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <g fill="#000000" fill-rule="evenodd">
                <path d="M3,8 L5,8 L5,6 L3,6 L3,8 L3,8 Z M0,14 L2,14 L2,12 L0,12 L0,14 L0,14 Z M6,14 L8,14 L8,12 L6,12 L6,14 L6,14 Z M6,11 L8,11 L8,9 L6,9 L6,11 L6,11 Z M3,14 L5,14 L5,12 L3,12 L3,14 L3,14 Z M0,11 L2,11 L2,9 L0,9 L0,11 L0,11 Z M6,8 L8,8 L8,6 L6,6 L6,8 L6,8 Z M0,5 L2,5 L2,3 L0,3 L0,5 L0,5 Z M0,8 L2,8 L2,6 L0,6 L0,8 L0,8 Z M12,8 L14,8 L14,6 L12,6 L12,8 L12,8 Z M12,11 L14,11 L14,9 L12,9 L12,11 L12,11 Z M12,5 L14,5 L14,3 L12,3 L12,5 L12,5 Z M6,5 L8,5 L8,3 L6,3 L6,5 L6,5 Z M9,14 L11,14 L11,12 L9,12 L9,14 L9,14 Z M9,8 L11,8 L11,6 L9,6 L9,8 L9,8 Z M12,14 L14,14 L14,12 L12,12 L12,14 L12,14 Z" opacity=".54"/>
                <polygon points="0 0 0 2 14 2 14 0"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="border-palette-cell" @click="setBorder('border-right')">
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <g fill="#000000" fill-rule="evenodd">
                <path d="M0,2 L2,2 L2,0 L0,0 L0,2 L0,2 Z M3,2 L5,2 L5,0 L3,0 L3,2 L3,2 Z M3,8 L5,8 L5,6 L3,6 L3,8 L3,8 Z M3,14 L5,14 L5,12 L3,12 L3,14 L3,14 Z M0,5 L2,5 L2,3 L0,3 L0,5 L0,5 Z M0,8 L2,8 L2,6 L0,6 L0,8 L0,8 Z M0,14 L2,14 L2,12 L0,12 L0,14 L0,14 Z M0,11 L2,11 L2,9 L0,9 L0,11 L0,11 Z M9,8 L11,8 L11,6 L9,6 L9,8 L9,8 Z M6,14 L8,14 L8,12 L6,12 L6,14 L6,14 Z M9,14 L11,14 L11,12 L9,12 L9,14 L9,14 Z M6,2 L8,2 L8,0 L6,0 L6,2 L6,2 Z M9,2 L11,2 L11,0 L9,0 L9,2 L9,2 Z M6,11 L8,11 L8,9 L6,9 L6,11 L6,11 Z M6,5 L8,5 L8,3 L6,3 L6,5 L6,5 Z M6,8 L8,8 L8,6 L6,6 L6,8 L6,8 Z" opacity=".54"/>
                <polygon points="12 0 12 14 14 14 14 0"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="border-palette-cell" @click="setBorder('border-bottom')">
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <g fill="#000000" fill-rule="evenodd">
                <path d="M5,0 L3,0 L3,2 L5,2 L5,0 L5,0 Z M8,6 L6,6 L6,8 L8,8 L8,6 L8,6 Z M8,9 L6,9 L6,11 L8,11 L8,9 L8,9 Z M11,6 L9,6 L9,8 L11,8 L11,6 L11,6 Z M5,6 L3,6 L3,8 L5,8 L5,6 L5,6 Z M11,0 L9,0 L9,2 L11,2 L11,0 L11,0 Z M8,3 L6,3 L6,5 L8,5 L8,3 L8,3 Z M8,0 L6,0 L6,2 L8,2 L8,0 L8,0 Z M2,9 L0,9 L0,11 L2,11 L2,9 L2,9 Z M12,11 L14,11 L14,9 L12,9 L12,11 L12,11 Z M12,5 L14,5 L14,3 L12,3 L12,5 L12,5 Z M12,8 L14,8 L14,6 L12,6 L12,8 L12,8 Z M12,0 L12,2 L14,2 L14,0 L12,0 L12,0 Z M2,0 L0,0 L0,2 L2,2 L2,0 L2,0 Z M2,3 L0,3 L0,5 L2,5 L2,3 L2,3 Z M2,6 L0,6 L0,8 L2,8 L2,6 L2,6 Z" opacity=".54"/>
                <polygon points="0 14 14 14 14 12 0 12"/>
              </g>
            </g>
          </svg>
        </div>
        <div class="border-palette-cell" @click="setBorder('border-none')">
          <svg width="14" height="14" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M6,14 L8,14 L8,12 L6,12 L6,14 L6,14 Z M3,8 L5,8 L5,6 L3,6 L3,8 L3,8 Z M3,2 L5,2 L5,0 L3,0 L3,2 L3,2 Z M6,11 L8,11 L8,9 L6,9 L6,11 L6,11 Z M3,14 L5,14 L5,12 L3,12 L3,14 L3,14 Z M0,5 L2,5 L2,3 L0,3 L0,5 L0,5 Z M0,14 L2,14 L2,12 L0,12 L0,14 L0,14 Z M0,2 L2,2 L2,0 L0,0 L0,2 L0,2 Z M0,8 L2,8 L2,6 L0,6 L0,8 L0,8 Z M6,8 L8,8 L8,6 L6,6 L6,8 L6,8 Z M0,11 L2,11 L2,9 L0,9 L0,11 L0,11 Z M12,11 L14,11 L14,9 L12,9 L12,11 L12,11 Z M12,14 L14,14 L14,12 L12,12 L12,14 L12,14 Z M12,8 L14,8 L14,6 L12,6 L12,8 L12,8 Z M12,5 L14,5 L14,3 L12,3 L12,5 L12,5 Z M12,0 L12,2 L14,2 L14,0 L12,0 L12,0 Z M6,2 L8,2 L8,0 L6,0 L6,2 L6,2 Z M9,2 L11,2 L11,0 L9,0 L9,2 L9,2 Z M6,5 L8,5 L8,3 L6,3 L6,5 L6,5 Z M9,14 L11,14 L11,12 L9,12 L9,14 L9,14 Z M9,8 L11,8 L11,6 L9,6 L9,8 L9,8 Z" opacity=".54"/></g>
          </svg>
        </div>

      </div>
      <div class="horizontal-driver"></div>
      <div class="border-palette-right">
        <div :class="'border-palette-cell toolbar-button '+(show.color?'active':'')" @click.stop="clickDropdown('color')">
          <svg width="19" height="18" :style="{opacity: 0.5,'border-bottom':'3px solid #'+color}">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M6.5,3.62 L0,10.12 L0,13 L2.88,13 L9.38,6.5 L6.5,3.62 Z M11.85,4.02 C12.05,3.82 12.05,3.51 11.85,3.31 L9.68,1.14 C9.48,0.94 9.17,0.94 8.97,1.14 L7.62,2.5 L10.5,5.38 L11.85,4.02 L11.85,4.02 Z" transform="translate(4,0)"/></g>
          </svg>
          <ColorPicker :hex="color" :type="'color'" v-on:save="saveStyle"></ColorPicker>
        </div>
        <div :class="'border-palette-cell toolbar-button '+(show.borderStyle?'active':'')" @click.stop="clickDropdown('borderStyle')">
          <svg width="14" height="10" :style="{opacity: 0.5,}">
            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M0,0 L14,0 L14,2 L0,2 L0,0 Z M0,4 L6,4 L6,6 L0,6 L0,4 Z M0,8 L2,8 L2,10 L0,10 L0,8 Z M8,4 L14,4 L14,6 L8,6 L8,4 Z M4,8 L6,8 L6,10 L4,10 L4,8 Z M8,8 L10,8 L10,10 L8,10 L8,8 Z M12,8 L14,8 L14,10 L12,10 L12,8 Z"/></g>
          </svg>
          <div class="border-dropdown">
            <div class="border-dropdown-option" @click.stop="saveStyle({type:'borderStyle',value:'normal'})">
              <div :class="'border-dropdown-check '+(borderStyle=='normal'?'active':'')"></div>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="1" style="user-select: none;"><line x1="0" y1="0.5" x2="50" y2="0.5" stroke-width="1" stroke="black" style="user-select: none;"></line></svg>
            </div>
            <div class="border-dropdown-option" @click.stop="saveStyle({type:'borderStyle',value:'bold'})">
              <div :class="'border-dropdown-check '+(borderStyle=='bold'?'active':'')"></div>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="3" style="user-select: none;"><line x1="0" y1="1.5" x2="50" y2="1.5" stroke-width="3" stroke="black" style="user-select: none;"></line></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/components/ColorPicker";
export default {
  name: "BorderPalette",
  props:{

  },
  components:{
    ColorPicker,
  },
  data(){
    return{
      borderStyle:'normal',
      color:'000000',
      lastClickItem:'',
      show:{
        color:false,
        borderStyle:false,
      },

    }
  },
  methods:{
    //监听ColorPicker的save事件，选择颜色
    saveStyle(data){
      this[data.type]=data.value;
      this.closeDropdown();
    },

    //触发设置边框的事件
    setBorder(border){
      this.$emit('setBorder', {type:border,color:this.color,style:this.borderStyle});
    },

    //关闭颜色选择器界面
    closeDropdown(){
      this.show={
        color: false,
        borderStyle: false,
      }
    },

    //显示颜色选择器界面
    clickDropdown(data){
      if(this.lastClickItem!=data&&this.show[data]!==true){
        this.closeDropdown();
      }
      this.show[data]=!this.show[data];
      this.lastClickItem=data;
    }
  }
}
</script>

<style scoped>
.border-palette{
  position: absolute;
  left:0;
  top:30px;
  background-color: #fff;
  padding: 5px;
  z-index: 800;
  box-shadow: 1px 2px 5px 2px rgba(51,51,51,15%);
}
.border-palette-style{
  display: flex;
  flex-wrap: wrap;
  width: 150px;
}
.border-palette-cell{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-palette-cell:hover,.border-palette-cell.active{
  background-color: #eee;
}
.toolbar-button{
  position: relative;
}
.toolbar-button.active > .border-dropdown{
  display: block;
}
.border-dropdown{
  position: absolute;
  left:0;
  top:30px;
  background-color: #fff;
  z-index: 800;
  box-shadow: 1px 2px 5px 2px rgba(51,51,51,15%);
  display: none;
}
.border-dropdown-option{
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-dropdown-option:hover{
  background-color: rgba(0,0,0,0.05);
}
.border-dropdown-check{
  width:10px;
  height: 10px;
  background-color: rgba(0,0,0,0.08);
  margin-right:12px;
}
.border-dropdown-check.active{
  background-color: #4b89ff;
}
</style>