<template>
  <div class="main"
       :style="{
          left:data.left*zoom-offsetLeft+'px',
          top:data.top*zoom-offsetTop+'px',
          width:data.width*zoom+'px',
          height:data.height*zoom+'px'
       }"
       @mousedown="chartMousedown($event)"
  >
    <div class="chart-box"
         ref="chart"
         :style="{width:data.width*zoom+'px',height:data.height*zoom+'px'}"
    >
    </div>
    <div v-show="data.selecting" class="image-resize-area" :style="{width:data.width*zoom+'px',height:data.height*zoom+'px'}">
      <div class="image-resize left-top" @mousedown.stop="resize('lt',$event)"></div>
      <div class="image-resize left-bottom" @mousedown.stop="resize('lb',$event)"></div>
      <div class="image-resize right-top" @mousedown.stop="resize('rt',$event)"></div>
      <div class="image-resize right-bottom" @mousedown.stop="resize('rb',$event)"></div>
      <div class="image-resize left" @mousedown.stop="resize('l',$event)"></div>
      <div class="image-resize bottom" @mousedown.stop="resize('b',$event)"></div>
      <div class="image-resize top" @mousedown.stop="resize('t',$event)"></div>
      <div class="image-resize right" @mousedown.stop="resize('r',$event)"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "Chart",
  props:{
    data:{
      type:Object,
      default:function (){
        return {
          chartData: {}
        }
      }
    },
    index:0,
    zoom:{
      default:1,
    },
    offsetLeft:0,
    offsetTop:0,
  },
  watch:{
    'data.chartData':function (){
      this.initData();
      this.setOption();
      setTimeout(()=>{
        this.chart.resize();
      },100);
    },
    'data.width':function(){
      this.chart.resize();
    },
    'data.height':function(){
      this.chart.resize();
    },
    zoom(){
      this.$nextTick(()=>{
        this.chart.resize();
      });
    }
  },
  computed:{
    line(){
      let option={
        backgroundColor:'#fff',
        title: {
          text: this.data.chartData.title,
          left:'center',
          top:'6%'
        },
        legend: {
          data: this.names,
          bottom:'6%',
          icon:'rect',
        },
        xAxis: {
          type: 'category',
          data: this.heads,
        },
        yAxis: {
          type: 'value'
        },
        series:[],
      }

      let markPoint={data:[]}, markLine={data:[]};
      if(this.data.chartData.showMax){
        markPoint.data.push({ type: 'max', name: 'Max' })
        // markLine.data.push({ type: 'max', name: 'Max' })
      }
      if(this.data.chartData.showMin){
        markPoint.data.push({ type: 'min', name: 'Min' })
        // markLine.data.push({ type: 'min', name: 'Min' })
      }
      if(this.data.chartData.showAverage){
        markLine.data.push({ type: 'average', name: 'Average' })
      }

      for (let i in this.data.chartData.series){
        option.series.push({
          data:this.data.chartData.options[i].datasets,
          type:'line',
          color:this.data.chartData.series[i].color,
          name:this.data.chartData.series[i].name.join(','),
          markPoint,
          markLine
        })
      }

      return option;
    },
    bar(){
      let option={
        backgroundColor:'#fff',
        title: {
          text: this.data.chartData.title,
          left:'center',
          top:'6%',
        },
        legend: {
          data: this.names,
          bottom:'6%',
          icon:'rect',
        },
        xAxis: {
          type: 'category',
          data: this.heads,
        },
        yAxis: {
          type: 'value'
        },
        series:[],
      }

      let label={};
      if(this.data.chartData.showValue){
        label.show=true;
      }

      for (let i in this.data.chartData.series){
        option.series.push({
          data:this.data.chartData.options[i].datasets,
          type:'bar',
          color:this.data.chartData.series[i].color,
          name:this.data.chartData.series[i].name.join(','),
          label
        })
      }

      return option;
    },
    pie(){
      let option={
        backgroundColor:'#fff',
        title: {
          text: this.data.chartData.title,
          left:'center',
          top:'6%',
        },
        legend: {
          data: this.names,
          bottom:'6%'
        },
        series:[],
      }

      let data=[],color=[];
      for (let i in this.data.chartData.series){
        color.push(this.data.chartData.series[i].color);
        let values=0;
        for (let j in this.data.chartData.options[i].datasets){
          values+=Number(this.data.chartData.options[i].datasets[j]);
        }
        data.push({
          value:values,
          itemStyle: {
            color: this.data.chartData.series[i].color
          },
          name:this.data.chartData.series[i].name.join(','),
        });
      }

      let label={
        minMargin: 5,
        edgeDistance: 10,
        lineHeight: 15,
        formatter:'{name|{b}}',
        rich: {
          name: {
            fontSize: 14,
          },
          value: {
            fontSize: 12,
            color: '#999'
          },
          scale: {
            fontSize: 12,
            color: '#999'
          },
        }
      };
      if(this.data.chartData.showValue){
        label.formatter += '\n{value|{c}}'
      }
      if(this.data.chartData.showScale){
        if(!this.data.chartData.showValue)
          label.formatter+='\n';
        label.formatter += '{scale|({d}%)}'
      }

      option.series.push({
        type: 'pie',
        radius: '50%',
        data:data,
        label,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        labelLayout: (params)=>{
          const isLeft = params.labelRect.x < this.chart.getWidth() / 2;
          const points = params.labelLinePoints;
          // Update the end point.
          points[2][0] = isLeft
              ? params.labelRect.x
              : params.labelRect.x + params.labelRect.width;
          return {
            labelLinePoints: points
          };
        },
      });

      return option;
    }
  },
  data(){
    return{
      chart:null,
      heads:[],
      names:[],
    }
  },
  methods:{
    getSrc(){
      return this.chart.getDataURL();
    },
    resize(type,$event){
      this.$emit('resizeMousedown',{type:type,e:$event,index:this.index});
    },
    chartMousedown(e){
      this.$emit('imageMousedown',{
        index:this.index,
        e:e,
      });
    },
    setOption(){
      this.chart.setOption(this[this.data.chartData.chartType],true);
    },
    initData() {
      // if(this.data.chartData.options.length>this.data.chartData.heads.length){
      //   this.heads=[];
      //   for(let i=0;i<this.data.chartData.options.length;i++){
      //     if(i>=this.data.chartData.heads.length)
      //         this.heads.push('');
      //     else this.heads.push(this.data.chartData.heads[i]);
      //   }
      // }
      // else this.heads=this.data.chartData.heads;
      this.heads=this.data.chartData.heads;

      this.names=[];
      for(let i=0;i<this.data.chartData.series.length;i++){
        this.names.push(this.data.chartData.series[i].name.join(','));
      }
    },
    initChart(){
      if (this.chart) {
        this.chart.dispose();
      }
      this.chart=echarts.init(this.$refs.chart);
    },
  },
  mounted() {
    this.initData();
    this.initChart();
    this.setOption();
  },
}
</script>

<style scoped>
.chart-canvas{
  width: 100%;
  height: 100%;
}
.main{
  position: absolute;
  z-index:750;
  user-select: none;
}
.image-resize-area{
  border: 1px solid #333;
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  cursor: move;
}
.image-resize{
  position: absolute;
  width: 12px;
  height: 12px;
  border: 1px solid #333;
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  background-color: #fff;
  user-select: none;
}
.image-resize.left-top{
  left:-6px;
  top:-6px;
  cursor: nwse-resize;
}
.image-resize.right-bottom{
  right:-6px;
  bottom:-6px;
  cursor: nwse-resize;
}
.image-resize.left-bottom{
  left:-6px;
  bottom:-6px;
  cursor: nesw-resize;
}
.image-resize.right-top{
  right:-6px;
  top:-6px;
  cursor: nesw-resize;
}
.image-resize.right{
  right:-6px;
  top:0;
  bottom:0;
  margin:auto 0;
  cursor: ew-resize;
}
.image-resize.left{
  left:-6px;
  top:0;
  bottom:0;
  margin:auto 0;
  cursor: ew-resize;
}
.image-resize.top{
  top:-6px;
  left:0;
  right:0;
  margin:0 auto;
  cursor: ns-resize;
}
.image-resize.bottom{
  bottom:-6px;
  left:0;
  right:0;
  margin:0 auto;
  cursor: ns-resize;
}
</style>