import Vue from 'vue'
import VueRouter from 'vue-router'
import {config} from "@/plugins/config";

import RouterView from '@/views/Router'
import LoginView from '@/views/Login'
import MainView from '@/views/Main'
import DashboardView from '@/views/Dashboard'
import UserListView from '@/views/User/List'
import UserCreateView from '@/views/User/Create'
import UserInfoView from '@/views/User/Info'
import UserModifyView from '@/views/User/Modify'
import TemplateListView from '@/views/Template/List'
import TemplateInfoView from '@/views/Template/Info'
import SettingView from '@/views/Setting'
import GroupView from '@/views/Group/List'

import auth from '@/plugins/auth'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: "/home",
        type: 'redirect',
    },
    {
        path: '/login',
        name: 'login',
        meta: {title: '登陆'},
        type: 'page',
        component: LoginView
    },
    {
        path: '/home',
        component: MainView,
        meta: {title: '首页', disable: false},
        type: 'home',
        children: [
            {
                path: "/",
                redirect: "template",
                type: 'redirect',
            },
            {
                path: 'template',
                meta: {title: '模版管理'},
                type: 'page',
                component: RouterView,
                children: [
                    {
                        path: "/",
                        redirect: "index",
                        type: 'redirect',
                    },
                    {
                        path: 'index',
                        name: 'TemplateList',
                        meta: {title: '模版列表'},
                        type: 'page',
                        component: TemplateListView
                    },
                    {
                        path: 'create',
                        name: 'TemplateCreate',
                        meta: {title: '新建模版'},
                        type: 'page',
                        component: TemplateInfoView
                    },
                    {
                        path: 'modify/:id',
                        name: 'TemplateModify',
                        meta: {title: '修改模版'},
                        type: 'page',
                        component: TemplateInfoView
                    },
                    {
                        path: 'history/:identifying_code',
                        name: 'TemplateHistory',
                        meta: {title: '历史记录'},
                        type: 'page',
                        component: TemplateInfoView
                    },
                ]
            },
            {
                path: 'group',
                name: 'group',
                meta: {title: '分组'},
                type: 'page',
                component: GroupView
            },
            {
                path: 'setting',
                name: 'setting',
                meta: {title: '设置'},
                type: 'page',
                component: SettingView
            },
        ]
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (auth.isHasToken() && auth.data.login == config.admin && (to.name === 'TemplateList' || to.name === 'TemplateCreate' || to.name === 'TemplateModify' || to.name === 'TemplateHistory')) {
        next({name: 'setting'});
    }

    if (to.name !== 'login' && !auth.isHasToken()) {
        console.log("Unauthorized auth");
        next({name: 'login'});
    } else if (to.name === 'login' && auth.isHasToken()) {
        next({name: 'TemplateList'});
    } else {
        next();
    }
});

export default router
