<template>
  <div class="user-manage p-20-15">
    <div class="user-btns">
      <router-link to="create">
        <el-button type="primary">新增用户</el-button>
      </router-link>
    </div>

    <el-table :data="tableData" border class="w-100 mt-2 user-table">
      <el-table-column fixed prop="id" label="编号" min-width="50"></el-table-column>
      <el-table-column prop="login" label="登陆名" min-width="100"></el-table-column>
      <el-table-column prop="nickname" label="昵称" min-width="100"></el-table-column>
      <el-table-column prop="type" label="权限" min-width="50"></el-table-column>
      <el-table-column prop="created_at" label="创建时间" min-width="100"></el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="info(scope.row.id)">查看</el-button>
          <el-button type="text" size="small" @click="modify(scope.row.id)">编辑</el-button>
          <el-popconfirm title="确定删除该用户吗？" @confirm="remove(scope.row.id)">
            <el-button type="text" size="small" slot="reference" class="ml-10" :disabled="scope.row.id==auth.getId()">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination background layout="prev, pager, next" :total="dataCount" :page-size="pageSize"
                   class="w-100 mt-2 mx-auto d-flex-c-c user-pagination"></el-pagination>
  </div>
</template>

<script>
import {group} from "@/plugins/config";
import auth from "@/plugins/auth";

export default {
  name: "index",
  data() {
    return {
      data: {},
      tableData: [],
      dataCount: 0,
      pageSize: 10,
      auth
    }
  },
  methods: {
    getUserList() {
      this.tableData = [];
      this.$http.post("user/list").then(res => {
        for (let e of res.data.items) {
          this.tableData.push({
            ...e,
            type: group[e.type]
          });
        }

        this.dataCount = res.data.count
      }).catch(() => {

      });
    },
    info(id) {
      this.$router.push({name: 'UserInfo', query: {id}})
    },
    modify(id) {
      this.$router.push({name: 'UserModify', query: {id}})
    },
    remove(id) {
      this.$http.delete(`user/remove/${id}`).then(res => {
        this.getUserList();
      }).catch(() => {
      });

    }
  },
  mounted() {
    this.getUserList();
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.mt-2 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.user-table {
  min-height: 450px;
}

</style>