const initData=(_this)=>{
    _this.$set(_this,'localSheet',{
        uploadData:{
            row:{},
            col:{},
            cell:{},
        },
        key:(_this.sheets.length===0?1:_this.sheets[_this.sheets.length-1].key+1),
        name:'sheet'+(_this.sheets.length===0?1:_this.sheets[_this.sheets.length-1].key+1),
        col:[],
        row:[],
        map:{},
        merges:[],
        requirements:[],
        zoom:1,
        chartData:{},
        chartSource:{},
        sourceSelecting:false,
        totalSourceSelecting:false,
        seriesSelecting:false,
        smartSelecting:false,
        smartPos:{},
        chartHead:{},
        headSelecting:false,
        calculate:false,
        cancelCellSave:false,
        dashSelectArea:[],
        select:{},
        selectAreaAttr:{},
        multipleSelect:[],
        multipleSelectAttr:[],
        multipleInputSelect:[],
        multipleInputSelectAttr:[],
        cellInputTypeSpecial:false,
        textAreaAttr:{},
        selecting:false,
        cellHeadArray:[],
        cellHeadSelecting:false,
        imageChangeFlag:true,
        imageSelecting:false,
        imageResizing:false,
        imageResizeType:'',
        nowImage:{
            index:null,
        },
        nowCell:{},
        nowCellHead:{},
        cellHeadSelect:{start:null,end:null,selecting:false},
        selectAreaCell:[],
        offsetLeft:0,
        offsetTop:0,
        rcRange:{},
        resizing:false,
        resizeData:{},
        resizeCoordinate:{},
        images:[],
        sheetRename:false,
        inputCellName:null,
        inputSaveFlag:true,
        inputShow:false,
        inputShowValue:'',
        inputShowCalc:'',
        inputSelecting:false,
        inputSelect:{},
        inputSelectAreaAttr:{},
        cellInputType:false,
        inputShowName:'',
        freeze:false,
        freezeRow:{index:0,size:0,scrollLimit:0},
        freezeCol:{index:0,size:0,scrollLimit:0},
    });
}

const colName=(num)=>{
    let ConvertToLetter='',a=num,b;
    do {
        a=Math.floor((num-1)/26);
        b=(num-1)%26;
        ConvertToLetter = String.fromCharCode(b + 65) + ConvertToLetter;
        num = a
    }while (num>0)

    return ConvertToLetter;
}

const findInObject=(obj,fn)=>{
    let result=undefined;
    result = Object.keys(obj).find((k)=>fn(obj[k]));
    return result;
}

const initCol=(_this,colLen=52,cols=null)=>{
    for (let num=0;num<colLen;num++){
        let c={
            name:colName(num+1),
            height:30,
            size:80,
            top:0,
            left:(num)*(80),
            index:(num),
        }
        if(cols){
            let result=findInObject(cols,(col)=>col.index==num);
            if(result!==undefined){
                c=cols[result];
            }
            else if(num>0){
                c.left=_this.localSheet.col[num-1].left+_this.localSheet.col[num-1].size;
            }
        }
        _this.localSheet.col.push(c);
    }
};

const initRow=(_this,rowLen=100,rows=null)=>{
    for (let num=0;num<rowLen;num++){
        let r={
            name:(num+1),
            width:40,
            size:30,
            left:0,
            top:(num)*(30),
            index:(num),
        }
        if(rows){
            let result=findInObject(rows,(row)=>row.index==num);
            if(result!==undefined){
                r=rows[result];
            }
            else if(num>0){
                r.top=_this.localSheet.row[num-1].top+_this.localSheet.row[num-1].size;
            }
        }
        _this.localSheet.row.push(r);
    }
};

const setCellData=(_this,i,j)=>{
    _this.$set(_this.localSheet.map,_this.localSheet.col[i].name+(_this.localSheet.row[j].name),{
        type:'cell',
        name:_this.localSheet.col[i].name+(_this.localSheet.row[j].name),
        ci:i,
        ri:j,
    })
    _this.$set(_this.localSheet.map[_this.localSheet.col[i].name+(_this.localSheet.row[j].name)],'data',{
        name:_this.localSheet.col[i].name+(_this.localSheet.row[j].name),
        content:'',
        value:'',
        backup:'',
        row:_this.localSheet.row[j].name,
        col:_this.localSheet.col[i].name,
        inputShow:false,
        valid:true,
        merge:false,
    });
    _this.$set(_this.localSheet.map[_this.localSheet.col[i].name+(_this.localSheet.row[j].name)].data,'cellType',{type:'normal',extra:0});
    _this.$set(_this.localSheet.map[_this.localSheet.col[i].name+(_this.localSheet.row[j].name)].data,'cellStyle',{
        width:_this.localSheet.col[i].size,
        height:_this.localSheet.row[j].size,
        // left:i*(_this.localSheet.col[i].size),
        // top:j*(_this.localSheet.row[i].size),
        left:(_this.localSheet.col[i].left),
        top:(_this.localSheet.row[j].top),
        bold:false,
        italic:false,
        underline:false,
        font:'Microsoft YaHei',
        fontSize:12,
        color:'333333',
        textAlign:'left',
        verticalAlign:'center',
        backgroundColor:'none',
        borderLeftWidth:0,
        borderLeftColor:'e6e6e7',
        borderRightWidth:0,
        borderRightColor:'e6e6e7',
        borderTopWidth:0,
        borderTopColor:'e6e6e7',
        borderBottomWidth:0,
        borderBottomColor:'e6e6e7',
        zIndex:5,
    });
}

const initCell=(_this)=>{
    for(let j=0;j<_this.localSheet.row.length;j++){
        for(let i=0;i<_this.localSheet.col.length;i++){
            setCellData(_this,i,j);
        }
    }
};

const newSheet=(_this,option= null)=>{
    if(option){
        initData(_this);
        _this.localSheet.name=option.name;
        _this.localSheet.merges=option.merges;
        for (let i in option.images){
            option.images[i].selecting=false;
            if(option.images[i].type=='Chart'){
                delete option.images[i].src;
            }
        }
        _this.localSheet.images=option.images;
        _this.localSheet.requirements=option.requirements;
        if(option.freeze){
            _this.localSheet.freeze=option.freeze;
            _this.localSheet.freezeCol=option.freezeCol;
            _this.localSheet.freezeRow=option.freezeRow;
            _this.localSheet.offsetLeft=option.offsetLeft;
            _this.localSheet.offsetTop=option.offsetTop;
        }
        initCol(_this,option.colLen,option.col);
        initRow(_this,option.rowLen,option.row);
        initCell(_this);

        for (let i in option.cell){
            _this.localSheet.map[i]=option.cell[i];
        }

        for (let i in option.merges){
            option.merges[i]=_this.localSheet.map[option.merges[i].name];
        }

        _this.localSheet.uploadData=option;
    }
    else{
        initData(_this);
        initCol(_this);
        initRow(_this);
        initCell(_this);
    }

    if(_this.sheets.length)
        _this.localSheet.name='sheet'+(_this.sheets[_this.sheets.length-1].key+1)
    else _this.localSheet.name='sheet'+(_this.sheets.length+1)
    _this.sheets.push(_this.localSheet);
}

const initNewCell=(_this,length,type)=>{
    let range={};
    if(type=='row'){
        range={
            row:length,
            col:0,
        }
    }
    else{
        range={
            row:0,
            col:length,
        }
    }


    for(let j=range.row;j<_this.localSheet.row.length;j++){
        for(let i=range.col;i<_this.localSheet.col.length;i++){
            setCellData(_this,i,j);
        }
    }
}

const addNewRow=(_this,l=100)=>{
    let length=_this.localSheet.row.length;
    for (let num=length;num<length+l;num++){
        _this.localSheet.row.push({
            name:(num+1),
            width:40,
            size:30,
            left:0,
            top:(num)*(30),
            index:(num),
        });
    }
    initNewCell(_this,length,'row');
}

const addNewCol=(_this,l=52)=>{
    let length=_this.localSheet.col.length;
    for (let num=length;num<length+l;num++){
        _this.localSheet.col.push({
            name:colName(num+1),
            height:30,
            size:80,
            top:0,
            left:(num)*(80),
            index:(num),
        });
    }
    initNewCell(_this,length,'col');
}

export {
    newSheet,
    addNewCol,
    addNewRow,
    setCellData,
}