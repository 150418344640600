<template>
  <div class="color-picker" @click.stop="">
    <div class="flex">
      <div class="color-select-area">
        <div class="color-usual flex flex-wrap">
          <div v-for="color in usual" class="color-box" @click="selectColor(color)">
            <div class="color-cell" :style="{'background-color':'#'+color}"></div>
          </div>

        </div>
        <div class="vertical-driver"></div>
        <div class="color-preset flex flex-wrap">
          <div v-for="color in colors" class="color-box" @click="selectColor(color)">
            <div class="color-cell" :style="{'background-color':'#'+color}"></div>
          </div>
        </div>
        <div class="vertical-driver"></div>
        <div class="color-range-area flex">
          <input type="color" class="color-select" v-model="color">
          <div class="color-range-button-area">
            <button class="color-range-button primary-color text-white" @click="chooseColor">确定</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ColorPicker",
  props:{
    hex:"",
    type:"",
  },
  data(){
    return{
      usual:['333333'],
      colors:['FFFFFF','000000','E7E5E6','445569','5B9CD6','ED7D31','A5A5A5','FFC001','4371C6','71AE47',
        'F2F2F2','7F7F7F','D0CECF','D5DCE4','DEEAF6','FCE5D5','EDEDED','FFF3CD','D9E2F3','E3EFD9',
        'D8D8D8','595959','AFABAC','ADB8CA','BDD7EE','F7CCAC','DBDBDB','FFE59A','B3C6E7','C5E0B3',
        'BFBFBF','3F3F3F','756F6F','8596B0','9CC2E6','F4B184','C9C9C9','FED964','8EAADA','A7D08C',
        'A5A5A5','262626','3A3839','333F4F','2E75B5','C45A10','7B7B7B','7E6000','203864','365624',
        'C00000','FE0000','FDC101','FFFF01','93D051','00B04E','01B0F1','0170C1','012060','7030A0',],
      color:'',
    }
  },
  methods:{
    selectColor(color){
      this.setColor(color);
      this.$emit('save',{
        type:this.type,
        value: color,
      });

      let i=this.usual.findIndex(c=>c===color);
      if(i!==-1){
        this.usual.splice(i,1);
      }
      else if(this.usual.length>=10){
        this.usual.shift();
      }

      this.usual.unshift(color);
    },
    chooseColor(){
      this.selectColor(this.color.slice(1));
    },
    setColor(hex){
      this.color='#'+hex;
    },
    colorRGBToHex(r,g,b){
      return ((1<<24)+(r<<16)+(g<<8)+b).toString(16).slice(1);
    },
    colorHexToRGB(hex){
      return [1,3,5].map((h)=>parseInt(('#'+hex).substring(h,h+2),16));
    },
  },
  created() {
    this.setColor(this.hex);
  }
}
</script>

<style scoped>
.color-picker{
  position: absolute;
  left:0;
  top:30px;
  background-color: #fff;
  padding: 5px;
  z-index: 800;
  box-shadow: 1px 2px 5px 2px rgba(51,51,51,15%);
}
.color-select-area{
  width: 240px;
}
.color-box{
  padding: 1px;
  margin:2px;
  border: 1px solid transparent;
}
.color-box:hover{
  border-color: #ddd;
}
.color-cell{
  width: 16px;
  height: 16px;
}
.color-range-area{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.color-select{
  width: 160px;
  border:none;
  background-color: unset;
  padding: 0;
}
</style>