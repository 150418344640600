<template>
  <div class="outer" :style="{left:(data.left*zoom)-offsetLeft+'px',top:(data.top*zoom)-offsetTop+'px',}">
    <div :class="'select-area '+type"
         :style="{
            width:data.colSize*zoom+'px',height:data.rowSize*zoom+'px',
            border:'2px '+(type=='special'?'dashed':'solid')+' '+borderColor,'background-color':bgColor
         }"
    ></div>
    <div v-if="type=='normal'" class="select-dot" :style="{left:(data.colSize-2)*zoom+'px',top:(data.rowSize-2)*zoom+'px'}" @mousedown="$emit('selectDot')"></div>
  </div>

</template>

<script>
export default {
  name: "SelectArea",
  props: {
    data:Object,
    type:{
      default:'normal'
    },
    name:{
      default:null,
    },
    zoom:{
      default:1,
    },
    offsetTop:0,
    offsetLeft:0,
    borderColor:{
      default: '#5686F6',
    },
    bgColor:{
      default: '#EEF4FE4C',
    },
  },
  data(){
    return{
      style:{},
    }
  },
  methods:{

  },
  created() {
  }
}
</script>

<style scoped>
.outer{
  position: absolute;
  z-index:650;
}
.select-area{
  pointer-events: none;
  position: absolute;
  left:0;
  top:0;
  box-sizing: content-box;
}
.select-area.special{
  z-index:600;
}
.move-area{
  width: 100%;
  height: 100%;
  background-color: unset;
  border:2px solid rgb(33,115,70);
  position: absolute;
}
.select-dot{
  content: '';
  width: 8px;
  height: 8px;
  position: absolute;
  background: #5686F6;
  z-index: 650;
  border: 1px #fff solid;
  cursor: crosshair;
  user-select: none;
}
</style>