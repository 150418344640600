<template>
  <div class="group-manage p-20-15">
    <div class="group-btns" v-if="auth.isAdmin()" style="margin-bottom: 5px;">
      <el-button type="primary" @click="createGroup">新增分组</el-button>
    </div>

    <CreateGroup
        v-if="auth.isAdmin()"
        ref="createGroup"
        v-on:getGroup="getGroup"
    ></CreateGroup>

    <ModifyGroup
        v-if="auth.isAdmin()"
        ref="modifyGroup"
        :g="group"
        v-on:getGroup="getGroup"
    ></ModifyGroup>

    <el-table :data="tableData" border class="w-100 mt-2 user-table">
      <el-table-column fixed prop="id" label="编号" min-width="50"></el-table-column>
      <el-table-column prop="name" label="组名" min-width="100"></el-table-column>
      <el-table-column fixed="right" label="操作" width="200" v-if="auth.isAdmin()">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="modify(scope.row)">编辑</el-button>
          <el-popconfirm title="确定删除该分组吗？" @confirm="remove(scope.row.id)">
            <el-button type="text" size="small" slot="reference" class="ml-10">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination background layout="prev, pager, next" :total="dataCount" :page-size="pageSize"
                   class="w-100 mt-2 mx-auto d-flex-c-c user-pagination"></el-pagination>
  </div>
</template>

<script>
import CreateGroup from '../Create'
import ModifyGroup from '../Modify'
import auth from "@/plugins/auth";

export default {
  name: "index",
  data(){
    return{
      tableData: [],
      dataCount: 0,
      pageSize: 10,
      auth,
      group:{},
    }
  },
  components:{
    CreateGroup,
    ModifyGroup,
  },
  methods:{
    remove(id){
      this.$http.delete('group/remove/'+id).then(res=>{
        this.$toast('删除成功');
        this.getGroup();
      }).catch(e=>{
        this.$toast(e.response.data.message,'danger');
      });
    },
    modify(group){
      this.group=group;
      this.$refs.modifyGroup.show();
    },
    createGroup(){
      this.$refs.createGroup.show();
    },
    getGroup(){
      this.tableData = [];
      this.$http.post('group/list').then((res) => {
        this.tableData = res.data.data;
      }).catch(() => {
        this.tableData = [];
      });
    }
  },
  mounted() {
    this.getGroup();
  }
}
</script>

<style scoped>

</style>