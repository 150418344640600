<template>
  <el-header class="d-flex-c-b">
    <div>
      <img :src="logo" alt="" style="transform: scale(.6);transform-origin: left center;;">
    </div>

    <el-dropdown>
      <el-avatar :size="30" :src="UserIcon"></el-avatar>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item disabled>{{ auth.data.nickname }}</el-dropdown-item>
        <!--        <el-dropdown-item>个人信息</el-dropdown-item>-->
        <el-dropdown-item v-if="!auth.data.third" @click.native="dialogVisible=true">修改密码</el-dropdown-item>
        <el-dropdown-item divided @click.native="logout()">登出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog
        title="修改密码"
        width="400px"
        @close="dialogClose"
        :visible.sync="dialogVisible">
      <el-form>
        <el-form-item label="请输入新密码">
          <el-input v-model="newPassword"></el-input>
        </el-form-item>
        <el-form-item label="请重复输入新密码">
          <el-input v-model="newPasswordConfirmed"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogClose">取 消</el-button>
        <el-button type="primary" @click="modifyPassword" :loading="modifyPasswordLoading">修 改</el-button>
      </span>
    </el-dialog>
  </el-header>
</template>

<script>
import auth from '@/plugins/auth';
import logo from '@/assets/logo2.png';
import UserIcon from '@/assets/usericon.png';
import encryption from "@/plugins/encryption";
import {config} from "@/plugins/config";

export default {
  name: "index",
  data() {
    return {
      auth,
      logo,
      UserIcon,
      dialogVisible: false,
      newPassword: '',
      newPasswordConfirmed: '',
      modifyPasswordLoading: false
    }
  },
  methods: {
    logout() {
      this.$http.get('logout').then((res) => {
        this.$toast("登出成功。");
        localStorage.clear();
        setTimeout(function () {
          window.location.reload();
        }, 1500);
      }).catch(() => {
        localStorage.clear();
        setTimeout(function () {
          window.location.reload();
        }, 1500);
      });
    },
    modifyPassword() {
      this.modifyPasswordLoading = true;
      let new_password = encryption.encrypt(this.newPassword);
      let new_password_confirmation = encryption.encrypt(this.newPasswordConfirmed);
      this.$http.post('password', {
        new_password, new_password_confirmation
      }).then(res => {
        this.$toast('修改密码成功');
        this.dialogVisible = false;
        this.modifyPasswordLoading = false;
      }).catch(err=>{
        this.modifyPasswordLoading = false;
      });
    },
    dialogClose(){
      this.dialogVisible = false;
      this.newPassword = '';
      this.newPasswordConfirmed = '';
    }
  }
}
</script>

<style scoped>
.el-header {
  border-bottom: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px 0 rgb(30 30 30 / 10%);
}
</style>