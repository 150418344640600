<template>
  <div class="select-list selected-data">
    <div class="option-card card">
      <div class="card-info">
        <div class="title">
          <span>可选参数</span>
          <input type="text" class="list-search-input el-input__inner" placeholder="请输入搜索内容"
                 v-model="optionSearch">
        </div>
        <div class="list">
          <template v-for="o in optionParamsArr" v-if="(!inData(o))&&search(o,optionSearch)">
            <div class="list-data" :class="selectParamsValue===o.value?'selectData':''" @click="clickData(o)">
              <span>{{ o.name }}</span>
            </div>
          </template>
        </div>
      </div>
      <div class="params">

      </div>
    </div>

    <div class="list-btns">
      <div>
        <el-button type="primary" @click="addData">
          新增
          <i class="el-icon-arrow-right"></i>
        </el-button>
        <el-button style="margin:10px 0 0;" @click="removeData">
          <i class="el-icon-arrow-left" style="margin-left: -10px;"></i>
          删除
        </el-button>
      </div>
    </div>

    <div class="select-card card">
      <div class="card-info">
        <div class="title">
          <span>已选参数</span>
          <input type="text" class="list-search-input el-input__inner" placeholder="请输入搜索内容"
                 v-model="selectedSearch">
        </div>
        <div class="list" @dragover="listDragOver">
          <template v-for="o in selectedParamsArr" v-if="inData(o)&&search(o,selectedSearch)">
            <div class="list-data" :class="selectParamsValue===o.value?'selectData':''" @click="clickData(o)"
                 draggable="true" @dragstart="paramsDragStart(o)" @drop="paramsDragEnd" :data-id="o.value">
              <span :data-id="o.value">{{ o.name }}</span>
            </div>
          </template>
        </div>
      </div>
      <div class="params">
        <TableParamsInfo :paramsInfo="paramsInfo" :paramsData="paramsData" :datatype="datatype">
        </TableParamsInfo>
      </div>
    </div>
  </div>
</template>

<script>
import TableParamsInfo from "@/components/TableParamsInfo";

export default {
  name: "SelectList",
  components: {TableParamsInfo},
  props: {
    datatype:{
      default:'',
    },
    optionParamsArr: {
      type: Array,
      default: [],
    },
    selectedParamsArr: {
      type: Array,
      default: [],
    },
    paramsInfo: {
      type: Object,
      default: {}
    },
    paramsData: {
      type: Object,
      default: {value: {}, date: {}}
    }
  },
  data() {
    return {
      test: '',
      selectParamsValue: '',
      selectParams: {
        selectDataTypeCheckbox: [],
        absoluteDate: ''
      },
      optionSearch: '',
      selectedSearch: '',
      draging: {}
    }
  },
  mounted() {
    // this.initData()
  },
  updated() {
    // this.initData()
  },
  methods: {
    initData() {
      // console.log(this.optionParamsArr, this.selectedParamsArr, this.selectParams)
    },
    clickData(e) {
      this.selectParamsValue = e.value
      this.selectParams = e;
    },
    addData() {
      if (!this.selectInData()) {
        this.selectedParamsArr.push(this.selectParams);
      }
    },
    removeData() {
      if (this.selectInData()) {
        // let data = this.selectedParamsArr.find(e=>{return e.value === this.selectParamsValue;})
        let index = this.selectedParamsArr.indexOf(this.selectParams);
        this.selectedParamsArr.splice(index, 1);
      }
      //   this.selectedParamsArr.splice(this.selectedParamsArr.length-1, 1);
    },
    selectInData() {
      return this.inData(this.selectParams);
    },
    inData(o) {
      return (this.selectedParamsArr.find(e => {
        return e.value === o.value;
      }) ?? false);
    },
    search(o, search) {
      return (o.name.indexOf(search) !== -1);
    },
    listDragOver(e) {
      e.preventDefault();
    },
    paramsDragStart(o) {
      this.draging = o;
    },
    paramsDragEnd(e) {
      let to = e.toElement;
      let value = to.dataset.id;
      let selectedParams = this.selectedParamsArr.find(e => {
        return e.value === value
      });
      let newIndex = this.selectedParamsArr.indexOf(selectedParams);
      let oldIndex = this.selectedParamsArr.indexOf(this.draging);
      if (newIndex > oldIndex) {
        this.selectedParamsArr.splice(newIndex + 1, 0, this.draging);
        this.selectedParamsArr.splice(oldIndex, 1);
      } else if (oldIndex > newIndex) {
        this.selectedParamsArr.splice(oldIndex, 1);
        this.selectedParamsArr.splice(newIndex, 0, this.draging);
      }
      // console.log(newIndex, oldIndex);
    },
  },
  computed: {
    // selectOptionDataArr() {
    //   return !this.selectInData();
    // },
    // selectSelectedDataArr() {
    //   return this.selectInData();
    // }
  },
  watch: {
    optionSearch(newVal, oldVal) {

    },
    selectedSearch(newVal, oldVal) {

    }
  }

}
</script>

<style scoped>
.select-list {
  width: 100%;
  /*display: grid;*/
  /*grid-template-columns: 4fr 1fr 4fr;*/
  display: flex;

  color: #606266;

  transition: all .4s;


  user-select: none;
}

.card {
  border: 1px solid #D3D6D8;
}

.option-card {
  display: flex;
  width: 45%;
  transition: width .4s;
}

.select-card {
  display: flex;
  width: 45%;
  transition: width .4s;
}

.select-card, .option-card {
  height: calc(100vh - 465px);
  /*min-height: 200px;*/
}

.card-info {
  width: 100%;
  transition: width .4s;
}

.params {
  border-left: 1px solid #D3D6D8;
  padding: 10px;
  width: 0%;
  overflow: auto;
  display: none;
}

.list-btns {
  width: 10%;
}

.title {
  padding: 5px 10px;
  background: #f5f7fa;
  border-bottom: 1px solid #D3D6D8;
  line-height: 30px;
  height: 41px;
}

.title span{
  /*超出省略*/
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.list {
  /*padding: 5px 0;*/
  overflow: auto;
  list-style: none;
  /*height: 235px;*/
  height: calc(100% - 41px);
  box-sizing: border-box;
}

.list-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 30px;
}

.list-btns button {
  width: 100%;
}


.list-data {
  line-height: 20px;
  padding: 5px 10px;
  cursor: pointer;
}

.list-data:hover {
  background: #ecf0ff;
}

.list-data.selectData {
  background: #409EFF;
  color: white;
}


.option-data {
  /*grid-template-columns: 6fr 1fr 4fr;*/
}

.option-data .option-card {
  width: 60%;
}

.option-data .select-card {
  width: 30%;
}

.option-data .option-card .params {
  transition: width .4s;
  width: 60%;
  display: block;
}

.option-data .option-card .card-info {
  width: 40%;
}


.selected-data {
  /*grid-template-columns: 4fr 1fr 6fr;*/
}

.selected-data .option-card {
  width: 30%;
}

.selected-data .select-card {
  width: 70%;
}

.selected-data .select-card .params {
  width: 57%;
  display: block;
  transition: width .4s;
}

.selected-data .select-card .card-info {
  width: 43%;
}

.title {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.list-search-input {

}
</style>