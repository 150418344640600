import Table from './table';
import {bind,unbind} from './event';
import Vue from "vue";
import fa from "element-ui/src/locale/lang/fa";

function sheetReset(){
    const {
        el,
        table,
        data,
    }=this;

    el.style['width']= `${data.cellAreaWidth}px`;
    table.render();
}

export default class Sheet{
    constructor(targetEl, data,width,height,evt) {
        this.el=targetEl;
        this.table = new Table(this.el, data,width,height,evt);
        this.data=data;
        this.evt=evt;
        sheetInitEvents.call(this);
        sheetReset.call(this);
    }

    resetData(data){
        this.data=data;
        this.table.resetData(data);
        // this.reload();
    }

    reload() {
        sheetReset.call(this);
        return this;
    }

    listenMoving(){
        this.evt.movingListen=setInterval(()=>{
            this.listenMovingCallback();
        },1000/60);
    }

    listenMovingCallback(){
        if(this.data.selecting||this.data.inputSelecting||this.data.smartSelecting){
            let dir=0,changeX=0,changeY=0,limit=50,change=10;
            if(this.evt.movingListenPos.x>=0&&this.evt.movingListenPos.x<=limit){
                dir=-1;
                changeX=change;
            }
            if(this.evt.movingListenPos.x>=this.evt.cellAreaWidth-limit&&this.evt.movingListenPos.x<=this.evt.cellAreaWidth){
                dir=1;
                changeX=change;
            }
            if(this.evt.movingListenPos.y>=0&&this.evt.movingListenPos.y<=limit){
                dir=-1;
                changeY=change;
            }
            if(this.evt.movingListenPos.y>=this.evt.cellAreaHeight-limit&&this.evt.movingListenPos.y<=this.evt.cellAreaHeight){
                dir=1;
                changeY=change;
            }

            if(changeX||changeY){
                let newX=this.data.offsetLeft+(changeX*dir),
                    newY=this.data.offsetTop+(changeY*dir);

                newX=Math.max(0,Math.min(newX,(this.data.col[this.data.col.length - 1].left + this.data.col[this.data.col.length - 1].size)));
                newY=Math.max(0,Math.min(newY,(this.data.row[this.data.row.length - 1].top + this.data.row[this.data.row.length - 1].size)));

                this.evt.$refs.cab.scrollLeft = newX;
                this.data.offsetLeft = this.evt.$refs.cab.scrollLeft;
                this.evt.$refs.rab.scrollTop = newY;
                this.data.offsetTop = this.evt.$refs.rab.scrollTop;
                this.evt.resizeTable();

                if(this.data.selecting){
                    let cell=getCellByCoordinate.call(this,this.evt.movingListenPos.x,this.evt.movingListenPos.y);
                    this.data.select={
                        start:this.data.select.start,
                        end:cell,
                        selecting:true,
                    }
                }
                else if(this.data.inputSelecting){
                    let cell=getCellByCoordinate.call(this,this.evt.movingListenPos.x,this.evt.movingListenPos.y,false);

                    if(this.data.inputSelect.special){
                        let sci=this.data.selectAreaCell[0].ci,sri=this.data.selectAreaCell[0].ri,
                            eci=this.data.select.end.ci,
                            eri=this.data.select.end.ri,
                            ci=cell.ci,ri=cell.ri;

                        if(this.data.select.end.data.merge){
                            eci=this.data.select.end.data.mergeCellRange.col.max;
                            eri=this.data.select.end.data.mergeCellRange.row.max;
                        }

                        if(cell.ci>=sci&&cell.ci<=eci&&cell.ri>=sri&&cell.ri<=eri){
                            this.data.inputSelect={
                                start:{
                                    row:this.data.row[sri].name,
                                    col:this.data.col[sci].name,
                                },
                                end:{
                                    row:this.data.row[eri].name,
                                    col:this.data.col[eci].name,
                                },
                                selecting:true,
                                special:true,
                            }
                        }
                        else{
                            //行和列的变化差
                            let rl=0,cl=0;
                            if(cell.ci>=eci)
                                cl=cell.ci-eci;
                            else if(cell.ci<=sci)
                                cl=sci-cell.ci;

                            if(cell.ri>=eri)
                                rl=cell.ri-eri;
                            else if(cell.ri<=sri)
                                rl=sri-cell.ri;

                            if(cl>rl){
                                let minCol=Math.min(sci,cell.ci),
                                    maxCol=Math.max(eci,cell.ci);
                                this.data.inputSelect={
                                    start:{
                                        row:this.data.row[sri].name,
                                        col:this.data.col[minCol].name,
                                    },
                                    end:{
                                        row:this.data.row[eri].name,
                                        col:this.data.col[maxCol].name,
                                    },
                                    selecting:true,
                                    special:true,
                                }
                            }
                            else{
                                let minRow=Math.min(sri,cell.ri),
                                    maxRow=Math.max(eri,cell.ri);
                                this.data.inputSelect={
                                    start:{
                                        row:this.data.row[minRow].name,
                                        col:this.data.col[sci].name,
                                    },
                                    end:{
                                        row:this.data.row[maxRow].name,
                                        col:this.data.col[eci].name,
                                    },
                                    selecting:true,
                                    special:true,
                                }
                            }
                        }
                    }
                    else{
                        this.data.inputSelect={
                            start:this.data.inputSelect.start,
                            end:{
                                row:cell.data.row,
                                col:cell.data.col,
                            },
                            selecting:true,
                        }
                    }

                    if(this.data.cellInputType){
                        // this.data.cellInputTypeSpecial=true;
                    }
                }
            }
        }
    }
}

function objectEach(ob,target,require){
    let result=null;
    for (let i in ob){
        if(ob[i][require]==target[require])
            result=ob[i];
    }
    return result;
}

function getCellByCoordinate(x,y,type=true){
    const {data,evt}=this;
    let result={};
    for(let i in data.map){
        let cell=data.map[i];
        if(data.freezeCol.index>0||data.freezeRow.index>0){
            if(x+data.freezeCol.scrollLimit>=cell.data.cellStyle.left*data.zoom
                &&x+data.freezeCol.scrollLimit<=(cell.data.cellStyle.left+cell.data.cellStyle.width)*data.zoom
                &&y+data.freezeRow.scrollLimit>=cell.data.cellStyle.top*data.zoom
                &&y+data.freezeRow.scrollLimit<=(cell.data.cellStyle.top+cell.data.cellStyle.height)*data.zoom
                &&objectEach(evt.freezeCellShow,cell,'name')
            ){
                return cell;
            }
            else if(x+data.freezeCol.scrollLimit>=cell.data.cellStyle.left*data.zoom
                &&x+data.freezeCol.scrollLimit<=(cell.data.cellStyle.left+cell.data.cellStyle.width)*data.zoom
                &&y+data.offsetTop>=cell.data.cellStyle.top*data.zoom
                &&y+data.offsetTop<=(cell.data.cellStyle.top+cell.data.cellStyle.height)*data.zoom
                &&objectEach(evt.freezeRowShow,cell,'name')
            ){
                return cell;
            }
            else if(x+data.offsetLeft>=cell.data.cellStyle.left*data.zoom
                &&x+data.offsetLeft<=(cell.data.cellStyle.left+cell.data.cellStyle.width)*data.zoom
                &&y+data.freezeRow.scrollLimit>=cell.data.cellStyle.top*data.zoom
                &&y+data.freezeRow.scrollLimit<=(cell.data.cellStyle.top+cell.data.cellStyle.height)*data.zoom
                &&objectEach(evt.freezeColShow,cell,'name')
            ){
                return cell;
            }
        }

        if(x+data.offsetLeft>=cell.data.cellStyle.left*data.zoom
            &&x+data.offsetLeft<=(cell.data.cellStyle.left+cell.data.cellStyle.width)*data.zoom
            &&y+data.offsetTop>=cell.data.cellStyle.top*data.zoom
            &&y+data.offsetTop<=(cell.data.cellStyle.top+cell.data.cellStyle.height)*data.zoom){
            result=cell;
            if(type)
                return result;
        }
    }

    return result;
}

function sheetInitEvents(){
    //mousedown事件
    if(this.evt.identifying_code)
        return false;
    bind(this.el,'click',(e)=>{
        if(!this.data.cellInputType){
            try {
                this.evt.calcCellContent(this.data.inputShowValue)
                this.data.inputShowValue=this.data.nowCell.content;
            }
            catch (e){

            }
        }
    });

    bind(this.el,'mousedown',(e)=>{
        let cell=getCellByCoordinate.call(this,e.offsetX,e.offsetY);

        if(!this.data.cellInputType) {
            let error = false;
            try {
                this.evt.calcCellContent(this.data.inputShowValue)

                this.evt.$refs.cellInput.style.opacity='0';
                this.evt.$refs.cellInput.style.pointerEvents='none';
                this.evt.$nextTick(()=>{
                    setTimeout(()=>{
                        this.evt.$refs.cellInput.focus();
                        this.data.inputCellName=cell.name;
                    },100)
                })
                this.data.inputShow = false;
                this.data.calculate = false;
            }
            catch (e){
                console.log(e);
                if(e.message==='functionError'||e.message==='contentError'){
                    this.evt.$toast('公式中存在错误，请及时修正','danger');
                    // return false;
                    error = true;
                }
            }
            if(error) return;
        }
        if(cell.name){
            this.data.nowCell= {
                content:cell.data.content,
                row: cell.data.row,
                col: cell.data.col,
                name: cell.name,
                cellStyle: cell.data.cellStyle,
                timestamp: new Date().valueOf(),
            };
        }
        else{
            return false;
        }

        this.evt.closeMenu();
        this.evt.hideRenameInput();
        if(!this.data.sourceSelecting&&!this.data.headSelecting)
            this.evt.hideImageChoose();

        this.evt.movingListenPos={
            x:e.offsetX,
            y:e.offsetY,
        }
        this.listenMoving();
        this.evt.cellHeadSP=false;

        if(e.button==2){
            return false;
        }
        else if(this.data.cellInputType||this.data.sourceSelecting||this.data.headSelecting||this.evt.selectPrintAreaIndex||this.evt.selectRequirementAreaIndex||this.data.totalSourceSelecting||this.data.seriesSelecting){
            //编辑单元格时的选区 或 选择数据源的选区
            this.data.inputSelecting = true;
            if(this.data.inputSelect.start&&e.shiftKey){
                this.data.inputSelect = {
                    start: this.data.inputSelect.start,
                    end: {
                        row:cell.data.row,
                        col:cell.data.col,
                    },
                    selecting: true,
                };
            }
            else if(this.data.inputSelect.start&&(e.ctrlKey || e.metaKey)){
                if(this.data.cellInputType){
                    this.evt.backupText=this.data.inputShowValue;
                    this.data.cellInputTypeSpecial=true;
                    // let resolve = this.evt.backupText.split('');
                    // resolve.splice(this.evt.selectionStart,this.evt.selectionEnd-this.evt.selectionStart,',');
                    // this.evt.backupText=resolve.join('');
                }
                let index=this.data.multipleInputSelect.findIndex((s)=>{
                    return s.start.name===cell.name&&s.end.name===cell.name;
                });

                if(index===-1){
                    this.data.multipleInputSelect.push(deepClone(this.data.inputSelect));
                    this.data.inputSelect={
                        start: {
                            row:cell.data.row,
                            col:cell.data.col,
                        },
                        end: {
                            row:cell.data.row,
                            col:cell.data.col,
                        },
                        selecting:true,
                    }
                }
                else{
                    this.data.multipleInputSelect.splice(index,1);
                }
            }
            else{
                this.data.multipleInputSelect=[];
                this.data.inputSelect = {
                    start: {
                        row:cell.data.row,
                        col:cell.data.col,
                    },
                    end: {
                        row:cell.data.row,
                        col:cell.data.col,
                    },
                    selecting: true,
                };
            }
        }
        else{
            //开始选区，记录选区的起始点位
            this.data.selecting = true;
            if(this.data.select.start&&e.shiftKey){
                this.data.select={
                    start:this.data.select.start,
                    end:cell,
                    selecting:true,
                }
            }
            else if(this.data.select.start&&(e.ctrlKey || e.metaKey)){
                let index=this.data.multipleSelect.findIndex((s)=>{
                    return s.start.name===cell.name&&s.end.name===cell.name;
                });

                if(index===-1){
                    this.data.multipleSelect.push(deepClone(this.data.select));
                    this.data.select={
                        start:cell,
                        end:cell,
                        selecting:true,
                    }
                }
                else{
                    this.data.multipleSelect.splice(index,1);
                    this.data.select.selecting=false;
                    this.data.selecting=false;
                }
            }
            else{
                this.data.multipleSelect=[];
                this.data.select = {
                    start: cell,
                    end: cell,
                    selecting: true,
                };
            }
        }
    });

    bind(this.el,'mousemove',(e)=>{
        this.evt.movingListenPos={
            x:e.offsetX,
            y:e.offsetY,
        }
        // console.log(e);
        if(this.data.selecting){
            let cell=getCellByCoordinate.call(this,e.offsetX,e.offsetY);
            this.data.select={
                start:this.data.select.start,
                end:cell,
                selecting:true,
            }
        }
        else if(this.data.inputSelecting){
            let cell=getCellByCoordinate.call(this,e.offsetX,e.offsetY,false);

            if(this.data.inputSelect.special){
                let sci=this.data.selectAreaCell[0].ci,sri=this.data.selectAreaCell[0].ri,
                    eci=this.data.select.end.ci,
                    eri=this.data.select.end.ri,
                    ci=cell.ci,ri=cell.ri;

                if(this.data.select.end.data.merge){
                    eci=this.data.select.end.data.mergeCellRange.col.max;
                    eri=this.data.select.end.data.mergeCellRange.row.max;
                }

                if(cell.ci>=sci&&cell.ci<=eci&&cell.ri>=sri&&cell.ri<=eri){
                    this.data.inputSelect={
                        start:{
                            row:this.data.row[sri].name,
                            col:this.data.col[sci].name,
                        },
                        end:{
                            row:this.data.row[eri].name,
                            col:this.data.col[eci].name,
                        },
                        selecting:true,
                        special:true,
                    }
                }
                else{
                    //行和列的变化差
                    let rl=0,cl=0;
                    if(cell.ci>=eci)
                        cl=cell.ci-eci;
                    else if(cell.ci<=sci)
                        cl=sci-cell.ci;

                    if(cell.ri>=eri)
                        rl=cell.ri-eri;
                    else if(cell.ri<=sri)
                        rl=sri-cell.ri;

                    if(cl>rl){
                        let minCol=Math.min(sci,cell.ci),
                            maxCol=Math.max(eci,cell.ci);
                        this.data.inputSelect={
                            start:{
                                row:this.data.row[sri].name,
                                col:this.data.col[minCol].name,
                            },
                            end:{
                                row:this.data.row[eri].name,
                                col:this.data.col[maxCol].name,
                            },
                            selecting:true,
                            special:true,
                        }
                    }
                    else{
                        let minRow=Math.min(sri,cell.ri),
                            maxRow=Math.max(eri,cell.ri);
                        this.data.inputSelect={
                            start:{
                                row:this.data.row[minRow].name,
                                col:this.data.col[sci].name,
                            },
                            end:{
                                row:this.data.row[maxRow].name,
                                col:this.data.col[eci].name,
                            },
                            selecting:true,
                            special:true,
                        }
                    }
                }
            }
            else{
                this.data.inputSelect={
                    start:this.data.inputSelect.start,
                    end:{
                        row:cell.data.row,
                        col:cell.data.col,
                    },
                    selecting:true,
                }
            }

            if(this.data.cellInputType){
                // this.data.cellInputTypeSpecial=true;
            }
        }
        else if(this.data.resizing){
            let changeX=e.pageX-this.data.resizeCoordinate.x,changeY=e.pageY-this.data.resizeCoordinate.y;
            if(this.data.resizeData.type=='col'){
                let newX=this.data.resizeData.left+changeX;
                if(newX+this.data.resizeData.size<this.data.col[this.evt.findIndexInCol(this.data.resizeData.name)].left+10)
                    return false;

                this.data.resizeData.left=newX;

                this.data.resizeCoordinate={
                    x:e.pageX,
                    y:e.pageY,
                }
            }
            else{
                let newY=this.data.resizeData.top+changeY;
                if(newY+this.data.resizeData.size<this.data.row[this.evt.findIndexInRow(this.data.resizeData.name)].top+10)
                    return false;

                this.data.resizeData.top=newY;

                this.data.resizeCoordinate={
                    x:e.pageX,
                    y:e.pageY,
                }
            }
        }
    });

    window.onmouseup=(e)=>{
        if(this.evt.movingListen){
            clearInterval(this.evt.movingListen);
            this.evt.movingListen=null;
            this.evt.movingListenPos={
                x:null,
                y:null,
            }
        }
    }

    bind(this.el,'dblclick',(e)=>{
        if(!this.evt.checkInputValue()){
            return false;
        }
        if(!this.data.cellInputType)
            this.evt.showInput(this.data.select.start.name);
    })

    bind(this.el,'contextmenu',(e)=>{
        e.preventDefault();
        this.evt.contextmenu.type='cell';
        this.evt.contextmenu.left=e.clientX;
        this.evt.contextmenu.top=e.clientY;
        let cell=getCellByCoordinate.call(this,e.offsetX,e.offsetY);
        if(cell.name&&this.data.selectAreaCell.findIndex((c)=>c.name==cell.name)===-1){
            this.data.nowCell= {
                content:cell.data.content,
                row: cell.data.row,
                col: cell.data.col,
                name: cell.name,
                cellStyle: cell.data.cellStyle,
                timestamp: new Date().valueOf(),
            };

            this.data.select = {
                start: cell,
                end: cell,
                selecting: false,
            };
        }
    });

    bind(this.el,'drop',(e)=>{
        let cell=getCellByCoordinate.call(this,e.offsetX,e.offsetY);
        this.evt.addUndo({index:this.evt.sheetIndex,sheet:JSON.stringify(this.data)});
        if(this.evt.dragDataSet.param=='__DATE__')
            this.data.map[cell.name].data.content=this.evt.dragDataSet.param;
        else
            this.data.map[cell.name].data.content='_'+this.evt.dragDataSet.param;
        this.evt.insertUploadData('cell',cell.name,this.data.map[cell.name]);
        this.evt.sheet.resetData(this.data);

        e.preventDefault();
    });

    bind(this.el,'dragover',(e)=>{
        e.preventDefault();
    });
}

