//引入jsPDF

import './jspdf.umd.js';
import {colName,npx} from './preview.js'
let jsPDF
if(!window.jspdf){
    let jsPDFM=require('./jspdf.umd.js');
    jsPDF=jsPDFM.jsPDF;
}
else jsPDF=window.jspdf.jsPDF
/**
 * 通过传入的画布图片绘制canvas，然后将canvas转换为pdf
 */
export default function toPdf(data, index, printAreaIndex, filename) {
    let dpr = window.devicePixelRatio || 1;
    let [w,h,margin]=[0,0,38],w1,h1;
    if(data.printDirection==='portrait')
        //横向
    //     [w,h]=[447, 632.185]
    //     //纵向
    // else [w,h]=[6322185, 447];
        [w,h,w1,h1]=[793.706, 1122.52,447, 632.185];
    else [w,h,w1,h1]=[1122.52, 793.706, 632.185,447];
    const pdf = new jsPDF({orientation:data.printDirection}, 'px', [w1,h1]);
    let initIndex=(index!==null?index:0),times=(index!==null?index+1:this.sheets.length)
    for (let q=initIndex;q<times;q++){
        this.index = q;
        this.data = this.sheets[q];
        this.handleData()
        this.setAttr();
        this.render();

        if(q!==initIndex){
            pdf.addPage();
        }

        let contentWidth = parseInt(this.el.style.width);
        let contentHeight = parseInt(this.el.style.height);
        let pageWidth=Math.max(Math.min(w-margin*data.pageMargins.left-margin*data.pageMargins.right,w),100),
            pageHeight=Math.max(Math.min(h-margin*data.pageMargins.top-margin*data.pageMargins.bottom,h),100);
        let pageData = this.el.toDataURL('image/jpeg', 1.0);
        if (contentWidth <= pageWidth && contentHeight <= pageHeight) {
            let [posLeft,posTop]=[margin*data.pageMargins.left,margin*data.pageMargins.top];
            if(data.fitToPage.portraitCenter)
                posTop+=(pageHeight-contentHeight)/2
            else if(data.fitToPage.landscapeCenter)
                posLeft+=(pageWidth-contentWidth)/2
            pdf.addImage(pageData, 'JPEG', posLeft*(447/793.706), posTop*(447/793.706), contentWidth*(447/793.706), contentHeight*(447/793.706));
        }
        else {
            let canvas=document.createElement('canvas');
            let ctx=canvas.getContext('2d');

            if(data.fitToPage.value){
                if(data.fitToPage.width==1&&data.fitToPage.height==1){
                    let scale = contentHeight / contentWidth;
                    let [posLeft,posTop]=[margin*data.pageMargins.left,margin*data.pageMargins.top];
                    if (scale > (pageHeight/pageWidth)) {
                        if(data.fitToPage.portraitCenter)
                            posTop+=(pageHeight-contentHeight)/2
                        else if(data.fitToPage.landscapeCenter)
                            posLeft+=(pageWidth-(pageHeight / contentHeight * contentWidth))/2
                        pdf.addImage(pageData, 'JPEG', posLeft*(447/793.706), posTop*(447/793.706), pageHeight / contentHeight * contentWidth*(447/793.706), pageHeight*(447/793.706));
                    } else {
                        if(data.fitToPage.portraitCenter)
                            posTop+=(pageHeight-(pageWidth / contentWidth * contentHeight))/2
                        else if(data.fitToPage.landscapeCenter)
                            posLeft+=(pageWidth-contentWidth)/2
                        pdf.addImage(pageData, 'JPEG', posLeft*(447/793.706), posTop*(447/793.706), pageWidth*(447/793.706), pageWidth / contentWidth * contentHeight*(447/793.706));
                    }
                }
                else{
                    let storage=data.storage[q];
                    let {sci,sri,eci,eri}=printAreaIndex
                    //正常情况下会被分割的次数
                    let {widthLengthArr,heightLengthArr}=getClipArr(sci,sri,eci,eri,storage,pageWidth,pageHeight)

                    let widthLength = Math.min(data.fitToPage.width, widthLengthArr.length),
                        heightLength = Math.min(data.fitToPage.height, heightLengthArr.length),
                        change=1;
                    [widthLengthArr,heightLengthArr,change]=validateCrop(sci,sri,eci,eri,storage,widthLength,heightLength,pageWidth,pageHeight);

                    let hp=0;
                    for (let i=0;i<heightLengthArr.length;i++){
                        let wp=0;
                        for (let j=0;j<widthLengthArr.length;j++){
                            if (!(i == 0 && j == 0)) {
                                pdf.addPage();
                            }
                            canvas.style.width = `${widthLengthArr[j]}px`;
                            canvas.style.height = `${heightLengthArr[i]}px`;
                            canvas.width = npx(widthLengthArr[j]);
                            canvas.height = npx(heightLengthArr[i]);
                            ctx.clearRect(0,0,canvas.width,canvas.height)
                            ctx.drawImage(this.el,wp,hp,widthLengthArr[j]*(dpr),heightLengthArr[i]*(dpr),0,0,widthLengthArr[j]*dpr,heightLengthArr[i]*dpr)

                            let pd=canvas.toDataURL('image/jpeg',1.0);
                            let [posLeft,posTop]=[margin*data.pageMargins.left,margin*data.pageMargins.top];
                            if(data.fitToPage.portraitCenter)
                                posTop+=(pageHeight*change-heightLengthArr[i])/2
                            else if(data.fitToPage.landscapeCenter)
                                posLeft+=(pageWidth*change-widthLengthArr[j])/2

                            pdf.addImage(pd, 'JPEG', posLeft*(447/(793.706*change)), posTop*(447/(793.706*change)), widthLengthArr[j]*(447/(793.706*change)), heightLengthArr[i]*(447/(793.706*change)));

                            wp+=widthLengthArr[j]*(dpr);
                        }

                        hp+=heightLengthArr[i]*(dpr);
                    }
                }
            }
            else{
                let storage=data.storage[q];
                let {sci,sri,eci,eri}=printAreaIndex
                let {widthLengthArr,heightLengthArr}=getClipArr(sci,sri,eci,eri,storage,pageWidth,pageHeight)

                let hp=0;
                for (let i=0;i<heightLengthArr.length;i++){
                    let wp=0;
                    for (let j=0;j<widthLengthArr.length;j++){
                        if (!(i == 0 && j == 0)) {
                            pdf.addPage();
                        }
                        canvas.style.width = `${widthLengthArr[j]}px`;
                        canvas.style.height = `${heightLengthArr[i]}px`;
                        canvas.width = npx(widthLengthArr[j]);
                        canvas.height = npx(heightLengthArr[i]);
                        ctx.clearRect(0,0,canvas.width,canvas.height)
                        ctx.drawImage(this.el,wp,hp,widthLengthArr[j]*(dpr),heightLengthArr[i]*(dpr),0,0,widthLengthArr[j]*dpr,heightLengthArr[i]*dpr)

                        let pd=canvas.toDataURL('image/jpeg',1.0);
                        let [posLeft,posTop]=[margin*data.pageMargins.left,margin*data.pageMargins.top];
                        if(data.fitToPage.portraitCenter)
                            posTop+=(pageHeight-heightLengthArr[i])/2
                        else if(data.fitToPage.landscapeCenter)
                            posLeft+=(pageWidth-widthLengthArr[j])/2

                        pdf.addImage(pd, 'JPEG', posLeft*(447/793.706), posTop*(447/793.706), widthLengthArr[j]*(447/793.706), heightLengthArr[i]*(447/793.706));

                        wp+=widthLengthArr[j]*(dpr);
                    }

                    hp+=heightLengthArr[i]*(dpr);
                }
            }
        }
    }

    pdf.save(filename + ".pdf");
}

function validateCrop(sci,sri,eci,eri,storage,widthLength,heightLength,w,h){
    let newW=w,newH=h;
    let {widthLengthArr,heightLengthArr}=getClipArr(sci,sri,eci,eri,storage,newW,newH)
    let change=1.1;
    while (widthLengthArr.length>widthLength||heightLengthArr.length>heightLength){
        let result = getClipArr(sci,sri,eci,eri,storage,newW*change,newH*change)
        widthLengthArr=result.widthLengthArr;
        heightLengthArr=result.heightLengthArr;
        change+=0.1;
    }

    return [
        [...widthLengthArr],
        [...heightLengthArr],
        change
    ]
}

function getClipArr(sci,sri,eci,eri,storage,pageWidth,pageHeight,r=1){
    let heightVal=0,widthVal=0,widthLengthArr=[], heightLengthArr=[];
    for (let j=sci;j<=eci;j++){
        let col=colName(j+1),changeVal=0;
        if(storage.col[col])
            changeVal=storage.col[col].size*r
        else changeVal=80*r;

        if(changeVal+widthVal>pageWidth){
            widthLengthArr.push(widthVal);
            widthVal=0;
        }

        widthVal+=changeVal;

        if(j===eci){
            widthLengthArr.push(widthVal);
            widthVal=0;
        }
    }
    for (let i=sri;i<=eri;i++){
        let changeVal=0;
        if(storage.row[i])
            changeVal=storage.row[i].size*r;
        else changeVal=30*r;

        if(changeVal+heightVal>pageHeight){
            heightLengthArr.push(heightVal);
            heightVal=0;
        }

        heightVal+=changeVal;

        if(i===eri) {
            heightLengthArr.push(heightVal);
            heightVal=0;
        }
    }

    return {
        widthLengthArr,heightLengthArr
    }
}