import moment from "moment";
// import dayjs from "dayjs";
// const moment = dayjs;


const autofill=(_this,shiftKey)=>{
    let eci=_this.findIndexInCol(_this.localSheet.inputSelect.end.col),
        eri=_this.findIndexInRow(_this.localSheet.inputSelect.end.row),
        sci=_this.findIndexInCol(_this.localSheet.inputSelect.start.col),
        sri=_this.findIndexInRow(_this.localSheet.inputSelect.start.row),
        dir,end,seci=_this.localSheet.select.end.ci,seri=_this.localSheet.select.end.ri;

    if(_this.localSheet.select.end.data.merge){
        seci=_this.localSheet.select.end.data.mergeCellRange.col.max;
        seri=_this.localSheet.select.end.data.mergeCellRange.row.max;
    }

    if(sci<_this.localSheet.select.start.ci) {
        dir='left';
        end=sci;
    }
    else if(eci>seci) {
        dir='right';
        end=eci;
    }
    else if(sri<_this.localSheet.select.start.ri) {
        dir='top';
        end=sri;
    }
    else if(eri>seri) {
        dir='bottom';
        end=eri;
    }
    else return false;

    let result=canFill(_this,sci,sri,eci,eri,seci,seri,dir,end);
    if(result===true){
        _this.addUndo({index:_this.sheetIndex,sheet:JSON.stringify(_this.localSheet)});
        fill(_this,dir,end,sci,sri,eci,eri,seci,seri,shiftKey);
        // _this.preUploadData();
    }
    else if(result==="merge"){
        _this.$toast('不能对合并的单元格进行操作','danger');
    }
    else if(result==="error"){
        _this.$toast('无法操作','danger');
    }

}

const canFill=(_this,sci,sri,eci,eri,seci,seri,dir,end)=>{
    let arr=[],result=true;
    for (let i=sci;i<=eci;i++){
        for(let j=sri;j<=eri;j++){
            if(i<_this.localSheet.select.start.ci||i>seci||j<_this.localSheet.select.start.ri||j>seri){
                arr.push(_this.localSheet.col[i].name+_this.localSheet.row[j].name);
            }
        }
    }

    for (let i in arr){
        let cell=_this.localSheet.map[arr[i]];
        if(cell.data.merge||cell.data.mergeTo){
            result="merge";
        }
    }

    if(arr.length===0)
        result=null;

    if(_this.localSheet.selectAreaCell.findIndex(c=>c.data.merge===true)!==-1){
        if(dir==='top'){
            let len=Math.abs(seri-_this.localSheet.select.start.ri)+1;
            while (seri-len>=end){
                if(_this.localSheet.select.start.ri-len<0){
                    result="error";
                    break;
                }
                len+=Math.abs(seri-_this.localSheet.select.start.ri)+1;
            }
        }
        else if(dir==='bottom'){
            let len=Math.abs(seri-_this.localSheet.select.start.ri)+1;
            while (len+_this.localSheet.select.start.ri<=end){
                if(seri+len>=_this.localSheet.row.length){
                    result="error";
                    break;
                }
                len+=Math.abs(seri-_this.localSheet.select.start.ri)+1;
            }
        }
        else if(dir==='left'){
            let len=Math.abs(seci-_this.localSheet.select.start.ci)+1;
            while (seci-len>=end){
                if(_this.localSheet.select.start.ci-len<0){
                    result="error";
                    break;
                }
                len+=Math.abs(seci-_this.localSheet.select.start.ci)+1;
            }
        }
        else if(dir==='right'){
            let len=Math.abs(seci-_this.localSheet.select.start.ci)+1;
            while (_this.localSheet.select.start.ci+len<=end){
                if(seci+len>=_this.localSheet.col.length){
                    result="error";
                    break;
                }
                len+=Math.abs(seci-_this.localSheet.select.start.ci)+1;
            }
        }
    }

    return result;
}

//seci select.end.ci    seri select.end.ri
const fill=(_this,type,end,sci,sri,eci,eri,seci,seri,shiftKey)=>{
    // console.log(sci,sri,eci,eri,seci,seri);
    let newSelect=[],newFillRange=[];
    for (let i=sci;i<=eci;i++){
        for(let j=sri;j<=eri;j++){
            let name=_this.localSheet.col[i].name+_this.localSheet.row[j].name,
                cell=_this.localSheet.map[name];
            if(i<_this.localSheet.select.start.ci||i>seci||j<_this.localSheet.select.start.ri||j>seri){
                newFillRange.push({
                    name:name,
                    ci:cell.ci,
                    ri:cell.ri,
                    type:cell.data.cellType.type,
                });
            }
            else if(!cell.data.mergeTo){
                let c={
                    name:name,
                    ci:cell.ci,
                    ri:cell.ri,
                    type:cell.data.cellType.type,
                    content:cell.data.content,
                };
                if(cell.data.merge){
                   c.merge=cell.data.merge;
                   c.mergeCellRange=cell.data.mergeCellRange
                }
                newSelect.push(c);
            }
        }
    }

    ({
        left(){
            for(let j=_this.localSheet.select.start.ri;j<=seri;j++){
                let len=Math.abs(seci-_this.localSheet.select.start.ci)+1;
                //当需要填充的选区在范围内
                while (seci-len>=end){
                    for(let i=seci;i>=_this.localSheet.select.start.ci;i--){
                        //多行或者多列的选框填充时只填充在下拉选区内的部分
                        let name=_this.localSheet.col[i].name+_this.localSheet.row[j].name,
                            ncell=_this.deepClone(_this.localSheet.map[name]);
                        if(i-len>=end||ncell.data.mergeTo||ncell.data.merge){
                            _this.setCell(i-len,j,ncell);
                            let nname=_this.localSheet.col[i-len].name+_this.localSheet.row[j].name;
                            // _this.insertUploadData('cell',nname,_this.localSheet.map[nname],false);
                        }
                    }

                    len+=Math.abs(seci-_this.localSheet.select.start.ci)+1;
                }

            }
            // _this.preUploadData();
        },
        right(){
            for(let j=_this.localSheet.select.start.ri;j<=seri;j++){
                let len=Math.abs(seci-_this.localSheet.select.start.ci)+1;
                //当需要填充的选区在范围内
                while (_this.localSheet.select.start.ci+len<=end){
                    for(let i=_this.localSheet.select.start.ci;i<=seci;i++){
                        //多行或者多列的选框填充时只填充在下拉选区内的部分
                        let name=_this.localSheet.col[i].name+_this.localSheet.row[j].name,
                            ncell=_this.deepClone(_this.localSheet.map[name]);
                        if(i+len<=end||ncell.data.mergeTo||ncell.data.merge){
                            _this.setCell(i+len,j,ncell);
                            let nname=_this.localSheet.col[i+len].name+_this.localSheet.row[j].name;
                            // _this.insertUploadData('cell',nname,_this.localSheet.map[nname],false);
                        }
                    }

                    len+=Math.abs(seci-_this.localSheet.select.start.ci)+1;
                }

            }
            // _this.preUploadData();
        },
        top(){
            for(let i=_this.localSheet.select.start.ci;i<=seci;i++){
                let len=Math.abs(seri-_this.localSheet.select.start.ri)+1;
                //当需要填充的选区在范围内
                while (seri-len>=end){
                    for(let j=seri;j>=_this.localSheet.select.start.ri;j--){
                        //多行或者多列的选框填充时只填充在下拉选区内的部分
                        let name=_this.localSheet.col[i].name+_this.localSheet.row[j].name,
                            ncell=_this.deepClone(_this.localSheet.map[name]);
                        if(j-len>=end||ncell.data.mergeTo||ncell.data.merge){
                            _this.setCell(i,j-len,ncell);
                            let nname=_this.localSheet.col[i].name+_this.localSheet.row[j-len].name;
                            // _this.insertUploadData('cell',nname,_this.localSheet.map[nname],false);
                        }
                    }

                    len+=Math.abs(seri-_this.localSheet.select.start.ri)+1;
                }
            }
            // _this.preUploadData();
        },
        bottom(){
            for(let i=_this.localSheet.select.start.ci;i<=seci;i++){
                let len=Math.abs(seri-_this.localSheet.select.start.ri)+1;
                //当需要填充的选区在范围内
                while (len+_this.localSheet.select.start.ri<=end){
                    for(let j=_this.localSheet.select.start.ri;j<=seri;j++){
                        //多行或者多列的选框填充时只填充在下拉选区内的部分
                        let name=_this.localSheet.col[i].name+_this.localSheet.row[j].name,
                            ncell=_this.deepClone(_this.localSheet.map[name]);
                        if(j+len<=end||ncell.data.mergeTo||ncell.data.merge){
                            _this.setCell(i,j+len,ncell);
                            let nname=_this.localSheet.col[i].name+_this.localSheet.row[j+len].name;
                            // _this.insertUploadData('cell',nname,_this.localSheet.map[nname],false);
                        }
                    }

                    len+=Math.abs(seri-_this.localSheet.select.start.ri)+1;
                }
            }
            // _this.preUploadData();
        }
    })[type]();

    let result=autofillFunction(newSelect,newFillRange,type,shiftKey);

    for (let r of result){
        _this.localSheet.map[r.name].data.content=r.content;
        _this.insertUploadData('cell',r.name,_this.localSheet.map[r.name],false);
    }
    _this.preUploadData();
}

function autofillFunction(newSelect, newFillRange, angle,shiftKey) {
    const result = [];
    const angleCalc = angle === 'left' || angle === 'top' ? -1 : 1;
    const orientation = angle === 'left' || angle === 'right' ? 1 : 0;
    const types = new Map();
    const needs = new Map();
    let isMerge = false;

    newFillRange.forEach((cell) => {
        const type = splitName(cell.name)
        const key = type[orientation];
        needs.set(key, [...(needs.get(key) || []), cell.name])
    })

    newSelect.forEach(cell => {
        const type = splitName(cell.name)
        const key = type[orientation];

        // const needCells = needs.get(key);
        // console.log(needCells);

        if(cell.merge) isMerge = true;

        types.set(key, [...(types.get(key) || []), {
            name: cell.name,
            content: cell.content,
            // select: cell.select,
            cellLength: cell.merge ? (orientation
                ? cell.mergeCellRange.col.max - cell.mergeCellRange.col.min
                : cell.mergeCellRange.row.max - cell.mergeCellRange.row.min) + 1 : 1,
        }]);
    })

    types.forEach((originCells, key) => {
        const cells = originCells.sort((a, b) => {
            const type = orientation === 1 ? 0 : 1;
            let aIndex = splitName(a.name)[type];
            let bIndex = splitName(b.name)[type];

            if(type === 0) {
                aIndex = letterToNumber(aIndex);
                bIndex = letterToNumber(bIndex);
            }

            return aIndex - bIndex;
        })
        const regular = getRegular(cells, angleCalc, orientation,shiftKey);
        const newFillCells = [];
        const needCells = needs.get(key);
        let index = 0;
        let fillLength = 0;

        while(fillLength < needCells.length || (isMerge && (index % cells.length !== 0))) {
            const cell = cells[index % cells.length];
            const currentCell = newFillCells[newFillCells.length - 1] ||
                cells[angleCalc > 0 ? cells.length - 1 : 0];

            newFillCells.push({
                name: nextCell(currentCell, angleCalc, orientation),
                content: regular(index),
                cellLength: cell.cellLength
            })

            fillLength += cell.cellLength;
            index++;
        }

        result.push(...newFillCells);
    })

    // console.log(types)

    return result
}

// 分割字母和数字
function splitName(name){
    const letter = name.match(/[a-zA-Z]+/g)[0];
    const number = name.match(/\d+/g)[0];
    return [letter, number];
}

function nextCell(cell, angleCalc, orientation){
    const nameType = orientation === 0 ? 1 : 0;
    const nameArr = splitName(cell.name);
    let name = nameArr[nameType]
    if(nameType === 0){
        name = letterToNumber(name);
    } else {
        name = parseInt(name);
    }

    name += cell.cellLength * angleCalc;

    if(nameType === 0){
        name = numberToLetter(name)
    }

    nameArr[nameType] = name;
    return nameArr.join('')
}

// 字母转数字
function letterToNumber(letter) {
    let num = 0;
    for (let i = 0; i < letter.length; i++) {
        num += (letter.charCodeAt(i) - 64) * Math.pow(26, letter.length - i - 1);
    }
    return num;
}

// 数字转字母
function numberToLetter(num) {
    let letter = '';
    while (num > 0) {
        let remainder = num % 26;
        if (remainder === 0) {
            remainder = 26;
        }
        letter = String.fromCharCode(remainder + 64) + letter;
        num = (num - remainder) / 26;
    }
    return letter;
}

// 排序数组不影响元数据
function sortArr(arr) {
    return arr.map(item => item).sort((a, b) => a - b);
}

// 判断字符串是否为日期格式
function isDate(str) {
    return !isNaN(Date.parse(str));
}

// 判断字符串是否为时间格式
function isTime(str) {
    return !isNaN(Date.parse(`1970-01-01 ${str}`));
}

// 判断字符串是否为中文日期
function isChineseDate(str) {
    let date = str.replace(/年|月/g, '-').replace(/日/g, '');
    if(date.length <= 2) date = new Date().getMonth() + '-' + date;
    return !isNaN(Date.parse(date));
}

// 判断字符串是否为中文时间
function isChineseTime(str) {
    let date = str.replace(/时|分/g, ':').replace(/秒/g, '');
    if(date.length < 3) date = new Array(3 - date.length).fill(0).map((item, i) => {
        const d = new Date();
        return i === 0 ? d.getHours() : d.getMinutes()
    }).join(':') + ':' + date;
    return !isNaN(Date.parse(`1970-01-01 ${date}`));
}

// 根据字符串时间获得对应格式
function getDateFormat(str) {
    return str.replace(/(\d*)([\u4e00-\u9fa5a-zA-Z\:\-]*)/g, (match, $1, $2) => {
        let format = '';
        switch ($2) {
            case '年':
                format = 'yyyy';
                break;
            case '月':
                format = $1.length >= 2 ? 'MM' : 'M';
                break;
            case '日':
                format = $1.length >= 2 ? 'D' : 'D';
                break;
            case '时':
                format = $1.length >= 2 ? 'HH' : 'H';
                break;
            case '分':
                format = $1.length >= 2 ? 'mm' : 'm';
                break;
            case '秒':
                format = $1.length >= 2 ? 'ss' : 's';
                break;
        }
        return format + $2;
    })
}

function getRegular(cells, angleCalc, orientation,shiftKey){
    const contents = cells.map(cell => cell.content);
    // 判断是否全部为数字
    if(contents.every(content => !isNaN(content))){
        let defaultRegular = 0;
        // 如果只有一个数字
        // 这里是只复制
        // 如果你需要默认的规律，可以将下面的判断注释，然后将defaultRegular设置为你想要的数字
        if(contents.length <= 1) {
            if(shiftKey){
                const numbers = sortArr(contents);
                const initNumber = parseFloat(numbers[0]);
                return (index) => initNumber + ((index + 1) * 1) * angleCalc;
            }
            else return () => contents[0];
        }

        // 判断是否有规律
        const numbers = sortArr(contents);
        let regular = (numbers[1] || parseFloat(numbers[0]) + defaultRegular) - numbers[0];

        if(numbers.every((number, index) => index === 0 || number - numbers[index - 1] === regular)){
            const initNumber = parseFloat(angleCalc > 0 ? numbers[numbers.length - 1] : numbers[0]);
            return (index) => initNumber + ((index + 1) * regular) * angleCalc;
        }
    }

    // 判断是否为日期
    if(contents.every(content => isDate(content))){
        let defaultRegular = 1;
        // 同上
        // if(contents.length <= 1) return () => contents[0];
        if(contents.length <= 1) {
            if(shiftKey){
                const dates = sortArr(contents);
                const initDate = moment(dates[0]);
                return (index) => moment(initDate).add(((index + 1) * 86400000) * angleCalc).format('YYYY-MM-DD')
            }
            else return () => contents[0];
        }

        const dates = sortArr(contents);
        const regular = moment(dates[1] || moment(dates[0]).add(`${defaultRegular}`, 'days')).diff(moment(dates[0]));

        if(dates.every((date, index) => index === 0 || moment(date).diff(moment(dates[index - 1])) === regular)){
            const initDate = moment(angleCalc > 0 ? dates[dates.length - 1] : dates[0]);
            return (index) => moment(initDate).add(((index + 1) * regular) * angleCalc).format('YYYY-MM-DD')
        }
    }

    // 判断是否为时间
    if(contents.every(content => isTime(content))){
        let defaultRegular = 1;
        // 同上
        // if(contents.length <= 1) return () => contents[0];
        if(contents.length <= 1) {
            if(shiftKey){
                const times = sortArr(contents);
                const initTime = moment(`1970-01-01 ${times[0]}`);
                return (index) => moment(initTime).add(((index + 1) * 1000) * angleCalc).format('HH:mm:ss')
            }
            else return () => contents[0];
        }

        const times = sortArr(contents);
        const regular = moment(`1970-01-01 ${times[1] || moment(`1970-01-01 ${times[0]}`).add(`${defaultRegular}`, 'hours')}`).diff(moment(`1970-01-01 ${times[0]}`));

        if(times.every((time, index) => index === 0 || moment(`1970-01-01 ${time}`).diff(moment(`1970-01-01 ${times[index - 1]}`)) === regular)){
            const initTime = moment(`1970-01-01 ${angleCalc > 0 ? times[times.length - 1] : times[0]}`);
            return (index) => moment(initTime).add(((index + 1) * regular) * angleCalc).format('HH:mm:ss')
        }
    }

    // 判断是否为中文日期
    // if(contents.every(content => isChineseDate(content))){
    //     let defaultRegular = 0;
    //     // 同上
    //     // if(contents.length <= 1) return () => contents[0];
    //
    //     // 转换中文日期为标准日期
    //     const dates = sortArr(contents.map(content => content.replace(/年|月/g, '-').replace(/日/g, ''))).map(date => {
    //         const time = date.split('-');
    //         if(time.length < 3) {
    //             return new Array(3 - time.length).fill(0).map((item, i) => {
    //                 const d = new Date();
    //                 return i === 0 ? d.getFullYear() : d.getMonth() + 1
    //             }).join('-') + '-' + date;
    //         } else {
    //             return date;
    //         }
    //     })
    //     const regular = moment(dates[1] || moment(dates[0]).add(`${defaultRegular}`, 'days')).diff(moment(dates[0]));
    //     // console.log(dates, regular)
    //
    //     if(dates.every((chineseDate, index) => index === 0 || moment(chineseDate).diff(moment(dates[index - 1])) === regular)){
    //         const initChineseDate = moment(angleCalc > 0 ? dates[dates.length - 1] : dates[0]);
    //         const format = getDateFormat(contents[0]);
    //         return (index) => {
    //             return moment(initChineseDate).add(((index + 1) * regular) * angleCalc, 'milliseconds').format(format)
    //         }
    //     }
    // }

    // console.log(contents.every(content => isChineseTime(content)), contents)
    // 判断是否为中文时间
    // if(contents.every(content => isChineseTime(content))){
    //     let defaultRegular = 60;
    //     // 同上
    //     // if(contents.length <= 1) return () => contents[0];
    //     const flag = contents[0].indexOf('时') > -1;
    //     // 转换中文时间为标准时间
    //     const times = sortArr(contents.map(content => content.replace(/时|分/g, ':').replace(/秒/g, ''))).map(date => {
    //         const time = date.split(':');
    //         if(time.length <= 2 && date[date.length - 1] === ':'){
    //             return (flag ? '' : '00:') + date + (flag ? '00:' : '') + '00'
    //         }
    //         else if(time.length < 3) {
    //             return new Array(3 - time.length).fill(0).map((item, i) => {
    //                 const d = new Date();
    //                 return i === 0 ? d.getHours() : d.getMinutes() + 1
    //             }).join(':') + ':' + date;
    //         } else {
    //             return date;
    //         }
    //     });
    //     const regular = moment(`1970-01-01 ${times[1] || moment(`1970-01-01 ${times[0]}`).add(`${defaultRegular}`, 'seconds')}`).diff(moment(`1970-01-01 ${times[0]}`));
    //
    //     if(times.every((chineseTime, index) => index === 0 || moment(`1970-01-01 ${chineseTime}`).diff(moment(`1970-01-01 ${times[index - 1]}`)) === regular)){
    //         const initChineseTime = moment(`1970-01-01 ${angleCalc > 0 ? times[times.length - 1] : times[0]}`);
    //         const format = getDateFormat(contents[0]);
    //         return (index) => moment(initChineseTime).add(((index + 1) * regular) * angleCalc).format(format)
    //     }
    // }


    // 判断是否为中文时间
    if(contents.every(content => isChineseTime(content) || isChineseDate(content))) {
        const dates=contents.map(item => {
            const times = {};
            const reg = /(\d*)([年月日时分秒]*)/g;
            item.replace(reg, (a, b, c) => {
                if(c){
                    times[c] = b;
                }
            });

            return times;
        });

        const format={
            '年':'YYYY',
            '月':'M',
            '日':'D',
            '时':'H',
            '分':'m',
            '秒':'s',
        }
        const func={
            '年':'year',
            '月':'month',
            '日':'date',
            '时':'hour',
            '分':'minute',
            '秒':'seconds',
        }

        if(dates.length<=1){
            if(shiftKey){
                const initTime=dates[0];
                const keys=Object.keys(initTime);
                const timeFormat=keys.map(k=>{
                    return format[k];
                });
                const regular=timeFormat.map((f,i)=>{
                    if(i===timeFormat.length-1)
                        return 1;
                    else return 0;
                });
                let initChineseTime = moment(`${Object.values(initTime).join(' ')}`,`${timeFormat.join(' ')}`);
                return (index)=>{
                    let values=Object.values(initTime);
                    for (let i in values){
                        values[i]=values[i]*1+((regular[i]*1)*(index*1+1)*(angleCalc*1));
                        if(keys[i]==='月')
                            values[i]-=1;
                    }

                    for(let i in keys){
                        initChineseTime[func[keys[i]]](values[i]);
                    }

                    let preFormat=initChineseTime.format(timeFormat.join(' ')).split(' ');
                    let result='';
                    for (let i in preFormat){
                        result+=(preFormat[i]+keys[i]);
                    }

                    return result;
                }
            }
            else return () => contents[0]
        }

        const regular=Object.values(dates[1]).map((v,i)=>{
            const values=Object.values(dates[0]);
            return values.length>i?(v-values[i]):0;
        });

        const require=dates.every((time,index)=>{
            return index===0||(Object.keys(time).toString()===Object.keys(dates[index-1]).toString()&&
                Object.values(dates[index]).map((v,i)=>{
                    const values=Object.values(dates[index-1]);
                    return v-values[i];
                }).toString()===regular.toString());
        })

        if(require){
            const initTime=angleCalc > 0 ? dates[dates.length - 1] : dates[0];
            const keys=Object.keys(initTime);
            const timeFormat=keys.map(k=>{
                return format[k];
            });

            let initChineseTime = moment(`${Object.values(initTime).join(' ')}`,`${timeFormat.join(' ')}`);
            return (index)=>{
                let values=Object.values(initTime);
                for (let i in values){
                    values[i]=values[i]*1+((regular[i]*1)*(index*1+1)*(angleCalc*1));
                    if(keys[i]==='月')
                        values[i]-=1;
                }

                for(let i in keys){
                    initChineseTime[func[keys[i]]](values[i]);
                }

                let preFormat=initChineseTime.format(timeFormat.join(' ')).split(' ');
                let result='';
                for (let i in preFormat){
                    result+=(preFormat[i]+keys[i]);
                }

                return result;
            }
        }
    }

    // 判断是否为关联字段
    if(contents.every(content => isCell(content))){
        const cellContents = cells.map(cell => ({ content: cell.content, cellLength: cell.cellLength }));
        const initCell = angleCalc > 0 ? cellContents[cellContents.length - 1] : cellContents[0];

        return (index) => initCell.content.replace(/[a-zA-Z]+\d+/g, (cell) => {
            const [ x, y ] = cellToXY(cell);
            if(orientation === 0) return xyToCell(x, y + (index + 1) * angleCalc * initCell.cellLength);
            else return xyToCell(x + (index + 1) * angleCalc * initCell.cellLength, y);
        })
    }

    // 默认返回
    return (index) => angleCalc > 0 ? contents[index % contents.length] : contents[contents.length - 1 - index % contents.length];
}

// 判断字符串是否指向单元格
function isCell(str) {
    return str[0] === '=' && str.match(/[a-zA-Z]+\d+/g);
}

function cellToXY(name){
    const [letter, number] = splitName(name);
    return [letterToNumber(letter), parseInt(number)];
}

function xyToCell(x, y){
    return numberToLetter(x) + y;
}


export {
    autofill,
}