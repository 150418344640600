<template>
  <el-form class="p-20-15" :model="form" label-width="80px" :rules="rules" ref="ruleForm">
    <el-form-item label="登陆名称" prop="login">
      <el-input v-model="form.login" required></el-input>
    </el-form-item>
    <el-form-item label="用户昵称" prop="nickname">
      <el-input v-model="form.nickname"></el-input>
    </el-form-item>
    <el-form-item label="用户权限" prop="type">
      <el-select v-model="form.type" placeholder="请选择用户权限。" :disabled="id==auth.getId()">
        <template v-for="(item,i) in group">
          <el-option :label="item" :value="i"></el-option>
        </template>
      </el-select>
    </el-form-item>

    <el-form-item label="密码" prop="password">
      <el-input type="password" v-model="form.password" placeholder="为空保持原密码。"></el-input>
    </el-form-item>

    <el-form-item label="确认密码" prop="password_confirmation">
      <el-input type="password" v-model="form.password_confirmation" placeholder="为空保持原密码。"></el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit('ruleForm')">修改</el-button>
      <el-button @click="onCancel">返回</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {group} from "@/plugins/config";
import encryption from "@/plugins/encryption";
import auth from "@/plugins/auth";

export default {
  name: "index",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      group,
      auth,
      form: {
        login: '',
        nickname: '',
        type: '',
        password: '',
        password_confirmation: ''
      },
      rules: {
        login: [
          {required: true, message: '请输入登陆名称', trigger: 'blur'},
          {min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur'}
        ],
        nickname: [
          {required: true, message: '请输入用户昵称', trigger: 'blur'},
          {min: 3, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择用户权限', trigger: 'change'}
        ],
        password: [
          {min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur'}
        ],
        password_confirmation: [
          {min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ]
      },
      id: this.$route.query.id ?? 0
    }
  },
  methods: {
    getInfo() {
      this.$http.get(`user/info/${this.id}`).then(res => {
        this.form = res.data;
      });
    },
    onCancel() {
      this.$router.push({name: "UserList"});
    },
    onSubmit(formName) {
      let password = this.form.password === undefined ? '' : this.form.password,
          password_confirmation = this.form.password_confirmation === undefined ? '' : this.form.password_confirmation;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.put(`user/modify/${this.id}`, {
            ...this.form,
            password: encryption.encrypt(password),
            password_confirmation: encryption.encrypt(password_confirmation),
          }).then(res => {
            this.$toast("修改用户信息成功！");
            this.onCancel();
          }).catch(() => {
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getInfo();
  }
}
</script>

<style scoped>

</style>