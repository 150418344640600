import '@/utils'

import Vue from 'vue'

import App from './App.vue'
import router from './router'

import axios, {Axios} from "axios";
import el from 'element-ui'

import './assets/style.css'

import Toast from "@/plugins/toast";
import moment from "@/plugins/moment";
import {config} from "@/plugins/config";


Vue.use(el)

Vue.use(Toast);

Vue.use(moment)

Vue.prototype.$http = axios.create({
    baseURL: config.baseURL,
});

Vue.prototype.$http.interceptors.request.use((AxiosRequestConfig) => {

    AxiosRequestConfig.headers["Authorization"] = "Bearer " + config.token;
    AxiosRequestConfig.params = {
        ...AxiosRequestConfig.params,
        api_token: config.token
    }

    return AxiosRequestConfig;
});


Vue.prototype.$http.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if ((error.response.data.message !== "用户名或密码不正确" && error.response.data.message != "用户名或密码错误！！！") && error.response.status === 401) {
        localStorage.clear();
        setTimeout(function () {
            window.location.reload();
        }, 1500);
    }
    Vue.prototype.$toast(error.response.data.message, 'danger');
    return Promise.reject(error);
});

Vue.config.productionTip = false

Vue.prototype.$EventBus = new Vue();

new Vue({
    router,
    data() {
        return {
            templateType: '',
        }
    },
    render: h => h(App)
}).$mount('#app')
