const setBorder=(type,color,style,selectArea,map,row,col)=>{
    let mapping=[
        {type:'border-all',fun:borderAll},
        {type:'border-inside',fun:borderInside},
        {type:'border-horizontal',fun:borderHorizontal},
        {type:'border-vertical',fun:borderVertical},
        {type:'border-outside',fun:borderOutside},
        {type:'border-left',fun:borderLeft},
        {type:'border-right',fun:borderRight},
        {type:'border-top',fun:borderTop},
        {type:'border-bottom',fun:borderBottom},
        {type:'border-none',fun:borderNone},
    ];

    ((mapping.find((m)=>m.type===type)).fun)(color,transformStyleToNum(style),selectArea,map,row,col);
}

const transformStyleToNum=(style)=>{
    return style=='normal'?1:3;
}

const findIndexInCol=(col,cols)=>{
    return cols.findIndex((c)=>c.name==col);
}

const findIndexInRow=(row,rows)=>{
    return rows.findIndex((r)=>r.name==row);
}

const calcRange=(selectArea,row,col)=>{
    let colMax=0,colMin=9999,rowMax=0,rowMin=9999;
    for (let i in selectArea){
        let cell=selectArea[i];
        colMax=Math.max(colMax,findIndexInCol(cell.data.col,col));
        colMin=Math.min(colMin,findIndexInCol(cell.data.col,col));
        rowMax=Math.max(rowMax,findIndexInRow(cell.data.row,row));
        rowMin=Math.min(rowMin,findIndexInRow(cell.data.row,row));
    }

    return {
        row:{
            min:rowMin,
            max:rowMax,
        },
        col:{
            min:colMin,
            max:colMax,
        }
    }
}

const borderAll=(color,style,selectArea,map)=>{
    for (let i in selectArea){
        let cell=selectArea[i];
        map[cell.name].data.cellStyle.borderLeftColor=color;
        map[cell.name].data.cellStyle.borderLeftWidth=style;
        map[cell.name].data.cellStyle.borderRightColor=color;
        map[cell.name].data.cellStyle.borderRightWidth=style;
        map[cell.name].data.cellStyle.borderTopColor=color;
        map[cell.name].data.cellStyle.borderTopWidth=style;
        map[cell.name].data.cellStyle.borderBottomColor=color;
        map[cell.name].data.cellStyle.borderBottomWidth=style;
        map[cell.name].data.cellStyle.zIndex++;
    }
}

const borderInside=(color,style,selectArea,map,row,col)=>{
    let range=calcRange(selectArea,row,col);
    for (let i=range.col.min;i<=range.col.max;i++){
        for (let j=range.row.min;j<=range.row.max;j++){
            if(i!==range.col.min){
                map[col[i].name+row[j].name].data.cellStyle.borderLeftColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderLeftWidth=style;
            }
            if(i!==range.col.max){
                map[col[i].name+row[j].name].data.cellStyle.borderRightColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderRightWidth=style;
            }
            if(j!==range.row.min){
                map[col[i].name+row[j].name].data.cellStyle.borderTopColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderTopWidth=style;
            }
            if(j!==range.row.max){
                map[col[i].name+row[j].name].data.cellStyle.borderBottomColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderBottomWidth=style;
            }
            map[col[i].name+row[j].name].data.cellStyle.zIndex++;
        }
    }
}

const borderHorizontal=(color,style,selectArea,map,row,col)=>{
    let range=calcRange(selectArea,row,col);
    for (let i=range.col.min;i<=range.col.max;i++){
        for (let j=range.row.min;j<=range.row.max;j++){
            if(j!==range.row.min){
                map[col[i].name+row[j].name].data.cellStyle.borderTopColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderTopWidth=style;
            }
            if(j!==range.row.max){
                map[col[i].name+row[j].name].data.cellStyle.borderBottomColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderBottomWidth=style;
            }
            map[col[i].name+row[j].name].data.cellStyle.zIndex++;
        }
    }
}

const borderVertical=(color,style,selectArea,map,row,col)=>{
    let range=calcRange(selectArea,row,col);
    for (let i=range.col.min;i<=range.col.max;i++){
        for (let j=range.row.min;j<=range.row.max;j++){
            if(i!==range.col.min){
                map[col[i].name+row[j].name].data.cellStyle.borderLeftColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderLeftWidth=style;
            }
            if(i!==range.col.max){
                map[col[i].name+row[j].name].data.cellStyle.borderRightColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderRightWidth=style;
            }
            map[col[i].name+row[j].name].data.cellStyle.zIndex++;
        }
    }
}

const borderOutside=(color,style,selectArea,map,row,col)=>{
    let range=calcRange(selectArea,row,col);
    for (let i=range.col.min;i<=range.col.max;i++){
        for (let j=range.row.min;j<=range.row.max;j++){
            if(i===range.col.min){
                map[col[i].name+row[j].name].data.cellStyle.borderLeftColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderLeftWidth=style;
            }
            if(i===range.col.max){
                map[col[i].name+row[j].name].data.cellStyle.borderRightColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderRightWidth=style;
            }
            if(j===range.row.min){
                map[col[i].name+row[j].name].data.cellStyle.borderTopColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderTopWidth=style;
            }
            if(j===range.row.max){
                map[col[i].name+row[j].name].data.cellStyle.borderBottomColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderBottomWidth=style;
            }
            map[col[i].name+row[j].name].data.cellStyle.zIndex++;
        }
    }
}

const borderLeft=(color,style,selectArea,map,row,col)=>{
    let range=calcRange(selectArea,row,col);
    for (let i=range.col.min;i<=range.col.max;i++){
        for (let j=range.row.min;j<=range.row.max;j++){
            if(i===range.col.min){
                map[col[i].name+row[j].name].data.cellStyle.borderLeftColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderLeftWidth=style;
            }
            map[col[i].name+row[j].name].data.cellStyle.zIndex++;
        }
    }
}

const borderRight=(color,style,selectArea,map,row,col)=>{
    let range=calcRange(selectArea,row,col);
    for (let i=range.col.min;i<=range.col.max;i++){
        for (let j=range.row.min;j<=range.row.max;j++){
            if(i===range.col.max){
                map[col[i].name+row[j].name].data.cellStyle.borderRightColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderRightWidth=style;
            }
            map[col[i].name+row[j].name].data.cellStyle.zIndex++;
        }
    }
}

const borderTop=(color,style,selectArea,map,row,col)=>{
    let range=calcRange(selectArea,row,col);
    for (let i=range.col.min;i<=range.col.max;i++){
        for (let j=range.row.min;j<=range.row.max;j++){
            if(j===range.row.min){
                map[col[i].name+row[j].name].data.cellStyle.borderTopColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderTopWidth=style;
            }
            map[col[i].name+row[j].name].data.cellStyle.zIndex++;
        }
    }
}

const borderBottom=(color,style,selectArea,map,row,col)=>{
    let range=calcRange(selectArea,row,col);
    for (let i=range.col.min;i<=range.col.max;i++){
        for (let j=range.row.min;j<=range.row.max;j++){
            if(j===range.row.max){
                map[col[i].name+row[j].name].data.cellStyle.borderBottomColor=color;
                map[col[i].name+row[j].name].data.cellStyle.borderBottomWidth=style;
            }
            map[col[i].name+row[j].name].data.cellStyle.zIndex++;
        }
    }
}

const borderNone=(color,style,selectArea,map,row,col)=>{
    let normalColor='e6e6e7',normalWidth=0;
    for (let i in selectArea){
        let cell=selectArea[i];
        map[cell.name].data.cellStyle.borderLeftColor=normalColor;
        map[cell.name].data.cellStyle.borderLeftWidth=normalWidth;
        map[cell.name].data.cellStyle.borderRightColor=normalColor;
        map[cell.name].data.cellStyle.borderRightWidth=normalWidth;
        map[cell.name].data.cellStyle.borderTopColor=normalColor;
        map[cell.name].data.cellStyle.borderTopWidth=normalWidth;
        map[cell.name].data.cellStyle.borderBottomColor=normalColor;
        map[cell.name].data.cellStyle.borderBottomWidth=normalWidth;
        map[cell.name].data.cellStyle.zIndex++;
    }
}

export {
    setBorder,
}