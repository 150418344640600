<template>
  <div v-if="data.size>0" :class="'cell-head '+type+' '+(resizing?'resizing':'')+' '+(active?'active':'') + (nowResizeCellName===data.name?' nowResize':'')"
       :style="{width:(type=='col'?data.size:data.width)*zoom+'px',height:(type=='row'?data.size:data.height)*zoom+'px',left:data.left*zoom+'px',top:data.top*zoom+'px'}"
       @mousedown.stop="cellHeadMousedown" @mouseenter="hoverCellHead" @contextmenu.stop="showContextmenu"
  >
    <span :style="{transform:'scale('+zoom+')','transform-origin':'center center','pointer-events':'none'}">{{data.name}}</span>

    <div class="resize" v-if="!inputShow" @mousedown.stop="resize($event)" @dblclick.stop="selfAdaptionSize"></div>
  </div>
</template>

<script>
export default {
  name: "CellHead",
  props:{
    data:Object,
    type:'',
    zoom:{
      default:1,
    },
    active:Boolean,
    resizing:Boolean,
    nowResizeCellName:{
      default: null,
    },
    inputShow:Boolean
  },
  data(){
    return{

    }
  },
  methods:{
    selfAdaptionSize(){
      this.$emit('selfAdaptionSize',{type:this.type,name:this.data.name});
    },
    showContextmenu(e){
      e.preventDefault();
      this.$emit('showContextmenu',{type:this.type,name:this.data.name,x:e.clientX,y:e.clientY});
    },
    hoverCellHead(){
      this.$emit('hoverCellHead',{
        type:this.type,
        ...this.data,
        timestamp:new Date().valueOf(),
      });
    },
    cellHeadMousedown(e){
      if(e.buttons===1)
        this.$emit('cellHeadMousedown',{type:this.type,...this.data,timestamp:new Date().valueOf(),})
    },
    resize(e){
      this.$emit('resize',{type:this.type,data:this.data,e:e});
    }
  }
}
</script>

<style scoped>
.cell-head{
  user-select: none;
}
.cell-head.col{
  border-top: 1px solid #e0e2e6;
  border-right: 1px solid #e0e2e6;
}
.cell-head.row{
  border-left: 1px solid #e0e2e6;
  border-bottom: 1px solid #e0e2e6;
}
.cell-head .resize{
  position: absolute;
  background-color: #CFD9F7;
  display: none;
}
.cell-head.active{
  background-color: #E8ECF8;
}
.cell-head:hover{
  background-color: #E8ECF8;
}
.cell-head:not(.resizing):hover .resize,.cell-head.nowResize:hover .resize{
  display: block;
}
.cell-head.row .resize{
  bottom:0;
  left:0;
  cursor: row-resize;
  width: 100%;
  height: 5px;
}
.cell-head.col .resize{
  right:0;
  top:0;
  cursor: col-resize;
  height: 100%;
  width: 5px;
}
</style>