import JSEncrypt from "jsencrypt";

import {config} from "../config";

class Encryption{
    constructor() {
        this.jsencrypt = new JSEncrypt();
        this.jsencrypt.setPublicKey(config.pubkey);
    }

    decrypt(text){
        return this.jsencrypt.decrypt(text);
    }

    encrypt(text){
        return this.jsencrypt.encrypt(text);
    }

}


let encryption = new Encryption();

export default encryption;

