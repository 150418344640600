<template>
  <div class="dashboard p-20-15">
    Dashboard
  </div>
</template>

<script>
export default {
  name: "Dashboard"
}
</script>

<style scoped>

</style>