<template>
  <div class="excel-container flex">
    <div class="home"
         v-loading="loading"
         :style="{width:'calc( 100% - '+(sideBox.sideBoxShow?'280':'0')+'px )'}"
         @mousemove="scrollMove"
         @mouseleave="scrolling=false"
         @mouseup="scrolling=false;localSheet.inputSelecting=false;"
    >
      <ToolBar ref="toolbar" v-if="!identifying_code"
               :select-area="localSheet.selectAreaCell"
               :sheetIndex="sheetIndex"
               :freeze="localSheet.freeze"
               :freezeRow="localSheet.freezeRow"
               :freezeCol="localSheet.freezeCol"
               :templateID="template.id"
               :undoList="undoList"
               :redoList="redoList"
               v-on:saveStyle="saveStyle"
               v-on:setBorder="setBorder"
               v-on:mergeCell="mergeCell"
               v-on:insertImage="insertImage"
               v-on:selectFunction="selectFunction"
               v-on:freeze="freeze"
               v-on:getChartData="getChartData"
               v-on:selectSource="selectSource"
               v-on:selectHead="selectHead"
               v-on:totalSourceSelect="totalSourceSelect"
               v-on:seriesSelect="seriesSelect"
               v-on:createChart="createChart"
               v-on:saveExcel="saveExcel"
               v-on:download="downloadExcel('excel')"
               v-on:page="pageShow"
               v-on:requirement="requirementShow"
               v-on:dataSource="dataSourceShow"
               v-on:undo="undo"
               v-on:redo="redo"
               v-on:closeMenu="closeMenu"
               v-on:preview="preview"
      ></ToolBar>
      <div class="calculate flex">
        <div class="calculate-help">
          {{
            localSheet.selectAreaCell.length ?
                localSheet.selectAreaCell[0].name == localSheet.selectAreaCell[localSheet.selectAreaCell.length - 1].name ?
                    (localSheet.selectAreaCell[0].name) :
                    (localSheet.selectAreaCell[0].name + ':' + localSheet.selectAreaCell[localSheet.selectAreaCell.length - 1].name) : ''
          }}
        </div>
        <div :class="'calculate-operate-box '+(localSheet.calculate?'':'disabled')"
             @mousedown.stop="localSheet.cancelCellSave=true;localSheet.cellInputType = false;saveCell()">
          <i class="el-icon-close"></i>
        </div>
        <div :class="'calculate-operate-box '+(localSheet.calculate?'':'disabled')"
             @mousedown.stop="calcOk">
          <i class="el-icon-check"></i>
        </div>
        <div class="calculate-container">
          <textarea class="calculate-container-textarea"
                    v-model="localSheet.inputShowValue"
                    ref="calculate"
                    @focus="calculateTextareaFocus"
                    @blur="cellInputBlur('calculate')"
                    @keydown="cellInputKeydown"
                    @input="cellInputChange"
                    @mouseup.stop="cursorChange"
          ></textarea>
        </div>
      </div>
      <div class="flex container" :style="{height: 'calc(100% - 107px - '+(identifying_code?'0':'36')+'px)'}">
        <div class="table-area flex"
             ref="tableArea"
             @mouseup="tableAreaEnd"
             @mouseleave="stopAction"
             @mousemove="tableAreaMove"
        >
          <ResizeDashedLine v-if="localSheet.resizing"
                            :offset="{offsetLeft:localSheet.offsetLeft,offsetTop:localSheet.offsetTop}"
                            :data="localSheet.resizeData"></ResizeDashedLine>
          <div class="cell-head special" :style="{width:40*localSheet.zoom+'px',height:30*localSheet.zoom+'px'}"></div>

          <!--行头-->
          <div class="row-area-box"
               ref="rab"
               :style="{
              width: 40*localSheet.zoom+'px',
              height: 'calc(100% - '+30*localSheet.zoom+'px)',
              top:30*localSheet.zoom+'px',
            }"
          >
            <div class="row-area" :style="{top:0,}">
              <CellHead v-for="r in localSheet.row" :type="'row'" :resizing="localSheet.resizing" :data="r"
                        :zoom="localSheet.zoom"
                        :inputShow="localSheet.inputShow"
                        :nowResizeCellName="localSheet.resizing?localSheet.resizeData.name:null"
                        v-on:showContextmenu="showContextmenu"
                        v-on:resize="resize"
                        v-on:hoverCellHead="hoverCellHead"
                        v-on:cellHeadMousedown="cellHeadMousedown"
                        v-on:selfAdaptionSize="selfAdaptionSize"
                        :active="localSheet.cellHeadArray.findIndex((name)=>name==r.name)!==-1?true:false"></CellHead>
            </div>
          </div>

          <!--列头-->
          <div class="col-area-box"
               ref="cab"
               :style="{
              width: 'calc(100% - '+40*localSheet.zoom+'px)',
              height: 30*localSheet.zoom+'px',
              left:40*localSheet.zoom+'px',
            }"
          >
            <div class="col-area flex" :style="{left:0,}">
              <CellHead v-for="c in localSheet.col" :type="'col'" :resizing="localSheet.resizing" :data="c"
                        :zoom="localSheet.zoom"
                        :inputShow="localSheet.inputShow"
                        :nowResizeCellName="localSheet.resizing?localSheet.resizeData.name:null"
                        v-on:showContextmenu="showContextmenu"
                        v-on:resize="resize"
                        v-on:hoverCellHead="hoverCellHead"
                        v-on:cellHeadMousedown="cellHeadMousedown"
                        v-on:selfAdaptionSize="selfAdaptionSize"
                        :active="localSheet.cellHeadArray.findIndex((name)=>name==c.name)!==-1?true:false"></CellHead>
            </div>
          </div>

          <!--冻结时的行-->
          <div class="row-freeze-area-box" v-if="localSheet.freezeRow.index">
            <!--冻结时的行头-->
            <div class="row-area-box"
                 :style="{
                width: 40*localSheet.zoom+'px',
                height: 'calc(100% - '+30*localSheet.zoom+'px)',
                top:30*localSheet.zoom+'px',
             }">
              <div class="row-area" :style="{top:-localSheet.offsetTop+'px',}">
                <CellHead v-for="r in localSheet.row" :type="'row'" :resizing="localSheet.resizing" :data="r"
                          :zoom="localSheet.zoom"
                          :inputShow="localSheet.inputShow"
                          v-on:resize="resize"
                          v-on:hoverCellHead="hoverCellHead"
                          v-on:cellHeadMousedown="cellHeadMousedown"
                          :active="localSheet.cellHeadArray.findIndex((name)=>name==r.name)!==-1?true:false"></CellHead>
              </div>
            </div>
          </div>

          <!--冻结时的列-->
          <div class="col-freeze-area-box" v-if="localSheet.freezeCol.index">
            <!--冻结时的列头-->
            <div class="col-area-box"
                 :style="{
              width: 'calc(100% - 10px - '+40*localSheet.zoom+'px)',
              height: 30*localSheet.zoom+'px',
              left:40*localSheet.zoom+'px',
            }">
              <div class="col-area" :style="{left:-localSheet.offsetLeft+'px'}">
                <CellHead v-for="c in localSheet.col" :type="'col'" :resizing="localSheet.resizing" :data="c"
                          :zoom="localSheet.zoom"
                          :inputShow="localSheet.inputShow"
                          v-on:resize="resize"
                          v-on:hoverCellHead="hoverCellHead"
                          v-on:cellHeadMousedown="cellHeadMousedown"
                          :active="localSheet.cellHeadArray.findIndex((name)=>name==c.name)!==-1?true:false"></CellHead>
              </div>
            </div>
          </div>

          <!--冻结时的行列-->
          <div class="rc-freeze-area-box" v-if="localSheet.freezeCol.index||localSheet.freezeRow.index">
            <!--冻结时的行列头-->
            <div class="row-area-box"
                 :style="{
                  height:(localSheet.freezeCol.size+1-localSheet.freezeRow.scrollLimit)*localSheet.zoom+'px',
                  width: 40*localSheet.zoom+'px',
                  top:30*localSheet.zoom+'px',
               }">
              <div class="row-area" :style="{top:-localSheet.freezeRow.scrollLimit+'px'}">
                <CellHead
                    v-for="(r,i) in localSheet.row.filter(head=>findIndexInRow(head.name)<this.localSheet.freezeCol.index)"
                    :type="'row'" :resizing="localSheet.resizing" :data="r"
                    :zoom="localSheet.zoom"
                    :inputShow="localSheet.inputShow"
                    v-if="i<localSheet.freezeCol.index"
                    v-on:resize="resize"
                    v-on:hoverCellHead="hoverCellHead"
                    v-on:cellHeadMousedown="cellHeadMousedown"
                    :active="localSheet.cellHeadArray.findIndex((name)=>name==r.name)!==-1?true:false"></CellHead>
              </div>
            </div>

            <div class="col-area-box"
                 :style="{
              width:(localSheet.freezeRow.size+1-localSheet.freezeCol.scrollLimit)*localSheet.zoom+'px',
              height: 30*localSheet.zoom+'px',
              left:40*localSheet.zoom+'px',
            }">
              <div class="col-area" :style="{left:-localSheet.freezeCol.scrollLimit+'px',}">
                <CellHead
                    v-for="(c,i) in localSheet.col.filter(head=>findIndexInCol(head.name)<this.localSheet.freezeRow.index)"
                    :type="'col'" :resizing="localSheet.resizing" :data="c"
                    :zoom="localSheet.zoom"
                    :inputShow="localSheet.inputShow"
                    v-if="i<localSheet.freezeRow.index"
                    v-on:resize="resize"
                    v-on:hoverCellHead="hoverCellHead"
                    v-on:cellHeadMousedown="cellHeadMousedown"
                    :active="localSheet.cellHeadArray.findIndex((name)=>name==c.name)!==-1?true:false"></CellHead>
              </div>
            </div>
          </div>

          <div class="cell-area-box"
               :style="{
                margin:30*localSheet.zoom+'px 0 0 '+40*localSheet.zoom+'px',
                width: 'calc(100% - '+40*localSheet.zoom+'px)',
                height: 'calc(100% - '+30*localSheet.zoom+'px)',
              }"
               ref="cellAreaBox"
               @wheel="wheel(null,$event)"
          >
            <div class="cell-area flex">
            <span class="input-attr-calc" v-html="localSheet.inputShowCalc" ref="inputAttr"
                  :style="{left:localSheet.selectAreaAttr.left*this.localSheet.zoom-localSheet.offsetLeft+'px',
            top:localSheet.selectAreaAttr.top*this.localSheet.zoom-localSheet.offsetTop+'px',
            transform:'scale('+localSheet.zoom+')'}"></span>
              <textarea class="input-area" v-model="localSheet.inputShowValue"
                        ref="cellInput"
                        :style="{left:localSheet.textAreaAttr.left*this.localSheet.zoom-localSheet.offsetLeft+'px',
                top:localSheet.textAreaAttr.top*this.localSheet.zoom-localSheet.offsetTop+'px',
                'width':localSheet.textAreaAttr.colSize+'px',
                'height':localSheet.textAreaAttr.rowSize+'px',
                transform:'scale('+localSheet.zoom+')',
                'transform-origin':'left top',
                }" @keydown="cellInputKeydown" @input="cellInputChange" @mouseup.stop="cursorChange"
                        @blur="cellInputBlur" @focus="cellInputFocus"></textarea>
              <SelectArea v-if="localSheet.select.end&&selectAreaAfterChange.colSize>0&&selectAreaAfterChange.rowSize>0"
                          :data="selectAreaAfterChange"
                          :offsetLeft="localSheet.offsetLeft"
                          :offsetTop="localSheet.offsetTop"
                          :zoom="localSheet.zoom"
                          v-on:selectDot="selectDot"
              ></SelectArea>
              <SelectArea v-if="(localSheet.cellInputType||localSheet.sourceSelecting||localSheet.smartSelecting||localSheet.headSelecting||selectPrintAreaIndex||localSheet.totalSourceSelecting)
                              &&localSheet.inputSelecting"
                          :data="localSheet.inputSelectAreaAttr"
                          :offsetLeft="localSheet.offsetLeft"
                          :offsetTop="localSheet.offsetTop"
                          :type="'special'"
                          :zoom="localSheet.zoom"
              ></SelectArea>
              <SelectArea v-if="localSheet.totalSourceSelecting"
                          :data="localSheet.inputSelectAreaAttr"
                          :offsetLeft="localSheet.offsetLeft"
                          :offsetTop="localSheet.offsetTop"
                          :type="'special'"
                          :zoom="localSheet.zoom"
              ></SelectArea>
              <SelectArea v-for="ms in localSheet.multipleInputSelectAttr"
                          v-if="localSheet.totalSourceSelecting"
                          :data="ms.attr"
                          :offsetLeft="localSheet.offsetLeft"
                          :offsetTop="localSheet.offsetTop"
                          :type="'special'"
                          :zoom="localSheet.zoom"
              ></SelectArea>
              <SelectArea v-for="ms in localSheet.multipleSelectAttr"
                          :data="ms"
                          :offsetLeft="localSheet.offsetLeft"
                          :offsetTop="localSheet.offsetTop"
                          :zoom="localSheet.zoom"
              ></SelectArea>
              <SelectArea
                  v-for="(area,i) in localSheet.dashSelectArea"
                  v-if="localSheet.inputShow||(sideBox.type&&sideBox.type==='chart'&&sideBox.sideBoxShow)
                  ||(localSheet.nowImage.index!==null&&localSheet.images[localSheet.nowImage.index].type==='Chart')
                   ||(printArea!==''&&sideBox.type&&sideBox.type==='page'&&sideBox.sideBoxShow)
                    ||(requirement.requirementArea!==''&&sideBox.type&&sideBox.type==='requirement'&&requirementModal)"
                  :data="area.attr"
                  :name="area.name"
                  :borderColor="colors[i%colors.length].borderColor"
                  :bgColor="colors[i%colors.length].bgColor"
                  :offsetLeft="localSheet.offsetLeft"
                  :offsetTop="localSheet.offsetTop"
                  :type="'special'"
                  :zoom="localSheet.zoom"
              ></SelectArea>
              <div class="copy-area"
                   v-if="copyData.index>=0&&copyData.index==sheetIndex"
                   :style="{
                     left:((copyAreaAttr.left+1)*localSheet.zoom)-localSheet.offsetLeft+'px',
                     top:((copyAreaAttr.top+1)*localSheet.zoom)-localSheet.offsetTop+'px',
                     width:(copyAreaAttr.colSize+1)*localSheet.zoom+'px',
                     height:(copyAreaAttr.rowSize+1)*localSheet.zoom+'px',
                   }"
              >
                <div class="copy-selection copy-top"></div>
                <div class="copy-selection copy-bottom"></div>
                <div class="copy-selection copy-left"></div>
                <div class="copy-selection copy-right"></div>
              </div>
              <component v-for="(image,i) in localSheet.images"
                         v-if="image.type"
                         :key="image.uuid"
                         :ref="'image'+image.uuid"
                         :data="image"
                         :index="i"
                         :zoom="localSheet.zoom"
                         :offsetLeft="localSheet.offsetLeft"
                         :offsetTop="localSheet.offsetTop"
                         :is="image.type?image.type:'div'"
                         v-on:imageMousedown="imageMousedown"
                         v-on:resizeMousedown="resizeMousedown"
              >
              </component>
              <canvas ref="canvas" id="canvas"></canvas>
            </div>
          </div>
        </div>
        <Scrollbar :type="'vertical'" :length="scrollbarAttr.y" :offset="scrollbarAttr.offsetY" ref="scrollbarY"
                   v-on:scrollbarMousedown="scrollbarMousedown"></Scrollbar>
      </div>
      <Scrollbar :type="'horizontal'" :length="scrollbarAttr.x" :offset="scrollbarAttr.offsetX" ref="scrollbarX"
                 v-on:scrollbarMousedown="scrollbarMousedown"></Scrollbar>
      <SheetBar :sheets="sheets"
                :sheetIndex="sheetIndex"
                :identifying_code="identifying_code"
                :saveState="saveState"
                v-on:selectSheet="selectSheet"
                v-on:rename="showRenameInput"
                v-on:renameSave="renameSave"
                v-on:addSheet="addSheet"
                v-on:deleteSheet="deleteSheet"
                ref="sheetBar"
      ></SheetBar>
      <StatArea
          :zoom="localSheet.zoom"
          v-on:setZoom="setZoom"
      >
      </StatArea>
      <Modal
          ref="templateSave"
          :title="'保存模版'"
          :confirm="saveTemplate"
          :close="()=>template.show=false"
          :formName="'ruleForm'"
      >
        <el-form :model="templateForm" :rules="rules" ref="ruleForm" label-width="80px">
          <el-form-item label="模版名称" prop="name">
            <el-input v-model="templateForm.name"></el-input>
          </el-form-item>
          <el-form-item label="模版类型" prop="type">
            <el-select
                v-model="templateForm.type"
                class="w-100"
                placeholder="请选择模版类型">
              <el-option
                  v-for="item in templateTypeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="template.id" label="模版状态" prop="status">
            <el-select
                v-model="templateForm.status"
                class="w-100"
                placeholder="请选择模版状态">
              <el-option
                  v-for="item in templateStatusOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属分组" prop="tag">
            <el-select
                v-model="templateForm.group_id"
                filterable
                clearable
                class="w-100"
                placeholder="请选择所属分组">
              <el-option
                  v-for="item in groups"
                  :key="item.id"
                  :label="item.name"
                  :value="`${item.id}`">
              </el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="模版标签" prop="tag">-->
          <!--            <el-select-->
          <!--                v-model="templateForm.tag"-->
          <!--                multiple-->
          <!--                filterable-->
          <!--                allow-create-->
          <!--                default-first-option-->
          <!--                class="w-100"-->
          <!--                placeholder="请输入模版标签">-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <el-form-item label="模版描述" prop="description">
            <el-input type="textarea" v-model="templateForm.description"></el-input>
          </el-form-item>
        </el-form>
      </Modal>
      <Modal
          ref="copyDataset"
          :title="'复制数据源'"
          :confirm="copyDatasetConfirm"
          :close="()=>copyDatasetModal.show=false"
          :formName="'copyDatasetForm'"
          :confirmButtonText="'复制'"
          :confirmingButtonText="'复制中'"
      >
        <el-form :model="copyDatasetForm" :rules="copyDatasetRules" ref="copyDatasetForm" label-width="80px">
          <el-form-item label="模版" prop="templateID">
            <el-select
                v-model="copyDatasetForm.templateID"
                :loading="copyDatasetForm.loading"
                filterable
                remote
                :remote-method="getTemplatesRemote"
                v-el-select-loadmore="loadmore"
                class="w-100"
                placeholder="请选择模版"
                @change="getTemplatesRemote('')"
            >
              <el-option
                  v-for="item in copyDatasetTemplates"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </Modal>
      <div class="dataSourceArea" :style="{left:(dataSourceModalVisible?'0':'100%')}">
        <DataSource
            :dataset="dataset"
            :type="datasetModalType"
            :allData="dataTableTree"
            :templateType="template.type"
            v-on:dataSourceClose="dataSourceClose"
            v-on:appendDataSet="appendDataSet"
            v-on:modifySelectDataset="modifySelectDataset"
        ></DataSource>
      </div>
    </div>

    <SideBox
        v-on:closeSideBox="closeSideBox"
        v-show="sideBox.sideBoxShow"
    >
      <template v-slot:title>
        <span>{{ sideBox.sideBoxTitle }}</span>
      </template>
      <template v-slot:content>
        <div v-if="sideBox.type==='page'">
          <el-form label-width="80px">
            <el-form-item label="纸张大小">
              <el-select v-model="pageSize" placeholder="请选择纸张大小" size="mini" @change="preUploadData">
                <el-option label="A3" value="a3"></el-option>
                <el-option label="A4" value="a4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="打印方向">
              <el-select v-model="printDirection" placeholder="请选择打印方向" size="mini" @change="preUploadData">
                <el-option label="竖向" value="portrait"></el-option>
                <el-option label="横向" value="landscape"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="打印区域">
              <div :class="'flex print-area-item '+(selectPrintAreaIndex!==null?'active':'')">
                <span class="el-icon-edit-outline"
                      @click.stop="selectPrintArea"
                />
                <!--                {{ printArea }}-->
                <input type="text"
                       class="area-select-input"
                       v-model="printArea"
                       :disabled="selectPrintAreaIndex!==null"
                >
                <!--                <span class="print-area-item-close" @click.stop="clearPrintArea">&times;</span>-->
              </div>
            </el-form-item>
            <div class="page-area-item flex">
              <el-checkbox v-model="fitToPage.portraitCenter" @change="preUploadData">垂直居中</el-checkbox>
            </div>
            <div class="page-area-item flex">
              <el-checkbox v-model="fitToPage.landscapeCenter" @change="preUploadData">水平居中</el-checkbox>
            </div>
            <div class="page-area-item flex">
              <el-checkbox v-model="fitToPage.value" @change="preUploadData">调整为合适大小</el-checkbox>
            </div>
            <div class="page-area-item flex align-items-center" v-if="fitToPage.value">
              <el-input-number style="width: 120px" v-model="fitToPage.width" controls-position="right" :min="1"
                               size="mini" @change="preUploadData"></el-input-number>
              <span>页宽</span>
            </div>
            <div class="page-area-item flex align-items-center" v-if="fitToPage.value">
              <el-input-number style="width: 120px" v-model="fitToPage.height" controls-position="right" :min="1"
                               size="mini" @change="preUploadData"></el-input-number>
              <span>页高</span>
            </div>

          </el-form>
          <div style="margin:30px 0 10px 0">页边距</div>
          <el-form label-width="40px">
            <el-form-item label="顶部" size="mini">
              <el-input-number v-model="pageMargins.top" :precision="1" :step="0.1" :max="10" :min="0"
                               @change="preUploadData"></el-input-number>
            </el-form-item>
            <el-form-item label="左侧" size="mini">
              <el-input-number v-model="pageMargins.left" :precision="1" :step="0.1" :max="10" :min="0"
                               @change="preUploadData"></el-input-number>
            </el-form-item>
            <el-form-item label="右侧" size="mini">
              <el-input-number v-model="pageMargins.right" :precision="1" :step="0.1" :max="10" @change="preUploadData"
                               :min="0"></el-input-number>
            </el-form-item>
            <el-form-item label="底部" size="mini">
              <el-input-number v-model="pageMargins.bottom" :precision="1" :step="0.1" :max="10" @change="preUploadData"
                               :min="0"></el-input-number>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="sideBox.type==='dataSource'">
          <div class="dataset" draggable="true" @dragstart="drag({param:'__DATE__'})">
            <span>生成日期</span>
          </div>
          <div v-for="(d,i) in datasets" class="dataset" draggable="true" @dragstart="drag(d)">
            <svg class="datasetEdit" width="16" height="16" style="opacity: 0.5;" @click.stop="editDataset(d)">
              <g xmlns="http://www.w3.org/2000/svg">
                <path fill="#000000" fill-rule="evenodd"
                      d="M6.5,3.62 L0,10.12 L0,13 L2.88,13 L9.38,6.5 L6.5,3.62 Z M11.85,4.02 C12.05,3.82 12.05,3.51 11.85,3.31 L9.68,1.14 C9.48,0.94 9.17,0.94 8.97,1.14 L7.62,2.5 L10.5,5.38 L11.85,4.02 L11.85,4.02 Z"/>
              </g>
            </svg>
            <svg class="datasetCopy" width="14" height="14" viewBox="0 0 1024 1024" @click.stop="copyDataset(d)">
              <path
                  d="M720 192h-544A80.096 80.096 0 0 0 96 272v608C96 924.128 131.904 960 176 960h544c44.128 0 80-35.872 80-80v-608C800 227.904 764.128 192 720 192z m16 688c0 8.8-7.2 16-16 16h-544a16 16 0 0 1-16-16v-608a16 16 0 0 1 16-16h544a16 16 0 0 1 16 16v608z"
                  p-id="2553" fill="#000000"></path>
              <path
                  d="M848 64h-544a32 32 0 0 0 0 64h544a16 16 0 0 1 16 16v608a32 32 0 1 0 64 0v-608C928 99.904 892.128 64 848 64z"
                  p-id="2554" fill="#000000"></path>
              <path
                  d="M608 360H288a32 32 0 0 0 0 64h320a32 32 0 1 0 0-64zM608 520H288a32 32 0 1 0 0 64h320a32 32 0 1 0 0-64zM480 678.656H288a32 32 0 1 0 0 64h192a32 32 0 1 0 0-64z"
                  fill="#000000"></path>
            </svg>
            <el-popconfirm
                confirm-button-text='确定'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                title="确定删除吗？"
                @confirm="delDataset(i)"
            >
              <svg slot="reference" class="datasetDel" viewBox="0 0 1024 1024" width="16" height="16">
                <path
                    d="M768 384c-19.2 0-32 12.8-32 32l0 377.6c0 25.6-19.2 38.4-38.4 38.4L326.4 832c-25.6 0-38.4-19.2-38.4-38.4L288 416C288 396.8 275.2 384 256 384S224 396.8 224 416l0 377.6c0 57.6 44.8 102.4 102.4 102.4l364.8 0c57.6 0 102.4-44.8 102.4-102.4L793.6 416C800 396.8 787.2 384 768 384z"
                    p-id="3521" fill="#000000"></path>
                <path
                    d="M460.8 736l0-320C460.8 396.8 448 384 435.2 384S396.8 396.8 396.8 416l0 320c0 19.2 12.8 32 32 32S460.8 755.2 460.8 736z"
                    p-id="3522" fill="#000000"></path>
                <path
                    d="M627.2 736l0-320C627.2 396.8 608 384 588.8 384S563.2 396.8 563.2 416l0 320C563.2 755.2 576 768 588.8 768S627.2 755.2 627.2 736z"
                    p-id="3523" fill="#000000"></path>
                <path
                    d="M832 256l-160 0L672 211.2C672 166.4 633.6 128 588.8 128L435.2 128C390.4 128 352 166.4 352 211.2L352 256 192 256C172.8 256 160 268.8 160 288S172.8 320 192 320l640 0c19.2 0 32-12.8 32-32S851.2 256 832 256zM416 211.2C416 198.4 422.4 192 435.2 192l153.6 0c12.8 0 19.2 6.4 19.2 19.2L608 256l-192 0L416 211.2z"
                    fill="#000000"></path>
              </svg>
            </el-popconfirm>

            <div>
              <span v-if="d.source.preData.name">数据源名称：{{ d.source.preData.name }}</span>
              <br v-if="d.source.preData.name">
              <span>{{
                  d.source.preData.type == 'ndinfo' ? '节点信息' : (d.source.preData.type == 'rtinfo' ? '实时数据表信息' : (d.source.preData.type == 'htinfo' ? '历史数据表信息' : ''))
                }}</span>
              <span>-</span>
              <span v-if="d.source.preData.type=='ndinfo'">{{
                  ((dataTableTree[d.source.preData.type ?? ''] ?? []).find(e => {
                    return e.apptype === d.source.preData.nodeType
                  }) ?? {}).appname ?? ''
                }}</span>
              <span v-else>{{
                  ((dataTableTree[d.source.preData.type ?? ''] ?? []).find(e => {
                    return e.id === d.source.preData.table
                  }) ?? {}).table_name ?? ''
                }}</span>
              <!--            <span>{{d}}</span>-->
              <!--            <span v-else>{{d.source.table!=null?d.source.table.name:''}}-{{d.source.station?d.source.station.stname:''}}</span>-->
            </div>
            <div>
              <span>参数名：</span>
              <span>{{ d.param }}</span>
            </div>
          </div>
          <button @click.stop="showDatasetModal">添加数据源</button>
        </div>
        <div v-if="sideBox.type==='chart'">
          <ChartBoard :chartData="localSheet.chartData"
                      :sourceSelecting="localSheet.sourceSelecting"
                      :headSelecting="localSheet.headSelecting"
                      :totalSourceSelecting="localSheet.totalSourceSelecting"
                      :seriesSelecting="localSheet.seriesSelecting"
                      v-on:cancel="sideBox.sideBoxShow=false;$refs.toolbar.closeDropdown();sheet.resetData(localSheet);"
                      v-on:selectSource="selectSource"
                      v-on:selectHead="selectHead"
                      v-on:totalSourceSelect="totalSourceSelect"
                      v-on:seriesSelect="seriesSelect"
                      v-on:changeDataType="changeDataType"
                      v-on:createChart="createChart"
                      v-on:chartInputChange="chartInputChange"
          ></ChartBoard>
        </div>
        <div v-if="sideBox.type==='history'">
          <TemplateHistory
              ref="history"
              :histories="histories"
              v-on:revert="revert"
              v-on:selectHistory="selectHistory"
          ></TemplateHistory>
        </div>
        <div v-if="sideBox.type==='requirement'">
          <div v-for="(r,i) in localSheet.requirements" :key="i" class="requirement">
            <div>规则名称：{{ r.name }}</div>
            <div>应用范围：{{ r.requirementArea }}</div>
            <svg class="requirementEdit" width="16" height="16" style="opacity: 0.5;"
                 @click.stop="editRequirement(r,i)">
              <g xmlns="http://www.w3.org/2000/svg">
                <path fill="#000000" fill-rule="evenodd"
                      d="M6.5,3.62 L0,10.12 L0,13 L2.88,13 L9.38,6.5 L6.5,3.62 Z M11.85,4.02 C12.05,3.82 12.05,3.51 11.85,3.31 L9.68,1.14 C9.48,0.94 9.17,0.94 8.97,1.14 L7.62,2.5 L10.5,5.38 L11.85,4.02 L11.85,4.02 Z"/>
              </g>
            </svg>
            <el-popconfirm
                confirm-button-text='确定'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                title="确定删除吗？"
                @confirm="delRequirement(i)"
            >
              <svg slot="reference" class="requirementDel" viewBox="0 0 1024 1024" width="16" height="16">
                <path
                    d="M768 384c-19.2 0-32 12.8-32 32l0 377.6c0 25.6-19.2 38.4-38.4 38.4L326.4 832c-25.6 0-38.4-19.2-38.4-38.4L288 416C288 396.8 275.2 384 256 384S224 396.8 224 416l0 377.6c0 57.6 44.8 102.4 102.4 102.4l364.8 0c57.6 0 102.4-44.8 102.4-102.4L793.6 416C800 396.8 787.2 384 768 384z"
                    p-id="3521" fill="#000000"></path>
                <path
                    d="M460.8 736l0-320C460.8 396.8 448 384 435.2 384S396.8 396.8 396.8 416l0 320c0 19.2 12.8 32 32 32S460.8 755.2 460.8 736z"
                    p-id="3522" fill="#000000"></path>
                <path
                    d="M627.2 736l0-320C627.2 396.8 608 384 588.8 384S563.2 396.8 563.2 416l0 320C563.2 755.2 576 768 588.8 768S627.2 755.2 627.2 736z"
                    p-id="3523" fill="#000000"></path>
                <path
                    d="M832 256l-160 0L672 211.2C672 166.4 633.6 128 588.8 128L435.2 128C390.4 128 352 166.4 352 211.2L352 256 192 256C172.8 256 160 268.8 160 288S172.8 320 192 320l640 0c19.2 0 32-12.8 32-32S851.2 256 832 256zM416 211.2C416 198.4 422.4 192 435.2 192l153.6 0c12.8 0 19.2 6.4 19.2 19.2L608 256l-192 0L416 211.2z"
                    fill="#000000"></path>
              </svg>
            </el-popconfirm>
          </div>
          <button @click.stop="showRequirementModal">添加新规则</button>
        </div>
      </template>
    </SideBox>

    <div v-show="requirementModal" class="requirementModal"
         :style="(selectRequirementAreaIndex!==null?{overflow:'hidden','min-height':'unset',height:'80px'}:{})">
      <i class="el-icon-close" @click="requirementModal=false"></i>
      <div class="flex align-items-center require-mb">
        <div class="requirement-info-title">规则名称:</div>
        <input type="text" class="requirement-input" v-model="requirement.name">
      </div>
      <div class="flex align-items-center">
        <div class="requirement-info-title">应用范围:</div>
        <div :class="'requirement-area-select '+(selectRequirementAreaIndex!==null?'active':'')">
          <span class="el-icon-edit-outline"
                @click.stop="selectRequirementArea"
          />
          <input type="text"
                 class="area-select-input"
                 v-model="requirement.requirementArea"
                 :disabled="selectRequirementAreaIndex!==null"
          >
        </div>
      </div>
      <div class="vertical-driver"></div>
      <div class="toolbar flex require-mb">
        <!--    粗体-->
        <div :class="'toolbar-button '+(requirement.style.bold?'active':'')"
             @click.stop="requirement.style.bold=!requirement.style.bold" data-tooltip="粗体">
          <svg width="10" height="12" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <path fill="#000000" fill-rule="evenodd"
                    d="M9,3.5 C9,1.57 7.43,0 5.5,0 L1.77635684e-15,0 L1.77635684e-15,12 L6.25,12 C8.04,12 9.5,10.54 9.5,8.75 C9.5,7.45 8.73,6.34 7.63,5.82 C8.46,5.24 9,4.38 9,3.5 Z M5,2 C5.82999992,2 6.5,2.67 6.5,3.5 C6.5,4.33 5.82999992,5 5,5 L3,5 L3,2 L5,2 Z M3,10 L3,7 L5.5,7 C6.32999992,7 7,7.67 7,8.5 C7,9.33 6.32999992,10 5.5,10 L3,10 Z"/>
            </g>
          </svg>
        </div>
        <!--    斜体-->
        <div :class="'toolbar-button '+(requirement.style.italic?'active':'')"
             @click.stop="requirement.style.italic=!requirement.style.italic" data-tooltip="斜体">
          <svg width="12" height="12" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <polygon fill="#000000" fill-rule="evenodd"
                       points="4 0 4 2 6.58 2 2.92 10 0 10 0 12 8 12 8 10 5.42 10 9.08 2 12 2 12 0"/>
            </g>
          </svg>
        </div>
        <!--    下划线-->
        <div :class="'toolbar-button '+(requirement.style.underline?'active':'')"
             @click.stop="requirement.style.underline=!requirement.style.underline" data-tooltip="下划线">
          <svg width="12" height="15" style="opacity: 0.5;">
            <g xmlns="http://www.w3.org/2000/svg">
              <path fill="#000000"
                    d="M6,12 C8.76,12 11,9.76 11,7 L11,0 L9,0 L9,7 C9,8.75029916 7.49912807,10 6,10 C4.50087193,10 3,8.75837486 3,7 L3,0 L1,0 L1,7 C1,9.76 3.24,12 6,12 Z M0,13 L0,15 L12,15 L12,13 L0,13 Z"/>
            </g>
          </svg>
        </div>
        <!--    字体颜色-->
        <div :class="'toolbar-button '+((requirement.click.color)?'active':'')" @click.stop="clickDropdown('color')"
             data-tooltip="字体颜色">
          <svg width="19" height="18" :style="{opacity: 0.5,'border-bottom':'3px solid #'+requirement.style.color}">
            <g xmlns="http://www.w3.org/2000/svg" transform="translate(4,0)">
              <path fill="#000000" fill-rule="evenodd"
                    d="M7,0 L5,0 L0.5,12 L2.5,12 L3.62,9 L8.37,9 L9.49,12 L11.49,12 L7,0 L7,0 Z M4.38,7 L6,2.67 L7.62,7 L4.38,7 L4.38,7 Z"/>
            </g>
          </svg>
          <ColorPicker :hex="requirement.style.color" :type="'color'" v-on:save="saveRequirementStyle"></ColorPicker>
        </div>
        <!--    背景颜色-->
        <div :class="'toolbar-button '+((requirement.click.backgroundColor)?'active':'')"
             @click.stop="clickDropdown('backgroundColor')" data-tooltip="背景颜色">
          <svg width="16" height="19"
               :style="{opacity: 0.5,'border-bottom':'3px solid #'+requirement.style.backgroundColor}">
            <g xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fill-rule="evenodd">
                <path fill="#000000"
                      d="M14.5,8.87 C14.5,8.87 13,10.49 13,11.49 C13,12.32 13.67,12.99 14.5,12.99 C15.33,12.99 16,12.32 16,11.49 C16,10.5 14.5,8.87 14.5,8.87 L14.5,8.87 Z M12.71,6.79 L5.91,0 L4.85,1.06 L6.44,2.65 L2.29,6.79 C1.9,7.18 1.9,7.81 2.29,8.2 L6.79,12.7 C6.99,12.9 7.24,13 7.5,13 C7.76,13 8.01,12.9 8.21,12.71 L12.71,8.21 C13.1,7.82 13.1,7.18 12.71,6.79 L12.71,6.79 Z M4.21,7 L7.5,3.71 L10.79,7 L4.21,7 L4.21,7 Z"/>
              </g>
            </g>
          </svg>
          <ColorPicker :hex="requirement.style.backgroundColor" :type="'backgroundColor'"
                       v-on:save="saveRequirementStyle"></ColorPicker>
        </div>
        <!--    设置边框-->
        <!--        <div :class="'toolbar-button '+((requirement.click.border)?'active':'')" @click.stop="clickDropdown('border')" data-tooltip="边框">-->
        <!--          <svg width="14" height="14" style="opacity: 0.5;">-->
        <!--            <g xmlns="http://www.w3.org/2000/svg"><path fill="#000000" fill-rule="evenodd" d="M0,0 L0,14 L14,14 L14,0 L0,0 L0,0 Z M6,12 L2,12 L2,8 L6,8 L6,12 L6,12 Z M6,6 L2,6 L2,2 L6,2 L6,6 L6,6 Z M12,12 L8,12 L8,8 L12,8 L12,12 L12,12 Z M12,6 L8,6 L8,2 L12,2 L12,6 L12,6 Z"/></g>-->
        <!--          </svg>-->
        <!--          <BorderPalette v-on:setBorder=""></BorderPalette>-->
        <!--        </div>-->

      </div>
      <div class="flex align-items-center">
        <span>预览</span>
        <div class="require-preview-area">
          <div class="require-preview"
               :style="{
            backgroundColor:(requirement.style.backgroundColor==='none'?'unset':'#'+requirement.style.backgroundColor),
            color:(requirement.style.color==='none'?'none':'#'+requirement.style.color),
            'font-weight':(requirement.style.bold?'bold':'normal'),
            'font-style':(requirement.style.italic?'italic':'normal'),
            'text-decoration':(requirement.style.underline?'underline':'none'),
          }">
            AaBbCcYyZz
          </div>
        </div>
      </div>
      <div class="vertical-driver"></div>
      <div class="flex requirement-group align-items-center" v-for="(r,i) in requirement.require">
        <div>单元格值</div>
        <el-select size="mini" v-model="r.require" class="requirement-group-select">
          <el-option
              v-for="(item,j) in requireOptions"
              :key="i+j+item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-input
            size="mini"
            class="requirement-group-input"
            v-model="r.value"
        >
        </el-input>
        <i class="el-icon-close" @click.stop="removeRequire(i)"></i>
        <!--        <input type="text">-->
      </div>
      <button class="w-100" @click.stop="addNewRequire">添加新的规则</button>
      <div class="vertical-driver"></div>
      <div class="requirement-button-group">
        <el-button size="small" @click="requirementModal=false;requirementIndex=null;">取消</el-button>
        <el-button type="primary" size="small" @click.stop="createRequirement">确定</el-button>
      </div>
    </div>

    <Preview
        :visible="previewAreaShow"
        :type="template.type"
        :sheets="sheets"
        :datasets="datasets"
        v-on:close="previewAreaShow=false"
    ></Preview>

    <Modal
        ref="sizeChange"
        :width="400"
        :title="`修改${localSheet.cellHeadSelect.type==='col'?'列宽':'行高'}大小`"
        :confirm="sizeChange"
        :close="()=>sizeChanging=false"
        :formName="'sizeChangeForm'"
        :confirmButtonText="'修改'"
        :confirmingButtonText="'修改中'"
    >
      <el-form :model="sizeChangeForm" :rules="sizeChangeRules" ref="sizeChangeForm" label-width="80px">
        <el-form-item :label="`${localSheet.cellHeadSelect.type==='col'?'列宽':'行高'}`" prop="size">
          <el-input v-model="sizeChangeForm.size" type="number" :step="0.01"></el-input>
        </el-form-item>
      </el-form>
    </Modal>

    <div class="contextmenu"
         v-if="contextmenu.type!==null"
         :style="{top:contextmenu.top+'px',left:contextmenu.left+'px'}"
    >
      <div class="contextmenu-item" @click.stop="contextmenuAction('copy')">
        <span>复制</span>
      </div>
      <div class="contextmenu-item" @click.stop="contextmenuAction('cut')">
        <span>剪切</span>
      </div>
      <div class="contextmenu-item" @click.stop="contextmenuAction('paste')">
        <span>粘贴</span>
      </div>
      <div class="vertical-driver"></div>
      <div class="contextmenu-item" v-if="contextmenu.type!='col'" @click.stop="contextmenuAction('row')">
        <span>插入行</span>
      </div>
      <div class="contextmenu-item" v-if="contextmenu.type!='row'" @click.stop="contextmenuAction('col')">
        <span>插入列</span>
      </div>
      <div class="contextmenu-item" v-if="contextmenu.type!='col'" @click.stop="contextmenuAction('delRow')">
        <span>删除行</span>
      </div>
      <div class="contextmenu-item" v-if="contextmenu.type!='row'" @click.stop="contextmenuAction('delCol')">
        <span>删除列</span>
      </div>
      <div class="contextmenu-item" v-if="contextmenu.type!='col'" @click.stop="contextmenuAction('selfAdaptionSize')">
        <span>自动调整行高</span>
      </div>
      <div class="contextmenu-item" v-if="contextmenu.type!='col'" @click.stop="contextmenuAction('sizeChange')">
        <span>调整行高数值</span>
      </div>
      <div class="contextmenu-item" v-if="contextmenu.type!='row'" @click.stop="contextmenuAction('selfAdaptionSize')">
        <span>自动调整列宽</span>
      </div>
      <div class="contextmenu-item" v-if="contextmenu.type!='row'" @click.stop="contextmenuAction('sizeChange')">
        <span>调整列宽数值</span>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import DataSource from '../components/DataSource'
import TemplateHistory from '../components/TemplateHistory'
import ChartBoard from '../components/ChartBoard'
import SideBox from '../components/SideBox'
import Modal from '../components/Modal'
import ToolBar from '../components/ToolBar'
import Scrollbar from '../components/Scrollbar'
import SheetBar from '../components/SheetBar'
import StatArea from '../components/StatArea'
import ResizeDashedLine from '../components/ResizeDashedLine'
import CellHead from '../components/CellHead'
import InsertImage from '../components/InsertImage'
import Chart from '../components/Chart'
import SelectArea from '../components/SelectArea'
import Preview from '../components/Preview'
import * as _border from '@/core/border'
import {newSheet, addNewRow, addNewCol} from "@/core/init";
import {calcPostfix, infixToPostFix, participle} from "@/core/formula";
import Sheet from "@/core/sheet";
import moment from 'moment';
import {calcMergeCellWH, transferData} from "@/core/rcaction";
import {autofill} from "@/core/autofill";
import ColorPicker from "@/components/ColorPicker";
import BorderPalette from "@/components/BorderPalette";

export default {
  name: 'Home',
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      }
    }
  },
  data() {
    let checkSize = (rule, value, callback) => {
      if (Number(value) < 0) {
        return callback(new Error('数值不能小于0'));
      } else callback();
    };
    return {
      localSheet: {},
      sheets: [],
      sheetIndex: 0,
      pageMargins: {
        top: 1,
        left: 1,
        right: 1,
        bottom: 1,
      },
      fitToPage: {
        portraitCenter: false,
        landscapeCenter: false,
        value: false,
        width: 1,
        height: 1
      },
      pageSize: 'a4',
      printDirection: "portrait",
      printArea: '',
      requirement: {
        name: '',
        requirementArea: '',
        style: {
          backgroundColor: 'none',
          color: '333333',
          bold: false,
          italic: false,
          underline: false,
        },
        require: [],
        click: {
          color: false,
          border: false,
          backgroundColor: false,
        }
      },
      requirementModal: false,
      requirementIndex: null,
      selectRequirementAreaIndex: null,
      requireOptions: [
        {label: '大于', value: 'greater'},
        {label: '小于', value: 'less'},
        {label: '等于', value: 'equal'},
        {label: '不等于', value: 'notEqual'},
        {label: '大于等于', value: 'greaterEqual'},
        {label: '小于等于', value: 'lessEqual'},
      ],
      cellAreaWidth: 0,
      cellAreaHeight: 0,
      scrollbarWidth: 0,
      scrollbarHeight: 0,
      scrolling: false,
      backupText: '',
      selectionStart: 0,
      selectionEnd: 0,
      selectionSP: null,
      dataTableTree: {},
      operators: [
        '=',
        '<',
        '>',
        '<=',
        '>=',
        '(',
        '-',
        '+',
        '/',
        '*',
        ',',
      ],
      histories: [],
      colors: [
        {borderColor: '#4069C1', bgColor: '#C3D4F84C'},
        {borderColor: '#B14043', bgColor: '#E8A5A64C'},
        {borderColor: '#7B59B1', bgColor: '#C7B5E34D'},
        {borderColor: '#357A2E', bgColor: '#C3E7C04D'},
        {borderColor: '#A34681', bgColor: '#E1B2CF4D'},
        {borderColor: '#A9501E', bgColor: '#E0C5BA4D'},
      ],
      chartColors: ['#5A6FC0', '#9ECB7F', '#F2CA6B', '#DE6F6B', '#85BEDB', '#69A076', '#EC8A5D', '#9263AF', '#DC82C8'],
      identifying_code: null,
      dataSourceModalVisible: false,
      template: {
        id: this.$route.params.id ?? null,
        name: '',
        description: '',
        group_id: '',
        tag: [],
        type: 'day',
        status: 'draft',
        show: false,
      },
      templateForm: {
        name: '',
        description: '',
        group_id: '',
        tag: [],
        type: 'day',
        status: 'draft',
      },
      templateTypeOption: [
        {
          label: '日报表',
          value: 'day',
        },
        {
          label: '月报表',
          value: 'month',
        },
        {
          label: '年报表',
          value: 'year',
        },
        {
          label: '自定义时段报表',
          value: 'config',
        },
      ],
      templateStatusOption: [
        {
          label: '编辑',
          value: 'draft',
        },
        {
          label: '发布',
          value: 'publish',
        },
      ],
      rules: {
        name: [
          {required: true, message: '请输入模版名称', trigger: 'blur'},
        ],
        type: [
          {required: true, message: '请选择模版类型', trigger: 'blur'},
        ],
      },
      groups: [],
      sideBox: {
        sideBoxTitle: '',
        type: '',
        sideBoxShow: false,
      },
      loading: false,
      copyData: {},
      copyAreaAttr: {},
      undoList: [],
      redoList: [],
      dataset: null,
      datasets: [],
      datasetModalType: null,
      dragDataSet: null,
      cellHeadSP: false,
      contextmenu: {
        type: null,
        name: null,
        top: 0,
        left: 0,
      },
      previewAreaShow: false,
      selectPrintAreaIndex: null,
      saveState: {
        time: moment(),
        loading: false,
      },
      movingListen: null,
      copyDatasetData: null,
      copyDatasetModal: {
        show: false,
      },
      copyDatasetForm: {
        templateID: null,
        name: '',
        loading: false,
        take: 24,
        skip: 0
      },
      copyDatasetRules: {
        templateID: [
          {required: true, message: '请选择模版', trigger: 'change'},
        ],
      },
      copyDatasetTemplates: [],
      sizeChanging: false,
      sizeChangeForm: {
        size: 0,
      },
      sizeChangeRules: {
        size: [
          {required: true, message: '请输入具体数值', trigger: 'change'},
          {validator: checkSize, trigger: 'blur'}
        ]
      }
    }
  },
  components: {
    BorderPalette,
    ColorPicker,
    DataSource,
    InsertImage,
    Chart,
    SelectArea,
    ToolBar,
    SheetBar,
    CellHead,
    ResizeDashedLine,
    Scrollbar,
    StatArea,
    Modal,
    SideBox,
    ChartBoard,
    TemplateHistory,
    Preview,
  },
  watch: {
    'localSheet.select': {
      handler: function (newVal, oldVal) {
        if (!newVal.start) return;
        let array = [];
        let minRow = Math.min(this.findIndexInRow(newVal.start.data.row), this.findIndexInRow(newVal.end.data.row)),
            maxRow = Math.max(this.findIndexInRow(newVal.start.data.row), this.findIndexInRow(newVal.end.data.row)),
            minCol = Math.min(this.findIndexInCol(newVal.start.data.col), this.findIndexInCol(newVal.end.data.col)),
            maxCol = Math.max(this.findIndexInCol(newVal.start.data.col), this.findIndexInCol(newVal.end.data.col));

        for (let j = minRow; j <= maxRow; j++) {
          for (let i = minCol; i <= maxCol; i++) {
            let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
            if (!cell.data.valid && !this.cellHeadSP) {
              minRow = Math.min(minRow, cell.data.mergeTo.data.mergeCellRange.row.min);
              maxRow = Math.max(maxRow, cell.data.mergeTo.data.mergeCellRange.row.max);
              minCol = Math.min(minCol, cell.data.mergeTo.data.mergeCellRange.col.min);
              maxCol = Math.max(maxCol, cell.data.mergeTo.data.mergeCellRange.col.max);
            }
            if (cell.data.merge && !this.cellHeadSP) {
              minRow = Math.min(minRow, cell.data.mergeCellRange.row.min);
              maxRow = Math.max(maxRow, cell.data.mergeCellRange.row.max);
              minCol = Math.min(minCol, cell.data.mergeCellRange.col.min);
              maxCol = Math.max(maxCol, cell.data.mergeCellRange.col.max);
            }
          }
        }

        this.localSheet.rcRange = {
          row: {
            min: minRow,
            max: maxRow,
          },
          col: {
            min: minCol,
            max: maxCol,
          }
        }

        this.localSheet.cellHeadArray = [];
        for (let j = minRow; j <= maxRow; j++) {
          if (this.localSheet.cellHeadArray.findIndex((c) => c == this.localSheet.row[j].name) === -1)
            this.localSheet.cellHeadArray.push(this.localSheet.row[j].name);
          for (let i = minCol; i <= maxCol; i++) {
            if (this.localSheet.cellHeadArray.findIndex((c) => c == this.localSheet.col[i].name) === -1)
              this.localSheet.cellHeadArray.push(this.localSheet.col[i].name);
            if (this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.valid)
              array.push(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name])
          }
        }

        this.localSheet.multipleSelectAttr = [];
        for (let ms of this.localSheet.multipleSelect) {
          let minRow = Math.min(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
              maxRow = Math.max(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
              minCol = Math.min(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col)),
              maxCol = Math.max(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col));

          for (let j = minRow; j <= maxRow; j++) {
            for (let i = minCol; i <= maxCol; i++) {
              let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
              if (!cell.data.valid && !this.cellHeadSP) {
                minRow = Math.min(minRow, cell.data.mergeTo.data.mergeCellRange.row.min);
                maxRow = Math.max(maxRow, cell.data.mergeTo.data.mergeCellRange.row.max);
                minCol = Math.min(minCol, cell.data.mergeTo.data.mergeCellRange.col.min);
                maxCol = Math.max(maxCol, cell.data.mergeTo.data.mergeCellRange.col.max);
              }
              if (cell.data.merge && !this.cellHeadSP) {
                minRow = Math.min(minRow, cell.data.mergeCellRange.row.min);
                maxRow = Math.max(maxRow, cell.data.mergeCellRange.row.max);
                minCol = Math.min(minCol, cell.data.mergeCellRange.col.min);
                maxCol = Math.max(maxCol, cell.data.mergeCellRange.col.max);
              }
            }
          }

          let rcRange = {
            row: {
              min: minRow,
              max: maxRow,
            },
            col: {
              min: minCol,
              max: maxCol,
            }
          }

          for (let j = minRow; j <= maxRow; j++) {
            if (this.localSheet.cellHeadArray.findIndex((c) => c == this.localSheet.row[j].name) === -1)
              this.localSheet.cellHeadArray.push(this.localSheet.row[j].name);
            for (let i = minCol; i <= maxCol; i++) {
              if (this.localSheet.cellHeadArray.findIndex((c) => c == this.localSheet.col[i].name) === -1)
                this.localSheet.cellHeadArray.push(this.localSheet.col[i].name);
              if (this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.valid)
                array.push(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name])
            }
          }

          this.localSheet.multipleSelectAttr.push(this.fromRangeGetAttr(rcRange.row.min, rcRange.row.max, rcRange.col.min, rcRange.col.max));
        }

        this.localSheet.selectAreaCell = array;
        this.localSheet.selectAreaAttr = this.fromRangeGetAttr(this.localSheet.rcRange.row.min, this.localSheet.rcRange.row.max, this.localSheet.rcRange.col.min, this.localSheet.rcRange.col.max);

        //冻结时，选区包含的格子中含有冻结的部分时，重制offset
        if (this.localSheet.freeze) {
          if (this.localSheet.rcRange.col.min < this.localSheet.freezeRow.index) {
            this.$refs.cab.scrollLeft = this.localSheet.freezeCol.scrollLimit;
            this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
          }
          if (this.localSheet.rcRange.row.min < this.localSheet.freezeCol.index) {
            this.$refs.rab.scrollTop = this.localSheet.freezeRow.scrollLimit;
            this.localSheet.offsetTop = this.$refs.rab.scrollTop;
          }
        }
      }
    },
    'localSheet.inputSelect': {
      handler: function (newVal, oldVal) {
        //虚线选框
        if (newVal.start && (this.localSheet.cellInputType || this.localSheet.sourceSelecting || this.localSheet.headSelecting || this.localSheet.smartSelecting || this.selectPrintAreaIndex || this.selectRequirementAreaIndex || this.localSheet.totalSourceSelecting || this.localSheet.seriesSelecting)) {
          let cell = [];
          let minRow = Math.min(this.findIndexInRow(newVal.start.row), this.findIndexInRow(newVal.end.row)),
              maxRow = Math.max(this.findIndexInRow(newVal.start.row), this.findIndexInRow(newVal.end.row)),
              minCol = Math.min(this.findIndexInCol(newVal.start.col), this.findIndexInCol(newVal.end.col)),
              maxCol = Math.max(this.findIndexInCol(newVal.start.col), this.findIndexInCol(newVal.end.col));

          if (!this.localSheet.inputSelect.special)
            for (let j = minRow; j <= maxRow; j++) {
              for (let i = minCol; i <= maxCol; i++) {
                let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
                if (!cell.data.valid) {
                  minRow = Math.min(minRow, cell.data.mergeTo.data.mergeCellRange.row.min);
                  maxRow = Math.max(maxRow, cell.data.mergeTo.data.mergeCellRange.row.max);
                  minCol = Math.min(minCol, cell.data.mergeTo.data.mergeCellRange.col.min);
                  maxCol = Math.max(maxCol, cell.data.mergeTo.data.mergeCellRange.col.max);
                }
                if (cell.data.merge) {
                  minRow = Math.min(minRow, cell.data.mergeCellRange.row.min);
                  maxRow = Math.max(maxRow, cell.data.mergeCellRange.row.max);
                  minCol = Math.min(minCol, cell.data.mergeCellRange.col.min);
                  maxCol = Math.max(maxCol, cell.data.mergeCellRange.col.max);
                }
              }
            }
          else if (this.localSheet.select.end.data.merge) {
            maxRow = Math.max(maxRow, this.localSheet.select.end.data.mergeCellRange.row.max);
            maxCol = Math.max(maxCol, this.localSheet.select.end.data.mergeCellRange.col.max);
          }

          this.localSheet.rcRange = {
            row: {
              min: minRow,
              max: maxRow,
            },
            col: {
              min: minCol,
              max: maxCol,
            }
          }

          for (let j = minRow; j <= maxRow; j++) {
            for (let i = minCol; i <= maxCol; i++) {
              if (this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.valid)
                cell.push(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name])
            }
          }

          let minCellName = this.localSheet.col[this.localSheet.rcRange.col.min].name + this.localSheet.row[this.localSheet.rcRange.row.min].name,
              maxCellName = this.localSheet.col[this.localSheet.rcRange.col.max].name + this.localSheet.row[this.localSheet.rcRange.row.max].name;

          this.localSheet.multipleInputSelectAttr = [];
          this.localSheet.cellHeadArray = [];
          for (let ms of this.localSheet.multipleInputSelect) {
            let minRow = Math.min(this.findIndexInRow(ms.start.row), this.findIndexInRow(ms.end.row)),
                maxRow = Math.max(this.findIndexInRow(ms.start.row), this.findIndexInRow(ms.end.row)),
                minCol = Math.min(this.findIndexInCol(ms.start.col), this.findIndexInCol(ms.end.col)),
                maxCol = Math.max(this.findIndexInCol(ms.start.col), this.findIndexInCol(ms.end.col));

            for (let j = minRow; j <= maxRow; j++) {
              for (let i = minCol; i <= maxCol; i++) {
                let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
                if (!cell.data.valid) {
                  minRow = Math.min(minRow, cell.data.mergeTo.data.mergeCellRange.row.min);
                  maxRow = Math.max(maxRow, cell.data.mergeTo.data.mergeCellRange.row.max);
                  minCol = Math.min(minCol, cell.data.mergeTo.data.mergeCellRange.col.min);
                  maxCol = Math.max(maxCol, cell.data.mergeTo.data.mergeCellRange.col.max);
                }
                if (cell.data.merge) {
                  minRow = Math.min(minRow, cell.data.mergeCellRange.row.min);
                  maxRow = Math.max(maxRow, cell.data.mergeCellRange.row.max);
                  minCol = Math.min(minCol, cell.data.mergeCellRange.col.min);
                  maxCol = Math.max(maxCol, cell.data.mergeCellRange.col.max);
                }
              }
            }

            let rcRange = {
              row: {
                min: minRow,
                max: maxRow,
              },
              col: {
                min: minCol,
                max: maxCol,
              }
            }

            let minName = this.localSheet.col[rcRange.col.min].name + this.localSheet.row[rcRange.row.min].name,
                maxName = this.localSheet.col[rcRange.col.max].name + this.localSheet.row[rcRange.row.max].name;

            for (let j = minRow; j <= maxRow; j++) {
              if (this.localSheet.cellHeadArray.findIndex((c) => c == this.localSheet.row[j].name) === -1)
                this.localSheet.cellHeadArray.push(this.localSheet.row[j].name);
              for (let i = minCol; i <= maxCol; i++) {
                if (this.localSheet.cellHeadArray.findIndex((c) => c == this.localSheet.col[i].name) === -1)
                  this.localSheet.cellHeadArray.push(this.localSheet.col[i].name);
                if (this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.valid)
                  cell.push(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name])
              }
            }


            this.localSheet.multipleInputSelectAttr.push({
              name: minName + ':' + maxName,
              rcRange,
              attr: this.fromRangeGetAttr(rcRange.row.min, rcRange.row.max, rcRange.col.min, rcRange.col.max)
            });
          }

          if (this.localSheet.totalSourceSelecting) {
            let chart = {
              totalSourceRange: [],
              rcRange: []
            };

            for (let r of this.localSheet.multipleInputSelectAttr) {
              chart.rcRange.push(r.rcRange);
              chart.totalSourceRange.push(r.name);
            }
            chart.rcRange.push(this.localSheet.rcRange);
            chart.totalSourceRange.push(minCellName + ':' + maxCellName);
            chart.rangeStr = chart.totalSourceRange.join(',');
            this.localSheet.chartData.totalSource = chart;
            this.calcTotalSource(chart);
          } else if (this.localSheet.sourceSelecting) {
            let source = {
              sourceRange: [],
              rcRange: [],
            }
            for (let i in this.localSheet.multipleInputSelectAttr) {
              source.sourceRange.push(this.localSheet.multipleInputSelectAttr[i].name);
              source.rcRange.push(this.localSheet.multipleInputSelectAttr[i].rcRange);
            }
            source.sourceRange.push(minCellName + ':' + maxCellName);
            source.rcRange.push(this.localSheet.rcRange);
            source.rangeStr = source.sourceRange.join(',');

            this.localSheet.chartData.totalSource = {rangeStr: ''};
            this.localSheet.chartData.chartSource = source;
            this.calcChartOptions(this.localSheet.chartData.chartSource)
            this.calcChartSeries(this.localSheet.chartData.chartSeries)
            this.createSelectAreaByStr(this.localSheet.chartData.chartSource.sourceRange, 'source');
          } else if (this.localSheet.headSelecting) {
            let head = {
              headRange: [],
              rcRange: [],
            }
            for (let i in this.localSheet.multipleInputSelectAttr) {
              head.headRange.push(this.localSheet.multipleInputSelectAttr[i].name);
              head.rcRange.push(this.localSheet.multipleInputSelectAttr[i].rcRange);
            }
            head.headRange.push(minCellName + ':' + maxCellName);
            head.rcRange.push(this.localSheet.rcRange);
            head.rangeStr = head.headRange.join(',');

            this.localSheet.chartData.totalSource = {rangeStr: ''};
            this.localSheet.chartData.chartHead = head;
            this.calcChartHead(this.localSheet.chartData.chartHead)
            this.createSelectAreaByStr(this.localSheet.chartData.chartHead.headRange, 'head');
          } else if (this.localSheet.seriesSelecting) {
            let series = {
              seriesRange: [],
              rcRange: [],
            }
            for (let i in this.localSheet.multipleInputSelectAttr) {
              series.seriesRange.push(this.localSheet.multipleInputSelectAttr[i].name);
              series.rcRange.push(this.localSheet.multipleInputSelectAttr[i].rcRange);
            }
            series.seriesRange.push(minCellName + ':' + maxCellName);
            series.rcRange.push(this.localSheet.rcRange);
            series.rangeStr = series.seriesRange.join(',');

            this.localSheet.chartData.totalSource = {rangeStr: ''};
            this.localSheet.chartData.chartSeries = series;
            this.calcChartSeries(this.localSheet.chartData.chartSeries)
            this.createSelectAreaByStr(this.localSheet.chartData.chartSeries.seriesRange, 'series');
          } else if (this.localSheet.cellInputType) {
            let resolve = this.backupText.split('');
            let replaceName = '', flag = false;
            if (this.localSheet.cellInputTypeSpecial) {
              if (this.operators.findIndex(o =>
                  (o === this.localSheet.inputShowValue[this.selectionStart - 1])) === -1) {
                resolve.splice(this.selectionStart, 0, ',');
                this.backupText = resolve.join('')
                flag = true
                this.selectionSP = null;
              }
            }
            if (minCellName == maxCellName) {
              replaceName += minCellName;
              // resolve.splice(this.selectionStart, this.selectionEnd - this.selectionStart, minCellName);
            } else if (cell.length === 1) {
              replaceName += minCellName;
              // resolve.splice(this.selectionStart, this.selectionEnd - this.selectionStart, minCellName);
            } else {
              replaceName += (minCellName + ':' + maxCellName);
              // resolve.splice(this.selectionStart, this.selectionEnd - this.selectionStart, minCellName + ':' + maxCellName);
            }
            if (this.selectionSP) {
              resolve.splice(this.selectionSP[0], this.selectionSP[1] - this.selectionSP[0], replaceName);
            } else {
              if (flag) {
                this.selectionSP = [this.selectionStart + 1, this.selectionEnd + 1];
                resolve.splice(this.selectionStart + 1, this.selectionEnd - this.selectionStart, replaceName);
              } else if (Math.abs(this.selectionEnd - this.selectionStart) > 0) {
                resolve.splice(this.selectionStart, this.selectionEnd - this.selectionStart, replaceName);
                let strA = this.localSheet.inputShowValue.split("");
                strA.splice(this.selectionStart, this.selectionEnd - this.selectionStart)
                this.backupText = strA.join('');
                this.selectionSP = [this.selectionStart, this.selectionStart];
              } else {
                this.selectionSP = [this.selectionStart, this.selectionEnd];
                resolve.splice(this.selectionStart, this.selectionEnd - this.selectionStart, replaceName);
              }
            }

            this.$nextTick(() => {
              let pos = Math.min(this.selectionSP[0], this.selectionSP[1]) + replaceName.length;
              this.$refs.cellInput.setSelectionRange(pos, pos);
              this.selectionStart = pos;
              this.selectionEnd = pos;
            })

            this.localSheet.inputShowValue = resolve.join('');

            // const event = new CustomEvent("change", {
            //   bubbles: true
            // });
            // this.$refs.cellInput.dispatchEvent(event);
            // this.selectionStart = event.target.selectionStart;
            // this.selectionEnd = event.target.selectionEnd;
            // console.log(event.target.selectionStart, event.target.selectionEnd)

            this.localSheet.cellInputTypeSpecial = false;
          } else if (this.selectPrintAreaIndex !== null) {
            this.printArea = minCellName + ':' + maxCellName;
            this.localSheet.dashSelectArea = [];
            this.createSelectAreaByStr(this.printArea);
          } else if (this.selectRequirementAreaIndex !== null) {
            this.requirement.requirementArea = minCellName + ':' + maxCellName;
            this.localSheet.dashSelectArea = [];
            this.createSelectAreaByStr(this.requirement.requirementArea);
          }

          this.localSheet.inputSelectAreaCell = cell;
          this.localSheet.inputSelectAreaAttr = this.fromRangeGetAttr(this.localSheet.rcRange.row.min, this.localSheet.rcRange.row.max, this.localSheet.rcRange.col.min, this.localSheet.rcRange.col.max);

          if (this.localSheet.freeze) {
            if (this.localSheet.rcRange.col.min < this.localSheet.freezeRow.index) {
              this.$refs.cab.scrollLeft = this.localSheet.freezeCol.scrollLimit;
              this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
            }
            if (this.localSheet.rcRange.row.min < this.localSheet.freezeCol.index) {
              this.$refs.rab.scrollTop = this.localSheet.freezeRow.scrollLimit;
              this.localSheet.offsetTop = this.$refs.rab.scrollTop;
            }
          }
        }

      }
    },
    'localSheet.inputShowValue': {
      handler: function (newVal, oldVal) {
        //计算输入框的属性
        if (this.localSheet.inputShow) {
          this.localSheet.inputShowCalc = newVal;
          this.calcInputAttr();
          this.calcContentIncludeCell(newVal);
        }
      }
    },
    'sideBox.sideBoxShow': {
      handler: function (newVal) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.resizeTable();
          }, 180);
        })
      }
    },
    'printArea': function (newVal, oldVal) {
      this.localSheet.dashSelectArea = [];
      this.createSelectAreaByStr(this.printArea);
      this.preUploadData();
    },
    'localSheet.multipleSelect': function (newVal) {
      this.localSheet.multipleSelectAttr = [];
      for (let ms of newVal) {
        let minRow = Math.min(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
            maxRow = Math.max(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
            minCol = Math.min(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col)),
            maxCol = Math.max(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col));

        for (let j = minRow; j <= maxRow; j++) {
          for (let i = minCol; i <= maxCol; i++) {
            let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
            if (!cell.data.valid) {
              minRow = Math.min(minRow, cell.data.mergeTo.data.mergeCellRange.row.min);
              maxRow = Math.max(maxRow, cell.data.mergeTo.data.mergeCellRange.row.max);
              minCol = Math.min(minCol, cell.data.mergeTo.data.mergeCellRange.col.min);
              maxCol = Math.max(maxCol, cell.data.mergeTo.data.mergeCellRange.col.max);
            }
            if (cell.data.merge) {
              minRow = Math.min(minRow, cell.data.mergeCellRange.row.min);
              maxRow = Math.max(maxRow, cell.data.mergeCellRange.row.max);
              minCol = Math.min(minCol, cell.data.mergeCellRange.col.min);
              maxCol = Math.max(maxCol, cell.data.mergeCellRange.col.max);
            }
          }
        }

        let rcRange = {
          row: {
            min: minRow,
            max: maxRow,
          },
          col: {
            min: minCol,
            max: maxCol,
          }
        }

        for (let j = minRow; j <= maxRow; j++) {
          if (this.localSheet.cellHeadArray.findIndex((c) => c == this.localSheet.row[j].name) === -1)
            this.localSheet.cellHeadArray.push(this.localSheet.row[j].name);
          for (let i = minCol; i <= maxCol; i++) {
            if (this.localSheet.cellHeadArray.findIndex((c) => c == this.localSheet.col[i].name) === -1)
              this.localSheet.cellHeadArray.push(this.localSheet.col[i].name);
            if (this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.valid)
              if (this.localSheet.selectAreaCell.findIndex((s) => s.name == this.localSheet.col[i].name + this.localSheet.row[j].name) === -1)
                this.localSheet.selectAreaCell.push(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name])
          }
        }

        this.localSheet.multipleSelectAttr.push(this.fromRangeGetAttr(rcRange.row.min, rcRange.row.max, rcRange.col.min, rcRange.col.max));
      }
    },
  },
  computed: {
    //冻结时显示的行
    freezeRowShow: function () {
      let obj = {};
      for (let i in this.localSheet.map) {
        if (this.findIndexInCol(this.localSheet.map[i].data.col) < this.localSheet.freezeRow.index) {
          obj[i] = this.localSheet.map[i];
        }
      }
      return obj;
    },
    //冻结时显示的列
    freezeColShow: function () {
      let obj = {};
      for (let i in this.localSheet.map) {
        if (this.findIndexInRow(this.localSheet.map[i].data.row) < this.localSheet.freezeCol.index) {
          obj[i] = this.localSheet.map[i];
        }
      }
      return obj;
    },
    //冻结时行列都显示的单元格
    freezeCellShow: function () {
      let obj = {};
      for (let i in this.localSheet.map) {
        if (this.findIndexInRow(this.localSheet.map[i].data.row) < this.localSheet.freezeCol.index
            && this.findIndexInCol(this.localSheet.map[i].data.col) < this.localSheet.freezeRow.index) {
          obj[i] = this.localSheet.map[i];
        }
      }
      return obj;
    },
    //滚动条的属性
    scrollbarAttr: function () {
      return {
        x: this.cellAreaWidth / (this.localSheet.col[this.localSheet.col.length - 1].left + this.localSheet.col[this.localSheet.col.length - 1].size) * this.scrollbarWidth / this.localSheet.zoom,
        y: this.cellAreaHeight / (this.localSheet.row[this.localSheet.row.length - 1].top + this.localSheet.row[this.localSheet.row.length - 1].size) * this.scrollbarHeight / this.localSheet.zoom,
        offsetX: this.scrollbarWidth / (this.localSheet.col[this.localSheet.col.length - 1].left + this.localSheet.col[this.localSheet.col.length - 1].size) * this.localSheet.offsetLeft / this.localSheet.zoom,
        offsetY: this.scrollbarHeight / (this.localSheet.row[this.localSheet.row.length - 1].top + this.localSheet.row[this.localSheet.row.length - 1].size) * this.localSheet.offsetTop / this.localSheet.zoom,
      };
    },
    //冻结时的选框属性
    selectAreaAfterChange: function () {
      let rowSize, colSize, left, top;
      if (this.localSheet.selectAreaAttr.left - this.localSheet.offsetLeft / this.localSheet.zoom < this.localSheet.freezeRow.size - this.localSheet.freezeCol.scrollLimit) {
        if (this.localSheet.rcRange.col.min < this.localSheet.freezeRow.index) {
          let change = this.localSheet.offsetLeft / this.localSheet.zoom - this.localSheet.freezeCol.scrollLimit
          colSize = this.localSheet.selectAreaAttr.colSize - change;
          left = this.localSheet.selectAreaAttr.left + change;
          let s = 0;
          for (let i = this.localSheet.rcRange.col.min; i < Math.min(this.localSheet.freezeRow.index, this.localSheet.rcRange.col.max); i++) {
            s += (this.localSheet.col[i].size + 1);
          }
          colSize = Math.max(s, colSize);
        } else {
          let change = (this.localSheet.freezeRow.size - this.localSheet.freezeCol.scrollLimit) - (this.localSheet.selectAreaAttr.left - this.localSheet.offsetLeft / this.localSheet.zoom)
          colSize = this.localSheet.selectAreaAttr.colSize - change;
          left = this.localSheet.selectAreaAttr.left + change
        }
      } else {
        colSize = this.localSheet.selectAreaAttr.colSize;
        left = this.localSheet.selectAreaAttr.left;
      }
      if (this.localSheet.selectAreaAttr.top - this.localSheet.offsetTop / this.localSheet.zoom < this.localSheet.freezeCol.size - this.localSheet.freezeRow.scrollLimit) {
        if (this.localSheet.rcRange.row.min < this.localSheet.freezeCol.index) {
          let change = this.localSheet.offsetTop / this.localSheet.zoom - this.localSheet.freezeRow.scrollLimit
          rowSize = this.localSheet.selectAreaAttr.rowSize - change;
          top = this.localSheet.selectAreaAttr.top + change;
          let s = 0;
          for (let i = this.localSheet.rcRange.row.min; i < Math.min(this.localSheet.freezeCol.index, this.localSheet.rcRange.row.max); i++) {
            s += (this.localSheet.row[i].size + 1);
          }
          rowSize = Math.max(s, rowSize);
        } else {
          let change = (this.localSheet.freezeCol.size - this.localSheet.freezeRow.scrollLimit) - (this.localSheet.selectAreaAttr.top - this.localSheet.offsetTop / this.localSheet.zoom)
          rowSize = this.localSheet.selectAreaAttr.rowSize - change;
          top = this.localSheet.selectAreaAttr.top + change
        }
      } else {
        rowSize = this.localSheet.selectAreaAttr.rowSize;
        top = this.localSheet.selectAreaAttr.top;
      }


      return {
        left: left,
        top: top,
        rowSize: rowSize,
        colSize: colSize,
      }
    },
    //头部单元格选区所含cellHead
    selectCellHead: function () {
      let result = []
      if (this.localSheet.cellHeadSelect.start && this.localSheet.cellHeadSelect.end) {
        let si = Math.min(this.localSheet.cellHeadSelect.start.index, this.localSheet.cellHeadSelect.end.index),
            ei = Math.max(this.localSheet.cellHeadSelect.start.index, this.localSheet.cellHeadSelect.end.index);
        for (let i = si; i <= ei; i++) {
          result.push(this.localSheet[this.localSheet.cellHeadSelect.type][i]);
        }
      }
      return result;
    },
  },
  methods: {
    //创建新的条件格式
    createRequirement() {
      if(!this.requirement.require.length){
        this.$toast('请正确填写规则','danger');
        return;
      }

      for (let require of this.requirement.require){
        if(require.value.trim() === ''){
          this.$toast('请正确填写规则','danger');
          return;
        }
      }

      if (this.requirementIndex !== null) {
        this.$set(this.localSheet.requirements, this.requirementIndex, deepClone(this.requirement))
        this.requirementModal = false;
        this.requirementIndex = null;
        this.$toast('修改成功');
      } else {
        let requirements = deepClone(this.localSheet.requirements);
        requirements.push(deepClone(this.requirement));
        this.$set(this.localSheet, 'requirements', requirements);
        this.requirementModal = false;
        this.requirementIndex = null;
        this.$toast('创建成功');
      }

      this.sheet.resetData(this.localSheet);
      this.preUploadData();
      this.$forceUpdate();
    },

    editRequirement(r, i) {
      this.requirement = deepClone(r);
      this.requirementIndex = i;
      this.requirementModal = true;
      this.selectRequirementAreaIndex = null;
    },

    delRequirement(i) {
      this.localSheet.requirements.splice(i, 1);
      this.preUploadData();
    },

    saveRequirementStyle(data) {
      this.closeDropdown();
      this.requirement.style[data.type] = data.value;
    },

    //条件规则内的点击dropdown
    clickDropdown(data) {
      if (this.lastClickDropdown !== data && this.requirement.click[data] !== true) {
        this.closeDropdown();
      }

      this.requirement.click[data] = !this.requirement.click[data];
      this.lastClickDropdown = data;
    },

    closeDropdown() {
      this.requirement.click.border = false;
      this.requirement.click.color = false;
      this.requirement.click.backgroundColor = false;
    },

    //添加新的规则
    addNewRequire() {
      this.requirement.require.push({
        rule: 'cell',
        require: 'greater',
        value: '',
      });
    },

    //移除指定规则
    removeRequire(index) {
      this.requirement.require.splice(index,1);
    },

    //图表选取手动输入时的操作
    chartInputChange(type) {
      if (type === 'totalSource') {
        let rc = this.chartInputValidate(this.localSheet.chartData.totalSource.rangeStr, 2)
        if (rc) {
          let chart = {
            totalSourceRange: [],
            rcRange: rc
          };

          for (let r of rc) {
            chart.totalSourceRange.push(this.localSheet.col[r.col.min].name + this.localSheet.row[r.row.min].name + ':' + this.localSheet.col[r.col.max].name + this.localSheet.row[r.row.max].name);
          }
          chart.rangeStr = chart.totalSourceRange.join(',');
          this.localSheet.chartData.totalSource = chart;
          this.calcTotalSource(chart);
        }
      } else if (type === 'chartSource') {
        let rc = this.chartInputValidate(this.localSheet.chartData.chartSource.rangeStr, 2)
        if (rc) {
          let source = {
            sourceRange: [],
            rcRange: rc,
          }
          for (let r of rc) {
            source.sourceRange.push(this.localSheet.col[r.col.min].name + this.localSheet.row[r.row.min].name + ':' + this.localSheet.col[r.col.max].name + this.localSheet.row[r.row.max].name);
          }
          source.rangeStr = source.sourceRange.join(',');

          this.localSheet.chartData.totalSource = {rangeStr: ''};
          this.localSheet.chartData.chartSource = source;
          this.calcChartOptions(this.localSheet.chartData.chartSource)
          this.calcChartSeries(this.localSheet.chartData.chartSeries)
          this.createSelectAreaByStr(this.localSheet.chartData.chartSource.sourceRange, 'source');
        }
      } else if (type === 'chartHead') {
        let rc = this.chartInputValidate(this.localSheet.chartData.chartHead.rangeStr, 2)
        if (rc) {
          let head = {
            headRange: [],
            rcRange: rc,
          }
          for (let r of rc) {
            head.headRange.push(this.localSheet.col[r.col.min].name + this.localSheet.row[r.row.min].name + ':' + this.localSheet.col[r.col.max].name + this.localSheet.row[r.row.max].name);
          }
          head.rangeStr = head.headRange.join(',');

          this.localSheet.chartData.totalSource = {rangeStr: ''};
          this.localSheet.chartData.chartHead = head;
          this.calcChartHead(this.localSheet.chartData.chartHead)
          this.createSelectAreaByStr(this.localSheet.chartData.chartHead.headRange, 'head');
        }
      } else if (type === 'chartSeries') {
        let rc = this.chartInputValidate(this.localSheet.chartData.chartSeries.rangeStr, 1)
        if (rc) {
          let series = {
            seriesRange: [],
            rcRange: rc,
          }
          for (let r of rc) {
            series.seriesRange.push(this.localSheet.col[r.col.min].name + this.localSheet.row[r.row.min].name + ':' + this.localSheet.col[r.col.max].name + this.localSheet.row[r.row.max].name);
          }
          series.rangeStr = series.seriesRange.join(',');

          this.localSheet.chartData.totalSource = {rangeStr: ''};
          this.localSheet.chartData.chartSeries = series;
          this.calcChartSeries(this.localSheet.chartData.chartSeries)
          this.createSelectAreaByStr(this.localSheet.chartData.chartSeries.seriesRange, 'series');
        }
      }
    },

    //验证字符串是否符合表格区域的表达形式，num为多选区域长度
    chartInputValidate(str, num = -1) {
      let arr1 = str.split(',');
      if (num > 0 && arr1.length > num)
        return false;

      let rcRange = [];
      for (let i in arr1) {
        let arr2 = arr1[i].split(':');
        if (arr2.length === 2) {
          if (this.localSheet.map[arr2[0]] && this.localSheet.map[arr2[1]]) {
            rcRange.push({
              row: {
                min: this.localSheet.map[arr2[0]].ri,
                max: this.localSheet.map[arr2[1]].ri,
              },
              col: {
                min: this.localSheet.map[arr2[0]].ci,
                max: this.localSheet.map[arr2[1]].ci,
              }
            })
          } else return false;
        } else return false;
      }

      return rcRange;
    },

    //自适应宽高
    selfAdaptionSize(data) {
      this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
      let size = 0, offset, index;
      for (let cellHead of this.selectCellHead) {
        if (data.type === 'col') {
          index = this.findIndexInCol(cellHead.name);
          for (let i = 0; i < this.localSheet.row.length; i++) {
            let cell = this.localSheet.map[cellHead.name + this.localSheet.row[i].name];
            if (cell.data.content !== '' && cell.data.valid) {
              let txts = `${this.calcCellContent(cell.data.content)}`.split('\n');
              txts.forEach((txt) => {
                let width = this.sheet.table.draw.calcTextWidth(txt, cell.data.cellStyle);
                size = Math.max(size, width);
              });
            }
          }

          if (size === 0)
            offset = 0;
          else offset = size - this.localSheet.col[index].size;
        } else if (data.type === 'row') {
          index = this.findIndexInRow(cellHead.name);
          for (let i = 0; i < this.localSheet.col.length; i++) {
            let cell = this.localSheet.map[this.localSheet.col[i].name + cellHead.name];
            if (cell.data.content !== '' && cell.data.valid) {
              let txts = `${this.calcCellContent(cell.data.content)}`.split('\n');
              let height = txts.length * cell.data.cellStyle.fontSize + (txts.length - 1) * 2 + 10;
              size = Math.max(size, height);
            }
          }

          if (size === 0)
            offset = 0;
          else offset = size - this.localSheet.row[index].size;
        }

        this.rowColResize(data.type, cellHead.name, offset);

        this.insertUploadData(data.type, cellHead.name, this.localSheet[data.type][index], false);
      }
      this.preUploadData();

      this.sheet.resetData(this.localSheet);

      if (this.localSheet.selectAreaCell.length)
        this.localSheet.selectAreaAttr = this.fromRangeGetAttr(this.localSheet.rcRange.row.min, this.localSheet.rcRange.row.max, this.localSheet.rcRange.col.min, this.localSheet.rcRange.col.max);

      this.localSheet.multipleSelectAttr = [];
      for (let ms of this.localSheet.multipleSelect) {
        let minRow = Math.min(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
            maxRow = Math.max(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
            minCol = Math.min(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col)),
            maxCol = Math.max(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col));

        for (let j = minRow; j <= maxRow; j++) {
          for (let i = minCol; i <= maxCol; i++) {
            let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
            if (!cell.data.valid) {
              minRow = Math.min(minRow, cell.data.mergeTo.data.mergeCellRange.row.min);
              maxRow = Math.max(maxRow, cell.data.mergeTo.data.mergeCellRange.row.max);
              minCol = Math.min(minCol, cell.data.mergeTo.data.mergeCellRange.col.min);
              maxCol = Math.max(maxCol, cell.data.mergeTo.data.mergeCellRange.col.max);
            }
            if (cell.data.merge) {
              minRow = Math.min(minRow, cell.data.mergeCellRange.row.min);
              maxRow = Math.max(maxRow, cell.data.mergeCellRange.row.max);
              minCol = Math.min(minCol, cell.data.mergeCellRange.col.min);
              maxCol = Math.max(maxCol, cell.data.mergeCellRange.col.max);
            }
          }
        }

        let rcRange = {
          row: {
            min: minRow,
            max: maxRow,
          },
          col: {
            min: minCol,
            max: maxCol,
          }
        }
        this.localSheet.multipleSelectAttr.push(this.fromRangeGetAttr(rcRange.row.min, rcRange.row.max, rcRange.col.min, rcRange.col.max));
      }
    },

    selectPrintArea() {
      if (this.selectPrintAreaIndex)
        this.selectPrintAreaIndex = null;
      else this.selectPrintAreaIndex = true;
    },

    selectRequirementArea() {
      if (this.selectRequirementAreaIndex)
        this.selectRequirementAreaIndex = null;
      else this.selectRequirementAreaIndex = true;
    },

    clearPrintArea() {
      this.printArea = '';
    },

    delPrintArea(index) {
      this.printArea.splice(index, 1);

      if (this.selectPrintAreaIndex === index)
        this.selectPrintAreaIndex = null;
    },

    preview() {
      this.previewAreaShow = true;
    },

    //开始拖拽数据源
    drag(d) {
      this.dragDataSet = d;
    },

    //插入行列
    insertRC(type) {
      this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
      let i = null;
      if (type == 'row') {
        i = this.localSheet.selectAreaCell[0].ri;
        addNewRow(this, 1);
        transferData(this, 'row', i, 1);
      } else if (type == 'col') {
        i = this.localSheet.selectAreaCell[0].ci;
        addNewCol(this, 1);
        transferData(this, 'col', i, 1);
      }

      this.preUploadData();
    },

    delRC(type) {
      this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});

      for (let cellHead of this.selectCellHead) {
        let i = null;
        if (type == 'delRow') {
          i = this.localSheet.selectAreaCell[0].ri;
          transferData(this, 'row', i, -1);
        } else if (type == 'delCol') {
          i = this.localSheet.selectAreaCell[0].ci;
          transferData(this, 'col', i, -1);
        }
      }

      this.preUploadData();
    },

    showSizeChangeModal() {
      this.sizeChanging = true;
      this.sizeChangeForm.size = this.selectCellHead[0].size.toFixed(2);
      this.$refs.sizeChange.show();
    },

    //调整行列大小（具体数值）
    sizeChange() {
      return new Promise((resolve, reject) => {
        this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
        for (let cellHead of this.selectCellHead) {
          let offset = Math.min(this.sizeChangeForm.size, this.localSheet.cellHeadSelect.type === 'col' ? 1500 : 400) - cellHead.size;
          this.rowColResize(this.localSheet.cellHeadSelect.type, cellHead.name, offset);
          this.insertUploadData(this.localSheet.cellHeadSelect.type, cellHead.name, this.localSheet[this.localSheet.cellHeadSelect.type][cellHead.index], false);
        }
        this.preUploadData();

        this.sheet.resetData(this.localSheet);

        if (this.localSheet.selectAreaCell.length)
          this.localSheet.selectAreaAttr = this.fromRangeGetAttr(this.localSheet.rcRange.row.min, this.localSheet.rcRange.row.max, this.localSheet.rcRange.col.min, this.localSheet.rcRange.col.max);

        this.localSheet.multipleSelectAttr = [];
        for (let ms of this.localSheet.multipleSelect) {
          let minRow = Math.min(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
              maxRow = Math.max(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
              minCol = Math.min(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col)),
              maxCol = Math.max(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col));

          for (let j = minRow; j <= maxRow; j++) {
            for (let i = minCol; i <= maxCol; i++) {
              let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
              if (!cell.data.valid) {
                minRow = Math.min(minRow, cell.data.mergeTo.data.mergeCellRange.row.min);
                maxRow = Math.max(maxRow, cell.data.mergeTo.data.mergeCellRange.row.max);
                minCol = Math.min(minCol, cell.data.mergeTo.data.mergeCellRange.col.min);
                maxCol = Math.max(maxCol, cell.data.mergeTo.data.mergeCellRange.col.max);
              }
              if (cell.data.merge) {
                minRow = Math.min(minRow, cell.data.mergeCellRange.row.min);
                maxRow = Math.max(maxRow, cell.data.mergeCellRange.row.max);
                minCol = Math.min(minCol, cell.data.mergeCellRange.col.min);
                maxCol = Math.max(maxCol, cell.data.mergeCellRange.col.max);
              }
            }
          }

          let rcRange = {
            row: {
              min: minRow,
              max: maxRow,
            },
            col: {
              min: minCol,
              max: maxCol,
            }
          }
          this.localSheet.multipleSelectAttr.push(this.fromRangeGetAttr(rcRange.row.min, rcRange.row.max, rcRange.col.min, rcRange.col.max));
        }

        resolve();
      }).then(() => {
        this.$refs.sizeChange.hide();
      });
    },

    //右键菜单执行操作
    contextmenuAction(action) {
      switch (action) {
        case 'copy':
          this.copy();
          break;
        case 'cut':
          this.copy('cut');
          break;
        case 'paste':
          this.paste();
          break;
        case 'row':
          this.insertRC(action);
          break;
        case 'col':
          this.insertRC(action);
          break;
        case 'delRow':
          this.delRC(action);
          break;
        case 'delCol':
          this.delRC(action);
          break;
        case 'selfAdaptionSize':
          this.selfAdaptionSize({type: this.contextmenu.type, name: this.contextmenu.name});
          break;
        case 'sizeChange':
          this.showSizeChangeModal();
          break;
      }

      this.closeContextmenu();
    },

    dataSourceClose() {
      this.dataset = (this.dataset === null ? [null] : null);

      this.dataSourceModalVisible = false;
    },

    //点击修改数据源的icon
    editDataset(data) {
      this.dataset = data;
      this.dataSourceModalVisible = true;
      this.datasetModalType = 'edit';
    },

    //复制数据源
    copyDataset(data) {
      this.copyDatasetData = deepClone(data);
      this.copyDatasetTemplates = [];
      this.$refs.copyDataset.show();
      this.getTemplates();
      this.copyDatasetForm.templateID = this.template.id;
    },

    //获取模版
    getTemplates(name = null) {
      this.copyDatasetForm.loading = true;
      this.$http.post('template/list', {
        take: this.copyDatasetForm.take,
        skip: this.copyDatasetForm.skip,
        name: name,
      }).then(res => {
        let require1 = res.data.items.findIndex((i) => i.id === this.template.id),
            require2 = this.copyDatasetTemplates.findIndex((t) => t.id === this.template.id);
        if (require1 !== -1 && require2 !== -1 && name.trim() === '') {
          this.copyDatasetTemplates.shift();
        } else if (require1 === -1 && require2 === -1 && name.trim() === '') {
          this.copyDatasetTemplates.unshift({id: this.template.id, name: this.template.name});
        }
        this.copyDatasetTemplates = [...this.copyDatasetTemplates, ...res.data.items];
      }).catch(e => {
        this.copyDatasetTemplates = [];
      }).finally(() => {
        this.copyDatasetForm.loading = false;
      });
    },

    //远程搜索模版
    getTemplatesRemote(name) {
      this.copyDatasetTemplates = [];
      this.copyDatasetForm.name = name;
      this.copyDatasetForm.skip = 0;
      this.getTemplates(name);
    },

    //懒加载模版
    loadmore() {
      this.copyDatasetForm.skip += this.copyDatasetForm.take;
      this.getTemplates(this.copyDatasetForm.name);
    },

    //确认复制数据源方法
    copyDatasetConfirm() {
      return new Promise((resolve, reject) => {
        this.$http.get('template/info/' + this.copyDatasetForm.templateID).then(res => {
          let data = JSON.parse(res.data.data);
          let newData = deepClone(this.copyDatasetData);
          newData.indexKey = data.datasets.length ? data.datasets[data.datasets.length - 1].indexKey + 1 : 1;
          newData.param = 'param' + ((data.datasets.length ? data.datasets[data.datasets.length - 1].indexKey + 1 : 1));
          newData.key = window.UUID();
          newData.apiData.key = newData.key;
          data.datasets.push(newData);
          this.$http.post('/template/auto/' + this.template.id, {
            data: JSON.stringify(data),
          }).then(res => {
            if (this.copyDatasetForm.templateID === this.template.id) {
              this.saveState.time = res.data.template.updated_at;
              this.datasets.push(newData);
              this.preUploadData();
            }
            this.$refs.copyDataset.hide();
            resolve();
          }).catch(e => {
            reject(e);
          }).finally(() => {
            this.$refs.copyDataset.hide();
          });
        })
      });
    },

    //删除数据源
    delDataset(index) {
      for (let k in this.sheets) {
        for (let i in this.sheets[k].map) {
          let cell = this.sheets[k].map[i];
          if (cell.data.content === '_' + this.datasets[index].param) {
            cell.data.content = '';
          }
        }
      }
      this.datasets.splice(index, 1);
      this.preUploadData();
    },

    //添加数据源
    appendDataSet(data) {
      let apiData = this.apiDataFormat(data);

      this.datasets.push({
        source: data,
        param: 'param' + (this.datasets.length ? this.datasets[this.datasets.length - 1].indexKey + 1 : 1),
        indexKey: (this.datasets.length ? this.datasets[this.datasets.length - 1].indexKey + 1 : 1),
        key: apiData.key,
        apiData,
      });

      this.preUploadData();
    },

    autoSave() {
      this.saveState.loading = true;
      let data = JSON.parse(localStorage.webExcel);
      for (let i in data.storage) {
        for (let j in data.storage[i].images) {
          if (data.storage[i].images[j].type == 'Chart' && i * 1 === this.sheetIndex * 1)
            this.$nextTick(() => {
              data.storage[i].images[j].src = this.$refs['image' + data.storage[i].images[j].uuid][0].getSrc();
            })

        }
      }
      this.$http.post('/template/auto/' + this.template.id, {
        data: JSON.stringify(data),
      }).then(res => {
        console.log('autoSave');
        this.saveState.time = res.data.template.updated_at;
        this.saveState.loading = false;
      }).catch(e => {
        this.saveState.loading = false;
      });
    },

    apiDataFormat(data) {
      let key = window.UUID();
      let dataStyle = {
        rtinfo: 0,
        htinfo: 1,
        ndinfo: 2
      };

      let apiData = {
        key,
        datastyle: dataStyle[data.preData.type],
        recid: [],
      };


      if (data.preData.type == 'ndinfo') {
        for (let o of data.preData.nodeId) {
          apiData.recid.push(o.id)
        }
        apiData.entype = data.paramsData.value.entype;
        apiData.enkind = data.paramsData.value.enkind;
        apiData.apptype = data.preData.nodeType;
      } else {
        apiData.tbid = data.preData.table;
        for (let o of data.selectParams) {
          apiData.recid.push(o.value)
        }
        apiData.field = data.paramsData.value.value;
        apiData.info = data.paramsData.value.info;
      }

      for (let i in data.paramsData.date) {
        let o = data.paramsData.date[i];
        if (o !== null)
          apiData[i] = o;
      }


      return apiData;
    },

    //修改数据源
    modifySelectDataset(data) {
      this.dataset.source = data;

      let apiData = this.apiDataFormat(data);
      apiData.key = this.dataset.key;
      this.dataset.apiData = apiData;

      this.dataset = null;
      this.preUploadData();
      this.sheet && this.sheet.resetData(this.localSheet);
    },

    //显示数据源模态框
    showDatasetModal() {
      this.dataSourceModalVisible = true;
      this.dataset = null;
      this.datasetModalType = 'create';
    },

    //显示条件格式模态框
    showRequirementModal() {
      this.requirementModal = true;
      this.selectRequirementAreaIndex = null;
      this.$set(this, 'requirement', {
        name: '',
        requirementArea: '',
        require: [],
        click: {
          color: false,
          border: false,
          backgroundColor: false,
        }
      });
      this.$set(this.requirement, 'style', {
        backgroundColor: 'none',
        color: '333333',
        bold: false,
        italic: false,
        underline: false,
      })
      console.log(this.requirement.style.backgroundColor)
      console.log(this.requirement.style.backgroundColor==='none')
      this.$forceUpdate();
    },

    //calculate区域文本框聚焦
    calculateTextareaFocus() {
      if (this.localSheet.selectAreaCell.length && !this.localSheet.inputShow) {
        this.showInput(this.localSheet.selectAreaCell[0].name, false);
      }
      this.localSheet.calculate = true;
      this.$refs.toolbar.closeDropdown();
      this.closeMenu();

      this.$nextTick(() => {
        this.$refs.calculate.focus();
      })
    },

    cellInputFocus() {
      if (this.localSheet.inputShow)
        this.localSheet.calculate = true;
    },

    cellInputBlur(type = '') {
      if (type === 'calculate') {
        this.localSheet.calculate = false;
      }
      if (!this.localSheet.inputShow && this.localSheet.inputSaveFlag && !this.localSheet.calculate) {
        this.saveCell();
      }
    },

    //导出
    downloadExcel(type) {
      this.$http.get('template/download/' + this.template.id).then(res => {
        let link = document.createElement('a');
        if (type == 'excel')
          link.href = res.data.downloadUrl;
        else if (type == 'pdf')
          link.href = res.data.downloadUrlPdf;
        link.download = this.template.name;
        link.target = '_blank';
        link.click();
      }).catch(error => {
        console.log(error);
      });
    },

    //选择历史记录
    selectHistory(code) {
      this.loading = true;
      this.$http.get('history/info/' + code).then(res => {
        this.sheets = [];
        let data = JSON.parse(res.data.data);
        this.init(data);

        this.sideBox = {
          sideBoxTitle: '修改记录',
          type: 'history',
          sideBoxShow: true,
        };

        this.localSheet = this.sheets[this.sheetIndex];
        this.sheet && this.sheet.resetData(this.localSheet);
      }).finally(() => {
        this.loading = false;
      })
    },

    //恢复历史记录
    revert(data) {
      this.$http.post('history/revert', {
        template_id: data.template_id,
        identifying_code: data.identifying_code,
      }).then(res => {
        this.getHistory(data.template_id).then((data) => {
          this.$router.push({
            name: 'TemplateHistory',
            params: {identifying_code: data[data.length - 1].identifying_code}
          });
          this.$refs.history.selectHistory(0);
        });
      });
    },

    //关闭右侧菜单
    closeSideBox() {
      if (this.identifying_code) {
        this.$router.back();
      } else {
        if (this.sideBox.type == 'dataSource')
          this.dataSourceModalVisible = false;
        this.sideBox.sideBoxShow = false;
        this.selectPrintAreaIndex = null;
        this.$refs.toolbar.closeDropdown();
        this.sheet.resetData(this.localSheet);
      }
    },

    //根据选区自行区分系列和表头以及数据
    calcTotalSource(val) {
      let heads = {
        headRange: [],
        rcRange: []
      }, series = {
        seriesRange: [],
        rcRange: []
      }, source = {
        sourceRange: [],
        rcRange: []
      };
      for (let k in val.rcRange) {
        let cd = val.rcRange[k];
        if (this.localSheet.chartData.type == 'col') {
          if (k == 0) {
            series.seriesRange.push(this.localSheet.col[cd.col.min + 1].name + this.localSheet.row[cd.row.min].name + ':' + this.localSheet.col[cd.col.max].name + this.localSheet.row[cd.row.min].name);
            series.rcRange.push({
              row: {
                min: cd.row.min,
                max: cd.row.min,
              },
              col: {
                min: cd.col.min + 1,
                max: cd.col.max,
              }
            });

            source.sourceRange.push(this.localSheet.col[cd.col.min + 1].name + this.localSheet.row[cd.row.min + 1].name + ':' + this.localSheet.col[cd.col.max].name + this.localSheet.row[cd.row.max].name);
            source.rcRange.push({
              row: {
                min: cd.row.min + 1,
                max: cd.row.max,
              },
              col: {
                min: cd.col.min + 1,
                max: cd.col.max,
              }
            });

            heads.headRange.push(this.localSheet.col[cd.col.min].name + this.localSheet.row[cd.row.min + 1].name + ':' + this.localSheet.col[cd.col.min].name + this.localSheet.row[cd.row.max].name);
            heads.rcRange.push({
              row: {
                min: cd.row.min + 1,
                max: cd.row.max,
              },
              col: {
                min: cd.col.min,
                max: cd.col.min,
              }
            })
          } else {
            source.sourceRange.push(this.localSheet.col[cd.col.min + 1].name + this.localSheet.row[cd.row.min].name + ':' + this.localSheet.col[cd.col.max].name + this.localSheet.row[cd.row.max].name);
            source.rcRange.push({
              row: {
                min: cd.row.min,
                max: cd.row.max,
              },
              col: {
                min: cd.col.min + 1,
                max: cd.col.max,
              }
            });

            heads.headRange.push(this.localSheet.col[cd.col.min].name + this.localSheet.row[cd.row.min].name + ':' + this.localSheet.col[cd.col.min].name + this.localSheet.row[cd.row.max].name);
            heads.rcRange.push({
              row: {
                min: cd.row.min,
                max: cd.row.max,
              },
              col: {
                min: cd.col.min,
                max: cd.col.min,
              }
            })
          }
        } else {
          if (k == 0) {
            heads.headRange.push(this.localSheet.col[cd.col.min + 1].name + this.localSheet.row[cd.row.min].name + ':' + this.localSheet.col[cd.col.max].name + this.localSheet.row[cd.row.min].name);
            heads.rcRange.push({
              row: {
                min: cd.row.min,
                max: cd.row.min,
              },
              col: {
                min: cd.col.min + 1,
                max: cd.col.max,
              }
            });

            source.sourceRange.push(this.localSheet.col[cd.col.min + 1].name + this.localSheet.row[cd.row.min + 1].name + ':' + this.localSheet.col[cd.col.max].name + this.localSheet.row[cd.row.max].name);
            source.rcRange.push({
              row: {
                min: cd.row.min + 1,
                max: cd.row.max,
              },
              col: {
                min: cd.col.min + 1,
                max: cd.col.max,
              }
            });

            series.seriesRange.push(this.localSheet.col[cd.col.min].name + this.localSheet.row[cd.row.min + 1].name + ':' + this.localSheet.col[cd.col.min].name + this.localSheet.row[cd.row.max].name);
            series.rcRange.push({
              row: {
                min: cd.row.min + 1,
                max: cd.row.max,
              },
              col: {
                min: cd.col.min,
                max: cd.col.min,
              }
            })
          } else {
            source.sourceRange.push(this.localSheet.col[cd.col.min + 1].name + this.localSheet.row[cd.row.min].name + ':' + this.localSheet.col[cd.col.max].name + this.localSheet.row[cd.row.max].name);
            source.rcRange.push({
              row: {
                min: cd.row.min,
                max: cd.row.max,
              },
              col: {
                min: cd.col.min + 1,
                max: cd.col.max,
              }
            });

            series.seriesRange.push(this.localSheet.col[cd.col.min].name + this.localSheet.row[cd.row.min].name + ':' + this.localSheet.col[cd.col.min].name + this.localSheet.row[cd.row.max].name);
            series.rcRange.push({
              row: {
                min: cd.row.min,
                max: cd.row.max,
              },
              col: {
                min: cd.col.min,
                max: cd.col.min,
              }
            })
          }
        }
      }

      source.rangeStr = source.sourceRange && source.sourceRange.join(',');
      heads.rangeStr = heads.headRange && heads.headRange.join(',');
      series.rangeStr = series.seriesRange && series.seriesRange.join(',');

      this.localSheet.chartData.chartSource = source;
      this.localSheet.chartData.chartHead = heads;
      this.localSheet.chartData.chartSeries = series;
      this.localSheet.chartData.totalSourceRange = val.totalSourceRange;

      this.calcChartHead(heads);
      this.calcChartOptions(source);
      this.calcChartSeries(series);
      this.createSelectAreaByStr(this.localSheet.chartData.chartHead.headRange, 'head');
      this.createSelectAreaByStr(this.localSheet.chartData.chartSource.sourceRange, 'source');
      this.createSelectAreaByStr(this.localSheet.chartData.chartSeries.seriesRange, 'series');
    },

    //计算图表的系列
    calcChartSeries(series) {
      if (!series.rcRange) return false;
      let ss = [];
      for (let k in series.rcRange) {
        let rc = series.rcRange[k];

        if (rc.row.max - rc.row.min == 0) {
          for (let i = rc.col.min; i <= rc.col.max; i++) {
            let r = (ss.length >= this.localSheet.chartData.options.length ? [] : this.localSheet.chartData.options[ss.length].range);
            ss.push({
              name: [this.calcCellContent(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[rc.row.min].name].data.content)],
              color: this.chartColors[ss.length % this.colors.length],
              range: r
            })
          }
        } else if (rc.col.max - rc.col.min == 0) {
          for (let i = rc.row.min; i <= rc.row.max; i++) {
            let r = (ss.length >= this.localSheet.chartData.options.length ? [] : this.localSheet.chartData.options[ss.length].range);
            ss.push({
              name: [this.calcCellContent(this.localSheet.map[this.localSheet.col[rc.col.min].name + this.localSheet.row[i].name].data.content)],
              color: this.chartColors[ss.length % this.colors.length],
              range: r
            })
          }
        } else {
          for (let i = rc.col.min; i <= rc.col.max; i++) {
            let r = (ss.length >= this.localSheet.chartData.options.length ? [] : this.localSheet.chartData.options[ss.length].range);
            let s = {
              name: [],
              color: this.chartColors[ss.length % this.colors.length],
              range: r
            };
            for (let j = rc.row.min; j <= rc.row.max; j++) {
              s.name.push(this.calcCellContent(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.content))
            }
            ss.push(s);
          }
        }
      }

      this.localSheet.chartData.series = ss;
      this.localSheet.chartData.seriesRange = series.seriesRange;
    },

    //计算图表的数据
    calcChartOptions(val) {
      if (!val.rcRange) return false;
      let options = [];

      for (let k in val.rcRange) {
        let rc = val.rcRange[k];

        if (this.localSheet.chartData.type == 'row') {
          for (let j = rc.row.min; j <= rc.row.max; j++) {
            let data;
            if (k != 0 && options.findIndex(o => o.index === j) !== -1)
              data = options[options.findIndex(o => o.index === j)];
            else data = {
              index: j,
              range: [],
              datasets: []
            };
            let r = [];
            for (let i = rc.col.min; i <= rc.col.max; i++) {
              r.push(this.localSheet.col[i].name + this.localSheet.row[j].name);
              data.datasets.push(this.calcCellContent(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.content));
            }
            if (r.length)
              data.range.push(r[0] + ':' + r[r.length - 1]);
            options.push(data);
          }
        } else {
          for (let i = rc.col.min; i <= rc.col.max; i++) {
            let data;
            if (k != 0 && options.findIndex(o => o.index === i) !== -1)
              data = options[options.findIndex(o => o.index === i)];
            else data = {
              index: i,
              range: [],
              datasets: []
            };
            let r = [];
            for (let j = rc.row.min; j <= rc.row.max; j++) {
              r.push(this.localSheet.col[i].name + this.localSheet.row[j].name);
              data.datasets.push(this.calcCellContent(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.content));
            }
            if (r.length)
              data.range.push(r[0] + ':' + r[r.length - 1]);
            options.push(data);
          }
        }
      }

      this.localSheet.chartData.options = options;
      this.localSheet.chartData.sourceRange = val.sourceRange;
    },

    //计算图表的标题
    calcChartHead(val) {
      if (!val.rcRange) return false;
      let heads = [];
      for (let k in val.rcRange) {
        let rc = val.rcRange[k];
        if (rc.row.max - rc.row.min == 0) {
          for (let i = rc.col.min; i <= rc.col.max; i++) {
            heads.push(this.calcCellContent(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[rc.row.min].name].data.content))
          }
        } else if (rc.col.max - rc.col.min == 0) {
          for (let i = rc.row.min; i <= rc.row.max; i++) {
            heads.push(this.calcCellContent(this.localSheet.map[this.localSheet.col[rc.col.min].name + this.localSheet.row[i].name].data.content))
          }
        } else {
          for (let i = rc.col.min; i <= rc.col.max; i++) {
            let head = [];
            for (let j = rc.row.min; j <= rc.row.max; j++) {
              head.push(this.calcCellContent(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.content))
            }
            heads.push(head.join(''));
          }
        }
      }

      this.localSheet.chartData.heads = heads;
      this.localSheet.chartData.headRange = val.headRange;
    },

    //获取图表的数据
    getChartData(data) {
      this.localSheet.dashSelectArea = [];
      this.localSheet.multipleInputSelect = [];
      this.localSheet.inputSelectAreaAttr = {}
      if (this.localSheet.nowImage.index !== null && this.localSheet.images[this.localSheet.nowImage.index] && this.localSheet.images[this.localSheet.nowImage.index].type == 'Chart') {
        this.localSheet.chartData = {
          ...this.localSheet.images[this.localSheet.nowImage.index].chartData,
          index: this.localSheet.nowImage.index,
        }

        this.createSelectAreaByStr(this.localSheet.chartData.sourceRange, 'source');
        this.createSelectAreaByStr(this.localSheet.chartData.headRange, 'head');
        this.createSelectAreaByStr(this.localSheet.chartData.seriesRange, 'series');
      } else {
        this.localSheet.chartData = {
          title: '',
          type: 'col',
          showValue: false,
          showScale: false,
          showMax: false,
          showMin: false,
          showAverage: false,
          chartType: '',
          sourceRange: '',
          headRange: '',
          seriesRange: '',
          options: [],
          heads: [],
          series: [],
          chartSource: {
            rangeStr: ''
          },
          chartHead: {
            rangeStr: ''
          },
          chartSeries: {
            rangeStr: ''
          },
          totalSource: {
            rangeStr: ''
          },
          totalSourceRange: [],
        }
      }

      this.sideBox.sideBoxShow = data;
      this.sideBox.sideBoxTitle = '图表';
      this.sideBox.type = 'chart';
      this.sheet.resetData(this.localSheet);
    },

    //切换数据行列
    changeDataType() {
      if (this.localSheet.chartData.type == 'row')
        this.localSheet.chartData.type = 'col';
      else this.localSheet.chartData.type = 'row';

      if (this.localSheet.chartData.totalSource) {
        this.calcTotalSource(this.localSheet.chartData.totalSource)
      } else if (this.localSheet.chartData.sourceRange && this.localSheet.chartData.sourceRange.length) {
        let options = {
          sourceRange: [],
          rcRange: [],
        };
        for (let sr of this.localSheet.chartData.sourceRange) {
          let range = sr.split(':');
          options.sourceRange.push(sr);
          options.rcRange.push({
            row: {
              min: this.localSheet.map[range[0]].ri,
              max: this.localSheet.map[range[1]].ri,
            },
            col: {
              min: this.localSheet.map[range[0]].ci,
              max: this.localSheet.map[range[1]].ci,
            },
          })
        }
        this.calcChartOptions(options);
      }

    },

    //显示数据源区域
    dataSourceShow(data) {
      this.localSheet.dashSelectArea = [];
      this.sideBox.sideBoxShow = data;
      this.sideBox.sideBoxTitle = '数据源';
      this.sideBox.type = 'dataSource';
      this.sheet.resetData(this.localSheet);
    },

    //显示页面选项区域
    pageShow(data) {
      this.localSheet.dashSelectArea = [];
      this.sideBox.sideBoxShow = data;
      this.sideBox.sideBoxTitle = '页面选项';
      this.sideBox.type = 'page';
      this.selectPrintAreaIndex = null;

      this.localSheet.dashSelectArea = [];
      if (this.printArea !== '')
        this.createSelectAreaByStr(this.printArea);
      this.sheet.resetData(this.localSheet);
    },

    //显示条件格式区域
    requirementShow(data) {
      this.localSheet.dashSelectArea = [];
      this.sideBox.sideBoxShow = data;
      this.sideBox.sideBoxTitle = '条件格式';
      this.sideBox.type = 'requirement';
      this.sheet.resetData(this.localSheet);
    },

    //修改缩放大小
    setZoom(data) {
      this.localSheet.zoom = data;
      this.sheet.resetData(this.localSheet);
      if (this.localSheet.selectAreaCell.length)
        this.localSheet.selectAreaAttr = this.fromRangeGetAttr(this.localSheet.rcRange.row.min, this.localSheet.rcRange.row.max, this.localSheet.rcRange.col.min, this.localSheet.rcRange.col.max);

      this.localSheet.multipleSelectAttr = [];
      for (let ms of this.localSheet.multipleSelect) {
        let minRow = Math.min(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
            maxRow = Math.max(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
            minCol = Math.min(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col)),
            maxCol = Math.max(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col));

        for (let j = minRow; j <= maxRow; j++) {
          for (let i = minCol; i <= maxCol; i++) {
            let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
            if (!cell.data.valid) {
              minRow = Math.min(minRow, cell.data.mergeTo.data.mergeCellRange.row.min);
              maxRow = Math.max(maxRow, cell.data.mergeTo.data.mergeCellRange.row.max);
              minCol = Math.min(minCol, cell.data.mergeTo.data.mergeCellRange.col.min);
              maxCol = Math.max(maxCol, cell.data.mergeTo.data.mergeCellRange.col.max);
            }
            if (cell.data.merge) {
              minRow = Math.min(minRow, cell.data.mergeCellRange.row.min);
              maxRow = Math.max(maxRow, cell.data.mergeCellRange.row.max);
              minCol = Math.min(minCol, cell.data.mergeCellRange.col.min);
              maxCol = Math.max(maxCol, cell.data.mergeCellRange.col.max);
            }
          }
        }

        let rcRange = {
          row: {
            min: minRow,
            max: maxRow,
          },
          col: {
            min: minCol,
            max: maxCol,
          }
        }
        this.localSheet.multipleSelectAttr.push(this.fromRangeGetAttr(rcRange.row.min, rcRange.row.max, rcRange.col.min, rcRange.col.max));
      }

      this.$nextTick(() => {
        this.localSheet.offsetTop = this.$refs.rab.scrollTop;
        this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
      })
    },

    //创建图表
    createChart(data) {
      this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
      if (data.index >= 0) {
        let {
          chartType,
          headRange,
          heads,
          options,
          sourceRange,
          title,
          type,
          series,
          seriesRange,
          totalSource,
          chartSource,
          chartHead,
          chartSeries,
          totalSourceRange,
          showValue,
          showScale,
          showMax,
          showMin,
          showAverage,
        } = data;
        let cd = {
          chartType,
          headRange,
          heads,
          options,
          sourceRange,
          title,
          type,
          series,
          seriesRange,
          totalSource,
          chartSource,
          chartHead,
          chartSeries,
          totalSourceRange,
          showValue,
          showScale,
          showMax,
          showMin,
          showAverage,
        };

        this.localSheet.images[data.index].chartData = cd;
        // this.localSheet.images[data.index].chartData = data;
      } else {
        let chart;
        if (this.localSheet.selectAreaCell.length) {
          chart = {
            left: this.localSheet.selectAreaCell[0].data.cellStyle.left,
            top: this.localSheet.selectAreaCell[0].data.cellStyle.top,
          }
        } else chart = {
          left: 0,
          top: 0,
        }

        let w = 500, h = 300;
        let d = {
          src: '',
          selecting: false,
          width: w,
          height: h,
          originWidth: w,
          originHeight: h,
          ...chart,
          key: new Date().valueOf(),
          // chartData:data,
          uuid: window.UUID(),
          type: 'Chart',
        };

        if (d.left + d.width > this.localSheet.col[this.localSheet.col.length - 1].size + this.localSheet.col[this.localSheet.col.length - 1].left) {
          d.left -= ((d.left + d.width) - (this.localSheet.col[this.localSheet.col.length - 1].size + this.localSheet.col[this.localSheet.col.length - 1].left));
        }
        if (d.top + d.height > this.localSheet.row[this.localSheet.row.length - 1].size + this.localSheet.row[this.localSheet.row.length - 1].top) {
          d.top -= ((d.top + d.height) - (this.localSheet.row[this.localSheet.row.length - 1].size + this.localSheet.row[this.localSheet.row.length - 1].top));
        }

        d = {...d, ...this.fromCoordinateGetCellAttr({left: d.left, top: d.top}),}
        // this.localSheet.images.push(d);
        this.$set(this.localSheet.images, this.localSheet.images.length, d);
        this.$set(this.localSheet.images[this.localSheet.images.length - 1], 'chartData', data);
        this.localSheet.select = {};
      }
      this.preUploadData();
    },

    //开启选择图表的标题源
    selectHead(data) {
      this.localSheet.headSelecting = data;
    },

    //开启选择图表的数据源
    selectSource(data) {
      this.localSheet.sourceSelecting = data;
    },

    //开启图表的数据区域选择
    totalSourceSelect(data) {
      this.localSheet.totalSourceSelecting = data;
    },

    //开启图表系列选择
    seriesSelect(data) {
      this.localSheet.seriesSelecting = data;
    },

    //开启智能选择框
    selectDot() {
      let e = event || window.event;

      this.localSheet.smartSelecting = true;
      this.localSheet.smartPos = {
        x: e.screenX,
        y: e.screenY
      }

      let sci = this.localSheet.selectAreaCell[0].ci, sri = this.localSheet.selectAreaCell[0].ri,
          eci = this.localSheet.selectAreaCell[this.localSheet.selectAreaCell.length - 1].ci,
          eri = this.localSheet.selectAreaCell[this.localSheet.selectAreaCell.length - 1].ri;

      if (this.localSheet.selectAreaCell[this.localSheet.selectAreaCell.length - 1].data.merge) {
        eci = this.localSheet.selectAreaCell[this.localSheet.selectAreaCell.length - 1].data.mergeCellRange.col.max;
        eri = this.localSheet.selectAreaCell[this.localSheet.selectAreaCell.length - 1].data.mergeCellRange.row.max;
      }

      this.localSheet.inputSelecting = true;
      this.localSheet.inputSelect = {
        start: {
          row: this.localSheet.row[sri].name,
          col: this.localSheet.col[sci].name,
        },
        end: {
          row: this.localSheet.row[eri].name,
          col: this.localSheet.col[eci].name,
        },
        selecting: true,
        special: true,
      }

      this.sheet && this.sheet.listenMoving();
    },

    //显示右键菜单contextmenu
    showContextmenu(data) {
      this.inputShow = false;
      this.$refs.cellInput.style.opacity = '0';
      this.$refs.cellInput.style.pointerEvents = 'none';
      this.$refs.cellInput.focus();
      this.saveCell();

      let flag = false, index;
      if (data.type === 'col') {
        index = this.findIndexInCol(data.name);
      } else if (data.type === 'row') {
        index = this.findIndexInRow(data.name);
      }

      if (this.localSheet.cellHeadSelect.start)
        flag = (index >= this.localSheet.cellHeadSelect.start.index && index <= this.localSheet.cellHeadSelect.end.index);

      if ((data.type === this.localSheet.cellHeadSelect.type && !flag) || data.type !== this.localSheet.cellHeadSelect.type) {
        this.localSheet.cellHeadSelect = {
          start: this.localSheet.nowCellHead,
          end: this.localSheet.nowCellHead,
          selecting: false,
          type: this.localSheet.nowCellHead.type,
        }
        this.cellHeadCalc();
      }

      this.contextmenu.type = data.type;
      this.contextmenu.name = data.name;
      this.contextmenu.left = data.x;
      this.contextmenu.top = data.y;
    },

    //关闭右键菜单contextmenu
    closeContextmenu() {
      this.contextmenu.type = null;
    },

    //选中CellHead
    cellHeadMousedown() {
      if (this.identifying_code)
        return false;

      if (!this.checkInputValue()) {
        return false;
      }

      this.cellHeadSP = true;
      this.inputShow = false;
      this.$refs.cellInput.style.opacity = '0';
      this.$refs.cellInput.style.pointerEvents = 'none';
      this.$refs.cellInput.focus();
      this.saveCell();
      this.localSheet.cellHeadSelect = {
        start: this.localSheet.nowCellHead,
        end: this.localSheet.nowCellHead,
        selecting: true,
        type: this.localSheet.nowCellHead.type,
      }
      this.cellHeadCalc();
      this.closeMenu();
    },

    //计算选中头部格子时的选框区域
    cellHeadCalc() {
      if (this.localSheet.nowCellHead.type == this.localSheet.cellHeadSelect.start.type) {
        if (this.localSheet.nowCell.timestamp > this.localSheet.nowCellHead.timestamp)
          this.localSheet.cellHeadSelect.end = this.localSheet.nowCell;
        else this.localSheet.cellHeadSelect.end = this.localSheet.nowCellHead;
      } else {
        this.localSheet.cellHeadSelect.end = this.localSheet.nowCell;
      }

      let start, end;
      if (this.localSheet.cellHeadSelect.start.type == 'col') {
        start = this.localSheet.map[this.localSheet.cellHeadSelect.start.name + '1'];
        if (this.localSheet.cellHeadSelect.end.type) {
          end = this.localSheet.map[this.localSheet.cellHeadSelect.end.name + this.localSheet.row.length];
        } else {
          end = this.localSheet.map[this.localSheet.cellHeadSelect.end.col + this.localSheet.row.length];
        }
      } else {
        start = this.localSheet.map[this.localSheet.col[0].name + this.localSheet.cellHeadSelect.start.name];
        if (this.localSheet.cellHeadSelect.end.type) {
          end = this.localSheet.map[this.localSheet.col[this.localSheet.col.length - 1].name + this.localSheet.cellHeadSelect.end.name];
        } else {
          end = this.localSheet.map[this.localSheet.col[this.localSheet.col.length - 1].name + this.localSheet.cellHeadSelect.end.row];
        }
      }

      this.localSheet.select = {
        start: start,
        end: end,
        selecting: this.localSheet.select.selecting,
      }
    },

    //获取鼠标所在行列格子的信息
    hoverCellHead(data) {
      this.localSheet.nowCellHead = data;
    },

    //计算输入框的宽高
    calcInputAttr() {
      this.localSheet.textAreaAttr = {
        left: this.localSheet.selectAreaAttr.left,
        top: this.localSheet.selectAreaAttr.top,
        rowSize: this.localSheet.selectAreaAttr.rowSize,
        colSize: this.localSheet.selectAreaAttr.colSize,
      }

      //分割输入的内容
      let texts = this.localSheet.inputShowValue.toString().split('\n');
      let innerHTML = '';
      texts.forEach(function (text, index) {
        // 根据情况进行组装
        if (index === 0) {
          // 0 行直接使用
          innerHTML += text
        } else if (!text.length) {
          // 空行则手动加换行符与临时计算内容
          innerHTML += '<br/><span>s</span>'
        } else {
          // 非空行则回车加内容
          innerHTML += ('<br/>' + text)
        }
      });

      this.localSheet.inputShowCalc = innerHTML;
      this.$nextTick(() => {
        let width = this.$refs.inputAttr.clientWidth + 5,
            height = this.$refs.inputAttr.clientHeight,
            isEmptyLine = !texts[texts.length - 1].length;

        //就只剩一行并且是空行，则需要使用默认匡高
        if (texts.length === 1 && isEmptyLine) {
          width = this.localSheet.textAreaAttr.colSize;
          height = this.localSheet.textAreaAttr.rowSize;
        }

        this.localSheet.textAreaAttr.colSize = Math.max(this.localSheet.textAreaAttr.colSize, width);
        this.localSheet.textAreaAttr.rowSize = Math.max(this.localSheet.textAreaAttr.rowSize, height);
      });
    },

    //输入框聚焦时，鼠标更改光标位置
    cursorChange(e) {
      this.localSheet.multipleInputSelect = [];
      this.selectionStart = e.target.selectionStart;
      this.selectionEnd = e.target.selectionEnd;
      if (this.localSheet.inputShowValue[0] == '=' && this.operators.findIndex((op) => op == this.localSheet.inputShowValue[this.selectionStart - 1]) !== -1) {
        this.localSheet.cellInputType = true;
        this.backupText = this.localSheet.inputShowValue;
      }

      this.selectionSP = null;
    },

    //修改单元格的输入框键盘按下时的事件
    cellInputKeydown(e) {
      this.selectionSP = null;
      if(!e.ctrlKey && !e.metaKey) {
        this.localSheet.multipleInputSelect = [];
      }
      this.selectionStart = e.target.selectionStart;
      this.selectionEnd = e.target.selectionEnd;
      if (e.key == 'ArrowLeft' || e.key == 'ArrowRight') {
        if (e.key == 'ArrowLeft') {
          this.selectionStart = this.selectionStart - 1 < 0 ? 0 : this.selectionStart - 1;
          this.selectionEnd = this.selectionStart;
        } else if (e.key == 'ArrowRight') {
          this.selectionStart = this.selectionStart + 1 > this.localSheet.inputShowValue.length ? this.localSheet.inputShowValue.length : this.selectionStart + 1;
          this.selectionEnd = this.selectionStart;
        }
        //当前选中的单元格内的内容第一个字符为'='并且当前光标所在位置的前一个字符是符号时，允许进行特殊选区并备份内容
        if (this.localSheet.inputShowValue[0] == '=' && this.operators.findIndex((op) => op == this.localSheet.inputShowValue[this.selectionStart - 1]) !== -1) {
          this.localSheet.cellInputType = true;
          this.backupText = this.localSheet.inputShowValue;
        }
      } else if (e.key == 'Enter' && !e.shiftKey) {
        if(this.selectRequirementAreaIndex) {
          this.selectRequirementAreaIndex = null;
          return;
        }

        e.preventDefault();
        try {
          // this.calcCellContent(this.localSheet.inputShowValue)
          this.saveCell('enter');
          this.localSheet.cellInputType = false;
          this.selectionSP = null;
          this.$refs.calculate.blur();
        } catch (e) {
          if (e.message === 'functionError' || e.message === 'contentError') {
            this.$toast('公式中存在错误，请及时修正', 'danger');
          }
        }
      } else if (e.key == 'Escape') {
        this.localSheet.inputShowValue = this.localSheet.map[this.localSheet.selectAreaCell[0].name].data.content;
        this.$refs.cellInput.style.opacity = '0';
        this.$refs.cellInput.style.pointerEvents = 'none';
        this.$refs.cellInput.focus();
        this.localSheet.inputShow = false;
        this.localSheet.calculate = false;
        this.localSheet.cellInputType = false;
        this.selectionSP = null;
        this.$refs.calculate.blur();
      }
    },

    //修改单元格的输入框内容发生变化时的事件
    cellInputChange(e) {
      let start = e.target.selectionStart, end = e.target.selectionEnd;
      this.selectionStart = start;
      this.selectionEnd = end;
      if (this.localSheet.inputShowValue[0] == '=' && this.operators.findIndex((op) => op == this.localSheet.inputShowValue[start - 1]) !== -1) {
        this.localSheet.cellInputType = true;
        this.backupText = this.localSheet.inputShowValue;
      } else if (this.localSheet.inputShowValue.length || this.localSheet.inputShowValue[0] !== '=')
        this.localSheet.cellInputType = false;
    },

    //按住滚动条后移动的事件
    scrollMove(e) {
      if (this.scrolling) {
        let changeX = e.pageX - this.scrollPoint.x, changeY = e.pageY - this.scrollPoint.y;
        if (this.scrollPoint.type == 'vertical') {
          // let newTop = this.$refs.rab.scrollTop + changeY / this.scrollbarHeight * this.cellAreaHeight;
          let newTop = (changeY + this.scrollbarAttr.offsetY) / this.scrollbarHeight * (this.localSheet.row[this.localSheet.row.length - 1].top + this.localSheet.row[this.localSheet.row.length - 1].size);
          if (this.localSheet.freezeRow.index) {
            if (newTop >= this.localSheet.freezeRow.scrollLimit) {
              this.$refs.rab.scrollTop = newTop;
            } else {
              this.$refs.rab.scrollTop = this.localSheet.freezeRow.scrollLimit;
            }
            this.localSheet.offsetTop = this.$refs.rab.scrollTop;
          } else {
            this.$refs.rab.scrollTop = newTop;
            this.localSheet.offsetTop = this.$refs.rab.scrollTop;
          }
        } else {
          // let newLeft = this.$refs.cab.scrollLeft + changeX / this.scrollbarWidth * this.cellAreaWidth;
          let newLeft = (changeX + this.scrollbarAttr.offsetX) / this.scrollbarWidth * (this.localSheet.col[this.localSheet.col.length - 1].left + this.localSheet.col[this.localSheet.col.length - 1].size);
          if (this.localSheet.freezeCol.index) {
            if (newLeft >= this.localSheet.freezeCol.scrollLimit) {
              this.$refs.cab.scrollLeft = newLeft;
            } else {
              this.$refs.cab.scrollLeft = this.localSheet.freezeCol.scrollLimit;
            }
            this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
          } else {
            this.$refs.cab.scrollLeft = newLeft;
            this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
          }
        }

        this.scrollPoint.x = e.pageX;
        this.scrollPoint.y = e.pageY;

        this.sheet.resetData(this.localSheet);
        // this.autoAddRC();
      }
    },

    //按下滚动条时的mousedown事件
    scrollbarMousedown(data) {
      this.scrolling = true;
      this.scrollPoint = {
        type: data.type,
        x: data.e.pageX,
        y: data.e.pageY,
      }
    },

    //检查是否需要自动添加新的行列
    autoAddRC() {
      if (!this.$route.params.identifying_code) {
        if (this.localSheet.offsetTop / this.localSheet.zoom + this.cellAreaHeight > this.localSheet.row[this.localSheet.row.length - 2].top) {
          addNewRow(this);
        }
        // else if(this.localSheet.offsetLeft/this.localSheet.zoom+this.cellAreaWidth>this.localSheet.col[this.localSheet.col.length-2].left){
        //   addNewCol(this);
        // }

        // this.sheet.resetData(this.localSheet);
      }
    },

    //表格滚轮事件
    wheel(type = null, e) {
      e.preventDefault();
      // console.log(e);

      if ((Math.abs(e.deltaX) >= 3 && !e.shiftKey) || (e.shiftKey && Math.abs(e.deltaY) >= 3)) {
        let left = 0;
        if (e.shiftKey) {
          let f = e.deltaY / Math.abs(e.deltaY);
          left = this.$refs.cab.scrollLeft + Math.min(Math.max(Math.abs(e.deltaY), 30), 80) * f;
        } else {
          let f = e.deltaX / Math.abs(e.deltaX);
          left = this.$refs.cab.scrollLeft + Math.min(Math.max(Math.abs(e.deltaX), 30), 80) * f;
        }
        if (this.localSheet.freezeCol.index) {
          if (left >= this.localSheet.freezeCol.scrollLimit) {
            this.$refs.cab.scrollLeft = left;
          } else {
            this.$refs.cab.scrollLeft = this.localSheet.freezeCol.scrollLimit;
          }
          this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
        } else {
          this.$refs.cab.scrollLeft = left;
          this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
        }
      }
      if (Math.abs(e.deltaY) >= 3 && !e.shiftKey) {
        let f = e.deltaY / Math.abs(e.deltaY);
        let top = this.$refs.rab.scrollTop + Math.min(Math.max(Math.abs(e.deltaY), 30), 60) * f;
        if (this.localSheet.freezeRow.index) {
          if (top >= this.localSheet.freezeRow.scrollLimit) {
            this.$refs.rab.scrollTop = top;
          } else {
            this.$refs.rab.scrollTop = this.localSheet.freezeRow.scrollLimit;
          }
          this.localSheet.offsetTop = this.$refs.rab.scrollTop;
        } else {
          this.$refs.rab.scrollTop = top;
          this.localSheet.offsetTop = this.$refs.rab.scrollTop;
        }
      }

      // this.autoAddRC();
      this.sheet.resetData(this.localSheet);
    },

    //冻结单元格
    freeze(data) {
      this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
      this.localSheet.freeze = !this.localSheet.freeze;
      if (data && this.localSheet.selectAreaCell.length && (this.findIndexInRow(this.localSheet.selectAreaCell[0].data.row) !== 0
          || this.findIndexInCol(this.localSheet.selectAreaCell[0].data.col) !== 0)) {
        //冻结时，第一个完整显示的单元格将定位于左上角
        let rowFlag = true, colFlag = true;
        for (let i = 0; i <= this.findIndexInCol(this.localSheet.selectAreaCell[0].data.col); i++) {
          for (let j = 0; j <= this.findIndexInRow(this.localSheet.selectAreaCell[0].data.row); j++) {
            if (this.localSheet.col[i].left > this.localSheet.offsetLeft && colFlag) {
              this.$refs.cab.scrollLeft = this.localSheet.col[i - 1 >= 0 ? i - 1 : i].left;
              this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
              colFlag = false;
            }
            if (this.localSheet.row[j].top > this.localSheet.offsetTop && rowFlag) {
              this.$refs.rab.scrollTop = this.localSheet.row[j - 1 >= 0 ? j - 1 : j].top;
              this.localSheet.offsetTop = this.$refs.rab.scrollTop;
              rowFlag = false;
            }
          }
        }
        this.localSheet.freezeRow = {
          index: this.findIndexInCol(this.localSheet.selectAreaCell[0].data.col),
          size: 0,
          scrollLimit: this.localSheet.offsetTop
        };
        this.localSheet.freezeCol = {
          index: this.findIndexInRow(this.localSheet.selectAreaCell[0].data.row),
          size: 0,
          scrollLimit: this.localSheet.offsetLeft
        };
        for (let i = 0; i < this.localSheet.freezeCol.index; i++) {
          this.localSheet.freezeCol.size += (this.localSheet.row[i].size);
        }
        for (let i = 0; i < this.localSheet.freezeRow.index; i++) {
          this.localSheet.freezeRow.size += (this.localSheet.col[i].size);
        }
      } else {
        this.localSheet.freezeRow = {index: 0, size: 0, scrollLimit: 0};
        this.localSheet.freezeCol = {index: 0, size: 0, scrollLimit: 0};
      }

      this.sheet.resetData(this.localSheet);
      this.localSheet.uploadData.freeze = this.localSheet.freeze;
      this.localSheet.uploadData.freezeCol = this.localSheet.freezeCol;
      this.localSheet.uploadData.freezeRow = this.localSheet.freezeRow;
      this.localSheet.uploadData.offsetLeft = this.localSheet.offsetLeft;
      this.localSheet.uploadData.offsetTop = this.localSheet.offsetTop;
      this.preUploadData();
    },

    //复制选区内容
    copy(type = 'copy') {
      if (this.localSheet.selectAreaCell.length && !this.localSheet.inputShow && !this.localSheet.calculate) {
        this.copyData = {
          index: this.sheetIndex,
          type: type,
          range: {
            row: {
              min: this.localSheet.selectAreaCell[0].ri,
              max: this.localSheet.selectAreaCell[this.localSheet.selectAreaCell.length - 1].ri,
            },
            col: {
              min: this.localSheet.selectAreaCell[0].ci,
              max: this.localSheet.selectAreaCell[this.localSheet.selectAreaCell.length - 1].ci,
            }
          }
        };

        this.copyAreaAttr = {...this.selectAreaAfterChange};
      }
    },

    //粘贴
    paste() {
      if (this.localSheet.selectAreaCell.length && this.copyData.index >= 0) {
        let rl = this.copyData.range.row.max - this.copyData.range.row.min,
            cl = this.copyData.range.col.max - this.copyData.range.col.min,
            r = this.localSheet.selectAreaCell[0].ri,
            c = this.localSheet.selectAreaCell[0].ci;

        for (let i = r; i <= r + rl; i++) {
          for (let j = c; j <= c + cl; j++) {
            if (j < this.localSheet.col.length && i < this.localSheet.row.length &&
                this.localSheet.map[this.localSheet.col[j].name + this.localSheet.row[i].name]
                && (this.localSheet.map[this.localSheet.col[j].name + this.localSheet.row[i].name].data.merge
                    || this.localSheet.map[this.localSheet.col[j].name + this.localSheet.row[i].name].data.mergeTo)) {
              this.$toast('无法对合并单元格执行此操作', 'danger');
              return false;
            }
          }
        }

        this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});

        this.pasteAction(r, c, rl, cl);

        if (this.copyData.type == 'cut')
          this.copyData = {};

        this.sheet.resetData(this.localSheet);
      } else if (this.localSheet.selectAreaCell.length) {
        this.pasteFromClipboard();
      }
    },

    async pasteFromClipboard() {
      try {
        const text = await navigator.clipboard.readText();
        this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
        this.localSheet.selectAreaCell[0].data.content = text;
        this.sheet.resetData(this.localSheet);
      } catch (err) {
        // alert('您的浏览器不支持此项功能');
        console.error('您的浏览器不支持此项功能', err);
      }
    },

    //粘贴数据
    pasteAction(r, c, rl, cl) {
      let sheet = this.deepClone(this.sheets[this.copyData.index]);
      for (let i = 0; i <= rl; i++) {
        for (let j = 0; j <= cl; j++) {
          if (i + r >= this.localSheet.row.length) {
            addNewRow(this, 1);
          }
          if (j + c >= this.localSheet.col.length) {
            addNewCol(this, 1);
          }

          let cell = this.localSheet.map[this.localSheet.col[c + j].name + this.localSheet.row[r + i].name],
              name = sheet.col[this.copyData.range.col.min + j].name + sheet.row[this.copyData.range.row.min + i].name;

          this.setCell(c + j, r + i, this.deepClone(sheet.map[name]))

          this.insertUploadData('cell', cell.name, cell, false);

          if (this.copyData.type == 'cut') {
            this.sheets[this.copyData.index].map[name].data.content = "";
            this.sheets[this.copyData.index].map[name].data.valid = true;
            this.sheets[this.copyData.index].map[name].data.inputShow = false;
            this.sheets[this.copyData.index].map[name].data.merge = false;
            this.sheets[this.copyData.index].map[name].data.mergeCell = null;
            this.sheets[this.copyData.index].map[name].data.mergeCellRange = null;
            this.sheets[this.copyData.index].map[name].data.mergeTo = null;
            this.sheets[this.copyData.index].map[name].data.cellType = {
              type: 'normal',
              extra: 0,
            };
            this.sheets[this.copyData.index].map[name].data.cellStyle = {
              width: this.sheets[this.copyData.index].map[name].data.cellStyle.width,
              height: this.sheets[this.copyData.index].map[name].data.cellStyle.height,
              left: this.sheets[this.copyData.index].map[name].data.cellStyle.left,
              top: this.sheets[this.copyData.index].map[name].data.cellStyle.top,
              bold: false,
              italic: false,
              underline: false,
              font: 'Microsoft YaHei',
              fontSize: 12,
              color: '333333',
              textAlign: 'left',
              verticalAlign: 'center',
              backgroundColor: 'none',
              borderLeftWidth: 0,
              borderLeftColor: 'e6e6e7',
              borderRightWidth: 0,
              borderRightColor: 'e6e6e7',
              borderTopWidth: 0,
              borderTopColor: 'e6e6e7',
              borderBottomWidth: 0,
              borderBottomColor: 'e6e6e7',
              zIndex: 5,
            };

            this.removeFromUploadData('cell', name, this.copyData.index, false);
          }
        }
      }

      this.preUploadData();
    },

    //setCell
    setCell(ci, ri, ncell) {
      let cell = this.localSheet.map[this.localSheet.col[ci].name + this.localSheet.row[ri].name];
      cell.data.cellType = {...ncell.data.cellType};
      cell.data.cellStyle = {...ncell.data.cellStyle};
      cell.data.cellStyle.left = this.localSheet.col[cell.ci].left;
      cell.data.cellStyle.width = this.localSheet.col[cell.ci].size;
      cell.data.cellStyle.top = this.localSheet.row[cell.ri].top;
      cell.data.cellStyle.height = this.localSheet.row[cell.ri].size;
      cell.data.content = ncell.data.content;
      cell.data.valid = ncell.data.valid;
      cell.data.merge = ncell.data.merge;
      if (ncell.data.merge) {
        let mrl = ncell.data.mergeCellRange.row.max - ncell.data.mergeCellRange.row.min,
            mcl = ncell.data.mergeCellRange.col.max - ncell.data.mergeCellRange.col.min;

        cell.data.mergeCellRange = {
          row: {
            min: cell.ri,
            max: cell.ri + mrl,
          },
          col: {
            min: cell.ci,
            max: cell.ci + mcl,
          }
        };

        let {rowSize, colSize} = this.fromRangeGetAttr(cell.ri, cell.ri + mrl, cell.ci, cell.ci + mcl);
        cell.data.cellStyle.width = colSize;
        cell.data.cellStyle.height = rowSize;

        this.localSheet.merges.push(this.localSheet.map[cell.name]);
        cell.data.mergeCell = cell.name + ':' + this.localSheet.col[cell.ci + mcl].name + this.localSheet.row[cell.ri + mrl].name;
        cell.data.mergeCellArray = [];
        for (let m = cell.ri; m <= cell.ri + mrl; m++) {
          for (let n = cell.ci; n <= cell.ci + mcl; n++) {
            cell.data.mergeCellArray.push(this.localSheet.col[n].name + this.localSheet.row[m].name);
            this.insertUploadData('cell', this.localSheet.col[n].name + this.localSheet.row[m].name, this.localSheet.map[this.localSheet.col[n].name + this.localSheet.row[m].name], false)
            if (this.localSheet.col[n].name + this.localSheet.row[m].name != cell.name) {
              this.localSheet.map[this.localSheet.col[n].name + this.localSheet.row[m].name].data.mergeTo = cell;
            }
          }
        }
      } else {

      }
    },

    //绑定键盘事件
    bindKeydownEvent() {
      if (!this.$route.params.identifying_code) {
        document.onkeydown = (e) => {
          // console.log(e);
          if(this.requirementModal) return;

          if ((e.ctrlKey || e.metaKey)) {
            if (e.keyCode == 67 && !this.localSheet.inputShow) {
              // ctrl + c
              this.copy();
            } else if (e.keyCode == 88 && !this.localSheet.inputShow) {
              // ctrl + x
              this.copy('cut');
            } else if (e.keyCode == 86 && !this.localSheet.inputShow) {
              // ctrl + v
              this.paste();
            } else if (e.keyCode == 90) {
              if (e.shiftKey) {
                // ctrl + shift + z
                this.redo();
              } else {
                // ctrl + z
                this.undo();
              }
            }
          } else {
            if (e.key == 'Delete' || e.key == 'Backspace') {
              //删除选中的图片
              if (this.localSheet.nowImage.index !== null) {
                this.deleteImage(this.localSheet.nowImage.index);
              } else if (!this.localSheet.inputShow && this.localSheet.selectAreaCell.length) {
                this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});

                for (let i in this.localSheet.selectAreaCell) {
                  this.localSheet.selectAreaCell[i].data.content = '';
                }
                this.localSheet.inputShowValue = '';

                this.sheet.resetData(this.localSheet);

                this.preUploadData();
              }
            } else if (((e.keyCode >= 65 && e.keyCode <= 90)
                    || (e.keyCode >= 48 && e.keyCode <= 57)
                    || (e.keyCode >= 96 && e.keyCode <= 105)
                    || e.key === '=' || e.keyCode === 229
                    || (e.keyCode >= 219 && e.keyCode <= 222)
                    || e.keyCode === 186 || e.keyCode === 188 || e.keyCode === 188 || e.keyCode === 190 || e.keyCode === 191)
                && !e.metaKey && !e.ctrlKey && !this.sizeChanging && !this.template.show
                && (!this.sideBox.sideBoxShow || this.sideBox.type == 'dataSource' || this.sideBox.type == 'requirement')
                && !this.dataSourceModalVisible && !this.requirementModal
            ) {
              if (this.localSheet.selectAreaCell.length && !this.localSheet.inputShow && !this.localSheet.calculate) {
                this.select = {
                  start: this.localSheet.selectAreaCell[0],
                  end: this.localSheet.selectAreaCell[0],
                  selecting: false,
                }
                this.localSheet.selectAreaCell = [this.localSheet.selectAreaCell[0]];
                // e.preventDefault();

                let area = this.calcCellArea(this.localSheet.selectAreaCell[0].ri, this.localSheet.selectAreaCell[0].ri, this.localSheet.selectAreaCell[0].ci, this.localSheet.selectAreaCell[0].ci);

                this.localSheet.selectAreaAttr = this.fromRangeGetAttr(area.minRow, area.maxRow, area.minCol, area.maxCol);
                this.showInput(this.localSheet.selectAreaCell[0].name);
                this.$refs.cellInput.style.opacity = '1';
                this.$refs.cellInput.style.pointerEvents = 'all';
                // this.$refs.cellInput.focus();
                this.localSheet.inputShow = true;
                this.localSheet.inputShowValue = '';
                if (e.key === '=') {
                  this.localSheet.cellInputType = true;
                  this.backupText = this.localSheet.inputShowValue;
                  this.selectionStart = 1;
                  this.selectionEnd = 1;
                }
              }
            } else if (e.key == 'Tab') {
              // tab
              if (this.localSheet.selectAreaCell.length) {
                e.preventDefault();
                let flag = false
                if (this.localSheet.inputShow) {
                  if (this.saveCell() === undefined) {
                    this.localSheet.cellInputType = false;
                    flag = true;
                  }
                }

                if (flag)
                  this.moveSelectArea('ArrowRight');
              }
            } else if (e.key == 'ArrowRight' || e.key == 'ArrowDown' || e.key == 'ArrowLeft' || e.key == 'ArrowUp') {
              if (!this.localSheet.inputShow && this.localSheet.selectAreaCell.length) {
                this.moveSelectArea(e.key);
              }
            } else if (e.key == 'Escape') {
              if (this.copyData.index >= 0) {
                this.copyData = {};
              }
            }
          }
        }
      }

      window.onresize = (e) => {
        this.resizeTable();
      }

    },

    //通过传入的区域计算实际选区区域，多用于有合并单元格的情况
    calcCellArea(sri, eri, sci, eci) {
      let minRow = sri, maxRow = eri, minCol = sci, maxCol = eci;
      for (let i = sci; i <= eci; i++) {
        for (let j = sri; j <= eri; j++) {
          let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
          if (!cell.data.valid) {
            minRow = Math.min(minRow, cell.data.mergeTo.data.mergeCellRange.row.min);
            maxRow = Math.max(maxRow, cell.data.mergeTo.data.mergeCellRange.row.max);
            minCol = Math.min(minCol, cell.data.mergeTo.data.mergeCellRange.col.min);
            maxCol = Math.max(maxCol, cell.data.mergeTo.data.mergeCellRange.col.max);
          }
          if (cell.data.merge) {
            minRow = Math.min(minRow, cell.data.mergeCellRange.row.min);
            maxRow = Math.max(maxRow, cell.data.mergeCellRange.row.max);
            minCol = Math.min(minCol, cell.data.mergeCellRange.col.min);
            maxCol = Math.max(maxCol, cell.data.mergeCellRange.col.max);
          }
        }
      }

      return {
        minRow,
        maxRow,
        minCol,
        maxCol,
      }
    },

    //克隆数据
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    //通过方向键移动选区
    moveSelectArea(type) {
      let nowCell = this.localSheet.selectAreaCell[0],
          ri = nowCell.ri,
          ci = nowCell.ci;
      if (type == 'ArrowRight') {
        if (ci + 1 < this.localSheet.col.length) {
          ci += 1;
        } else if (ri + 1 < this.localSheet.row.length) {
          ri += 1;
          ci = 0;
        }
      } else if (type == 'ArrowLeft') {
        if (ci - 1 >= 0) {
          ci -= 1;
        } else if (ri - 1 >= 0) {
          ri -= 1;
          ci = this.localSheet.col.length - 1;
        }
      } else if (type == 'ArrowUp') {
        if (ri - 1 >= 0) {
          ri -= 1;
        }
      } else if (type == 'ArrowDown') {
        if (ri + 1 < this.localSheet.row.length) {
          ri += 1;
        }
      }

      let name = this.localSheet.col[ci].name + this.localSheet.row[ri].name;
      this.localSheet.select = {
        start: this.localSheet.map[name],
        end: this.localSheet.map[name],
        selecting: false,
      }

      //选区移动到超出显示界面的格子上时会调整画布显示的区域
      if (this.cellAreaWidth - this.localSheet.map[name].data.cellStyle.width < this.localSheet.map[name].data.cellStyle.left - this.localSheet.offsetLeft / this.localSheet.zoom) {
        this.$refs.cab.scrollLeft = (this.localSheet.map[name].data.cellStyle.left - (this.cellAreaWidth - this.localSheet.map[name].data.cellStyle.width)) * this.localSheet.zoom;
        this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
      } else if (this.localSheet.map[name].data.cellStyle.left < this.localSheet.offsetLeft / this.localSheet.zoom) {
        this.$refs.cab.scrollLeft = (this.localSheet.map[name].data.cellStyle.left) * this.localSheet.zoom;
        this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
      }

      if (this.cellAreaHeight - this.localSheet.map[name].data.cellStyle.height < this.localSheet.map[name].data.cellStyle.top - this.localSheet.offsetTop / this.localSheet.zoom) {
        this.$refs.rab.scrollTop = (this.localSheet.map[name].data.cellStyle.top - (this.cellAreaHeight - this.localSheet.map[name].data.cellStyle.height)) * this.localSheet.zoom;
        this.localSheet.offsetTop = this.$refs.rab.scrollTop;
      } else if (this.localSheet.map[name].data.cellStyle.top < this.localSheet.offsetTop / this.localSheet.zoom) {
        this.$refs.rab.scrollTop = (this.localSheet.map[name].data.cellStyle.top) * this.localSheet.zoom;
        this.localSheet.offsetTop = this.$refs.rab.scrollTop;
      }

      this.sheet.resetData(this.localSheet);
    },

    //表格区域发生变化时执行的函数
    resizeTable() {
      this.$nextTick(() => {
        this.cellAreaWidth = this.$refs.cellAreaBox.offsetWidth;
        this.cellAreaHeight = this.$refs.cellAreaBox.offsetHeight;
        this.scrollbarWidth = this.$refs.scrollbarX.$el.offsetWidth;
        this.scrollbarHeight = this.$refs.scrollbarY.$el.offsetHeight;
        this.sheet.table.setWH(this.cellAreaWidth, this.cellAreaHeight);
        this.sheet.reload();
      });
    },

    //删除sheet
    deleteSheet(index) {
      if (this.sheets.length > 1) {
        this.sheets.splice(index, 1);
        if (this.sheetIndex == index)
          this.sheetIndex = (index === 0 ? 0 : index - 1);

        this.localSheet = this.sheets[this.sheetIndex];
        this.$refs.sheetBar.init();
        this.sheet.resetData(this.localSheet)
        this.preUploadData();
      }
    },

    //添加新的sheet
    addSheet() {
      newSheet(this);
      this.$refs.sheetBar.init();
    },

    //监听toolbar的selectFunction，将选中单元格内容改为所选的函数
    selectFunction(func) {
      if (this.localSheet.selectAreaCell.length) {
        // this.addUndo({index:this.sheetIndex,sheet:JSON.stringify(this.localSheet)});
        // this.localSheet.map[this.localSheet.selectAreaCell[0].name].data.content=func;
        // this.insertUploadData('cell',this.localSheet.selectAreaCell[0].name,this.localSheet.map[this.localSheet.selectAreaCell[0].name]);
        this.showInput(this.localSheet.selectAreaCell[0].name);
        this.localSheet.inputShowValue = func;
        this.localSheet.inputShowCalc = func;
        this.localSheet.cellInputType = true;
        this.backupText = this.localSheet.inputShowValue;
        this.$nextTick(() => {
          let pos = func.length - 1;
          this.selectionSP = [pos, pos]
          this.$refs.cellInput.setSelectionRange(pos, pos);
        })
      }
    },

    //停止当前的动作（行列调整大小、图片的移动、图片调整大小）
    stopAction() {
      this.localSheet.selecting = false;
      this.localSheet.inputSelecting = false;
      this.localSheet.resizing = false;
      this.localSheet.imageSelecting = false;
      this.localSheet.imageResizing = false;
      this.localSheet.cellHeadSelect.selecting = false;
    },

    //删除选中的图片
    deleteImage(index) {
      this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
      this.localSheet.images.splice(index, 1);
      this.localSheet.nowImage.index = null;
      this.preUploadData();
    },

    //修改图片大小时的mousedown事件
    resizeMousedown(data) {
      this.localSheet.imageResizing = true;
      this.localSheet.imageResizeType = data.type;
      this.localSheet.nowImage = {
        index: data.index,
        x: data.e.pageX,
        y: data.e.pageY,
      }
    },

    //拖拽图片大小时的mousedown事件
    imageMousedown(data) {
      this.localSheet.select = {};
      this.hideRenameInput();
      this.hideImageChoose();
      this.$refs.toolbar.closeDropdown();
      this.localSheet.imageSelecting = true;
      this.localSheet.images[data.index].selecting = true;
      this.localSheet.nowImage = {
        index: data.index,
        x: data.e.pageX,
        y: data.e.pageY,
      };
      // if (this.sideBox.sideBoxShow && this.sideBox.type == 'chart') {
      //   this.getChartData(true);
      // }
      if (this.localSheet.images[data.index].type == 'Chart') {
        this.getChartData(false);
      }
    },

    //重命名sheet
    renameSave(data) {
      if (data.value.length > 30) {
        this.$toast('分页名称长度不能超过30', 'danger');
        return false;
      } else if (data.value.length === 0) {
        this.$toast('分页名称长度不能为0', 'danger');
        return false;
      }

      let count = 0;
      for (let i in this.sheets) {
        if (this.sheets[i].name == data.value && i != data.index)
          count++;
      }

      if (count > 0) {
        this.$toast('分页名称已存在', 'danger');
        return false;
      }

      this.sheets[data.index].name = data.value;
      this.hideRenameInput('all');
      this.preUploadData();
    },

    //取消所有选中的图片
    hideImageChoose() {
      for (let i in this.localSheet.images) {
        this.localSheet.images[i].selecting = false;
      }
      this.localSheet.nowImage.index = null;
    },

    //隐藏重命名sheet的输入框
    hideRenameInput(type = null) {
      for (let i in this.sheets) {
        if (this.sheetIndex == i && this.sheets[i].sheetRename && !type)
          return false;
        this.sheets[i].sheetRename = false;
      }
    },

    //显示重命名sheet的输入框
    showRenameInput(index) {
      this.hideRenameInput();
      this.sheets[index].sheetRename = true;
    },

    //通过传入的图片的left，top值获取图片左上角所在格子的属性
    fromCoordinateGetCellAttr(coordinate) {
      let result = {
        cellName: '',
        offsetLeft: 0,
        offsetTop: 0,
      };

      let {left, top} = coordinate;
      for (let i in this.localSheet.map) {
        let cell = this.localSheet.map[i];
        if (left >= cell.data.cellStyle.left && left <= cell.data.cellStyle.left + cell.data.cellStyle.width
            && top >= cell.data.cellStyle.top && top <= cell.data.cellStyle.top + cell.data.cellStyle.height) {
          return result = {
            cellName: cell.name,
            offsetLeft: left - cell.data.cellStyle.left,
            offsetTop: top - cell.data.cellStyle.top,
          }
        }
      }
      this.sheet.resetData(this.localSheet);

      return result;
    },

    //插入图片
    insertImage(base64) {
      this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
      let image = {};
      if (this.localSheet.selectAreaCell.length) {
        image = {
          left: this.localSheet.selectAreaCell[0].data.cellStyle.left,
          top: this.localSheet.selectAreaCell[0].data.cellStyle.top,
        }
      } else image = {
        left: 0,
        top: 0,
      }

      let img = new Image();
      img.src = base64;
      img.onload = () => {
        let w = img.width, h = img.height;
        if (w >= h && w > 800) {
          w = 800;
          h = Math.floor(img.height / img.width * w);
        } else if (h > w && h > 600) {
          h = 600;
          w = Math.floor(img.width / img.height * h);
        }

        let src = null;
        this.uploadImage(base64).then(res => {
          // src = res.data.url;
          src = "./back-end/public/output/images/" + res.data.filePath;
          let data = {
            src,
            selecting: false,
            width: w,
            height: h,
            originWidth: img.width,
            originHeight: img.height,
            ...image,
            key: new Date().valueOf(),
            uuid: window.UUID(),
            type: 'InsertImage',
          };

          if (data.left + data.width > this.localSheet.col[this.localSheet.col.length - 1].size + this.localSheet.col[this.localSheet.col.length - 1].left) {
            data.left -= ((data.left + data.width) - (this.localSheet.col[this.localSheet.col.length - 1].size + this.localSheet.col[this.localSheet.col.length - 1].left));
          }
          if (data.top + data.height > this.localSheet.row[this.localSheet.row.length - 1].size + this.localSheet.row[this.localSheet.row.length - 1].top) {
            data.top -= ((data.top + data.height) - (this.localSheet.row[this.localSheet.row.length - 1].size + this.localSheet.row[this.localSheet.row.length - 1].top));
          }

          data = {...data, ...this.fromCoordinateGetCellAttr({left: data.left, top: data.top}),}

          this.localSheet.images.push(data);
          this.localSheet.select = {};
          this.preUploadData();
        }).catch(e => {
          this.$toast('上传图片失败', 'danger');
          this.undoList.pop();
        });
      }
    },

    //检查所有图片位置
    checkImagesPos() {
      for (let image of this.localSheet.images) {
        let result = this.fromCoordinateGetCellAttr({left: image.left, top: image.top});
        image.cellName = result.cellName;
        image.offsetLeft = result.offsetLeft;
        image.offsetTop = result.offsetTop;
      }
    },

    //上传图片
    async uploadImage(base64) {
      return await this.$http.post('image', {
        name: 'image' + this.localSheet.images.length,
        data: base64,
      });
    },

    //处理撤回需要保存的数据
    getUndoData(data) {
      let sheet = data;
      let d = {...sheet.uploadData};

      let len = this.getUploadRowColLength(d);
      d.rowLen = len.row;
      d.colLen = len.col;

      if (sheet.freeze) {
        d.freeze = sheet.freeze;
        d.freezeCol = {...sheet.freezeCol};
        d.freezeRow = {...sheet.freezeRow};
        d.offsetLeft = sheet.offsetLeft;
        d.offsetTop = sheet.offsetTop;
      }

      d.images = sheet.images;

      d.merges = sheet.merges;
      d.name = sheet.name;
      d.requirements = sheet.requirements;

      return d;
    },

    //每次操作都将会被记录
    addUndo(data) {
      // this.redoList = [];
      // if (this.undoList.length >= 20)
      //   this.undoList.shift();
      //
      // let d = this.getUndoData(JSON.parse(data.sheet));
      //
      // console.log(d);
      // this.undoList.push({index: data.index, sheet: d});

      this.redoList = [];
      if (this.undoList.length >= 20)
        this.undoList.shift();

      this.undoList.push(data);
    },

    //判断能否撤回
    canUndo() {
      return !this.localSheet.selecting && !this.localSheet.imageSelecting && !this.localSheet.imageResizing && !this.localSheet.inputSelecting && !this.localSheet.cellHeadSelect.selecting && !this.localSheet.resizing;
    },

    //undo 撤回操作
    undo() {
      if (this.canUndo() && this.undoList.length > 0) {
        //撤回操作部分需要重写 因为保存的数据改了 ！！！！！！！！
        this.redoList.push({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});

        let data = this.undoList.pop();
        this.sheets[data.index] = JSON.parse(data.sheet);
        this.selectSheet(data.index);
        this.stopAction();

        this.$refs.cellInput.style.opacity = '0';
        this.$refs.cellInput.style.pointerEvents = 'none';
        this.$refs.cellInput.focus();
        this.localSheet.inputShow = false;
        this.localSheet.calculate = false;
        if (this.localSheet.selectAreaCell.length)
          this.localSheet.inputShowValue = this.localSheet.selectAreaCell[0].data.content;
        else this.localSheet.inputShowValue = "";
        this.preUploadData();
      }
    },

    //redo 取消撤回
    redo() {
      if (this.canUndo() && this.redoList.length > 0) {
        this.undoList.push({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
        let data = this.redoList.pop();
        this.sheets[data.index] = JSON.parse(data.sheet);
        this.selectSheet(data.index);
        this.stopAction();

        this.$refs.cellInput.style.opacity = '0';
        this.$refs.cellInput.style.pointerEvents = 'none';
        this.$refs.cellInput.focus();
        this.localSheet.inputShow = false;
        this.localSheet.calculate = false;
        if (this.localSheet.selectAreaCell.length)
          this.localSheet.inputShowValue = this.localSheet.selectAreaCell[0].data.content;
        else this.localSheet.inputShowValue = "";
        this.preUploadData();
      }
    },

    //从uploadData中移除数据
    removeFromUploadData(type, name, index = null, normal = true) {
      if (index !== null)
        delete this.sheets[index].uploadData[type][name];
      else delete this.localSheet.uploadData[type][name];
      if (normal)
        this.preUploadData();
    },

    //添加数据进uploadData
    insertUploadData(type, key, value, normal = true) {
      if (this.localSheet.uploadData[type][key] !== undefined) {
        this.removeFromUploadData(type, key, null, false)
      }
      this.localSheet.uploadData[type][key] = value;
      if (normal)
        this.preUploadData();
    },

    //上传数据前的预处理(每次操作后都会保存变化的数据)
    preUploadData() {
      let storage = [];
      for (let i in this.sheets) {
        let sheet = this.sheets[i];
        let data = {...sheet.uploadData};

        let len = this.getUploadRowColLength(data);
        data.rowLen = len.row;
        data.colLen = len.col;

        if (sheet.freeze) {
          data.freeze = sheet.freeze;
          data.freezeCol = {...sheet.freezeCol};
          data.freezeRow = {...sheet.freezeRow};
          data.offsetLeft = sheet.offsetLeft;
          data.offsetTop = sheet.offsetTop;
        }

        data.images = sheet.images;

        data.merges = sheet.merges;
        data.name = sheet.name;
        data.requirements = sheet.requirements;

        storage.push(data);
      }

      let result = {
        storage: storage,
        pageMargins: this.pageMargins,
        pageSize: this.pageSize,
        printDirection: this.printDirection,
        datasets: this.datasets,
        printArea: this.printArea,
        fitToPage: this.fitToPage,
        // undoList:this.undoList,
        // redoList:this.redoList,
        // datasets:this.datasets,
      };
      if (this.template.id)
        result.template = this.template;
      localStorage.webExcel = JSON.stringify(result);

      this.autoSave();
    },

    //计算出需要上传的行列长度
    getUploadRowColLength(data) {
      let rowL = 99, colL = 49;
      for (let i in data.row) {
        if (i !== 'len')
          rowL = Math.max(rowL, data.row[i].index);
      }
      for (let i in data.col) {
        if (i !== 'len')
          colL = Math.max(colL, data.col[i].index);
      }
      for (let i in data.cell) {
        rowL = Math.max(rowL, data.cell[i].ri);
        colL = Math.max(colL, data.cell[i].ci);
      }

      return {
        row: Math.ceil(rowL / 50) * 50,
        col: Math.ceil(colL / 26) * 26,
      }
    },

    //显示保存模版的模态框
    saveExcel() {
      if (this.template.id) {
        this.templateForm.name = this.template.name;
        this.templateForm.description = this.template.description;
        this.templateForm.type = this.template.type;
        this.templateForm.tag = this.template.tag;
        this.templateForm.group_id = this.template.group_id;
        this.templateForm.status = this.template.status;
      } else {
        this.templateForm.name = '';
        this.templateForm.description = '';
        this.templateForm.type = 'year';
        this.templateForm.tag = [];
        this.templateForm.group_id = '';
      }
      this.template.show = true;
      this.$refs.templateSave.loading = false;
      this.$refs.templateSave.show();
    },

    //保存当前模版
    saveTemplate() {
      this.preUploadData();
      let data = JSON.parse(localStorage.webExcel);
      //处理图表
      for (let i in data.storage) {
        for (let j in data.storage[i].images) {
          if (data.storage[i].images[j].type == 'Chart')
            data.storage[i].images[j].src = this.$refs['image' + data.storage[i].images[j].uuid][0].getSrc();
        }
      }

      this.sheetIndex = 0;
      this.localSheet = this.sheets[this.sheetIndex];
      if (this.localSheet.freeze) {
        this.localSheet.offsetLeft = this.localSheet.freezeCol.scrollLimit;
        this.localSheet.offsetTop = this.localSheet.freezeRow.scrollLimit;
        this.$refs.cab.scrollLeft = this.localSheet.freezeCol.scrollLimit;
        this.$refs.rab.scrollTop = this.localSheet.freezeRow.scrollLimit;
      } else {
        this.localSheet.offsetLeft = 0;
        this.localSheet.offsetTop = 0;
        this.$refs.cab.scrollLeft = 0;
        this.$refs.rab.scrollTop = 0;
      }
      this.localSheet.select = {};
      this.localSheet.zoom = 1;
      this.sheet.resetData(this.localSheet);
      let promise = new Promise((resolve, reject) => {
        resolve();
      });
      this.$nextTick(() => {
        let thumbnail = '';
        //制作做略图
        promise = html2canvas(this.$refs.cellAreaBox).then((canvas) => {
          thumbnail = canvas.toDataURL();
          let url = 'template/', uploadData = {
            name: this.templateForm.name,
            description: this.templateForm.description,
            tag: this.templateForm.tag,
            group_id: this.templateForm.group_id,
            type: this.templateForm.type,
            status: this.templateForm.status,
            data: JSON.stringify(data),
            thumbnail: thumbnail,
          }
          if (this.template.id) {
            url += 'modify/' + this.template.id;
            uploadData.id = this.template.id;
          } else url += 'create';
          return this.$http.post(url, uploadData).then(res => {
            this.template.name = res.data.template.name;
            this.template.description = res.data.template.description;
            this.template.tag = res.data.template.tag;
            this.template.group_id = res.data.template.group_id;
            this.template.type = res.data.template.type;
            this.template.status = res.data.template.status;
            this.$root.templateType = this.template.type;
            this.preUploadData()
            this.$toast('保存成功');
            this.$refs.templateSave.hide();
            if (!this.template.id)
              this.$router.push({name: 'TemplateModify', params: {id: res.data.template.id}, replace: true})
          });
        });


      });

      return promise;
    },

    //切换sheet
    selectSheet(index) {
      if (!this.checkInputValue()) {
        return false;
      }
      this.sheetIndex = index;
      this.resetState();
      this.localSheet = this.sheets[index];
      this.$refs.rab.scrollTop = this.localSheet.offsetTop;
      this.$refs.cab.scrollLeft = this.localSheet.offsetLeft;
      this.sheet.resetData(this.localSheet);
    },

    //重置状态
    resetState() {
      this.hideImageChoose();
      this.hideRenameInput();
      this.closeMenu();
      this.inputShow = false;
    },

    //关闭菜单
    closeMenu(flag = true) {
      if (!this.localSheet.headSelecting && !this.localSheet.sourceSelecting && !this.localSheet.totalSourceSelecting && !this.localSheet.seriesSelecting && this.selectPrintAreaIndex === null && flag)
        this.$refs.toolbar.closeDropdown();
      this.$refs.sheetBar.sheetContextmenu();
      //关闭单元格的菜单
      this.closeContextmenu();
    },

    //所有在表格区域内的mousemove事件（如选框、调整行列大小、图片移动、图片调整大小）
    tableAreaMove(e) {
      if (this.localSheet.resizing) {
        let changeX = e.pageX - this.localSheet.resizeCoordinate.x,
            changeY = e.pageY - this.localSheet.resizeCoordinate.y;
        if (this.localSheet.resizeData.type == 'col') {
          let newX = this.localSheet.resizeData.left + changeX;
          if (newX + this.localSheet.resizeData.size < this.localSheet.col[this.findIndexInCol(this.localSheet.resizeData.name)].left + 10)
            return false;

          this.localSheet.resizeData.left = newX;

          this.localSheet.resizeCoordinate = {
            x: e.pageX,
            y: e.pageY,
          }
        } else {
          let newY = this.localSheet.resizeData.top + changeY;
          if (newY + this.localSheet.resizeData.size < this.localSheet.row[this.findIndexInRow(this.localSheet.resizeData.name)].top + 10)
            return false;

          this.localSheet.resizeData.top = newY;

          this.localSheet.resizeCoordinate = {
            x: e.pageX,
            y: e.pageY,
          }
        }
      } else if (this.localSheet.imageSelecting) {
        let changeX = e.pageX - this.localSheet.nowImage.x, changeY = e.pageY - this.localSheet.nowImage.y;

        //当图片位置发生变化时记录
        if (this.localSheet.imageChangeFlag && (changeX !== 0 || changeY !== 0)) {
          this.localSheet.imageChangeFlag = false;
          this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
        }

        let newX = this.localSheet.images[this.localSheet.nowImage.index].left + changeX,
            newY = this.localSheet.images[this.localSheet.nowImage.index].top + changeY;
        if (newX + this.localSheet.images[this.localSheet.nowImage.index].width <= this.localSheet.col[this.localSheet.col.length - 1].left + this.localSheet.col[this.localSheet.col.length - 1].size
            && newX >= 0)
          this.localSheet.images[this.localSheet.nowImage.index].left = newX;
        if (newY + this.localSheet.images[this.localSheet.nowImage.index].height <= this.localSheet.row[this.localSheet.row.length - 1].top + this.localSheet.row[this.localSheet.row.length - 1].size
            && newY >= 0)
          this.localSheet.images[this.localSheet.nowImage.index].top = newY;

        this.localSheet.nowImage.x = e.pageX;
        this.localSheet.nowImage.y = e.pageY;

        let {
          cellName,
          offsetLeft,
          offsetTop
        } = this.fromCoordinateGetCellAttr({
          left: this.localSheet.images[this.localSheet.nowImage.index].left,
          top: this.localSheet.images[this.localSheet.nowImage.index].top
        });

        this.localSheet.images[this.localSheet.nowImage.index].offsetLeft = offsetLeft;
        this.localSheet.images[this.localSheet.nowImage.index].offsetTop = offsetTop;
        this.localSheet.images[this.localSheet.nowImage.index].cellName = cellName;
      } else if (this.localSheet.imageResizing) {
        let x = this.localSheet.images[this.localSheet.nowImage.index].left,
            y = this.localSheet.images[this.localSheet.nowImage.index].top,
            w = this.localSheet.images[this.localSheet.nowImage.index].width,
            h = this.localSheet.images[this.localSheet.nowImage.index].height;
        let diffX = e.pageX - this.localSheet.nowImage.x,
            diffY = e.pageY - this.localSheet.nowImage.y;
        let type = this.localSheet.imageResizeType;

        if (this.localSheet.imageChangeFlag && (diffX !== 0 || diffY !== 0)) {
          this.localSheet.imageChangeFlag = false;
          this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
        }

        if (type == 'lt') {
          diffX *= -1;
        } else if (type == 'lb' || type == 'l') {
          x += diffX;
          diffX *= -1;
        }

        let newW = w, newH = h;
        if (type == 'l' || type == 'r') {
          newW = diffX + w;
        } else if (type == 'b') {
          newH = diffY + h;
        } else if (type == 't') {
          y += diffY;
          newH = -diffY + h;
        } else {
          newW = diffX + w;
          newH = Math.floor(this.localSheet.images[this.localSheet.nowImage.index].originHeight / this.localSheet.images[this.localSheet.nowImage.index].originWidth * newW);
          diffY = newH - h;
        }

        if (type == 'rt') {
          y -= diffY;
        } else if (type == 'lt') {
          x -= diffX;
          y -= diffY;
        }

        if (x < 0 || x + newW > this.localSheet.col[this.localSheet.col.length - 1].left + this.localSheet.col[this.localSheet.col.length - 1].size ||
            y < 0 || y + newH > this.localSheet.row[this.localSheet.row.length - 1].top + this.localSheet.row[this.localSheet.row.length - 1].size)
          return false;

        this.localSheet.images[this.localSheet.nowImage.index].width = newW;
        this.localSheet.images[this.localSheet.nowImage.index].height = newH;
        this.localSheet.images[this.localSheet.nowImage.index].left = x;
        this.localSheet.images[this.localSheet.nowImage.index].top = y;

        if (type == 'r' || type == 'l' || type == 't' || type == 'b') {
          this.localSheet.images[this.localSheet.nowImage.index].originHeight = newH
          this.localSheet.images[this.localSheet.nowImage.index].originWidth = newW;
        }

        this.localSheet.nowImage.x = e.pageX;
        this.localSheet.nowImage.y = e.pageY;
      } else if (this.localSheet.cellHeadSelect.selecting) {
        this.cellHeadCalc();
      }
    },

    //行列修改宽高的方法
    rowColResize(type, name, offset) {
      let index, mergeToArr = [], arg1, arg2, i1, i2,
          otherType = (type === 'col' ? 'row' : 'col');
      index = (type === 'col' ? this.findIndexInCol(name) : (type === 'row' ? this.findIndexInRow(name) : null));
      arg1 = (type === 'col' ? 'left' : 'top');
      arg2 = (type === 'col' ? 'width' : 'height');


      for (let i = index; i < this.localSheet[type].length; i++) {
        let os;
        if (i !== index) {
          this.localSheet[type][i][arg1] += offset;
          os = this.localSheet[type][i][arg1];
        } else {
          this.localSheet[type][index].size += offset;
          os = this.localSheet[type][index].size;
        }

        if (i > 0) {
          this.localSheet[type][i][arg1] = this.localSheet[type][i - 1][arg1] + this.localSheet[type][i - 1].size
        }

        for (let j = 0; j < this.localSheet[otherType].length; j++) {
          i1 = (type === 'col' ? i : j);
          i2 = (type === 'col' ? j : i);
          if (i === index) {
            if (this.localSheet.map[this.localSheet.col[i1].name + this.localSheet.row[i2].name].data.merge) {
              this.localSheet.map[this.localSheet.col[i1].name + this.localSheet.row[i2].name].data.cellStyle[arg2] += offset;
              calcMergeCellWH(this, this.localSheet.col[i1].name + this.localSheet.row[i2].name);
            } else {
              this.localSheet.map[this.localSheet.col[i1].name + this.localSheet.row[i2].name].data.cellStyle[arg2] = os;
            }
            if (!this.localSheet.map[this.localSheet.col[i1].name + this.localSheet.row[i2].name].data.valid) {
              let mergeCellName = this.localSheet.map[this.localSheet.col[i1].name + this.localSheet.row[i2].name].data.mergeTo.name;
              if (mergeToArr.findIndex((n) => n == mergeCellName) === -1) {
                this.localSheet.map[mergeCellName].data.cellStyle[arg2] += offset;
                mergeToArr.push(mergeCellName);
                calcMergeCellWH(this, mergeCellName);
              }
            }
          } else {
            this.localSheet.map[this.localSheet.col[i1].name + this.localSheet.row[i2].name].data.cellStyle[arg1] = os;
          }
        }
      }
    },

    //所有表格区域内的mouseup事件
    tableAreaEnd(e) {
      if (this.localSheet.selecting) {
        let se = [this.localSheet.select.start, this.localSheet.select.end];
        se.sort((a, b) => {
          if (a.ci == b.ci)
            return a.ri - b.ri;
          else return a.ci - b.ci;
        });

        this.localSheet.select.start = se[0];
        this.localSheet.select.end = se[1];

        this.localSheet.selecting = false;
      } else if (this.localSheet.inputSelecting) {
        if (this.localSheet.inputSelect.special) {
          //autofill
          autofill(this, e.shiftKey);
          this.localSheet.inputSelect.special = false;
        } else this.$refs.cellInput.focus();
      } else if (this.localSheet.resizing) {
        this.localSheet.resizing = false;
        if (this.localSheet.resizeData.type == 'col') {
          let index = this.findIndexInCol(this.localSheet.resizeData.name),
              offset = this.localSheet.resizeData.left - this.localSheet.col[index].left,
              mergeToArr = [];

          if (offset !== 0)
            this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});

          this.rowColResize(this.localSheet.resizeData.type, this.localSheet.resizeData.name, offset);

          this.insertUploadData('col', this.localSheet.resizeData.name, this.localSheet.col[index]);
        } else {
          let index = this.findIndexInRow(this.localSheet.resizeData.name),
              offset = this.localSheet.resizeData.top - this.localSheet.row[index].top,
              mergeToArr = [];

          if (offset !== 0)
            this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});

          this.rowColResize(this.localSheet.resizeData.type, this.localSheet.resizeData.name, offset);

          this.insertUploadData('row', this.localSheet.resizeData.name, this.localSheet.row[index]);
        }

        if (this.localSheet.selectAreaCell.length)
          this.localSheet.selectAreaAttr = this.fromRangeGetAttr(this.localSheet.rcRange.row.min, this.localSheet.rcRange.row.max, this.localSheet.rcRange.col.min, this.localSheet.rcRange.col.max);
        this.localSheet.multipleSelectAttr = [];
        for (let ms of this.localSheet.multipleSelect) {
          let minRow = Math.min(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
              maxRow = Math.max(this.findIndexInRow(ms.start.data.row), this.findIndexInRow(ms.end.data.row)),
              minCol = Math.min(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col)),
              maxCol = Math.max(this.findIndexInCol(ms.start.data.col), this.findIndexInCol(ms.end.data.col));

          for (let j = minRow; j <= maxRow; j++) {
            for (let i = minCol; i <= maxCol; i++) {
              let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
              if (!cell.data.valid) {
                minRow = Math.min(minRow, cell.data.mergeTo.data.mergeCellRange.row.min);
                maxRow = Math.max(maxRow, cell.data.mergeTo.data.mergeCellRange.row.max);
                minCol = Math.min(minCol, cell.data.mergeTo.data.mergeCellRange.col.min);
                maxCol = Math.max(maxCol, cell.data.mergeTo.data.mergeCellRange.col.max);
              }
              if (cell.data.merge) {
                minRow = Math.min(minRow, cell.data.mergeCellRange.row.min);
                maxRow = Math.max(maxRow, cell.data.mergeCellRange.row.max);
                minCol = Math.min(minCol, cell.data.mergeCellRange.col.min);
                maxCol = Math.max(maxCol, cell.data.mergeCellRange.col.max);
              }
            }
          }

          let rcRange = {
            row: {
              min: minRow,
              max: maxRow,
            },
            col: {
              min: minCol,
              max: maxCol,
            }
          }
          this.localSheet.multipleSelectAttr.push(this.fromRangeGetAttr(rcRange.row.min, rcRange.row.max, rcRange.col.min, rcRange.col.max));
        }
      } else if (this.localSheet.imageSelecting) {
        this.localSheet.imageSelecting = false;
        this.localSheet.imageChangeFlag = true;
        this.preUploadData();
      } else if (this.localSheet.imageResizing) {
        this.localSheet.imageResizing = false;
        this.localSheet.imageChangeFlag = true;
        this.preUploadData();
      } else if (this.localSheet.cellHeadSelect.selecting) {
        this.localSheet.cellHeadSelect.selecting = false;
      }

      this.localSheet.select.selecting = false;
      this.localSheet.inputSelecting = false;
      this.localSheet.inputSelect.selecting = false;

      this.sheet.resetData(this.localSheet);
    },

    //监听CellHead组件的resize事件的回调函数，记录调整大小的行或列的属性
    resize(data) {
      this.localSheet.resizeData = {
        type: data.type,
        ...data.data,
      };
      this.localSheet.resizeCoordinate = {
        x: data.e.pageX,
        y: data.e.pageY,
      }
      this.localSheet.resizing = true;
    },

    //合并单元格以及取消合并
    mergeCell() {
      if(this.localSheet.inputShowValue && this.saveCell() === false){
        return false;
      }
      this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
      if (this.localSheet.selectAreaCell.length == 1 && this.localSheet.selectAreaCell[0].data.merge) {
        let cellArr = [];
        for (let i in this.localSheet.selectAreaCell[0].data.mergeCellArray) {
          let name = this.localSheet.selectAreaCell[0].data.mergeCellArray[i];
          let cell = this.localSheet.map[name]
          cell.data.valid = true;
          cell.data.cellStyle.width = this.localSheet.col[cell.ci].size;
          cell.data.cellStyle.height = this.localSheet.row[cell.ri].size;
          cellArr.push(this.localSheet.map[name]);
          if (i > 0) {
            this.removeFromUploadData('cell', name, null, false);
            this.localSheet.map[name].data.mergeTo = null;
            this.localSheet.map[name].data.mergeCellArray = [];
          }
        }
        this.localSheet.selectAreaCell[0].data.merge = false;
        this.localSheet.selectAreaCell[0].data.cellStyle.width = this.localSheet.col[this.findIndexInCol(this.localSheet.selectAreaCell[0].data.col)].size;
        this.localSheet.selectAreaCell[0].data.cellStyle.height = this.localSheet.row[this.findIndexInRow(this.localSheet.selectAreaCell[0].data.row)].size;
        this.removeFromMerges(this.localSheet.selectAreaCell[0].name);
        this.localSheet.selectAreaCell = cellArr;
      } else {
        let {
          rowSize,
          colSize
        } = this.fromRangeGetAttr(this.localSheet.rcRange.row.min, this.localSheet.rcRange.row.max, this.localSheet.rcRange.col.min, this.localSheet.rcRange.col.max);

        this.localSheet.selectAreaCell[0].data.merge = true;
        this.localSheet.selectAreaCell[0].data.mergeCell =
            this.localSheet.col[this.localSheet.rcRange.col.min].name +
            this.localSheet.row[this.localSheet.rcRange.row.min].name +
            ':' +
            this.localSheet.col[this.localSheet.rcRange.col.max].name +
            this.localSheet.row[this.localSheet.rcRange.row.max].name;
        this.localSheet.selectAreaCell[0].data.mergeCellRange = {...this.localSheet.rcRange};
        this.localSheet.selectAreaCell[0].data.mergeCellArray = [];
        this.localSheet.selectAreaCell[0].data.cellStyle.width = colSize;
        this.localSheet.selectAreaCell[0].data.cellStyle.height = rowSize;

        for (let j = this.localSheet.rcRange.row.min; j <= this.localSheet.rcRange.row.max; j++) {
          for (let i = this.localSheet.rcRange.col.min; i <= this.localSheet.rcRange.col.max; i++) {
            if (this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.merge) {
              // && (i !== this.localSheet.rcRange.col.min || j !== this.localSheet.rcRange.row.min)) {
              this.removeFromMerges(this.localSheet.col[i].name + this.localSheet.row[j].name);
            }
            this.localSheet.selectAreaCell[0].data.mergeCellArray.push(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].name);
            if (i !== this.localSheet.rcRange.col.min || j !== this.localSheet.rcRange.row.min) {
              let cell = this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name];
              cell.data.content = "";
              cell.data.valid = false;
              cell.data.merge = false;
              cell.data.mergeCell = null;
              cell.data.mergeCellRange = null;
              cell.data.mergeTo = this.localSheet.selectAreaCell[0];
              cell.data.cellType = {
                type: 'normal',
                extra: 0,
              };
              cell.data.cellStyle = {
                width: cell.data.cellStyle.width,
                height: cell.data.cellStyle.height,
                left: cell.data.cellStyle.left,
                top: cell.data.cellStyle.top,
                bold: false,
                italic: false,
                underline: false,
                font: 'Microsoft YaHei',
                fontSize: 12,
                color: '333333',
                textAlign: 'left',
                verticalAlign: 'center',
                backgroundColor: 'none',
                borderLeftWidth: 0,
                borderLeftColor: 'e6e6e7',
                borderRightWidth: 0,
                borderRightColor: 'e6e6e7',
                borderTopWidth: 0,
                borderTopColor: 'e6e6e7',
                borderBottomWidth: 0,
                borderBottomColor: 'e6e6e7',
                zIndex: 5,
              };
            }
          }
        }

        this.localSheet.merges.push(this.localSheet.map[this.localSheet.selectAreaCell[0].name]);
        // console.log({...this.localSheet.merges});
        for (let i in this.localSheet.selectAreaCell) {
          this.insertUploadData('cell', this.localSheet.selectAreaCell[i].name, this.localSheet.selectAreaCell[i], false);
        }
        this.localSheet.selectAreaCell = [this.localSheet.selectAreaCell[0]];
      }

      this.preUploadData();

      this.sheet.resetData(this.localSheet);
    },

    //从记录合并单元格的数组中移除
    removeFromMerges(name) {
      let index = this.localSheet.merges.findIndex((m) => {
        return m.name == name;
      });
      this.localSheet.merges.splice(index, 1);
    },

    //通过最大最小行列获得选中区域的left,top,width,height
    fromRangeGetAttr(minRow, maxRow, minCol, maxCol) {
      let rowSize = 0, colSize = 0;
      for (let j = minRow; j <= maxRow; j++) {
        rowSize += (this.localSheet.row[j].size);
      }
      for (let j = minCol; j <= maxCol; j++) {
        colSize += (this.localSheet.col[j].size);
      }

      return {
        rowSize: rowSize,
        colSize: colSize,
        left: this.localSheet.col[minCol].left - 2 / this.localSheet.zoom,
        top: this.localSheet.row[minRow].top - 2 / this.localSheet.zoom,
      }
    },

    //设置边框样式
    setBorder(border) {
      this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
      _border.setBorder(border.type, border.color, border.style, this.localSheet.selectAreaCell, this.localSheet.map, this.localSheet.row, this.localSheet.col);
      this.sheet.resetData(this.localSheet);
      for (let i in this.localSheet.selectAreaCell) {
        this.insertUploadData('cell', this.localSheet.selectAreaCell[i].name, this.localSheet.selectAreaCell[i], false);
      }

      this.preUploadData();
    },

    //获取传入的字符串中所包含的单元格并生成选区
    calcContentIncludeCell(val) {
      this.localSheet.dashSelectArea = [];
      if (val[0] === '=') {
        let participleArray = participle(val.slice(1));
        for (let i in participleArray) {
          let name = participleArray[i];
          if (this.localSheet.map[name]) {
            this.localSheet.dashSelectArea.push({
              name: name,
              attr: {
                rowSize: this.localSheet.map[name].data.cellStyle.height,
                colSize: this.localSheet.map[name].data.cellStyle.width,
                left: this.localSheet.map[name].data.cellStyle.left - 2 / this.localSheet.zoom,
                top: this.localSheet.map[name].data.cellStyle.top - 2 / this.localSheet.zoom,
              }
            });
          } else if (name.split(':').length == 2 && this.localSheet.map[name.split(':')[0]] && this.localSheet.map[name.split(':')[1]]) {
            this.createSelectAreaByStr(name);
          }
        }
      }
    },

    //通过传入的选区字符串生成选区
    createSelectAreaByStr(name, type = null) {
      let start, end;
      if (Array.isArray(name)) {
        if (type == 'source' || type == 'head' || type == 'series') {
          this.localSheet.dashSelectArea = this.localSheet.dashSelectArea.filter(a => a.type != type);
        }
        for (let n of name) {
          start = this.localSheet.map[n.split(':')[0]];
          end = this.localSheet.map[n.split(':')[1]];

          let minRow = Math.min(start.ri, end.ri), maxRow = Math.max(start.ri, end.ri),
              minCol = Math.min(start.ci, end.ci),
              maxCol = Math.max(start.ci, end.ci);
          let attr = this.fromRangeGetAttr(minRow, maxRow, minCol, maxCol);
          this.localSheet.dashSelectArea.push({name, attr, type});
        }
      } else {
        if (name.split(':').length > 1 && this.localSheet.map[name.split(':')[0]] && this.localSheet.map[name.split(':')[1]]) {
          start = this.localSheet.map[name.split(':')[0]];
          end = this.localSheet.map[name.split(':')[1]];
          let minRow = Math.min(start.ri, end.ri), maxRow = Math.max(start.ri, end.ri),
              minCol = Math.min(start.ci, end.ci),
              maxCol = Math.max(start.ci, end.ci);
          let attr = this.fromRangeGetAttr(minRow, maxRow, minCol, maxCol);
          if (type == 'source' || type == 'head' || type == 'series') {
            let index = this.localSheet.dashSelectArea.findIndex((a) => a.type == type);
            if (index !== -1) {
              this.localSheet.dashSelectArea[index] = {name, attr, type};
            } else {
              this.localSheet.dashSelectArea.push({name, attr, type});
            }
          } else {
            this.localSheet.dashSelectArea.push({name, attr});
          }
        }
      }
    },

    //显示修改单元格内容的输入框
    showInput(name, option = true) {
      if (this.localSheet.sourceSelecting || this.localSheet.headSelecting || this.localSheet.totalSourceSelecting || this.localSheet.seriesSelecting) return false;
      if (option) {
        this.localSheet.inputShowValue = this.localSheet.map[name].data.content;
        this.localSheet.inputShowCalc = this.localSheet.map[name].data.content;
      }
      this.localSheet.selecting = false;
      this.localSheet.inputSelecting = false;
      this.localSheet.smartSelecting = false;
      this.backupText = this.localSheet.inputShowValue;
      this.localSheet.inputCellName = name;

      this.$refs.cellInput.style.opacity = '1';
      this.$refs.cellInput.style.pointerEvents = 'all';
      this.localSheet.inputShow = true;
      this.localSheet.calculate = true;
      this.localSheet.inputSaveFlag = true;
      this.copyData = {};
      this.selectionStart = this.localSheet.inputShowValue.length;
      this.selectionEnd = this.localSheet.inputShowValue.length;
      if (this.operators.findIndex(op => op == this.localSheet.inputShowValue[this.localSheet.inputShowValue.length - 1]) !== -1 && this.localSheet.inputShowValue[0] == '=') {
        this.localSheet.cellInputType = true;
      } else this.localSheet.cellInputType = false;
      this.calcInputAttr();
      this.$nextTick(() => {
        if (option)
          this.$refs.cellInput.focus();
      });

      this.calcContentIncludeCell(this.localSheet.map[name].data.content);

      let cell = this.localSheet.map[this.localSheet.selectAreaCell[0].name];
      if (cell.data.cellStyle.left <= this.localSheet.offsetLeft / this.localSheet.zoom + this.localSheet.freezeRow.size - this.localSheet.freezeCol.scrollLimit
          && cell.data.cellStyle.left >= this.localSheet.freezeRow.size) {
        this.$refs.cab.scrollLeft = (cell.data.cellStyle.left - (this.localSheet.freezeRow.size - this.localSheet.freezeCol.scrollLimit)) * this.localSheet.zoom;
        this.localSheet.offsetLeft = this.$refs.cab.scrollLeft;
      }
      if (cell.data.cellStyle.top <= this.localSheet.offsetTop / this.localSheet.zoom + this.localSheet.freezeCol.size - this.localSheet.freezeRow.scrollLimit
          && cell.data.cellStyle.top >= this.localSheet.freezeCol.size) {
        this.$refs.rab.scrollTop = (cell.data.cellStyle.top - (this.localSheet.freezeCol.size - this.localSheet.freezeRow.scrollLimit)) * this.localSheet.zoom;
        this.localSheet.offsetTop = this.$refs.rab.scrollTop;
      }

      this.sheet.resetData(this.localSheet);
    },

    //监听toolbar函数的saveStyle事件的回调函数，修改单元格样式
    saveStyle(data) {
      if (this.localSheet.selectAreaCell.length) {
        this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
        for (let i in this.localSheet.selectAreaCell) {
          if (data.type == 'cellType') {
            this.localSheet.map[this.localSheet.selectAreaCell[i].name].data.cellType = {...data.value};
            if (data.value.type == 'date' || data.value.type == 'time') {
              this.localSheet.map[this.localSheet.selectAreaCell[i].name].data.content = this.valueFormat(this.localSheet.selectAreaCell[i].name);
            }
          } else {
            this.localSheet.map[this.localSheet.selectAreaCell[i].name].data.cellStyle[data.type] = data.value;
          }

          this.insertUploadData('cell', this.localSheet.selectAreaCell[i].name, this.localSheet.map[this.localSheet.selectAreaCell[i].name], false);
        }

        this.preUploadData();
        this.localSheet.inputShowValue = this.localSheet.selectAreaCell[0].data.content;
        this.sheet.resetData(this.localSheet);
      }
    },

    //通过列名从列的数组中找到对应数据
    findIndexInCol(col) {
      return this.localSheet.col.findIndex((c) => {
        return col == c.name;
      });
    },

    //通过行名从行的数组中找到对应数据
    findIndexInRow(row) {
      return this.localSheet.row.findIndex((r) => {
        return row == r.name;
      });
    },

    //单元格修改保存按钮
    calcOk() {
      this.saveCell();
    },

    checkInputValue() {
      let result = false;
      try {
        this.calcCellContent(this.localSheet.inputShowValue)
        result = true;
      } catch (e) {
        if (e.message === 'functionError' || e.message === 'contentError') {
          this.$toast('公式中存在错误，请及时修正', 'danger');
        }
      }

      return result;
    },

    //输入框失焦时触发此事件，保存输入的内容
    saveCell(type = 'normal') {
      if (!this.localSheet.cancelCellSave) {
        try {
          this.calcCellContent(this.localSheet.inputShowValue)
        } catch (e) {
          if (e.message === 'functionError' || e.message === 'contentError') {
            this.$toast('公式中存在错误，请及时修正', 'danger');
            return false;
          }
        }
        if ((!this.localSheet.cellInputType || type === 'enter') && this.localSheet.inputCellName) {
          if (this.localSheet.map[this.localSheet.inputCellName].data.content !== this.localSheet.inputShowValue) {
            this.addUndo({index: this.sheetIndex, sheet: JSON.stringify(this.localSheet)});
            // if (!this.localSheet.calculate) {
            this.$refs.cellInput.style.opacity = '0';
            this.$refs.cellInput.style.pointerEvents = 'none';
            this.$refs.cellInput.focus();
            this.localSheet.inputShow = false;
            // }
            this.localSheet.calculate = false;
            this.localSheet.inputSaveFlag = false;
            this.localSheet.map[this.localSheet.inputCellName].data.content = this.localSheet.inputShowValue;
            this.insertUploadData('cell', this.localSheet.inputCellName, this.localSheet.map[this.localSheet.inputCellName]);
            this.sheet.resetData(this.localSheet);
          } else {
            this.$refs.cellInput.style.opacity = '0';
            this.$refs.cellInput.style.pointerEvents = 'none';
            this.$refs.cellInput.focus();
            this.localSheet.inputShow = false;
            this.localSheet.calculate = false;
            this.localSheet.inputSaveFlag = false;
          }
        } else this.localSheet.cellInputType = false;
      } else {
        this.localSheet.inputSaveFlag = false;
        this.localSheet.cancelCellSave = false;

        this.$refs.cellInput.style.opacity = '0';
        this.$refs.cellInput.style.pointerEvents = 'none';
        this.$refs.cellInput.focus();
        this.localSheet.inputShow = false;
        this.localSheet.calculate = false;
        this.localSheet.inputShowValue = this.localSheet.map[this.localSheet.inputCellName].data.content;
      }
    },

    valueFormat(cellName) {
      if (cellName == this.localSheet.inputShowName)
        return false;
      let data = this.localSheet.map[cellName].data;
      let type = data.cellType.type;

      let value = this.calcCellContent(data.content);

      if (type == 'text')
        value = data.content;
      else if (type == 'number' && !isNaN(Number(value)) && Number(value) != '')
        eval(`value = (${Number(value)}).toFixed(data.cellType.extra);`)
      else if (type == 'date')
        value = this.dateFormat(value, cellName);
      else if (type == 'time')
        value = this.timeFormat(value, cellName);

      return value;
    },
    dateFormat(value, cellName) {
      let data;

      if (value == '')
        data = '';
      else if (Number(value) == 0) {
        data = '1990-01-00'
        this.localSheet.map[cellName].data.content = data;
      } else if (Number(value) < 0)
        data = Array(99).fill('#').join('');
      else if (Number(value) > 0) {
        data = moment('1990-01-01').add(Number(value) - 1, 'days').format('YYYY/MM/DD');
        if (this.dateOverstep(data)) data = Array(99).fill('#').join('');
        else this.localSheet.map[cellName].data.content = data;
      } else if (moment(value, ['H:m:s'], true).isValid())
        data = '1990-01-00'
      else if (moment(value, ['YYYY/M/D', 'YYYY-M-D', 'YYYY/M/D H:m:s', 'YYYY-M-D H:m:s'], true).isValid()) {
        data = moment(value, ['YYYY/M/D', 'YYYY-M-D', 'YYYY/M/D H:m:s', 'YYYY-M-D H:m:s']).format('YYYY/MM/DD')
        this.localSheet.map[cellName].data.content = data;
      } else
        data = value;

      return data;
    },
    dateOverstep(value) {
      if (moment(value).year() >= 10000) return true
      return false
    },
    timeFormat(value, cellName) {
      let data;

      if (value == '')
        data = '';
      else if (Number(value) == 0) {
        data = '12:00:00';
        this.localSheet.map[cellName].data.content = data;
      } else if (Number(value) < 0)
        data = Array(99).fill('#').join('');
      else if (Number(value) > 0) {
        data = moment('1990-01-01 00:00:00').add({
          days: Math.floor(Number(value)),
          seconds: Number(value) % 1 * 86400
        }).format('HH:mm:ss');
        if (this.dateOverstep(data)) data = Array(99).fill('#').join('');
        else this.localSheet.map[cellName].data.content = data;
      } else if (moment(value, ['YYYY/M/D', 'YYYY-M-D', 'YYYY/M/D H:m:s', 'YYYY-M-D H:m:s', 'H:m:s'], true).isValid()) {
        data = moment(value, ['YYYY/M/D', 'YYYY-M-D', 'YYYY/M/D H:m:s', 'YYYY-M-D H:m:s', 'H:m:s']).format('HH:mm:ss')
        this.localSheet.map[cellName].data.content = data;
      } else
        data = value;


      return data;
    },

    //将rpn数组内的指定单元格数据替换，times为递归执行次数
    transformParam(rpn, times) {
      let result = [];
      for (let i in rpn.output) {
        if (rpn.output[i].split(':').length == 2) {
          let arr = [];
          for (let name of rpn.output[i].split(':')) {
            if (!this.localSheet.map[name]) {
              result.push(rpn.output[i]);
              break;
            } else {
              arr.push(this.localSheet.map[name]);
            }
          }

          if (arr.length === rpn.output[i].split(':').length) {
            let row1 = arr[0].data.row,
                col1 = arr[0].data.col,
                row2 = arr[1].data.row,
                col2 = arr[1].data.col,
                values = [];

            for (let i = Math.min(this.findIndexInCol(col1), this.findIndexInCol(col2)); i <= Math.max(this.findIndexInCol(col1), this.findIndexInCol(col2)); i++) {
              for (let j = Math.min(this.findIndexInRow(row1), this.findIndexInRow(row2)); j <= Math.max(this.findIndexInRow(row1), this.findIndexInRow(row2)); j++) {
                let number = this.calcCellContent(this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.content, times);
                if (number !== false && this.localSheet.map[this.localSheet.col[i].name + this.localSheet.row[j].name].data.valid)
                  values.push(number);
                else return false;
              }
            }

            result.push(values);
          }
        } else if (this.localSheet.map[rpn.output[i]]) {
          let number = this.calcCellContent(this.localSheet.map[rpn.output[i]].data.content, times);
          if (number !== false)
            result.push(number);
          else return false;
        } else {
          result.push(rpn.output[i]);
        }
      }

      return {
        output: result,
        args: rpn.args,
      };
    },

    //计算单元格内的表达式，times为递归次数
    calcCellContent(val, times = 0) {
      let result;
      times++;
      if (times > 1000)
        return false;
      if (val !== null && val !== undefined && val[0] === '=') {
        try {
          //分割字符串
          let participleArray = participle(val.slice(1));
          //验证表达式是否存在错误
          let validate = this.validateContent(participleArray);
          if (!validate)
            throw new Error('contentError')
          //中缀转后缀
          let rpn = infixToPostFix(participleArray);
          let afterTransform = this.transformParam(rpn, times);
          if (afterTransform !== false)
              //计算后缀表达式
            result = calcPostfix(afterTransform);
          else return false;
        } catch (e) {
          if (e.message === 'functionError') {
            throw new Error('functionError')
          } else if (e.message === 'contentError') {
            throw new Error('contentError')
          }
          // console.log('error');
          result = '#VALUE';
        }
      } else if (val !== null && val !== undefined && val == '__DATE__') {
        result = '#生成日期';
      } else if (val !== null && val !== undefined && val[0] === '_') {
        //数据源
        // result = '#数据源-' + val.slice(1);
        let dataset = this.getDatasetByParam(val.slice(1));
        if (dataset)
          result = '#数据源-' + (dataset.source.preData.name ? dataset.source.preData.name : dataset.param);
        else result = val;
      } else {
        result = val;
      }

      return result;
    },

    validateContent(content) {
      let c = [...content]
      if (this.operators.findIndex((o) => o === c[c.length - 1]) !== -1)
        return false;
      return c.every((element, index, array) => {
        if (index === 0) {
          if (this.operators.findIndex((o) => o === element) === -1)
            return true;
          else return false;
        } else {
          let i1 = this.operators.findIndex((o) => o === array[index - 1]),
              i2 = this.operators.findIndex((o) => o === element);
          if (element === '(' && array[index - 1] === '(')
            return true;
          else if (i1 !== -1 && element === '(')
            return true;
          else if (i1 !== -1 && element === ')')
            return false;
          else if (i1 !== -1 && i2 !== -1)
            return false;
          else return true;
        }
      });
    },

    //通过param获取对应的dataset
    getDatasetByParam(param) {
      return this.datasets.find(d => {
        return d.param == param;
      });
    },

    //获取历史记录
    getHistory(id) {
      return this.$http.get('history/list/' + id).then(res => {
        this.histories = res.data;
        return res.data;
      });
    },

    //初始化数据
    init(data) {
      for (let i in data.storage) {
        newSheet(this, data.storage[i]);
      }
      this.pageSize = data.pageSize;
      this.printDirection = data.printDirection;
      this.pageMargins = data.pageMargins;
      this.datasets = data.datasets ?? [];
      this.fitToPage = data.fitToPage ?? {
        portraitCenter: false,
        landscapeCenter: false,
        value: false,
        width: 1,
        height: 1
      };
      this.printArea = data.printArea ?? '';
    },

    getDataTableTree() {
      this.$http.get('uiinterface?reqType=GetTableTree').then(res => {
        this.dataTableTree = res.data === '' ? {} : res.data;
      });
    },
    getGroup() {
      this.groups = [];
      this.$http.post('group/list').then((res) => {
        this.groups = res.data.data;
      }).catch(() => {
      });
    },
  },
  mounted() {
    this.cellAreaWidth = this.$refs.cellAreaBox.offsetWidth;
    this.cellAreaHeight = this.$refs.cellAreaBox.offsetHeight;
    this.scrollbarWidth = this.$refs.scrollbarX.$el.offsetWidth;
    this.scrollbarHeight = this.$refs.scrollbarY.$el.offsetHeight;
    this.sheet = new Sheet(this.$refs.canvas, this.localSheet, this.cellAreaWidth, this.cellAreaHeight, this);

  },
  created() {
    this.getGroup();
    this.getDataTableTree();
    this.loading = true;
    for (let i = 0; i < 1; i++) {
      newSheet(this);
    }

    if (this.$route.params.identifying_code) {
      this.identifying_code = this.$route.params.identifying_code;
      this.$http.get('history/info/' + this.identifying_code).then(res => {
        this.sheets = [];
        let data = JSON.parse(res.data.data);
        this.init(data);

        this.sideBox = {
          sideBoxTitle: '修改记录',
          type: 'history',
          sideBoxShow: true,
        };

        this.localSheet = this.sheets[this.sheetIndex];
        this.sheet && this.sheet.resetData(this.localSheet);

        return res.data.template_id;
      }).then((id) => {
        this.getHistory(id);
      }).finally(() => {
        this.loading = false;
      });
    } else if (localStorage.webExcel && JSON.parse(localStorage.webExcel).template) {
      this.sheets = [];
      let data = JSON.parse(localStorage.webExcel);
      this.init(data);
      this.template = data.template;
      this.$root.templateType = this.template.type;
      this.$EventBus.$emit('templateName', this.template.name);
      this.loading = false;
    } else if (this.$route.params.id) {
      this.$http.get('template/info/' + this.$route.params.id).then(res => {
        this.sheets = [];
        let data = JSON.parse(res.data.data);
        this.init(data);
        this.template.name = res.data.name;
        this.template.description = res.data.description;
        this.template.type = res.data.type;
        this.template.tag = res.data.tag;
        this.template.group_id = res.data.group_id;
        this.template.status = res.data.status;
        this.$root.templateType = this.template.type;
        this.localSheet = this.sheets[this.sheetIndex];
        this.sheet && this.sheet.resetData(this.localSheet);
        this.saveState.time = res.data.updated_at;

        this.$EventBus.$emit('templateName', this.template.name);
      }).finally(() => {
        this.loading = false;
      });
    } else if (localStorage.webExcel) {
      this.sheets = [];
      let data = JSON.parse(localStorage.webExcel);
      this.init(data);
      this.loading = false;
    } else {
      this.loading = false;
    }

    this.localSheet = this.sheets[this.sheetIndex];

    this.bindKeydownEvent();
  }
}
</script>　

<style scoped>
.excel-container {
  overflow: hidden;
}

.home, .excel-container {
  height: 100%;
  transition: all .15s;
}

.home {
  position: relative;
}

.table-area {
  position: relative;
  width: calc(100% - 12px);
  height: 100%;
}

.row-area {
  height: 100%;
  position: absolute;
  left: 0;
}

.col-area {
  position: absolute;
  top: 0;
  width: 100%;
}

.cell-area {
  height: 100%;
  position: relative;
}

.cell-area-box {
  overflow: hidden;
  scrollbar-width: none;
}

.col-area-box {
  position: absolute;
  top: 0;
  overflow: hidden;
  /*width: calc(100% - 40px);*/
  /*height: 30px;*/
  /*left:40px;*/
}

.row-area-box {
  position: absolute;
  left: 0;
  flex: 0 0 40px;
  overflow: hidden;
  /*width: 40px;*/
  /*height: calc(100% - 30px);*/
  /*top:30px;*/
}

.row-freeze-area-box {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  z-index: 550;
}

.row-freeze-area-box .row-area-box {
  z-index: 550;
  height: 100%;
}

.row-cell-area-box {
  position: absolute;
  /*top:30px;*/
  /*left:40px;*/
  height: 100%;
  overflow: hidden;
  border-right: 1px solid #91ADF0;
  z-index: 550;
  background-color: #fff;
  scrollbar-width: none;
}

.row-cell-area, .col-cell-area, .rc-cell-area {
  position: absolute;
  width: 100%;
  height: 100%;
}

.col-freeze-area-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 550;
}

.col-freeze-area-box .col-area-box {
  z-index: 550;
  width: 100%;
}

.col-cell-area-box {
  position: absolute;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #91ADF0;
  z-index: 550;
  background-color: #fff;
  scrollbar-width: none;
}

.col-area, .row-area, .cell-area, .rc-cell-area, .row-cell-area, .col-cell-area {
  transform-origin: left top;
}

.rc-freeze-area-box {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  z-index: 570;
}

.rc-cell-area-box {
  position: absolute;
  /*top:30px;*/
  /*left:40px;*/
  overflow: hidden;
  border-bottom: 1px solid #91ADF0;
  border-right: 1px solid #91ADF0;
  z-index: 550;
  background-color: #fff;
}

.row-cell-area-box::-webkit-scrollbar, .col-cell-area-box::-webkit-scrollbar, .cell-area-box::-webkit-scrollbar {
  display: none;
}

.input-area {
  position: absolute;
  z-index: 660;
  outline: none;
  user-select: none;
  resize: none;
  display: flex;
  border: 2px solid #5686F6;
  background-color: #fff;
  box-sizing: content-box;
  margin: 0;
  padding: 0;
  word-break: break-all;
  white-space: pre-wrap;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}

.input-attr-calc {
  white-space: normal;
  word-break: break-all;
  position: absolute;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.el-input-number {
  width: 100%;
}

.calculate {
  width: 100%;
  height: 30px;
  border-top: 1px solid #E0E2E6;
}

.calculate-help {
  flex: 0 0 100px;
  height: 100%;
  border-right: 1px solid #E0E2E6;
  border-left: 1px solid #E0E2E6;
  font-size: 14px;
  padding-left: 5px;
  display: flex;
  align-items: center;
}

.calculate-operate-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 25px;
  color: #d6d6d6;
  pointer-events: none;
}

.calculate-operate-box:not(.disabled) {
  cursor: pointer;
  color: #585858;
  pointer-events: auto;
}

.calculate-operate-box:not(.disabled):hover i {
  color: #5cb6ff;
}

.calculate-operate-box i {
  font-weight: bold;
}

.calculate-container {
  flex: 0 1 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding: 0 5px;
  border-left: 1px solid #E0E2E6;
}

.calculate-container-textarea {
  width: 100%;
  outline: none;
  border: none;
  height: 16px;
  resize: none;
  position: absolute;
  top: 6px;
  word-break: break-all;
  white-space: pre-wrap;
  font-family: Arial;
}

.copy-area {
  position: absolute;
  z-index: 650;
  pointer-events: none;
  box-sizing: content-box;
}

.copy-selection {
  position: absolute;
  background-color: transparent;
}

.copy-top {
  left: 0;
  right: 0;
  height: 2px;
  top: 0;
  background-position: bottom;
  background-image: url('../assets/EwaAntH.gif');
}

.copy-bottom {
  left: 0;
  right: 0;
  height: 2px;
  bottom: 0;
  background-image: url('../assets/EwaAntH.gif');
}

.copy-left {
  left: 0;
  bottom: 0;
  width: 2px;
  top: 0;
  background-position: right;
  background-image: url('../assets/EwaAntV.gif');
}

.copy-right {
  top: 0;
  right: 0;
  width: 2px;
  bottom: 0;
  background-image: url('../assets/EwaAntV.gif');
}

.requirement {
  position: relative;
  border: 1px solid #e4e4e7;
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  min-height: 50px;
}

.requirementModal {
  position: fixed;
  top: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2002;
  width: 500px;
  min-height: 200px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
}

.requirementModal > .el-icon-close {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 16px;
}

.el-icon-close {
  cursor: pointer;
}

.require-mb {
  margin-bottom: 10px;
}

.require-preview-area {
  padding: 5px;
  background-color: #fff;
  border: 1px solid #333;
  height: 34px;
  width: 260px;
  text-align: center;
  margin-left: 10px;
}

.requirement-group {
  justify-content: space-between;
  height: 30px;
  margin-bottom: 10px;
}

.requirement-group-input {
  flex: 0 0 160px;
}

.requirement-group-select {
  flex: 0 0 100px;
}

.requirement-info-title {
  margin-right: 10px;
}

.requirement-input {
  height: 28px;
  outline: none;
  border: 1px solid #DCDFE6;
  color: #606266;
  border-radius: 3px;
  padding: 0 5px;
}

.requirement-area-select {
  border: 1px solid #DCDFE6;
  color: #606266;
  border-radius: 3px;
  position: relative;
  height: 28px;
  padding: 0 5px;
  width: 163px;
}

.requirement-area-select.active {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.requirementModal .el-icon-edit-outline {
  top: 4px;
}

.dataset {
  position: relative;
  border: 1px solid #e4e4e7;
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  min-height: 70px;
}

.dataset:hover {
  background-color: #f4f6f7;
}

.dataset:hover .datasetEdit, .dataset:hover .datasetCopy, .dataset:hover .datasetDel, .requirement:hover .requirementEdit, .requirement:hover .requirementDel {
  display: block;
}

.datasetEdit, .datasetCopy, .datasetDel, .requirementEdit, .requirementDel {
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
  cursor: pointer;
  opacity: 0.5;
}

.datasetCopy, .requirementDel {
  right: 10px;
  top: 28px;
}

.datasetDel {
  right: 10px;
  top: 46px;
}

.contextmenu {
  position: absolute;
  box-shadow: 1px 2px 5px 2px rgb(51 51 51 / 15%);
  background: #fff;
  z-index: 1000;
  width: 160px;
  pointer-events: auto;
  overflow: auto;
}

.contextmenu-item {
  user-select: none;
  font-size: 14px;
  border: 1px solid transparent;
  outline: none;
  height: 32px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 26px;
  list-style: none;
  padding: 2px 10px;
  cursor: default;
  text-align: left;
  overflow: hidden;
}

.contextmenu-item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.dataSourceArea {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: 0.3s;
  z-index: 899;
}

.dataSourceArea .el-icon-close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.print-area-item {
  border: 1px solid #DCDFE6;
  color: #606266;
  padding: 3px 5px;
  flex-grow: 1;
  border-radius: 3px;
  height: 28px;
  justify-content: center;
  transition: .1s;
  margin-bottom: 10px;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-top: 5px;
}

/*.print-area-item.active, .print-area-item:hover {*/
.print-area-item.active {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.area-select-input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  text-align: center;
}

.el-icon-edit-outline {
  position: absolute;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
}

.print-area-item-close {
  position: absolute;
  right: 10px;
  font-size: 16px;
  display: none;
  cursor: pointer;
}

.print-area-item:hover .print-area-item-close, .print-area-item:hover .el-icon-edit-outline {
  display: block;
}

.page-area-item {
  padding-left: 10px;
  margin-bottom: 10px;
}

.page-area-item span {
  margin-left: 10px;
  font-size: 14px;
}

.toolbar-button {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f6f7;
  height: 26px;
  min-width: 26px;
  margin: 0 1px;
  padding: 0;
  text-align: center;
  border-radius: 2px;
  position: relative;
  user-select: none;
}

.toolbar-button:hover {
  background: rgba(0, 0, 0, 0.08);
}

.toolbar-button.active {
  background: rgba(0, 0, 0, 0.08);
}

.toolbar-button.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.toolbar-button:not(.nh)::before {
  content: attr(data-tooltip);
  border-radius: 3px;
  background-color: #000;
  position: absolute;
  top: 30px;
  padding: 5px 10px;
  z-index: 799;
  color: #fff;
  font-size: 12px;
  width: max-content;
  left: 0;
  display: none;
}

.toolbar-button:not(.active):hover::before {
  display: block;
}

.requirement-button-group {
  text-align: right;
}

.requirement-button-group button {
  margin-left: 10px;
}
</style>

