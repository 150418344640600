<template>
  <div id="app" @click="appClick">
    <router-view :key="$route.path"/>
  </div>
</template>

<style>
* {
  margin: 0;
  padding: 0;
}

html, body, #app {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
</style>

<script>
import auth from '@/plugins/auth'
import $ from '@/assets/jquery-3.6.1';

export default {
  name: "Login",
  data() {
    return {
      name: '',
      password: ''
    }
  },
  methods: {
    session() {
      if (auth.isHasToken()) {
        this.$http.get('session').then(res => {
          auth.setData(res.data);
          this.$toast(`${res.data.nickname}，欢迎回来!`);
        }).catch((e) => {

        });

        return true;
      } else {
        return false;
      }
    },
    appClick(e) {
      // document.onclick = (e) => {
      //   let o = e.path.find(e => {
      //     return e._prevClass === "date-picker-box";
      //   });
      //   if (o === undefined) {
      //     $('.date-picker').removeClass('active');
      //   }
      // }
    }
  },
  created() {
    this.session();
    this.appClick();

    this.$root.template = {};
  }

}
</script>