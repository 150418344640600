<template>
  <div class="stat-area">
    <div class="stat-zoom-content">
      <div class="stat-zoom-minus flex" @click.stop="changeZoom('minus')">
        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="2px" viewBox="0 0 14 2" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g fill="#444D5A">
              <rect x="0" y="0" width="14" height="2"/>
            </g>
          </g>
        </svg>
      </div>
      <div class="stat-zoom-slider">
        <input class="stat-zoom-range" type="range" max="200" min="10" step="1" v-model="zoomData"
               @input="zoomChange"
        >
      </div>
      <div class="stat-zoom-plus flex" @click.stop="changeZoom('plus')">
        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g fill="#444D5A">
              <rect x="0" y="6" width="14" height="2"/>
              <rect transform="translate(7.000000, 7.000000) rotate(-270.000000) translate(-7.000000, -7.000000) " x="0" y="6" width="14" height="2"/>
            </g>
          </g>
        </svg>
      </div>
      <div class="stat-zoom-ratioText" @click="resetZoom">
        {{zoomData}}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatArea",
  props:{
    zoom:{
      required:true,
      default:1,
    }
  },
  watch:{
    zoom(newVal){
      this.zoomData=Math.floor(newVal*100);
    }
  },
  data(){
    return {
      zoomData:Math.ceil(this.zoom*100),
    }
  },
  methods:{
    changeZoom(type){
      let z;
      if(type=='minus'){
        z=Math.ceil((this.zoomData-10)/10)/10;
      }
      else if(type=='plus'){
        z=Math.floor((this.zoomData+10)/10)/10;
      }

      if(z<0.1||z>2)return false;

      this.$emit('setZoom',z);
    },
    zoomChange(){
      this.$emit('setZoom',this.zoomData/100);
    },
    resetZoom() {
      this.$emit('setZoom',1);
    }
  }
}
</script>

<style scoped>
.stat-area{
  font-size: 12px;
  height: 24px;
  display: flex;
  justify-content: end;
  padding: 0 10px;
  background: #f5f6f7;
  border-top: 1px solid #e1e1e1;
}
.stat-zoom-content{
  overflow: hidden;
  user-select: none;
  width: 190px;
  height: 24px;
  display: flex;
  align-items: center;
}
.stat-zoom-content .stat-zoom-plus,.stat-zoom-content .stat-zoom-minus{
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.stat-zoom-content .stat-zoom-ratioText{
  width: 40px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
}
.stat-zoom-content .stat-zoom-ratioText:hover{
  background-color:#e1e4e8;
}
.stat-zoom-content .stat-zoom-slider{
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  margin:0 5px;
}
.stat-zoom-range{
  width: 100px;
  height: 3px;
}
.stat-zoom-range::-webkit-slider-container{
  background-color: #D3D6D8;
}
.stat-zoom-range::-moz-range-progress{
  /*待测试*/
  background-color: #D3D6D8;
}
.stat-zoom-range::-webkit-slider-thumb{
  cursor: pointer;
}
.stat-zoom-range::-moz-range-thumb{
  cursor: pointer;
}
</style>