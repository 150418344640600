<template>
  <el-dialog title="" :visible.sync="dialogVisible" :width="width" v-on:close="close">

    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="起始日期">
        <el-date-picker
            size="mini"
            v-model="form.from_date"
            align="center"
            :type="getInputType()"
            placeholder="选择日期"
            :picker-options="(type!=='year'&&type!=='month')?pickerOptions:{}">
        </el-date-picker>
      </el-form-item>
      <el-form-item v-if="type=='config'" label="结束日期">
        <el-date-picker
            size="mini"
            v-model="form.to_date"
            align="center"
            :type="getInputType()"
            placeholder="选择日期"
            :picker-options="(type!=='year'&&type!=='month')?pickerOptions:{}">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" size="mini" @click="preview">预览</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" :disabled="!canvas" @click="downloadExcel">下载excel</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" :disabled="!canvas" @click="downloadPdf">下载pdf</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" :disabled="!canvas" @click="downloadCurrentPagePdf">下载当前分页pdf</el-button>
      </el-form-item>
    </el-form>

    <div class="preview-area" ref="previewArea">
      <canvas ref="canvas" height="400px"></canvas>
    </div>

    <div slot="footer" class="dialog-footer">

    </div>
  </el-dialog>
</template>

<script>
import Preview from "@/core/preview/preview";
import {config} from "@/plugins/config";
import auth from "@/plugins/auth";

export default {
  name: "Preview",
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    width:{
      type:String,
      default: '90%'
    },
    type:{
      default:'year',
    },
    sheets:{
      type:Array,
    },
    datasets:{
      type:Array,
      default:function (){
        return [];
      },
    }
  },
  watch:{
    visible(newVal){
      if(newVal)
        this.init();
      this.dialogVisible=newVal;
    },
    sheets(newVal){
      this.sheet=window.deepClone(newVal);
    }
  },
  data(){
    return{
      dialogVisible:false,
      form:{
        from_date:'',
        to_date:null,
      },
      sheet:{},
      pickerOptions:{
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }],
      },
      backup:null,
      s:null,
      canvas:null,
      test:null,
    }
  },
  methods:{
    downloadExcel(){
      this.canvas.download('excel');
    },
    downloadPdf(){
      this.canvas.download('pdf');
    },
    downloadCurrentPagePdf(){
      this.canvas.download('currentPagePdf');
    },
    getInputType(){
      let result='';
      switch (this.type){
        case 'year':result='year';break;
        case 'month':result='month';break;
        case 'day':result='date';break;
        case 'config':result='date';break;
      }

      return result;
    },
    preview(){
      this.canvas=new Preview(this.$refs.previewArea);
      this.canvas.datasets=this.datasets;
      this.canvas.setBaseURL(config.baseURL);
      this.canvas.setUserID(auth.data.userid);
      // this.canvas.setBaseURL(`http://coding.recline.cn/back-end/public/api`);

      this.s=window.deepClone(this.$parent.sheets);
      // this.canvas.renderByData(this.s,this.type,this.form.from_date,this.form.to_date);
      this.canvas.renderByTemplate(this.$parent.template.id,this.form.from_date,this.form.to_date);
    },
    close(){
      this.$emit('close');
    },
    init(){
      this.form.from_date=new Date().valueOf();
      this.form.to_date=new Date().valueOf();
      if(this.canvas){
        this.canvas.clear();
        this.canvas.resize(200,400);
      }
    }
  },
  created() {
    this.sheet=window.deepClone(this.sheets);
  }
}
</script>

<style scoped>

</style>