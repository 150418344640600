<template>
  <div id="main">
    <WeHeader></WeHeader>
    <div class="d-flex-c-b h-100 main-container">
      <WeAside></WeAside>

      <div class="view">
        <div class="view-container p-20-15">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <template v-for="e of routes" v-if="e.title!=='模版列表'">
              <el-breadcrumb-item v-if="e.path" :to="{ path: e.path }">
                {{ e.title }}
              </el-breadcrumb-item>
              <el-breadcrumb-item v-else>
                {{ e.title + (e.title == '修改模版' ? ('（' + templateName + ')') : '') }}
              </el-breadcrumb-item>
            </template>
          </el-breadcrumb>
        </div>

        <div class="view-container" style="">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WeAside from "@/layout/WeAside";
import WeHeader from "@/layout/WeHeader";
import fa from "element-ui/src/locale/lang/fa";

export default {
  name: "Main",
  data() {
    return {
      templateName: '',
    };
  },
  components: {
    WeAside,
    WeHeader,
  },
  watch: {

  },
  created() {
    this.$EventBus.$on('templateName',(name)=>{
      this.templateName=name;
    });
  },
  computed: {
    routes() {
      let route = [];
      for (let i of this.$route.matched) {
        route.push({
          path: this.isCurrentPage(i.path, this.$route.path),
          title: i.meta.title ?? ''
        });
      }

      return route;
    }
  },
  methods: {
    isCurrentPage(pathA, pathB, defaultPathA = '/index', defaultPathB = '/dashboard') {
      if (pathA === pathB) return false;
      if (pathA === defaultPathA && pathB === defaultPathA + defaultPathB) return false
      if (pathA + "/index" === pathB) return false;
      if (pathA === '/home/template/modify/:id') return false;

      return pathA;
    }
  },
}
</script>

<style scoped>
@import url(element-ui);

.view {
  width: 100%;
  height: calc(100vh - 60px);
  background: #F9FAFD;
  overflow-x: hidden;
  overflow-y: scroll;
}

.view-container {
  margin: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(30 30 30 / 10%);
}

</style>