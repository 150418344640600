<template>
  <div class="history-box">
    <div v-for="(h,i) in his" :class="'history-card '+(i==index?'active':'')" @click.stop="selectHistory(i)">
      <div class="history-version">第{{histories.length-i}}版<span v-if="i==0">(当前)</span></div>
      <div class="history-content">
        <div class="history-time">{{h.updated_at}}</div>
        <div class="history-author">
          {{h?.modify_user?.nickname}}
        </div>
      </div>

      <div class="history-revert" v-if="i!==0" @click="revert(h)">
        <svg width="16" height="16" style="margin-right:3px;">
          <g xmlns="http://www.w3.org/2000/svg">
            <path fill="#7D7D7D" transform="matrix(-1 0 0 1 17 3)" fill-rule="evenodd" d="M11.5656391,4.43436088 L9,7 L16,7 L16,0 L13.0418424,2.95815758 C11.5936787,1.73635959 9.72260775,1 7.67955083,1 C4.22126258,1 1.25575599,3.10984908 0,6 L2,7 C2.93658775,4.60974406 5.12943697,3.08011229 7.67955083,3 C9.14881247,3.0528747 10.4994783,3.57862053 11.5656391,4.43436088 Z" />
          </g>
        </svg>
        <span>还原</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateHistory",
  props:{
    histories:[],
  },
  watch:{
    histories(newVal){
      this.his=newVal.concat();
      this.index=this.getIndex();
    }
  },
  data(){
    return {
      index:0,
      his:[]
    }
  },
  methods:{
    revert(h){
      this.$emit('revert',{template_id:h.template_id,identifying_code:h.identifying_code});
    },
    selectHistory(i){
      this.index=i;
      this.$emit('selectHistory',this.his[i].identifying_code);
    },
    getIndex(){
      let index=this.his.findIndex((h)=>{
        return h.identifying_code==this.$route.params.identifying_code;
      })
      if(index!==-1)
        return index;
      else return 0;
    },
  },
  mounted() {
    this.index=this.getIndex();
  }
}
</script>

<style scoped>
.history-box{
  height: 100%;
  overflow: auto;
  scrollbar-color:#E0E0E0;
}
.history-box::-webkit-scrollbar-thumb{
  background-color: #E0E0E0;
}
.history-card{
  padding: 15px 20px;
  position: relative;
}
.history-card:hover {
  background-color: #F4F4F4;
}
.history-card.active {
  background-color: #F4F4F4;
}
.history-version{
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.64);
  margin-bottom:6px;
}
.history-time{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.history-author{
  margin-top:10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.64);
}
.history-card:hover .history-revert{
  display: flex;
}
.history-revert{
  position: absolute;
  right:20px;
  top:20px;
  font-size: 12px;
  cursor: pointer;
  z-index: 9999;
  color: rgba(0, 0, 0, 0.4);
  align-items: center;
  display: none;
}
.history-revert:hover path{
  fill: #3B86F3;
}
.history-revert:hover span{
  color: #4db0f7;
}
</style>