<template>
  <Modal
      ref="modifyGroup"
      :title="'创建分组'"
      :confirm="modifyGroup"
      :formName="'ruleForm'"
      :confirmButtonText="'修改'"
      :confirmingButtonText="'修改中'"
  >
    <el-form :model="group" :rules="rules" ref="ruleForm" label-width="80px">
      <el-form-item label="分组名称" prop="name">
        <el-input v-model="group.name"></el-input>
      </el-form-item>
      <el-form-item label="所属公司" prop="coid">
        <el-select
            v-model="group.coid"
            class="w-100"
            placeholder="请选择所属公司">
          <el-option
              v-for="item in co"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
export default {
  name: "index",
  props:{
    g:{
      type:Object,
    }
  },
  components:{
    Modal,
  },
  watch:{
    g(val){
      this.group={...val};
    },
  },
  data(){
    return{
      group:{...this.g},
      co:[],
      rules:{
        name: [
          {required: true, message: '请输入分组名称', trigger: 'blur'},
        ],
        coid: [
          {required: true, message: '请选择公司', trigger: 'blur'},
        ],
      }
    }
  },
  methods:{
    modifyGroup(){
      return this.$http.post(`group/modify/${this.group.id}`,this.group).then(res=>{
        this.$toast('修改成功');
        this.$refs.modifyGroup.hide();
        this.$emit('getGroup');
      }).catch(e=>{
        this.$toast(e.response.data.message,'danger');
      });
    },
    getCompany() {
      this.$http.get('uiinterface?reqType=GetCoofUsr').then(res => {
        // console.log(res)
        if (res.data.err === '用户未登录') {
          return;
        }
        this.co = res.data.data;

        //判断公司列表是否不为空
        if ((this.co.length ?? 0) > 0) {
          //默认选择第一个公司
          this.group.coid = this.co[0].id;
        } else {
          //如果公司列表为空
          this.co = [{id: '-1', name: '暂无公司'}];
          this.group.coid = '';
        }
      }).catch(err => {

      });
    },
    clear(){
      this.group={...this.g}
    },
    show(){
      this.clear();
      this.$refs.modifyGroup.show();
    },
    hide(){
      this.$refs.modifyGroup.hide();
    }
  },
  created() {
    this.getCompany();
  }
}
</script>

<style scoped>

</style>