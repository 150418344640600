import moment from './moment.js'
import {getChart} from './chart.js'
import toPdf from "./toPdf.js";

function getData(tablestyle, from_date, to_date) {
    let request = {
        "tablestyle": tablestyle,
        "dataset": [],
    };
    if (tablestyle === 3) {
        request.from_date = transferDate(tablestyle, from_date);
        request.to_date = transferDate(tablestyle, to_date);
    } else request.date = transferDate(tablestyle, from_date);

    for (let set of this.datasets) {
        if (set.apiData.date_type === 'start_date')
            set.apiData.start_date += ':00';
        if (set.apiData.date_type === 'end_date')
            set.apiData.end_date += ':00';
        for (let storage of this.allData.storage){
            for (let j in storage.cell){
                let param = storage.cell[j].data.content.toString().split('_')[1];
                if (param == set.param&&request.dataset.findIndex((d)=>d.key===set.key)===-1) {
                    request.dataset.push(set.apiData);
                }
            }
        }
    }

    let form = new FormData();
    form.append('data', JSON.stringify(request));
    return fetch(this.baseURL + 'processtable' + '?userid=' + this.userId, {
        method: 'POST',
        body: form,
        headers: {
            // 'Content-Type': 'multipart/form-data; '
        },
    });
}

function getDateByTableStyle(tablestyle, from_date, to_date){
    let result="";
    if(tablestyle===3)
        result=transferDate(tablestyle, from_date)+'~'+transferDate(tablestyle, to_date)
    else
        result=transferDate(tablestyle, from_date);
    return result;
}

const transferDate = (tablestyle, date) => {
    let m = moment(date);
    if (tablestyle === 0)
        return m.format('YYYY-MM-DD');
    else if (tablestyle === 1)
        return m.format('YYYY-MM');
    else if (tablestyle === 2)
        return m.format('YYYY');
    else if (tablestyle === 3)
        return m.format('YYYY-MM-DD');
}

export const colName = (num) => {
    let ConvertToLetter = '', a = num, b;
    do {
        a = Math.floor((num - 1) / 26);
        b = (num - 1) % 26;
        ConvertToLetter = String.fromCharCode(b + 65) + ConvertToLetter;
        num = a
    } while (num > 0)

    return ConvertToLetter;
}

function addNewRow(_this, l = 50) {
    let length = this.data.row.length;
    for (let num = length; num < length + l; num++) {
        this.data.row.push({
            name: (num + 1),
            width: 40,
            size: 30,
            left: 0,
            top: (num) * (30),
            index: (num),
        });
    }
    initNewCell.call(this, length, 'row');
}

function addNewCol(l = 26) {
    let length = this.data.col.length;
    for (let num = length; num < length + l; num++) {
        this.data.col.push({
            name: colName(num + 1),
            height: 30,
            size: 80,
            top: 0,
            left: (num) * (80),
            index: (num),
        });
    }
    initNewCell.call(this, length, 'col');
}

function initNewCell(length, type) {
    let range = {};
    if (type == 'row') {
        range = {
            row: length,
            col: 0,
        }
    } else {
        range = {
            row: 0,
            col: length,
        }
    }


    for (let j = range.row; j < this.data.row.length; j++) {
        for (let i = range.col; i < this.data.col.length; i++) {
            setCellData.call(this, i, j);
        }
    }
}

function setCellData(i, j) {
    this.data.map[this.data.col[i].name + this.data.row[j].name] = {
        type: 'cell',
        name: this.data.col[i].name + this.data.row[j].name,
        ci: i,
        ri: j,
        data: {
            name: this.data.col[i].name + (this.data.row[j].name),
            content: '',
            value: '',
            backup: '',
            row: this.data.row[j].name,
            col: this.data.col[i].name,
            inputShow: false,
            valid: true,
            merge: false,
            cellType: {
                type: 'normal',
                extra: 0,
            },
            cellStyle: {
                width: this.data.col[i].size,
                height: this.data.row[j].size,
                left: (this.data.col[i].left),
                top: (this.data.row[j].top),
                bold: false,
                italic: false,
                underline: false,
                font: 'Microsoft YaHei',
                fontSize: 12,
                color: '333333',
                textAlign: 'left',
                verticalAlign: 'center',
                backgroundColor: 'none',
                borderLeftWidth: 0,
                borderLeftColor: 'e6e6e7',
                borderRightWidth: 0,
                borderRightColor: 'e6e6e7',
                borderTopWidth: 0,
                borderTopColor: 'e6e6e7',
                borderBottomWidth: 0,
                borderBottomColor: 'e6e6e7',
                zIndex: 5,
            }
        }
    }
}

function initSheet(data) {
    for (let i in data.storage) {
        newSheet.call(this, data.storage[i]);
    }
    this.datasets = data.datasets ?? [];
    this.fitToPage = data.fitToPage ?? {};
    this.pageMargins = data.pageMargins ?? {};
    this.pageSize = data.pageSize ?? '';
    this.printArea = data.printArea ?? '';
    this.printDirection = data.printDirection ?? '';
    this.template = data.template ?? {};
}

function initData() {
    this.data = {
        uploadData: {
            row: {},
            col: {},
            cell: {},
        },
        name: 'sheet' + (this.sheets.length + 1),
        col: [],
        row: [],
        map: {},
        merges: [],
        images: [],
    }
}

const findInObject = (obj, fn) => {
    let result = undefined;
    result = Object.keys(obj).find((k) => fn(obj[k]));
    return result;
}

function initCol(colLen = 26, cols = null) {
    for (let num = 0; num < colLen; num++) {
        let c = {
            name: colName(num + 1),
            height: 30,
            size: 80,
            top: 0,
            left: (num) * (80),
            index: (num),
        }
        if (cols) {
            let result = findInObject(cols, (col) => col.index == num);
            if (result !== undefined) {
                c = cols[result];
            } else if (num > 0) {
                c.left = this.data.col[num - 1].left + this.data.col[num - 1].size;
            }
        }
        this.data.col.push(c);
    }
}

function initRow(rowLen = 50, rows = null) {
    for (let num = 0; num < rowLen; num++) {
        let r = {
            name: (num + 1),
            width: 40,
            size: 30,
            left: 0,
            top: (num) * (30),
            index: (num),
        }
        if (rows) {
            let result = findInObject(rows, (row) => row.index == num);
            if (result !== undefined) {
                r = rows[result];
            } else if (num > 0) {
                r.top = this.data.row[num - 1].top + this.data.row[num - 1].size;
            }
        }
        this.data.row.push(r);
    }
}

function initCell() {
    for (let j = 0; j < this.data.row.length; j++) {
        for (let i = 0; i < this.data.col.length; i++) {
            setCellData.call(this, i, j);
        }
    }
}

function newSheet(option = null) {
    if (option) {
        initData.call(this);
        this.data.name = option.name;
        this.data.merges = option.merges;
        for (let i in option.images) {
            option.images[i].selecting = false;
            if (option.images[i].type == 'Chart') {
                delete option.images[i].src;
            }
        }
        this.data.images = option.images;
        this.data.requirements=option.requirements;
        if (option.freeze) {
            this.data.freeze = option.freeze;
            this.data.freezeCol = option.freezeCol;
            this.data.freezeRow = option.freezeRow;
            this.data.offsetLeft = option.offsetLeft;
            this.data.offsetTop = option.offsetTop;
        }
        initCol.call(this, option.colLen, option.col);
        initRow.call(this, option.rowLen, option.row);
        initCell.call(this);

        for (let i in option.cell) {
            this.data.map[i] = option.cell[i];
        }

        this.data.uploadData = option;
    } else {
        initData.call(this);
        initCol.call(this);
        initRow.call(this);
        initCell.call(this);
    }

    this.sheets.push(this.data);
}

function preUploadData() {
    let storage = [];
    for (let i in this.sheets) {
        let sheet = this.sheets[i];
        let data = {...sheet.uploadData};

        if (sheet.freeze) {
            data.freeze = sheet.freeze;
            data.freezeCol = {...sheet.freezeCol};
            data.freezeRow = {...sheet.freezeRow};
            data.offsetLeft = sheet.offsetLeft;
            data.offsetTop = sheet.offsetTop;
        }

        // data.images = sheet.images;

        data.merges = sheet.merges;
        data.name = sheet.name;
        data.requirements=sheet.requirements;

        storage.push(data);
    }

    let result = {
        storage: storage,
        fitToPage: this.fitToPage,
        pageMargins: this.pageMargins,
        pageSize: this.pageSize,
        printArea: this.printArea,
        printDirection: this.printDirection,
        template: this.template,
    };

    return result;
}

export default class Preview {
    constructor(targetEl) {
        this.datasets = [];
        this.sheets = [];
        this.response = [];
        this.extraLen = [];
        this.index = 0;
        this.from_date = null;
        this.to_date = null;
        this.baseURL = '';
        this.userId = null;
        this.operators = [
            '=',
            '<',
            '>',
            '<=',
            '>=',
            '(',
            '-',
            '+',
            '/',
            '*',
            ',',
        ];

        this.addCssStyle();

        this.dom = targetEl;
        targetEl.innerHTML = `
            <div class="preview-box" style="width:100%;height:calc(100% - 50px);overflow: auto"></div>
            <div class="sheets"></div>
        `;
        this.el = document.createElement('canvas');

        targetEl.querySelector('.preview-box').append(this.el);
        this.draw = new Draw(this.el, 200, 400, 1);

    }

    download(type) {
        if (this.fileName || this.html) {
            let indexBackup=this.index;
            if (type === 'pdf') {
                let data=preUploadData.call(this);
                toPdf.call(this,data,null,this.printAreaIndex, this.options.name + getDateByTableStyle(this.tableStyle,this.from_date,this.to_date));

                this.index = indexBackup;
                this.data = this.sheets[this.index];
                // this.handleData()
                this.setAttr();
                this.render();
            }
            else if(type==='currentPagePdf'){
                let data=preUploadData.call(this);
                toPdf.call(this,data,this.index,this.printAreaIndex, this.options.name + getDateByTableStyle(this.tableStyle,this.from_date,this.to_date));
            }
            else{
                let link = document.createElement('a');
                link.href = this.baseURL+'../../output/excel/' + this.fileName;
                link.download = this.options.name + getDateByTableStyle(this.tableStyle,this.from_date,this.to_date) + (type === 'excel' ? '.xlsx' : '.pdf');
                link.target = '_blank';
                link.click();
            }
        }
        // else alert('请先使用预览功能或等待链接获取完毕');
    }

    reset() {
        this.downloadUrlPdf = '';
        this.downloadUrl = '';
        this.fileName = '';
        this.fileNamePdf = '';
        this.html = '';
    }

    downloadRequest() {
        if (this.sheets.length) {
            let form = new FormData(), data = preUploadData.call(this);
            form.append('data', JSON.stringify(data));
            form.append('name', this.options.name);
            form.append('description', this.options.description);
            form.append('tag', this.options.tag);
            form.append('status', this.options.status);
            form.append('type', this.options.type);
            // form.append('data',data);
            fetch(this.baseURL + 'download' + '?userid=' + this.userId, {
                method: 'POST',
                body: form,
            }).then(r => r.json()).then(res => {
                this.downloadUrl = res.downloadUrl;
                this.downloadUrlPdf = res.downloadUrlPdf;
                this.fileName = res.fileName;
                this.fileNamePdf = res.fileNamePdf;
                this.html = res.html;
                //批量替换文本
                // this.html.replaceAll(res.publicPath, res.url);
            }).catch(e => {
                alert('Excel文件生成失败，请检查模版内的函数是否正确使用');
            })
        }
    }

    addCssStyle() {
        const styled = document.createElement('style')
        styled.innerHTML = `
        .sheets{
                margin-top: 10px;
                display: flex;
               
            }
            .third-sheet-button{
                cursor: pointer;
                padding: 0 15px;
                height:40px;
                background-color: #f5f6f7;
                color:#80868b;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size:14px;
                border:1px solid #e0e0e0;
            }
            .third-sheet-button.active{
                background-color: #fff;
                color: rgba(0, 0, 0, 0.65);
            }
          
        `
        document.head.append(styled);
    }

    setSheetBar() {
        this.dom.querySelector('.sheets').innerHTML = '';
        let el = ``;
        for (let i in this.sheets) {
            el += `<div class="third-sheet-button ${(i == 0 ? 'active' : '')}" data-index="${i}">${this.sheets[i].name}</div>`;
        }
        this.dom.querySelector('.sheets').innerHTML = el;
        let btn = this.dom.querySelector('.sheets').querySelectorAll('div');
        for (let i = 0; i < btn.length; i++) {
            btn[i].addEventListener('click', () => {
                btn[this.index].classList.remove('active');
                this.index = i;
                btn[i].classList.add('active');
                this.data = this.sheets[i];
                // this.handleData()
                this.setAttr();
                this.render();
            });
        }
    }

    setBaseURL(url) {
        if(url[url.length-1]==='/')
            this.baseURL=url
        else this.baseURL = url+'/';
    }

    setUserID(id) {
        this.userId = id;
    }

    loading() {
        if (this.loadingDom) {
            this.loadingDom.remove();
            this.loadingDom = null;
        }
        this.loadingDom = document.createElement('div');
        this.loadingDom.innerHTML = '<span>加载中</span>';
        this.dom.insertBefore(this.loadingDom, this.dom.querySelector('.preview-box'));
    }

    //通过传入模版id和时间获取模版数据 再通过获得的数据得到最终表格布局和内容 并渲染
    renderByTemplate(templateID, from_date, to_date = null) {
        this.reset();
        this.index = 0;
        this.loading();
        return this.getTemplate(templateID).then(r => {
            return r.json();
        }).then(res => {
            this.sheets = [];
            let data = JSON.parse(res.data);
            let type = res.type;
            this.options = {
                name: res.name,
                description: res.description,
                tag: res.tag,
                status: res.status,
                type: res.type,
            }
            this.allData=data;
            initSheet.call(this, data);
            this.renderByData(this.sheets, type, from_date, to_date);
        }).catch(error => {
            this.loadingDom.innerHTML = '<span>获取失败</span>';
        });
    }

    getTemplate(templateID) {
        return fetch(this.baseURL + 'third/template/' + templateID + '?userid=' + this.userId, {
            method: 'GET',
        });
    }

    //从api获取数据后处理原表格
    handleData() {
        this.extraLen=[];
        for(let k=0;k<this.sheets.length;k++){
            let sheetData=this.sheets[k];
            for (let i in sheetData.col) {
                for (let j in sheetData.row) {
                    if (sheetData.map[sheetData.col[i].name + sheetData.row[j].name].data.content == '__DATE__') {
                        if (this.to_date)
                            sheetData.map[sheetData.col[i].name + sheetData.row[j].name].data.content = this.from_date + '~' + this.to_date;
                        else sheetData.map[sheetData.col[i].name + sheetData.row[j].name].data.content = this.from_date;
                        this.insertUploadData('cell', sheetData.col[i].name + sheetData.row[j].name, sheetData.map[sheetData.col[i].name + sheetData.row[j].name],k);
                    } else if (sheetData.map[sheetData.col[i].name + sheetData.row[j].name].data.content
                        && sheetData.map[sheetData.col[i].name + sheetData.row[j].name].data.content.length
                        && sheetData.map[sheetData.col[i].name + sheetData.row[j].name].data.content[0] == '_') {
                        let param = sheetData.map[sheetData.col[i].name + sheetData.row[j].name].data.content.split('_')[1];
                        // let dataset=this.datasets.find((d)=>d.param==param);
                        let dataset = this.datasets.find((d) => d.param == param)
                        if (dataset !== undefined) {
                            let data = this.response.find((r) => r.key === dataset.key);
                            sheetData.map[sheetData.col[i].name + sheetData.row[j].name].data.content = "";
                            this.replaceData(data,k, i, j, dataset.source.paramsData.direction);
                        } else {

                        }
                    }
                }
            }
        }


        this.handleChart();
    }

    handleChart() {
        for (let k=0;k<this.sheets.length;k++){
            let sheetData=this.sheets[k];
            for (let i in sheetData.uploadData.images) {
                if (sheetData.uploadData.images[i].type === 'Chart') {
                    this.chartData = sheetData.uploadData.images[i].chartData;
                    this.chartAttr = sheetData.uploadData.images[i];
                    sheetData.uploadData.images[i].src = getChart.call(this);
                }
            }
        }
    }

    calcCellContentCopy(val) {
        return calcCellContent.call(this, val);
    }

    insertUploadData(type, key, value,index=null) {
        if(index)
            this.sheets[index].uploadData[type][key]=value;
        else this.data.uploadData[type][key] = value;
    }

    moveDataToRight(sheet, ci, ri,sheetIndex=null) {
        let cell = sheet.map[sheet.col[ci].name + sheet.row[ri].name],
            nextCell = sheet.map[sheet.col[ci + 1].name + sheet.row[ri].name];

        if (nextCell.data.content !== '') {
            this.moveDataToRight(sheet,nextCell.ci, nextCell.ri,sheetIndex);
        }

        nextCell.data.content = cell.data.content;
        cell.data.content = '';
        if (sheet.uploadData.cell[cell.name])
            this.insertUploadData('cell', nextCell.name, nextCell,sheetIndex);
    }

    moveDataToBottom(sheet, ci, ri,sheetIndex=null) {
        let cell = sheet.map[sheet.col[ci].name + sheet.row[ri].name],
            nextCell = sheet.map[sheet.col[ci].name + sheet.row[ri + 1].name];

        if (nextCell.data.content !== '') {
            this.moveDataToBottom(sheet,nextCell.ci, nextCell.ri,sheetIndex);
        }

        nextCell.data.content = cell.data.content;
        cell.data.content = '';
        if (sheet.uploadData.cell[cell.name])
            this.insertUploadData('cell', nextCell.name, nextCell,sheetIndex);
    }

    replaceData(data, sheetIndex, ci, ri, direction) {
        let sheet = this.sheets[sheetIndex];
        let dataset = data.dataset;

        //水平
        if (direction == 'horizontal') {
            let i = 0,flag=false,extra={col:0,row:0};
            for (let k in dataset) {
                let j=0,d;
                if(dataset[k] instanceof Array){
                    d = dataset[k];
                }
                else if (dataset[k] instanceof Object) {
                    d=Object.values(dataset[k]);
                }
                else{
                    if (i + ci * 1 >= sheet.col.length)
                        addNewCol.call(this, 1);

                    if (i !== 0 && j === 0 && sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name].data.content !== '') {
                        addNewCol.call(this, 1);
                        this.moveDataToRight(sheet,i + ci * 1, j + ri * 1,sheetIndex);
                    }

                    sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name].data.content = (dataset[k]!==null?dataset[k]:' ');
                    this.insertUploadData('cell', sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name, sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name],sheetIndex);

                    i++;
                    continue;
                }

                flag=false;
                for (let key in d) {
                    if(d[key] instanceof Array){
                        j=0;
                        for (let k in d[key]) {
                            if (i + ci * 1 >= sheet.col.length)
                                addNewCol.call(this, 1);
                            if (j + ri * 1 >= sheet.row.length)
                                addNewRow.call(this, 1);

                            if (i !== 0 && j === 0 && sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name].data.content !== '') {
                                addNewCol.call(this, 1);
                                this.moveDataToRight(sheet,i + ci * 1, j + ri * 1,sheetIndex);
                            } else if (j !== 0 && sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name].data.content !== '') {
                                addNewRow.call(this, 1);
                                this.moveDataToBottom(sheet,i + ci * 1, j + ri * 1,sheetIndex);
                            }

                            sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name].data.content = (d[key][k]!==null?d[key][k]:' ');
                            this.insertUploadData('cell', sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name, sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name],sheetIndex);

                            j++;
                        }

                        i++;
                    }
                    else{
                        if (i + ci * 1 >= sheet.col.length)
                            addNewCol.call(this, 1);
                        if (j + ri * 1 >= sheet.row.length)
                            addNewRow.call(this, 1);

                        if (i !== 0 && j === 0 && sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name].data.content !== '') {
                            addNewCol.call(this, 1);
                            this.moveDataToRight(sheet,i + ci * 1, j + ri * 1,sheetIndex);
                        } else if (j !== 0 && sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name].data.content !== '') {
                            addNewRow.call(this, 1);
                            this.moveDataToBottom(sheet,i + ci * 1, j + ri * 1,sheetIndex);
                        }

                        sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name].data.content = (d[key]!==null?d[key]:' ');
                        this.insertUploadData('cell', sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name, sheet.map[sheet.col[i + ci * 1].name + sheet.row[j + ri * 1].name],sheetIndex);

                        j++;
                        flag=true;
                    }
                    extra.row=Math.max(extra.row,j);
                }

                if(flag)
                    i++;
            }
            extra.col=Math.max(extra.col,i);
            this.extraLen.push({row:extra.row-1+ri*1,col:extra.col-1+ci*1});
        }
        //垂直
        else {
            let i = 0,flag=false,extra={col:0,row:0};
            for (let k in dataset) {
                let j=0,d;
                if(dataset[k] instanceof Array){
                    d = dataset[k];
                }
                else if (dataset[k] instanceof Object) {
                    d=Object.values(dataset[k]);
                }
                else {
                    if (i + ri * 1 >= sheet.row.length)
                        addNewRow.call(this, 1);

                    if (i !== 0 && j === 0 && sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name].data.content !== '') {
                        addNewRow.call(this, 1);
                        this.moveDataToBottom(sheet,j + ci * 1, i + ri * 1,sheetIndex);
                    }

                    sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name].data.content = (dataset[k]!==null?dataset[k]:' ');
                    this.insertUploadData('cell', sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name, sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name],sheetIndex);

                    i++;
                    continue;
                }

                flag=false;
                for (let key in d) {
                    if(d[key] instanceof Array){
                        j=0;
                        for (let k in d[key]){
                            if (j + ci * 1 >= sheet.col.length)
                                addNewCol.call(this, 1);
                            if (i + ri * 1 >= sheet.row.length)
                                addNewRow.call(this, 1);

                            if (i !== 0 && j === 0 && sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name].data.content !== '') {
                                addNewRow.call(this, 1);
                                this.moveDataToBottom(sheet,j + ci * 1, i + ri * 1,sheetIndex);
                            } else if (j !== 0 && sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name].data.content !== '') {
                                addNewCol.call(this, 1);
                                this.moveDataToRight(sheet,j + ci * 1, i + ri * 1,sheetIndex);
                            }

                            sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name].data.content = (d[key][k]!==null?d[key][k]:' ');
                            this.insertUploadData('cell', sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name, sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name],sheetIndex);

                            j++;
                        }

                        i++;
                    }
                    else{
                        if (j + ci * 1 >= sheet.col.length)
                            addNewCol.call(this, 1);
                        if (i + ri * 1 >= sheet.row.length)
                            addNewRow.call(this, 1);

                        if (i !== 0 && j === 0 && sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name].data.content !== '') {
                            addNewRow.call(this, 1);
                            this.moveDataToBottom(sheet,j + ci * 1, i + ri * 1,sheetIndex);
                        } else if (j !== 0 && sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name].data.content !== '') {
                            addNewCol.call(this, 1);
                            this.moveDataToRight(sheet,j + ci * 1, i + ri * 1,sheetIndex);
                        }

                        sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name].data.content = (d[key]!==null?d[key]:' ');
                        this.insertUploadData('cell', sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name, sheet.map[sheet.col[j + ci * 1].name + sheet.row[i + ri * 1].name],sheetIndex);

                        j++;
                        flag=true;
                    }
                    extra.col=Math.max(extra.col,j);
                }

                if(flag)
                    i++;
            }
            extra.row=Math.max(extra.row,i);
            this.extraLen.push({row:extra.row-1+ri*1,col:extra.col-1+ci*1});
        }
    }

    //通过传入最终表格布局和内容直接渲染
    renderByData(data, tablestyle, from_date, to_date = null) {
        this.index = 0;
        let tablestyleOption = {
            'day': 0,
            'month': 1,
            'year': 2,
            'config': 3,
        };
        this.from_date = transferDate(tablestyleOption[tablestyle], from_date);
        if (tablestyleOption[tablestyle] === 3)
            this.to_date = transferDate(tablestyleOption[tablestyle], to_date);
        else this.to_date = null;
        let res = getData.call(this, tablestyleOption[tablestyle], from_date, to_date);
        this.tableStyle=tablestyleOption[tablestyle];
        this.sheets = deepClone(data);
        this.data = this.sheets[this.index];
        res.then((r) => {
            return r.json();
        }).then(res => {
            this.response = res.data;
            this.handleData();

            this.setAttr();

            this.render();

            this.setSheetBar();

            this.loadingDom.remove();
            this.loadingDom = null;

            this.downloadRequest();
        }).catch(error => {
            // alert(error);
            console.log(error);
            this.loadingDom.innerHTML = '<span>获取失败</span>';
        })

    }

    setAttr() {
        let sci = null, sri = null, eci = null, eri = null;
        for (let i in this.data.uploadData.cell) {
            let cell = this.data.uploadData.cell[i];
            if(cell.data.content===''&&cell.data.cellStyle.backgroundColor==='none'
                &&cell.data.cellStyle.borderBottomWidth<=0&&cell.data.cellStyle.borderTopWidth<=0
                &&cell.data.cellStyle.borderLeftWidth<=0&&cell.data.cellStyle.borderRightWidth<=0){
                continue;
            }

            let ri,ci;
            if(cell.data.mergeTo){
                ri=cell.data.mergeTo.data.mergeCellRange.row.max;
                ci=cell.data.mergeTo.data.mergeCellRange.col.max;
            }
            else if(cell.data.merge){
                ri=cell.data.mergeCellRange.row.max;
                ci=cell.data.mergeCellRange.col.max;
            }
            else{
                ri=cell.ri;
                ci=cell.ci;
            }

            if (sci === null)
                sci = ci;
            else sci = Math.min(sci, ci);

            if (sri === null)
                sri = ri;
            else sri = Math.min(sri, ri);

            if (eci === null)
                eci = ci;
            else eci = Math.max(eci, ci);

            if (eri === null)
                eri = ri;
            else eri = Math.max(eri, ri);
        }

        for (let image of this.data.uploadData.images) {
            let scell = getCellByCoordinate.call(this, image.left, image.top),
                ecell = getCellByCoordinate.call(this, image.left + image.width, image.top + image.height);

            if (sci === null)
                sci = scell.ci;
            else sci = Math.min(sci, scell.ci);

            if (sri === null)
                sri = scell.ri;
            else sri = Math.min(sri, scell.ri);

            if (eci === null)
                eci = ecell.ci;
            else eci = Math.max(eci, ecell.ci);

            if (eri === null)
                eri = ecell.ri;
            else eri = Math.max(eri, ecell.ri);
        }

        if(this.allData.printArea){
            let printArea=this.allData.printArea.split(':');
            sci=this.data.map[printArea[0]].ci;
            sri=this.data.map[printArea[0]].ri;
            eci=this.data.map[printArea[1]].ci;
            eri=this.data.map[printArea[1]].ri;
        }

        let extraColLen=0,extraRowLen=0;
        //根据该区域内的数据源内容拓展显示区域
        for (let extra of this.extraLen){
            eci=Math.max(eci,extra.col);
            eri=Math.max(eri,extra.row);
        }

        this.printAreaIndex={
            sci,sri,eci,eri
        }


        let newMap = {}, rowSize = 0, colSize = 0;

        if (eci !== null && sci !== null) {
            for (let i = sci; i <= eci; i++) {
                for (let j = sri; j <= eri; j++) {
                    let c = this.data.map[this.data.col[i].name + this.data.row[j].name];
                    newMap[c.name] = c;
                }
            }

            colSize = this.data.col[eci].left + this.data.col[eci].size;
            rowSize = this.data.row[eri].top + this.data.row[eri].size;

            this.newMap = newMap;
            this.offsetLeft = this.data.col[sci].left;
            this.offsetTop = this.data.row[sri].top;
            this.width = colSize-this.offsetLeft;
            this.height = rowSize-this.offsetTop;
        } else {
            this.width = 200;
            this.height = 400;
            this.newMap = newMap;
            this.offsetLeft = 0;
            this.offsetTop = 0;
        }

    }

    resize(width, height) {
        this.draw.resize(width, height);
    }

    render() {
        const {data, width, height, newMap, offsetLeft, offsetTop} = this;
        this.draw.resize(width, height);
        this.clear();
        this.draw.scale(1, 1);
        this.draw.fillBg();

        renderContent.call(this, newMap, offsetLeft, offsetTop, 1);

        renderImage.call(this, data.uploadData.images, offsetLeft, offsetTop);
    }

    clear() {
        this.draw.clear();
    }
}

function getCellByCoordinate(x, y) {
    const {data} = this;
    let result = null;
    for (let i in data.map) {
        let cell = data.map[i];
        if (x >= cell.data.cellStyle.left
            && x <= (cell.data.cellStyle.left + cell.data.cellStyle.width)
            && y >= cell.data.cellStyle.top
            && y <= (cell.data.cellStyle.top + cell.data.cellStyle.height)) {
            result = cell;
            return result;
        }
    }

    return result;
}

function renderImage(images, x, y) {
    const {draw} = this;
    draw.save();
    draw.translate(-x, -y);

    for (let image of images) {
        draw.image(image);
    }

    draw.restore();
}

function renderContent(data, x, y, zoom, type = null) {
    const {draw} = this;
    const d = this.data;
    draw.save();
    draw.translate(-x / zoom, -y / zoom);

    if (type) {
        if (type == 'row') {
            draw.ctx.rect(0, 0, npx(d.freezeRow.size), 999999);
        } else if (type == 'col') {
            draw.ctx.rect(0, 0, 999999, npx(d.freezeCol.size));
        } else if (type == 'fixed') {
            draw.ctx.rect(0, 0, npx(d.freezeRow.size), npx(d.freezeCol.size));
        }

        draw.clip();
    }

    let cells = sort(data);
    //渲染单元格
    for (let i in cells) {
        if(cells[i].data.valid)
            if(cells[i].data.merge)
                renderCell.call(this,draw,cells[i],{left:cells[i].data.cellStyle.left,width:cells[i].data.cellStyle.width,top:cells[i].data.cellStyle.top,height:cells[i].data.cellStyle.height});
            else renderCell.call(this,draw,cells[i],{left:d.col[cells[i].ci].left,width:d.col[cells[i].ci].size,top:d.row[cells[i].ri].top,height:d.row[cells[i].ri].size});


    }

    draw.restore();
}

function renderCell(draw, data,style) {
    const dbox = getDrawBox(style);
    const d=this.data;
    let borders = {};
    if (data.data.cellStyle.borderTopWidth > 0) {
        borders.top = {
            width: data.data.cellStyle.borderTopWidth,
            color: data.data.cellStyle.borderTopColor,
        }
    }
    if (data.data.cellStyle.borderLeftWidth > 0) {
        borders.left = {
            width: data.data.cellStyle.borderLeftWidth,
            color: data.data.cellStyle.borderLeftColor,
        }
    }
    if (data.data.cellStyle.borderRightWidth > 0) {
        borders.right = {
            width: data.data.cellStyle.borderRightWidth,
            color: data.data.cellStyle.borderRightColor,
        }
    }
    if (data.data.cellStyle.borderBottomWidth > 0) {
        borders.bottom = {
            width: data.data.cellStyle.borderBottomWidth,
            color: data.data.cellStyle.borderBottomColor,
        }
    }

    dbox.setBorders(borders);
    dbox.bgcolor = data.data.cellStyle.backgroundColor;
    let cellText = calcCellContent.call(this, data.data.content);
    let s={
        align: data.data.cellStyle.textAlign,
        valign: data.data.cellStyle.verticalAlign,
        font:data.data.cellStyle.font,
        fontSize:data.data.cellStyle.fontSize,
        color:'#'+data.data.cellStyle.color,
        underline: data.data.cellStyle.underline,
        bold: data.data.cellStyle.bold,
        italic: data.data.cellStyle.italic,
    }


    if(cellText!==''){
        if(!d.requirements)
            d.requirements=[];
        for (let r of d.requirements){
            let words=r.requirementArea.split(':');
            if(d.map[words[0]]&&d.map[words[1]]
                &&data.ri>=d.map[words[0]].ri&&data.ri<=d.map[words[1]].ri
                &&data.ci>=d.map[words[0]].ci&&data.ci<=d.map[words[1]].ci){

                let flag=true;
                for(let require of r.require){
                    let param2=calcCellContent.call(this,require.value);
                    let rs=executeFormula(require.require,cellText,param2)
                    if(!rs){
                        flag=false;
                    }
                }

                if(flag){
                    dbox.bgcolor=r.style.backgroundColor;
                    s.color='#'+r.style.color;
                    s.bold=r.style.bold;
                    s.italic=r.style.italic;
                    s.underline=r.style.underline;
                }
            }
        }
    }

    draw.rect(dbox, () => {
        draw.text(cellText, dbox,s);
    });
    draw.strokeBorders(dbox);
}

function getDrawBox(data) {
    const {
        left, top, width, height,
    } = data;
    return new DrawBox(left, top, width, height, 5);
}

function sort(data) {
    let cells = [];
    for (let i in data) {
        cells.push(data[i]);
    }

    cells.sort((a, b) => {
        return a.data.cellStyle.zIndex - b.data.cellStyle.zIndex;
    });

    return cells;
}

function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

//draw
function dpr() {
    return window.devicePixelRatio || 1;
}

function thinLineWidth() {
    return dpr() - 0.5;
}

export function npx(px) {
    return parseInt(px * dpr(), 10);
}

export function npxLine(px) {
    const n = npx(px);
    return n > 0 ? n - 0.5 : 0.5;
}

class DrawBox {
    constructor(x, y, w, h, padding = 0) {
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
        this.padding = padding;
        this.bgcolor = '#ffffff';
        // border: {width, color}
        this.borderTop = null;
        this.borderRight = null;
        this.borderBottom = null;
        this.borderLeft = null;
    }

    setBorders({top, bottom, left, right,}) {
        if (top) this.borderTop = top;
        if (right) this.borderRight = right;
        if (bottom) this.borderBottom = bottom;
        if (left) this.borderLeft = left;
    }

    topxys() {
        const {x, y, width} = this;
        return [[x, y], [x + width, y]];
    }

    rightxys() {
        const {
            x, y, width, height,
        } = this;
        return [[x + width, y], [x + width, y + height]];
    }

    bottomxys() {
        const {
            x, y, width, height,
        } = this;
        return [[x, y + height], [x + width, y + height]];
    }

    leftxys() {
        const {
            x, y, height,
        } = this;
        return [[x, y], [x, y + height]];
    }

    innerWidth() {
        return this.width - (this.padding * 2) - 2;
    }

    innerHeight() {
        return this.height - (this.padding * 2) - 2;
    }

    textx(align) {
        const { width,padding } = this;
        let { x } = this;
        if (align === 'left') {
            x += padding;
        } else if (align === 'center') {
            x += width / 2;
        } else if (align === 'right') {
            x += width - padding;
        }
        return x;
    }

    texty(align, h, fontSize) {
        const { height, padding } = this;
        let { y } = this;
        if (align === 'start') {
            y += padding + (fontSize - 4);
        } else if (align === 'center') {
            y += height / 2 - h / 2 + 3;
        } else if (align === 'end') {
            y += height - padding - h;
        }
        return y;
    }
}

function drawFontLine(type, tx, ty, align, valign, blheight, blwidth) {
    let floffset = {x: 0, y: 0};
    // if (type === 'underline') {
    //     if (valign === 'end') {
    //         floffset.y = 0;
    //     } else if (valign === 'start') {
    //         floffset.y = -(blheight + 2);
    //     } else {
    //         floffset.y = -blheight / 2;
    //     }
    // }

    if (align === 'center') {
        floffset.x = blwidth / 2;
    } else if (align === 'right') {
        floffset.x = blwidth;
    }

    this.line(
        [tx - floffset.x, ty + 2],
        [tx - floffset.x + blwidth, ty + 2],
    );
}

class Draw {
    constructor(el, width, height, zoom) {
        this.el = el;
        this.ctx = el.getContext('2d');
        this.resize(width, height);
        this.ctx.scale(zoom / dpr(), zoom / dpr());
    }

    resize(width, height) {
        this.el.style.width = `${width}px`;
        this.el.style.height = `${height}px`;
        this.el.width = npx(width);
        this.el.height = npx(height);
    }

    clear() {
        const {width, height} = this.el;
        this.ctx.clearRect(0, 0, width, height);
        return this;
    }

    attr(options) {
        Object.assign(this.ctx, options);
        return this;
    }

    save() {
        this.ctx.save();
        this.ctx.beginPath();
        return this;
    }

    restore() {
        this.ctx.restore();
        return this;
    }

    beginPath() {
        this.ctx.beginPath();
        return this;
    }

    clip() {
        this.ctx.clip();
        return this;
    }

    translate(x, y) {
        this.ctx.translate(npx(x), npx(y));
        return this;
    }

    scale(x, y) {
        this.ctx.scale(x, y);
        return this;
    }

    clearRect(x, y, w, h) {
        this.ctx.clearRect(x, y, w, h);
        return this;
    }

    fillRect(x, y, w, h) {
        this.ctx.fillRect(npx(x) - 0.5, npx(y) - 0.5, npx(w), npx(h));
        return this;
    }

    fillText(text, x, y) {
        this.ctx.fillText(text, npx(x), npx(y));
        return this;
    }

    fillBg(){
        const {ctx} = this;
        const {width, height} = this.el;
        ctx.save();
        ctx.fillStyle = '#ffffff';
        ctx.rect(0, 0, width, height);
        ctx.fill();
        ctx.restore()
        return this;
    }

    border({width, color}) {
        const {ctx} = this;
        ctx.lineWidth = npx(width);
        ctx.strokeStyle = '#' + color;
        return this;
    }

    line(...xys) {
        const {ctx} = this;
        if (xys.length > 1) {
            ctx.beginPath();
            const [x, y] = xys[0];
            ctx.moveTo(npxLine(x), npxLine(y));
            for (let i = 1; i < xys.length; i += 1) {
                const [x1, y1] = xys[i];
                ctx.lineTo(npxLine(x1), npxLine(y1));
            }
            ctx.stroke();
        }
        return this;
    }

    strokeBorders(box) {
        const {ctx} = this;
        ctx.save();

        const {
            borderTop, borderRight, borderBottom, borderLeft,
        } = box;
        if (borderTop) {
            this.border({...borderTop});
            this.line(...box.topxys());
        }
        if (borderRight) {
            this.border({...borderRight});
            this.line(...box.rightxys());
        }
        if (borderBottom) {
            this.border({...borderBottom});
            this.line(...box.bottomxys());
        }
        if (borderLeft) {
            this.border({...borderLeft});
            this.line(...box.leftxys());
        }
        ctx.restore();
    }

    rect(box, dtextcb) {
        const { ctx } = this;
        const {
            x, y, width, height, bgcolor,
        } = box;
        ctx.save();
        ctx.beginPath();
        ctx.rect(npxLine(x), npxLine(y), npx(width), npx(height));
        ctx.clip();
        if(bgcolor!=='none'){
            ctx.fillStyle = '#'+bgcolor || '#fff';
            ctx.fill();
        }
        dtextcb();
        ctx.restore();
    }

    text(mtxt, box, attr = {}, textWrap = false) {
        const { ctx } = this;
        const {
            align, valign, font, color, underline, bold, italic, fontSize
        } = attr;

        const tx = box.textx(align);
        ctx.save();
        ctx.beginPath();

        this.attr({
            textAlign: align,
            textBaseline: valign,
            font: `${italic ? 'italic' : ''} ${bold ? 'bold' : ''} ${npx(fontSize)}px ${font}`,
            fillStyle: color,
            strokeStyle: color,
        });

        const txts = `${mtxt}`.split('\n');
        const biw = box.innerWidth();

        let  ntxts = [];
        txts.forEach((it) => {
            const txtWidth = ctx.measureText(it).width;
            if (textWrap && txtWidth > npx(biw)) {
                let textLine = { w: 0, len: 0, start: 0 };
                for (let i = 0; i < it.length; i += 1) {
                    if (textLine.w >= npx(biw)) {
                        ntxts.push(it.substr(textLine.start, textLine.len));
                        textLine = { w: 0, len: 0, start: i };
                    }
                    textLine.len += 1;
                    textLine.w += ctx.measureText(it[i]).width + 1;
                }
                if (textLine.len > 0) {
                    ntxts.push(it.substr(textLine.start, textLine.len));
                }
            }
            else {
                ntxts.push(it);
            }
        });

        const txtHeight = (ntxts.length - 1) * (fontSize + 2);
        let ty = box.texty(valign, txtHeight,fontSize);
        ntxts.forEach((txt) => {
            const txtWidth = ctx.measureText(txt).width/dpr();
            if(txt){
                // console.log(txtWidth)
            }
            this.fillText(txt, tx, ty);
            if (underline) {
                drawFontLine.call(this, 'underline', tx, ty, align, valign, fontSize, txtWidth);
            }
            ty += fontSize + 2;
        });

        ctx.restore();
        return this;
    }

    image(img) {
        const {ctx} = this;
        ctx.save()
        let image = new Image();
        image.src = img.src;
        image.setAttribute('crossorigin', 'anonymous');
        image.onload = () => {
            // ctx.drawImage(image,img.left,img.top,img.width,img.height);
            ctx.drawImage(image, npxLine(img.left), npxLine(img.top), npx(img.width), npx(img.height));
        }
        ctx.restore();
    }
}


//formula
function calcCellContent(val, times = 0) {
    let result;
    times++;
    if (times > 1000)
        return false;
    if (val!==null&&val!==undefined&&val[0] === '=') {
        try {
            //分割字符串
            let participleArray = participle(val.slice(1));
            //验证表达式是否存在错误
            let validate=validateContent.call(this, participleArray);
            if(!validate)
                throw new Error('contentError')
            //中缀转后缀
            let rpn = infixToPostFix(participleArray);
            let afterTransform = transformParam.call(this, rpn, times);
            if (afterTransform !== false)
                //计算后缀表达式
                result = calcPostfix(afterTransform);
            else return false;
        } catch (e) {
            if(e.message==='functionError'){
                throw new Error('functionError')
            }
            else if(e.message==='contentError'){
                throw new Error('contentError')
            }
            console.log(e);
            result = '#VALUE';
        }
    }
    else if(val!==null&&val!==undefined&&val=='__DATE__'){
        result = '#生成日期';
    }
    else if (val!==null&&val!==undefined&&val[0] === '_') {
        //数据源
        // result = '#数据源-' + val.slice(1);
        let dataset = getDatasetByParam.call(this,val.slice(1));
        if(dataset)
            result = '#数据源-' + (dataset.source.preData.name?dataset.source.preData.name:dataset.param);
        else result=val;
    } else {
        result = val;
    }

    return result;
}

function validateContent(content) {
    let c = [...content]
    if (this.operators.findIndex((o) => o === c[c.length - 1]) !== -1)
        return false;
    return c.every((element, index, array) => {
        if (index === 0) {
            if (this.operators.findIndex((o) => o === element) === -1)
                return true;
            else return false;
        } else {
            let i1 = this.operators.findIndex((o) => o === array[index - 1]),
                i2 = this.operators.findIndex((o) => o === element);
            if (element === '(' && array[index - 1] === '(')
                return true;
            else if (i1 !== -1 && element === '(')
                return true;
            else if (i1 !== -1 && element === ')')
                return false;
            else if (i1 !== -1 && i2 !== -1)
                return false;
            else return true;
        }
    });
}

//通过param获取对应的dataset
function getDatasetByParam(param) {
    return this.datasets.find(d => {
        return d.param == param;
    });
}

function findIndexInCol(col) {
    return this.data.col.findIndex((c) => {
        return col == c.name;
    });
}

function findIndexInRow(row) {
    return this.data.row.findIndex((r) => {
        return row == r.name;
    });
}

function transformParam(rpn, times) {
    let result = [];
    for (let i in rpn.output) {
        if (rpn.output[i].split(':').length == 2) {
            let arr = [];
            for (let name of rpn.output[i].split(':')) {
                if (!this.data.map[name]) {
                    result.push(rpn.output[i]);
                    break;
                } else {
                    arr.push(this.data.map[name]);
                }
            }

            if (arr.length === rpn.output[i].split(':').length) {
                let row1 = arr[0].data.row,
                    col1 = arr[0].data.col,
                    row2 = arr[1].data.row,
                    col2 = arr[1].data.col,
                    values = [];

                for (let i = Math.min(findIndexInCol.call(this, col1), findIndexInCol.call(this, col2)); i <= Math.max(findIndexInCol.call(this, col1), findIndexInCol.call(this, col2)); i++) {
                    for (let j = Math.min(findIndexInRow.call(this, row1), findIndexInRow.call(this, row2)); j <= Math.max(findIndexInRow.call(this, row1), findIndexInRow.call(this, row2)); j++) {
                        let number = calcCellContent.call(this, this.data.map[this.data.col[i].name + this.data.row[j].name].data.content, times);
                        if (number !== false && this.data.map[this.data.col[i].name + this.data.row[j].name].data.valid)
                            values.push(number);
                        else return false;
                    }
                }

                result.push(values);
            }
        } else if (this.data.map[rpn.output[i]]) {
            let number = calcCellContent.call(this, this.data.map[rpn.output[i]].data.content, times);
            if (number !== false)
                result.push(number);
            else return false;
        } else {
            result.push(rpn.output[i]);
        }
    }

    return {
        output: result,
        args: rpn.args,
    };
}

const transformNumber = (num) => {
    let reg = /^-?[0-9]+.?[0-9]*/;
    if (reg.test(Number(num)))
        return Number(num);
    else if (!isNaN(new Date(num))) {
        return new Date(num).valueOf() / 86400000 + 25569;
    } else return 0;
}

const calcPrecision=(num)=>{
    let baseNum;
    try {
        baseNum = num.toString().split(".")[1].length;
    } catch (e) {
        baseNum = 0;
    }

    return baseNum;
}

function toFixed(number, precision) {
    let str = number + ''
    let len = str.length
    let last = str.substring(len - 1, len)
    if (last == '5') {
        last = '6'
        str = str.substring(0, len - 1) + last
        return (str - 0).toFixed(precision)
    } else {
        return number.toFixed(precision)
    }
}

const negative = (a) => {
    return reduce(0 , transformNumber(a));
}

const reverse = (a) => {
    return !transformNumber(a);
}

const add = (a, b) => {
    let precision=Math.max(calcPrecision(a),calcPrecision(b));
    return toFixed(transformNumber(a) + transformNumber(b),precision);
}

const reduce = (a, b) => {
    let precision=Math.max(calcPrecision(a),calcPrecision(b));
    return toFixed(transformNumber(a) - transformNumber(b),precision);
}

const multiply = (a, b) => {
    let precision=Math.max(calcPrecision(a),calcPrecision(b));
    return transformNumber(a).toString().replace(',','') * transformNumber(b).toString().replace(',','') / Math.pow(10, precision);
}

const divide = (a, b) => {
    let precision1=calcPrecision(a),
        precision2=calcPrecision(b),
        baseNum1 = Number(a.toString().replace(".", "")),
        baseNum2 = Number(b.toString().replace(".", ""));
    return (baseNum1 / baseNum2) * Math.pow(10, precision2 - precision1);
}

const equal = (a, b) => {
    let reg = /^[0-9]+.?[0-9]*/, left, right;
    if (reg.test(Number(a)))
        left = Number(a);
    else if (a == 'true' || a == 'TRUE')
        left = true;
    else if (a == 'false' || a == 'FALSE')
        left = false;
    if (reg.test(Number(b)))
        right = Number(b);
    else if (b == 'true' || b == 'TRUE')
        right = true;
    else if (b == 'false' || b == 'FALSE')
        right = false;

    return left == right;
}

const less = (a, b) => {
    let reg = /^[0-9]+.?[0-9]*/, left, right;
    if (reg.test(Number(a)))
        left = Number(a);
    else if (a == 'true' || a == 'TRUE')
        left = true;
    else if (a == 'false' || a == 'FALSE')
        left = false;
    if (reg.test(Number(b)))
        right = Number(b);
    else if (b == 'true' || b == 'TRUE')
        right = true;
    else if (b == 'false' || b == 'FALSE')
        right = false;

    return left < right;
}

const lessEqual = (a, b) => {
    let reg = /^[0-9]+.?[0-9]*/, left, right;
    if (reg.test(Number(a)))
        left = Number(a);
    else if (a == 'true' || a == 'TRUE')
        left = true;
    else if (a == 'false' || a == 'FALSE')
        left = false;
    if (reg.test(Number(b)))
        right = Number(b);
    else if (b == 'true' || b == 'TRUE')
        right = true;
    else if (b == 'false' || b == 'FALSE')
        right = false;

    return left <= right;
}

const greater = (a, b) => {
    let reg = /^[0-9]+.?[0-9]*/, left, right;
    if (reg.test(Number(a)))
        left = Number(a);
    else if (a == 'true' || a == 'TRUE')
        left = true;
    else if (a == 'false' || a == 'FALSE')
        left = false;
    if (reg.test(Number(b)))
        right = Number(b);
    else if (b == 'true' || b == 'TRUE')
        right = true;
    else if (b == 'false' || b == 'FALSE')
        right = false;

    return left > right;
}

const greaterEqual = (a, b) => {
    let reg = /^[0-9]+.?[0-9]*/, left, right;
    if (reg.test(Number(a)))
        left = Number(a);
    else if (a == 'true' || a == 'TRUE')
        left = true;
    else if (a == 'false' || a == 'FALSE')
        left = false;
    if (reg.test(Number(b)))
        right = Number(b);
    else if (b == 'true' || b == 'TRUE')
        right = true;
    else if (b == 'false' || b == 'FALSE')
        right = false;

    return left >= right;
}

const notEqual = (a, b) => {
    let reg = /^[0-9]+.?[0-9]*/, left, right;
    if (reg.test(Number(a)))
        left = Number(a);
    else if (a == 'true' || a == 'TRUE')
        left = true;
    else if (a == 'false' || a == 'FALSE')
        left = false;
    if (reg.test(Number(b)))
        right = Number(b);
    else if (b == 'true' || b == 'TRUE')
        right = true;
    else if (b == 'false' || b == 'FALSE')
        right = false;

    return left != right;
}

const SUM = (num1, num2 = undefined, ...array) => {
    let nums = [], result = 0;
    if (Array.isArray(num1))
        nums = num1;
    else nums.push(num1);

    if (Array.isArray(num2)) {
        nums = [...nums, ...num2];
    } else if (num2)
        nums.push(num2);

    for (let i in array) {
        if (Array.isArray(array[i])) {
            nums = [...nums, ...array[i]];
        } else nums.push(array[i]);
    }

    for (let n of nums) {
        result=add(result,transformNumber(n))
    }

    return result;
}

const AVERAGE = (num1, num2 = undefined, ...array) => {
    let nums = [], result = 0;
    if (Array.isArray(num1))
        nums = num1;
    else nums.push(num1);

    if (Array.isArray(num2)) {
        nums = [...nums, ...num2];
    } else if (num2)
        nums.push(num2);

    for (let i in array) {
        if (Array.isArray(array[i])) {
            nums = [...nums, ...array[i]];
        } else nums.push(array[i]);
    }

    for (let n of nums) {
        result=add(result,transformNumber(n))
    }

    return divide(result,nums.length);
}

const COUNT = (num1, num2 = undefined, ...array) => {
    let nums = [], result = 0;
    let reg = /^[0-9]+.?[0-9]*/;

    if (Array.isArray(num1))
        nums = num1;
    else nums.push(num1);

    if (Array.isArray(num2)) {
        nums = [...nums, ...num2];
    } else if (num2)
        nums.push(num2);

    for (let i in array) {
        if (Array.isArray(array[i])) {
            nums = [...nums, ...array[i]];
        } else nums.push(array[i]);
    }

    for (let n of nums) {
        if (reg.test(Number(n)))
            result++;
    }

    return result;
}

const MAX = (num1, num2 = undefined, ...array) => {
    let nums = [], result = 0;

    if (Array.isArray(num1))
        nums = num1;
    else nums.push(num1);

    if (Array.isArray(num2)) {
        nums = [...nums, ...num2];
    } else if (num2)
        nums.push(num2);

    for (let i in array) {
        if (Array.isArray(array[i])) {
            nums = [...nums, ...array[i]];
        } else nums.push(array[i]);
    }

    result = nums[0];
    for (let n of nums) {
        result = Math.max(result, transformNumber(n));
    }

    return result;
}

const MIN = (num1, num2 = undefined, ...array) => {
    let nums = [], result = 0;

    if (Array.isArray(num1))
        nums = num1;
    else nums.push(num1);

    if (Array.isArray(num2)) {
        nums = [...nums, ...num2];
    } else if (num2)
        nums.push(num2);

    for (let i in array) {
        if (Array.isArray(array[i])) {
            nums = [...nums, ...array[i]];
        } else nums.push(array[i]);
    }

    result = nums[0];
    for (let n of nums) {
        result = Math.min(result, transformNumber(n));
    }

    return result;
}

const CONCAT = (num1, ...array) => {
    let nums = [], result = '';

    if (Array.isArray(num1))
        nums = num1;
    else nums.push(num1);

    for (let i in array) {
        if (Array.isArray(array[i])) {
            nums = [...nums, ...array[i]];
        } else nums.push(array[i]);
    }

    for (let n of nums) {
        if (n.trim() !== '') {
            result += n.toString();
        }
    }

    return result;
}

const IF = (requirement, truePart, falsePart) => {
    if (requirement)
        return truePart.replace(/\'/g, '').replace(/\"/g, '');
    else return falsePart.replace(/\'/g, '').replace(/\"/g, '');
}

const ABS = (num) => {
    return Math.abs(transformNumber(num));
}

const INT = (num) => {
    return Math.floor(transformNumber(num));
}

const MOD = (num1, num2) => {
    return transformNumber(num1) % transformNumber(num2);
}

const ROUND = (num, digits = 0) => {
    let number = transformNumber(num);
    if (digits >= 0) {
        return number.toFixed(digits);
    } else {
        let digitsNum = Math.pow(10, digits);
        return Math.round(number * digitsNum) / digitsNum;
    }
}


const operator = [
    {
        order: 2,
        value: '+',
        func: add,
    },
    {
        order: 2,
        value: '-',
        func: reduce,
    },
    {
        order: 3,
        value: '*',
        func: multiply,
    },
    {
        order: 3,
        value: '/',
        func: divide,
    },
    {
        order: 4,
        value: '^',
        func: negative,
    },
    {
        order: 4,
        value: '!',
        func: reverse,
    },
    {
        order: 1,
        value: '=',
        func: equal,
    },
    {
        order: 1,
        value: '<',
        func: less,
    },
    {
        order: 1,
        value: '<=',
        func: lessEqual,
    },
    {
        order: 1,
        value: '>',
        func: greater,
    },
    {
        order: 1,
        value: '>=',
        func: greaterEqual,
    },
    {
        order: 1,
        value: '<>',
        func: notEqual,
    },
    {
        value: '(',
    },
    {
        value: ')',
    },
    {
        value: ',',
    },
];

const func = [
    {
        name: 'SUM',
        func: SUM,
    },
    {
        name: 'ABS',
        func: ABS,
    },
    {
        name: 'INT',
        func: INT,
    },
    {
        name: 'MOD',
        func: MOD,
    },
    {
        name: 'ROUND',
        func: ROUND,
    },
    {
        name: 'AVERAGE',
        func: AVERAGE,
    },
    {
        name: 'COUNT',
        func: COUNT,
    },
    {
        name: 'MAX',
        func: MAX,
    },
    {
        name: 'MIN',
        func: MIN,
    },
    {
        name: 'CONCAT',
        func: CONCAT,
    },
    {
        name: 'IF',
        func: IF,
    },
];

//分割字符串
const participle = (val) => {
    let part = [];

    let str = "";
    for (let i in val) {
        let index = operator.findIndex((op) => op.value === val[i]);
        if (index === -1) {
            str += val[i];
            if (i == val.length - 1) {
                part.push(str);
            }
        } else {
            if ((val[i] == '<' || val[i] == '>') && str.length === 0) {
                str += val[i];
                continue;
            }

            let w = val[i];
            if (str.length !== 0) {
                if ((str == '<' && (val[i] == '=' || val[i] == '>')) || (str == '>' && val[i] == '=')) {
                    str += val[i];
                    part.push(str);
                    str = "";
                    continue;
                }

                part.push(str);
                str = "";
            } else if (val[i] === '-' && str.length === 0 && (part.length === 0 || part[part.length - 1] !== ')')) {
                w = '^';
            }

            part.push(w);
        }
    }

    return part;
}

//中缀转后缀
const infixToPostFix = (words) => {
    let output = [], op = [], args = [], args_count = [];
    while (words.length) {
        let word = words.shift();
        if (word === '(') {
            op.push(word);
        } else if (word === ')') {
            while (op[op.length - 1] !== '(') {
                output.push(op.pop());
                if (!op.length) {
                    throw 'Error';
                }
            }

            op.pop();
            if (func.findIndex(f => f.name === op[op.length - 1]) !== -1) {
                args[args.length - 1]++;
                args_count.push(args.pop());
                output.push(op.pop());
            }
        } else if (func.findIndex(f => f.name === word) !== -1) {
            op.push(word);
            args.push(0);
        } else if (word === ',') {
            args[args.length - 1]++;
            while (op[op.length - 1] !== '(') {
                output.push(op.pop());
                if (!op.length) {
                    throw 'Error';
                }
            }
        } else if (operator.findIndex(op => op.value === word) !== -1) {
            while (op.length && op[op.length - 1] !== '(' &&
            operator.find((o) => o.value === word).order <= operator.find((o) => o.value === op[op.length - 1]).order) {
                output.push(op.pop());
            }

            op.push(word);
        } else {
            output.push(word);
        }
    }

    while (op.length) {
        if (op[op.length - 1] === '(') {
            throw 'Error';
        }

        output.push(op.pop());
    }


    return {
        output,
        args: args_count,
    }
}

//计算后缀表达式
const calcPostfix = (postfix) => {
    let stack = [];
    while (postfix.output.length) {
        let el = postfix.output.shift(), operationIndex = -1, funcIndex = -1;
        operationIndex = operator.findIndex(op => op.value === el);
        funcIndex = func.findIndex(f => f.name === el);
        if (operationIndex !== -1) {
            let param1, param2;
            if (el === '^') {
                param1 = stack.pop();
                stack.push((operator[operationIndex].func)(param1));
            } else {
                param1 = stack.pop();
                param2 = stack.pop();
                stack.push((operator[operationIndex].func)(param2, param1));
            }
        } else if (funcIndex !== -1) {
            let params = [], num = postfix.args.pop();
            for (let i = 0; i < num; i++) {
                params.unshift(stack.pop());
            }
            stack.push((func[funcIndex].func)(...params));
        } else {
            stack.push(el);
        }
    }

    if (stack[0] instanceof Array) {
        let result = 0;
        for (let i in stack[0]) {
            if (stack[0][i] === '') {
                result += 0
            } else result = add(result,parseFloat(stack[0][i]));
        }
        return result;
    } else return stack[0];
}

const requirement=[
    {
        key:'greater',
        func:greater,
    },
    {
        key:'less',
        func:less,
    },
    {
        key:'equal',
        func:equal,
    },
    {
        key:'notEqual',
        func:notEqual,
    },
    {
        key:'greaterEqual',
        func:greaterEqual,
    },
    {
        key:'lessEqual',
        func:lessEqual,
    },
]

const executeFormula=(formula,param1,param2)=>{
    let index=requirement.findIndex((r)=>r.key==formula)
    if(index!==-1)
        return (requirement[index].func)(param1,param2);
    else return false;
}