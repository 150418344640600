<template>
  <div>
    <template v-for="(o,i) in paramsInfo" v-if="(paramsInfo.value??[]).length!==0">
      <div class="date" v-if="o.length!==0">
        <h4>{{ paramsDataName[i] }}</h4>
        <template v-if="i==='value'">
          <template v-for="(j,ji) in o">
            <div style="margin-left: 5px;margin-bottom: 10px;">
              <p style="margin: 0;padding: 0;height: 30px;line-height: 30px;">{{ paramsDataName[ji] }}</p>
              <el-checkbox-group
                  v-model="paramsData['value']['enkind']"
                  v-if="ji==='enkind'"
                  style="margin-left: 10px;margin-top: 3px;">
                <template v-for="k in j">
                  <div style="height: 20px;line-height: 20px;">
                    <el-checkbox :label="k.val">{{ k.name }}</el-checkbox>
                  </div>
                </template>
              </el-checkbox-group>


              <el-radio-group v-model="paramsData['value']['entype']" v-if="ji==='entype'"
                              style="margin-left: 10px;margin-top: 3px;">
                <template v-for="k in j">
                  <div style="height: 20px;line-height: 20px;">
                    <el-radio :label="k.objid">{{ k.val }}</el-radio>
                  </div>
                </template>
              </el-radio-group>
            </div>
          </template>
        </template>



        <template v-if="i==='date'">
          <div style="margin-top: -10px;">
            <template v-for="(j,ji) in o">
              <div class="month" v-if="ji==='from_month'">
                <el-form-item label="月">
                  <el-select v-model="paramsData.date.from_month" size="mini" placeholder="开始">
                    <el-option
                        v-for="(item,i) in j"
                        :key="i"
                        :disabled="validateDate(item,paramsData.date.to_month,'greater')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <span class="connector">-</span>
                  <el-select v-model="paramsData.date.to_month" size="mini" placeholder="结束">
                    <el-option
                        v-for="(item,i) in j"
                        :key="i"
                        :disabled="validateDate(item,paramsData.date.from_month,'less')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="day" v-if="ji==='from_day'">
                <el-form-item label="日">
                  <el-select v-model="paramsData.date.from_day" size="mini" placeholder="开始">
                    <el-option
                        v-for="(item,i) in j"
                        :key="i"
                        :disabled="validateDate(item,paramsData.date.to_day,'greater')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <span class="connector">-</span>
                  <el-select v-model="paramsData.date.to_day" size="mini" placeholder="结束">
                    <el-option
                        v-for="(item,i) in j"
                        :key="i"
                        :disabled="validateDate(item,paramsData.date.from_day,'less')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="hour" v-if="ji==='from_hour'">
                <el-form-item label="小时">
                  <el-select v-model="paramsData.date.from_hour" placeholder="开始" size="mini">
                    <el-option
                        v-for="(item,hi) in paramsInfo.date.from_hour"
                        :key="hi"
                        :disabled="validateDate(item,paramsData.date.to_hour,'greater')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <span class="connector">-</span>
                  <el-select v-model="paramsData.date.to_hour" placeholder="结束" size="mini">
                    <el-option
                        v-for="(item,hi) in paramsInfo.date.to_hour"
                        :key="hi"
                        :disabled="validateDate(item,paramsData.date.from_hour,'less')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="minute" v-if="ji==='from_minute'">
                <el-form-item label="分钟">
                  <el-select v-model="paramsData.date.from_minute" placeholder="开始" size="mini">
                    <el-option
                        v-for="(item,hi) in paramsInfo.date.from_minute"
                        :key="hi"
                        :disabled="validateDate(item,paramsData.date.to_minute,'greater')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                  <span class="connector">-</span>
                  <el-select v-model="paramsData.date.to_minute" placeholder="结束" size="mini">
                    <el-option
                        v-for="(item,hi) in paramsInfo.date.to_minute"
                        :key="hi"
                        :disabled="validateDate(item,paramsData.date.from_minute,'less')"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>

              <div class="interval" v-if="ji==='interval'">
                <el-form-item label="间隔">
                  <el-select v-model="paramsData.date.interval" size="mini" placeholder="时间">
                    <el-option
                        v-for="(item,i) in (j.find(e=>{return e.unit == paramsData.date.intervalUnit;})??[]).val"
                        :key="i"
                        :label="item.unit"
                        :value="item">
                    </el-option>
                  </el-select>
                  <span class="connector">单位</span>
                  <el-select v-model="paramsData.date.intervalUnit" size="mini" placeholder="单位"
                             @change="defaultUnit">
                    <el-option
                        v-for="(item,i) in j"
                        :key="i"
                        :label="item.unit"
                        :value="item.unit">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </div>
        </template>
      </div>
    </template>
    <template v-if="(paramsInfo.value??[]).length!==0">
      <div>
        <h4>参数排列方向</h4>
        <el-radio-group v-model="paramsData.direction">
          <div style="margin-bottom: 5px;height: 20px;line-height: 20px;">
            <el-radio label="horizontal">横向</el-radio>
          </div>
          <div style="margin-bottom: 5px;height: 20px;line-height: 20px;">
            <el-radio label="vertical">纵向</el-radio>
          </div>
        </el-radio-group>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "NodeParamsInfo",
  props: {
    datatype:{
      default:'',
    },
    paramsInfo: {
      type: Object
    },
    paramsData: {
      type: Object
    },
  },
  data() {
    return {
      paramsDataName: {
        'date': '时间信息',
        'value': '取值类型',
        'enkind': '数值类型',
        'entype': '能源种类'
      },
      test: ''
    }
  },
  methods: {
    rename(number, startText, middleText, endText) {
      if (number < 0) {
        return startText[0] + middleText + (Math.abs(number)) + endText;
      } else {
        return startText[1] + middleText + number + endText;
      }
    },
    transferDate(d) {
      if (d === '-0') return 0;
      return d >= 0 ? (d - -1000) : Math.abs(d);
    },
    validateDate(item, require, type = 'greater') {
      return false;
      if (require === '') {
        return false;
      } else {
        // console.log(item, require, this.transferDate(item), this.transferDate(require))
        if (type == 'greater')
          return this.transferDate(item) > this.transferDate(require);
        else if (type == 'less')
          return this.transferDate(item) < this.transferDate(require);
      }
    },
    defaultUnit(){
      // console.log(this.paramsData.interval)
      this.paramsData.date.interval = this.paramsInfo.date.interval.filter(i => i.unit == this.paramsData.date.intervalUnit)[0].val[0];
    }
  }
}
</script>

<style scoped>

</style>