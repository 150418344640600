<template>
  <div :class="'image-box '"
       :style="{left:data.left*zoom-offsetLeft+'px',
       top:data.top*zoom-offsetTop+'px',
       width:data.width*zoom+'px',
       height:data.height*zoom+'px'}"
       @mousedown="imageMousedown($event)"
  >
    <img :src="data.src" alt="" :style="{width:data.width*zoom+'px',height:data.height*zoom+'px'}">
    <div v-show="data.selecting" class="image-resize-area" :style="{width:data.width*zoom+'px',height:data.height*zoom+'px'}">
      <div class="image-resize left-top" @mousedown.stop="resize('lt',$event)"></div>
      <div class="image-resize left-bottom" @mousedown.stop="resize('lb',$event)"></div>
      <div class="image-resize right-top" @mousedown.stop="resize('rt',$event)"></div>
      <div class="image-resize right-bottom" @mousedown.stop="resize('rb',$event)"></div>
      <div class="image-resize left" @mousedown.stop="resize('l',$event)"></div>
      <div class="image-resize bottom" @mousedown.stop="resize('b',$event)"></div>
      <div class="image-resize top" @mousedown.stop="resize('t',$event)"></div>
      <div class="image-resize right" @mousedown.stop="resize('r',$event)"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InsertImage",
  props:{
    data:Object,
    index:0,
    zoom:{
      default:1,
    },
    offsetLeft:0,
    offsetTop:0,
  },
  data(){
    return{

    }
  },
  methods:{
    resize(type,$event){
      this.$emit('resizeMousedown',{type:type,e:$event,index:this.index});
    },
    imageMousedown(e){
      this.$emit('imageMousedown',{
        index:this.index,
        e:e,
      });
    },

  }
}
</script>

<style scoped>
.image-box{
  width: max-content;
  height: max-content;
  position: absolute;
  z-index:750;
  user-select: none;
}
.image-resize-area{
  border: 1px solid #333;
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  cursor: move;
}
.image-resize{
  position: absolute;
  width: 12px;
  height: 12px;
  border: 1px solid #333;
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  background-color: #fff;
  user-select: none;
}
.image-resize.left-top{
  left:-6px;
  top:-6px;
  cursor: nwse-resize;
}
.image-resize.right-bottom{
  right:-6px;
  bottom:-6px;
  cursor: nwse-resize;
}
.image-resize.left-bottom{
  left:-6px;
  bottom:-6px;
  cursor: nesw-resize;
}
.image-resize.right-top{
  right:-6px;
  top:-6px;
  cursor: nesw-resize;
}
.image-resize.right{
  right:-6px;
  top:0;
  bottom:0;
  margin:auto 0;
  cursor: ew-resize;
}
.image-resize.left{
  left:-6px;
  top:0;
  bottom:0;
  margin:auto 0;
  cursor: ew-resize;
}
.image-resize.top{
  top:-6px;
  left:0;
  right:0;
  margin:0 auto;
  cursor: ns-resize;
}
.image-resize.bottom{
  bottom:-6px;
  left:0;
  right:0;
  margin:0 auto;
  cursor: ns-resize;
}
</style>