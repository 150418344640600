<template>
  <div class="toast-container"
       is="transition-group"
       v-on:after-enter="afterEnter"
       name="toast">
    <div v-for="(toast,k) in $root.toasts" :class="`toast ${toastType(toast.type)}`" :data-key="k" :key="toast.key">
      {{toast.msg}}
    </div>
  </div>
</template>

<script>
export default {
  name: "Toast",
  methods: {
    afterEnter(dom) {
      let index = dom.getAttribute("data-key");
      this.$root.toasts.splice(index, 1);
    },
    toastType(type) {
      let bg = "", text = "";

      ({
        default() {
          bg = "white";
          text = "black";
        },
        primary() {
          bg = "blue";
          text = "white";
        },
        danger() {
          bg = "red";
          text = "white";
        },
        warn() {
          bg = "yellow";
          text = "white";
        },
        info() {
          bg = "green";
          text = "white";
        },
      })[type]?.();

      return `bg-${bg} text-${text}`;
    }
  }

}
</script>

<style scoped>
.toast-enter-active {
  animation: toast-in 1.3s;
}

.toast-leave-active {
  animation: toast-in 1.3s reverse;
}

@keyframes toast-in {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  20% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.toast-container {
  pointer-events: none;
  position: fixed;
  width: 100%;
  top: 110px;
  left: 0;
  opacity: 0.9;
  z-index: 2500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toast {
  position: absolute;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  max-width: 40%;
  word-break: break-all;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 14px;
}
</style>