import {Draw,DrawBox,npx} from '@/core/draw'
import {executeFormula} from "@/core/formula";

function getDrawBox(data) {
    const {
        left, top, width, height,
    } = data;
    return new DrawBox(left, top, width, height,5);
}

export function renderCell(draw, data,style) {
    const dbox=getDrawBox(style);
    const d=this.data;
    dbox.setBorders({
        top:{
            width:data.data.cellStyle.borderTopWidth,
            color:data.data.cellStyle.borderTopColor,
        },
        left:{
            width:data.data.cellStyle.borderLeftWidth,
            color:data.data.cellStyle.borderLeftColor,
        },
        right:{
            width:data.data.cellStyle.borderRightWidth,
            color:data.data.cellStyle.borderRightColor,
        },
        bottom:{
            width:data.data.cellStyle.borderBottomWidth,
            color:data.data.cellStyle.borderBottomColor,
        },
    });
    dbox.bgcolor = data.data.cellStyle.backgroundColor;
    let s={
        align: data.data.cellStyle.textAlign,
        valign: data.data.cellStyle.verticalAlign,
        font:data.data.cellStyle.font,
        fontSize:data.data.cellStyle.fontSize,
        color:'#'+data.data.cellStyle.color,
        underline: data.data.cellStyle.underline,
        bold: data.data.cellStyle.bold,
        italic: data.data.cellStyle.italic,
    }
    let cellText=this.evt.valueFormat(data.name);
    if(cellText!==''){
        if(!d.requirements)
            d.requirements=[];
        for (let r of d.requirements){
            let words=r.requirementArea.split(':');
            if(d.map[words[0]]&&d.map[words[1]]
                &&data.ri>=d.map[words[0]].ri&&data.ri<=d.map[words[1]].ri
                &&data.ci>=d.map[words[0]].ci&&data.ci<=d.map[words[1]].ci){

                let flag=true;
                for(let require of r.require){
                    let param2=this.evt.calcCellContent(require.value);
                    let rs=executeFormula(require.require,cellText,param2)
                    if(!rs){
                        flag=false;
                    }
                }

                if(flag){
                    dbox.bgcolor=r.style.backgroundColor;
                    s.color='#'+r.style.color;
                    s.bold=r.style.bold;
                    s.italic=r.style.italic;
                    s.underline=r.style.underline;
                }
            }
        }
    }


    draw.rect(dbox,()=>{
        draw.text(cellText, dbox, s);
    });
    draw.strokeBorders(dbox);
}

function renderContent(data,x,y,zoom,rows,cols,type=null) {
    const {draw,width,height}=this;
    const d=this.data;
    draw.save();
    draw.translate(-x/zoom, -y/zoom);

    if(type){
        if(type=='row'){
            draw.ctx.rect(0,0,npx(d.freezeRow.size),999999);
        }
        else if(type=='col'){
            draw.ctx.rect(0,0,999999,npx(d.freezeCol.size));
        }
        else if(type=='fixed'){
            draw.ctx.rect(0,0,npx(d.freezeRow.size),npx(d.freezeCol.size));
        }

        draw.clip();
    }

    let cells=sort(data);
    //渲染单元格
    for(let i in cells){
        let cell=cells[i];
        if(cell.data.valid&&cols[cell.ci].left+cols[cell.ci].size>=x/zoom
            &&rows[cell.ri].top+rows[cell.ri].size>=y/zoom
            &&cols[cell.ci].left<=x/zoom+width/zoom
            &&rows[cell.ri].top<=y/zoom+height/zoom){
            if(cell.data.merge)
                renderCell.call(this,draw,cells[i],{left:cell.data.cellStyle.left,width:cell.data.cellStyle.width,top:cell.data.cellStyle.top,height:cell.data.cellStyle.height});
            else renderCell.call(this,draw,cells[i],{left:cols[cell.ci].left,width:cols[cell.ci].size,top:rows[cell.ri].top,height:rows[cell.ri].size});

        }
    }

    draw.restore();
}

function sort(data){
    let cells=[];
    for(let i in data){
        cells.push(data[i]);
    }

    cells.sort((a,b)=>{
        return a.data.cellStyle.zIndex-b.data.cellStyle.zIndex;
    });

    return cells;
}


function renderFreezeHighlightLine(offsetLeft,offsetTop,rowFreezeSize,colFreezeSize){
    const {data,draw} = this;
    draw.save().translate(-offsetLeft,-offsetTop).attr({strokeStyle:'#91ADF0'});

    draw.line([0, colFreezeSize], [999999, colFreezeSize]);
    draw.line([rowFreezeSize, 0], [rowFreezeSize, 999999]);

    draw.restore();
}

class Table{
    constructor(el,data,width,height,evt) {
        this.el = el;
        this.draw = new Draw(el, width, height,data.zoom);
        this.data = data;
        this.width = width;
        this.height = height;
        this.evt = evt;
    }

    resetData(data){
        this.data=data;
        this.render();
    }

    setWH(width,height){
        this.width=width;
        this.height=height;
    }

    render(){
        const {data,width,height,evt}=this;
        this.draw.resize(width, height);
        this.clear();
        this.draw.scale(data.zoom,data.zoom);
        //渲染单元格
        renderContent.call(this,data.map,data.offsetLeft,data.offsetTop,data.zoom,data.row,data.col);

        //渲染冻结时的行单元格
        if(data.freezeRow.index>0){
            renderContent.call(this,evt.freezeRowShow,data.freezeCol.scrollLimit,data.offsetTop,data.zoom,'row');
        }

        //渲染冻结时的列单元格
        if(data.freezeCol.index>0){
            renderContent.call(this,evt.freezeColShow,data.offsetLeft,data.freezeRow.scrollLimit,data.zoom,'col');
        }

        //渲染冻结时的固定单元格
        if(data.freezeCol.index>0&&data.freezeRow.index>0){
            renderContent.call(this,evt.freezeCellShow,data.freezeCol.scrollLimit,data.freezeRow.scrollLimit,data.zoom,'fixed');
        }

        if(data.freezeCol.index>0||data.freezeRow.index>0)
            renderFreezeHighlightLine.call(this,data.freezeCol.scrollLimit,data.freezeRow.scrollLimit,data.freezeRow.size,data.freezeCol.size);
    }

    clear(){
        this.draw.clear();
    }
}

export default Table;