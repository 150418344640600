function dpr() {
    return window.devicePixelRatio || 1;
}

function thinLineWidth() {
    return dpr() - 0.5;
}

function npx(px) {
    return parseInt(px * dpr(), 10);
}

function npxLine(px) {
    const n = npx(px);
    return n > 0 ? n - 0.5 : 0.5;
}

class DrawBox{
    constructor(x,y,w,h,padding=0) {
        this.x = x;
        this.y = y;
        this.width = w;
        this.height = h;
        this.padding = padding;
        this.bgcolor = '#ffffff';
        // border: {width, color}
        this.borderTop = null;
        this.borderRight = null;
        this.borderBottom = null;
        this.borderLeft = null;
    }

    setBorders({top, bottom, left, right,}) {
        if (top) this.borderTop = top;
        if (right) this.borderRight = right;
        if (bottom) this.borderBottom = bottom;
        if (left) this.borderLeft = left;
    }

    topxys() {
        const { x, y, width } = this;
        return [[x, y], [x + width, y]];
    }

    rightxys() {
        const {
            x, y, width, height,
        } = this;
        return [[x + width, y], [x + width, y + height]];
    }

    bottomxys() {
        const {
            x, y, width, height,
        } = this;
        return [[x, y + height], [x + width, y + height]];
    }

    leftxys() {
        const {
            x, y, height,
        } = this;
        return [[x, y], [x, y + height]];
    }

    innerWidth() {
        return this.width - (this.padding * 2) - 2;
    }

    innerHeight() {
        return this.height - (this.padding * 2) - 2;
    }

    textx(align) {
        const { width,padding } = this;
        let { x } = this;
        if (align === 'left') {
            x += padding;
        } else if (align === 'center') {
            x += width / 2;
        } else if (align === 'right') {
            x += width - padding;
        }
        return x;
    }

    texty(align, h, fontSize) {
        const { height, padding } = this;
        let { y } = this;
        if (align === 'start') {
            y += padding + (fontSize - 4);
        } else if (align === 'center') {
            y += height / 2 - h / 2 + 3;
        } else if (align === 'end') {
            y += height - padding - h;
        }
        return y;
    }
}

function drawFontLine(type, tx, ty, align, valign, blheight, blwidth){
    let floffset = { x: 0, y: 0 };
    // if (type === 'underline') {
    //     if (valign === 'end') {
    //         floffset.y = 0;
    //     } else if (valign === 'start') {
    //         floffset.y = -(blheight + 2);
    //     } else {
    //         floffset.y = -blheight / 2;
    //     }
    // }

    if (align === 'center') {
        floffset.x = blwidth / 2;
    } else if (align === 'right') {
        floffset.x = blwidth;
    }

    this.line(
        [tx - floffset.x, ty+2],
        [tx - floffset.x + blwidth, ty+2],
    );
}

class Draw{
    constructor(el, width, height, zoom) {
        this.el = el;
        this.ctx = el.getContext('2d');
        this.resize(width, height);
        this.ctx.scale(zoom/dpr(), zoom/dpr());
    }

    resize(width,height){
        this.el.style.width = `${width}px`;
        this.el.style.height = `${height}px`;
        this.el.width = npx(width);
        this.el.height = npx(height);
    }

    clear() {
        const { width, height } = this.el;
        this.ctx.clearRect(0, 0, width, height);
        return this;
    }

    attr(options) {
        Object.assign(this.ctx, options);
        return this;
    }

    save() {
        this.ctx.save();
        this.ctx.beginPath();
        return this;
    }

    restore() {
        this.ctx.restore();
        return this;
    }

    beginPath() {
        this.ctx.beginPath();
        return this;
    }

    clip() {
        this.ctx.clip();
        return this;
    }

    translate(x, y) {
        this.ctx.translate(npx(x), npx(y));
        return this;
    }

    scale(x, y) {
        this.ctx.scale(x, y);
        return this;
    }

    clearRect(x, y, w, h) {
        this.ctx.clearRect(x, y, w, h);
        return this;
    }

    fillRect(x, y, w, h) {
        this.ctx.fillRect(npx(x) - 0.5, npx(y) - 0.5, npx(w), npx(h));
        return this;
    }

    fillText(text, x, y) {
        this.ctx.fillText(text, npx(x), npx(y));
        return this;
    }

    border({width, color}) {
        const { ctx } = this;
        ctx.lineWidth = npx(width);
        ctx.strokeStyle = '#'+color;
        return this;
    }

    line(...xys) {
        const { ctx } = this;
        if (xys.length > 1) {
            ctx.beginPath();
            const [x, y] = xys[0];
            ctx.moveTo(npxLine(x), npxLine(y));
            for (let i = 1; i < xys.length; i += 1) {
                const [x1, y1] = xys[i];
                ctx.lineTo(npxLine(x1), npxLine(y1));
            }
            ctx.stroke();
        }
        return this;
    }

    strokeBorders(box){
        const { ctx } = this;
        ctx.save();

        const {
            borderTop, borderRight, borderBottom, borderLeft,
        } = box;
        if (borderTop) {
            this.border({...borderTop});
            this.line(...box.topxys());
        }
        if (borderRight) {
            this.border({...borderRight});
            this.line(...box.rightxys());
        }
        if (borderBottom) {
            this.border({...borderBottom});
            this.line(...box.bottomxys());
        }
        if (borderLeft) {
            this.border({...borderLeft});
            this.line(...box.leftxys());
        }
        ctx.restore();
    }

    rect(box, dtextcb) {
        const { ctx } = this;
        const {
            x, y, width, height, bgcolor,
        } = box;
        ctx.save();
        ctx.beginPath();
        ctx.rect(npxLine(x), npxLine(y), npx(width), npx(height));
        ctx.clip();
        if(bgcolor!=='none'){
            ctx.fillStyle = '#'+bgcolor || '#fff';
            ctx.fill();
        }
        dtextcb();
        ctx.restore();
    }

    text(mtxt, box, attr = {}, textWrap = false) {
        const { ctx } = this;
        const {
            align, valign, font, color, underline, bold, italic, fontSize
        } = attr;

        const tx = box.textx(align);
        ctx.save();
        ctx.beginPath();

        this.attr({
            textAlign: align,
            textBaseline: valign,
            font: `${italic ? 'italic' : ''} ${bold ? 'bold' : ''} ${npx(fontSize)}px ${font}`,
            fillStyle: color,
            strokeStyle: color,
        });

        const txts = `${mtxt}`.split('\n');
        const biw = box.innerWidth();

        let  ntxts = [];
        txts.forEach((it) => {
            const txtWidth = ctx.measureText(it).width;
            if (textWrap && txtWidth > npx(biw)) {
                let textLine = { w: 0, len: 0, start: 0 };
                for (let i = 0; i < it.length; i += 1) {
                    if (textLine.w >= npx(biw)) {
                        ntxts.push(it.substr(textLine.start, textLine.len));
                        textLine = { w: 0, len: 0, start: i };
                    }
                    textLine.len += 1;
                    textLine.w += ctx.measureText(it[i]).width + 1;
                }
                if (textLine.len > 0) {
                    ntxts.push(it.substr(textLine.start, textLine.len));
                }
            }
            else {
                ntxts.push(it);
            }
        });

        const txtHeight = (ntxts.length - 1) * (fontSize + 2);
        let ty = box.texty(valign, txtHeight,fontSize);
        ntxts.forEach((txt) => {
            const txtWidth = ctx.measureText(txt).width/dpr();
            if(txt){
                // console.log(txtWidth)
            }
            this.fillText(txt, tx, ty);
            if (underline) {
                drawFontLine.call(this, 'underline', tx, ty, align, valign, fontSize, txtWidth);
            }
            ty += fontSize + 2;
        });

        ctx.restore();
        return this;
    }

    calcTextWidth(txt,style,padding=5){
        const { ctx } = this;
        ctx.save();
        this.attr({
            font: `${style.italic ? 'italic' : ''} ${style.bold ? 'bold' : ''} ${npx(style.fontSize)}px ${style.font}`,
        })
        let width=ctx.measureText(txt).width/dpr()+padding*2;
        ctx.restore();

        return width;
    }
}

export {
    Draw,
    DrawBox,
    thinLineWidth,
    npx,
}