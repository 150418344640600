<template>
  <aside class="sidebar-container">
    <el-menu :default-active="$router.history.current.path" class="el-menu-vertical-demo" :collapse="isCollapse" router>
      <el-menu-item index="/home/template/index" v-if="auth.data.login!=config.admin">
        <i class="el-icon-document"></i>
        <span slot="title">模版管理</span>
      </el-menu-item>
      <el-menu-item index="/home/group" v-if="auth.data.login!=config.admin">
        <i class="el-icon-collection-tag"></i>
        <span slot="title">分组管理</span>
      </el-menu-item>
      <el-menu-item index="/home/setting" v-if="auth.isAdmin()">
        <i class="el-icon-setting"></i>
        <span slot="title">设置</span>
      </el-menu-item>
    </el-menu>
  </aside>
</template>

<script>
import auth from "@/plugins/auth";
import {config} from "@/plugins/config";

export default {
  name: "index",
  data() {
    return {
      isCollapse: true,
      routes: [],
      auth,
      config
    }
  },
  methods: {},
  created() {
  }
}
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}

.el-menu-vertical-demo {
  height: calc(100vh - 60px);
}
</style>